import _ from 'lodash';
import {
  call, put, select, all,
} from 'redux-saga/effects';

import api from '../../services/api';
import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { selectBusinessUsers } from '../../selectors';

export default function* testFacebookConnectionByStores({
  payload: { connectionInfo, onSuccess, onError },
}) {
  try {
    yield put(showToaster(getToasterOptions(
      `Testing the ${connectionInfo?.type} connection...`,
      'info', null, 7000, true, null, 'top', 'center',
    )));

    const operations = connectionInfo.storesInfo.map((st) => call(
      api.getMetaUserPermissionsAndRoles, {
        userId: connectionInfo.userId,
        fbAdAccountId: st.adAccountId,
        fbBusinessManagerId: st.businessId,
        fbPageId: st.pageId,
      },
    ));
    const results = yield all(operations);
    const result = _.every(results, 'hasAllPermissions', true);
    const resultsWithErrors = _.filter(results, { hasAllPermissions: false }).concat({
      campaignStoresInfo: connectionInfo.storesInfo,
    });

    const currentUsers = yield select(selectBusinessUsers);
    const marketingChannelUser = currentUsers.find(
      (user) => user.id === connectionInfo.userId,
    );

    if (onSuccess) { onSuccess(result); }

    const messageType = result ? 'success' : 'error';
    const toasterKey = messageType === 'success'
      ? `test_connection_${connectionInfo?.type}_${messageType}`
      : `test_connection_${connectionInfo?.type}_${messageType}_stores`;
    yield put(showToaster(getToasterOptions(
      toasterKey,
      messageType, null, messageType === 'error' ? 30000 : 7000, true, null, 'top', 'center',
      {
        ...connectionInfo,
        ...(marketingChannelUser && {
          userName: `${marketingChannelUser.givenName} ${marketingChannelUser.familyName}`,
        }),
        ...(resultsWithErrors && messageType === 'error' && {
          error: JSON.stringify(resultsWithErrors),
        }),
      },
    )));
  } catch (error) {
    logger.error({
      error,
      context: { saga: 'testFacebookConnectionByStores' },
      params: { connectionInfo },
    });

    if (onError) { onError(error); }

    yield put(showToaster(getToasterOptions(
      `test_connection_${connectionInfo?.type}_error`,
      'error', null, 7000, true, null, 'top', 'center',
      {
        ...connectionInfo,
        error: JSON.stringify(error, null, 2),
      },
    )));
  }
}
