import { put, call } from 'redux-saga/effects';
import api from '../../services/api';

import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* updateCustomField({ payload }) {
  const { onSave, customField, onError } = payload;

  try {
    yield put(showToaster(getToasterOptions(
      `Updating the ${customField.name} Custom Field...`,
      'info', null, 7000, true, null, 'bottom', 'right',
    )));
    yield call(api.updateCustomField, customField?.id, customField);

    if (onSave) onSave();
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to update the custom field. Try again in a few minutes.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'updateCustomField' },
      params: { payload },
    });
    if (onError) onError(e);
  }
}
