import { fork } from 'redux-saga/effects';
import api from '../../services/api';
import logger from '../../services/logger';

export default function* createActivityLog(payload) {
  try {
    yield fork(
      api.createActivityLog,
      payload,
    );
  } catch (ex) {
    logger.error({
      error: ex,
      context: { saga: 'createActivityLog' },
      params: { payload },
    });
  }
}
