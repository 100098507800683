import {
  call,
  put,
} from 'redux-saga/effects';

import {
  setMarketingChannelsLoading,
  setMarketingChannels,
} from '../../actions';
import api from '../../services/api';
import logger from '../../services/logger';

export default function* getMarketingChannels({ payload: businessId }) {
  try {
    yield put(setMarketingChannelsLoading(true));
    const marketingChannels = yield call(api.getMarketingChannelsByBusinessId, businessId);

    yield put(setMarketingChannels(marketingChannels));
    yield put(setMarketingChannelsLoading(false));
  } catch (e) {
    yield put(setMarketingChannelsLoading(false));
    logger.error({
      error: e,
      context: { saga: 'getMarketingChannels' },
      params: { businessId },
    });
  }
}
