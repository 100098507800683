import _ from 'lodash';
import { mergeCampaignAdItem } from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/campaign/campaign';

import {
  DATA_CAMPAIGN,
  DATA_CAMPAIGN_LOADING,
  DATA_CAMPAIGN_ADS,
  DATA_CAMPAIGN_NAME,
  DATA_CAMPAIGN_GOAL,
  DATA_CAMPAIGN_OBJECTIVE,
  DATA_CAMPAIGN_UPDATE_ADS,
  DATA_CAMPAIGN_PIXEL_EVENT_TYPE,
  DATA_CAMPAIGN_AD_TYPE,
  DATA_CAMPAIGN_OVERRIDE_AUTO_AGING,
  DATA_CAMPAIGN_EVENT_ID,
  DATA_CAMPAIGN_REQUEST_INFO,
  DATA_CAMPAIGN_OPTIMIZATION_GOAL,
} from '../constants';

const defaultState = {
  campaign: null,
  campaignLoading: true,
};

export default {
  defaultState,
  reducers: {
    [DATA_CAMPAIGN]: (state, { payload: campaign }) => ({ ...state, campaign }),
    [DATA_CAMPAIGN_LOADING]: (state, { payload: campaignLoading }) => ({
      ...state,
      campaignLoading,
    }),
    [DATA_CAMPAIGN_ADS]: (state, { payload }) => ({
      ...state,
      campaign: {
        ...state.campaign,
        ads: payload,
      },
    }),
    [DATA_CAMPAIGN_NAME]: (state, { payload: name }) => ({
      ...state,
      campaign: {
        ...state.campaign,
        name,
      },
    }),
    [DATA_CAMPAIGN_GOAL]: (state, { payload: goal }) => ({
      ...state,
      campaign: {
        ...state.campaign,
        goal,
      },
    }),
    [DATA_CAMPAIGN_OBJECTIVE]: (state, { payload }) => ({
      ...state,
      campaign: {
        ...state.campaign,
        objective: payload,
      },
    }),
    [DATA_CAMPAIGN_UPDATE_ADS]: (state, { payload }) => ({
      ...state,
      campaign: {
        ...state.campaign,
        ads: state.campaign?.ads.map((adItem) => mergeCampaignAdItem(adItem, payload)),
      },
    }),
    [DATA_CAMPAIGN_PIXEL_EVENT_TYPE]: (state, { payload }) => ({
      ...state,
      campaign: {
        ...state.campaign,
        pixelEventType: payload,
      },
    }),
    [DATA_CAMPAIGN_OPTIMIZATION_GOAL]: (state, { payload }) => ({
      ...state,
      optimizationGoal: payload,
    }),
    [DATA_CAMPAIGN_AD_TYPE]: (state, { payload }) => ({
      ...state,
      campaign: {
        ...state.campaign,
        ads: state.campaign?.ads.map((adItem) => {
          const ad = _.get(adItem, ['ad']);
          return { ...adItem, ad: { ...ad, type: payload } };
        }),
      },
    }),
    [DATA_CAMPAIGN_OVERRIDE_AUTO_AGING]: (state, { payload }) => ({
      ...state,
      campaign: {
        ...state.campaign,
        overrideAutoAging: payload,
      },
    }),
    [DATA_CAMPAIGN_EVENT_ID]: (state, { payload: eventId }) => ({
      ...state,
      campaign: {
        ...state.campaign,
        eventId,
      },
    }),
    [DATA_CAMPAIGN_REQUEST_INFO]: (state, { payload: requestInfo }) => ({
      ...state,
      campaign: {
        ...state.campaign,
        requestInfo,
      },
    }),
  },
};
