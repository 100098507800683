import _ from 'lodash';
import {
  call, put, select,
} from 'redux-saga/effects';
import { MarketingChannelTypes } from '@targetable/targetable-types/dist/types/marketing-channel/types';
import { getUserFacebookIntegration } from '@targetable/targetable-web-framework/lib/services/user';

import api from '../../services/api';
import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { selectBusinessUsers } from '../../selectors';

const getMarketingApiKey = (marketingChannel) => {
  switch (marketingChannel.type) {
    case MarketingChannelTypes.MailChimp:
      return marketingChannel?.mailchimpApiKey
        ? marketingChannel?.mailchimpApiKey
        : `${marketingChannel?.mailchimpAccessToken}-${marketingChannel?.mailchimpServer}`;
    case MarketingChannelTypes.Google:
      return marketingChannel?.googleClientAccountId;
    default:
      return null;
  }
};

const getMarketingErrorMessage = (error, type) => {
  if (!_.isEmpty(error)) {
    switch (type) {
      case MarketingChannelTypes.MailChimp:
        return _.get(error, ['body']) || error;
      case MarketingChannelTypes.Google:
        return _.get(error, ['details', '0', 'errors']) || error;
      default:
        return error;
    }
  }
  return 'Unknown error';
};

const getMessageTypeByResponse = (result, type) => {
  switch (type) {
    case MarketingChannelTypes.MailChimp:
    case MarketingChannelTypes.Google:
      return result?.success ? 'success' : 'error';
    case MarketingChannelTypes.Facebook:
      return result?.hasAllPermissions ? 'success' : 'error';
    case MarketingChannelTypes.Paperform:
      return result ? 'success' : 'error';
    default:
      return 'error';
  }
};

export default function* testMarketingChannelConnection({
  payload: { marketingChannel, onSuccess, onError },
}) {
  try {
    let marketingChannelUser;

    yield put(showToaster(getToasterOptions(
      `Testing the ${marketingChannel?.type} connection...`,
      'info', null, 7000, true, null, 'top', 'center',
    )));

    let result;
    const marketingChannelData = marketingChannel;
    if (marketingChannel?.type === MarketingChannelTypes.Facebook) {
      if (!marketingChannel?.fbBusinessManagerId) { // Case when test connection in advanced mode
        const user = yield call(api.getUser, marketingChannel?.userId, false);
        const userFbIntegration = getUserFacebookIntegration(user);
        const accessToken = userFbIntegration?.accessToken;
        const page = yield call(api.getPagesBySearch, null, marketingChannel.fbPageId, accessToken);
        const fbBusinessId = page?.business?.id;
        marketingChannelData.fbBusinessManagerId = fbBusinessId;
      }
      result = yield call(api.getMetaUserPermissionsAndRoles, marketingChannelData);
      const currentUsers = yield select(selectBusinessUsers);
      marketingChannelUser = currentUsers.find(
        (user) => user.id === marketingChannel.userId,
      );
    } else if (marketingChannel?.type === MarketingChannelTypes.Paperform) {
      result = yield call(
        api.getPaperformsByMarketingChannelId,
        marketingChannel?.id,
      );
    } else {
      result = yield call(
        api.testConnectionStatus,
        marketingChannel.type,
        getMarketingApiKey(marketingChannel),
        marketingChannel?.isTestAccount,
      );
    }

    if (onSuccess) { onSuccess(result); }

    const messageType = getMessageTypeByResponse(result, marketingChannel?.type);
    yield put(showToaster(getToasterOptions(
      `test_connection_${marketingChannel?.type}_${messageType}`,
      messageType, null, messageType === 'error' ? 30000 : 7000, true, null, 'top', 'center',
      {
        ...marketingChannel,
        ...(marketingChannelUser && {
          userName: `${marketingChannelUser.givenName} ${marketingChannelUser.familyName}`,
        }),
        ...(result && messageType === 'error' && {
          error: JSON.stringify(
            getMarketingErrorMessage(result?.error || result, marketingChannel?.type), null, 2,
          ),
        }),
        ...(!marketingChannel?.mailchimpApiKey && marketingChannel?.type === MarketingChannelTypes.MailChimp && { mailchimpApiKey: 'OAuth' }),
        ...(marketingChannel?.type === MarketingChannelTypes.Paperform
          && { paperformSpaceId: marketingChannel?.paperform?.spaceId }),
      },
    )));
  } catch (error) {
    logger.error({
      error,
      context: { saga: 'testMarketingChannelConnection' },
      params: { marketingChannel },
    });

    if (onError) { onError(error); }

    yield put(showToaster(getToasterOptions(
      `test_connection_${marketingChannel?.type}_error`,
      'error', null, 7000, true, null, 'top', 'center',
      {
        ...marketingChannel,
        error: JSON.stringify(error, null, 2),
      },
    )));
  }
}
