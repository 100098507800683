import { put, call } from 'redux-saga/effects';
import i18n from '../../services/i18n';

import api from '../../services/api';
import {
  showToaster,
  setLoadingAsync,
  setUserEmail,
} from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* updateUserEmail({ payload: { userId, email } }) {
  try {
    yield put(setLoadingAsync(true));
    yield call(api.updateUser, userId, { email });
    yield put(setLoadingAsync(false));
    yield put(setUserEmail({ userId, email }));
    yield put(showToaster(getToasterOptions(i18n.t('update_user_email_success'), 'success', null, 5000)));
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to update user email', 'error')));
    logger.error({
      error: e,
      context: { saga: 'updateUserEmail' },
      params: { userId, email },
    });
  }
}
