import { call, put } from 'redux-saga/effects';

import api from '../../services/api';

import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import getMarketingChannels from '../getMarketingChannels/getMarketingChannels';

export default function* saveMarketingChannel({ payload }) {
  const {
    marketingChannelData,
    onError,
    onDone,
  } = payload;

  try {
    yield call(
      api.createMarketingChannel,
      marketingChannelData,
    );
    yield call(getMarketingChannels, { payload: marketingChannelData.businessId });
    yield put(showToaster(getToasterOptions('Marketing Channel added successfully', 'success', null, 5000)));
    if (onDone) onDone();
  } catch (e) {
    if (onError) onError();
    yield put(showToaster(getToasterOptions('Failed saving marketing channel data. Try again in a few minutes.', 'error', null, 5000)));

    logger.error({
      error: e,
      context: { saga: 'saveUrlPresets' },
      params: {
        marketingChannelData,
      },
    });
  }
}
