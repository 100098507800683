/* eslint-disable no-unused-expressions */
import { put, call } from 'redux-saga/effects';

import i18n from '../../services/i18n';
import api from '../../services/api';
import {
  showToaster,
  setLoadingAsync,
} from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import getStoresByBusiness from '../getStoresByBusiness/getStoresByBusiness';

/**
 * Updates a store pixel
 * @param {object} props
 * @param {object} props.payload
 * @param {string} props.payload.storeName
 * @param {string} props.payload.storeId
 * @param {string} props.payload.pixelId
 * @param {string} props.payload.verifiedDomain
 * @param {function} props.payload.[onCompleted]
 * @param {function} props.payload.[onError]
 * @param {string} props.payload.businessId
 */
export default function* updateStorePixel({ payload }) {
  const {
    storeName, storeId, pixelId, verifiedDomain, businessId,
    onCompleted, onError,
  } = payload;
  try {
    yield put(showToaster(getToasterOptions(
      'Updating store pixel...', 'info', null, 7000, true, null, 'bottom', 'right',
    )));
    yield put(setLoadingAsync(true));
    if (verifiedDomain && pixelId) {
      // The validation endpoint requires the protocol or it will fail.
      const domainWithProtocol = verifiedDomain?.startsWith('http') ? verifiedDomain : `https://${verifiedDomain}`;
      const siteExists = yield call(api.siteExists, domainWithProtocol);
      if (!siteExists) {
        yield put(setLoadingAsync(false));
        onError && onError();
        return;
      }
    }

    yield call(api.updateStorePixel, storeId, pixelId, verifiedDomain);
    yield put(showToaster(getToasterOptions(i18n.t('update_store_pixel_success', { storeName }), 'success', null, 5000)));
    yield call(getStoresByBusiness, { payload: businessId });
    onCompleted && onCompleted(true);
  } catch (e) {
    yield put(showToaster(getToasterOptions(i18n.t('update_store_pixel_error'), 'error')));
    logger.error({
      error: e,
      context: { saga: 'updateStorePixel' },
      params: { payload },
    });
    onCompleted && onCompleted(false);
  }
  yield put(setLoadingAsync(false));
}
