import moment from 'moment';
import { call } from 'redux-saga/effects';
import { MarketingChannelTypes } from '@targetable/targetable-types/dist/types/marketing-channel/types';

import api from '../../services/api';

export default function* saveSuggestedCampaign(suggestedCampaign) {
  if (suggestedCampaign.type === MarketingChannelTypes.MailChimp) {
    return yield call(
      api.updateAdDraft,
      suggestedCampaign.id,
      suggestedCampaign,
    );
  }
  if (suggestedCampaign.id) {
    return yield call(
      api.updateSuggestedCampaign,
      suggestedCampaign.id,
      suggestedCampaign,
    );
  }
  return yield call(
    api.createSuggestedCampaign,
    { ...suggestedCampaign, created: moment.utc().valueOf(), saved: moment.utc().valueOf() },
  );
}
