import { put, call } from 'redux-saga/effects';
import i18n from '../../services/i18n';
import api from '../../services/api';
import { showToaster, setLoadingAsync } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* createMarketingEvent({
  payload: {
    data, onDone,
  },
}) {
  try {
    yield put(setLoadingAsync(true));
    yield call(
      api.createMarketingEvent,
      data,
    );
    yield put(showToaster(getToasterOptions(i18n.t('create_marketing_event_status', 'Marketing event were created successfully!'), 'success', null, 5000)));
    yield put(setLoadingAsync(false));
    if (onDone) {
      onDone();
    }
  } catch (e) {
    yield put(showToaster(getToasterOptions(i18n.t('create_marketing_event_status_error', 'An error occurred when creating the Marketing event'), 'error')));
    yield put(setLoadingAsync(false));
    logger.error({
      error: e,
      context: { saga: 'createMarketingEvent' },
      params: {
        data,
      },
    });
  }
}
