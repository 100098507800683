import {
  REPORTS_LOADING,
  DATA_CAMPAIGN_REPORT,
  DATA_CAMPAIGN_REPORTS,
  DATA_STORE_DATE_REPORTS,
} from '../constants';

export default {
  defaultState: {
    campaignReports: {},
    storeDateReports: [],
    loading: false,
  },
  reducers: {
    [REPORTS_LOADING]: (state, { payload }) => ({ ...state, loading: payload }),
    [DATA_CAMPAIGN_REPORT]: (state, { payload }) => ({
      ...state, campaignReports: { [payload.id]: payload },
    }),
    [DATA_CAMPAIGN_REPORTS]: (state, { payload }) => ({ ...state, campaignReports: payload }),
    [DATA_STORE_DATE_REPORTS]: (state, { payload }) => ({ ...state, storeDateReports: payload }),
  },
};
