import { put, call } from 'redux-saga/effects';
import api from '../../services/api';
import { showToaster, setLoadingAsync } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* importMarketingGoogleSearchAd({ payload }) {
  const { onSave, data, onError } = payload;

  try {
    yield put(showToaster(getToasterOptions(
      'Importing the Google Search Ad into Targetable...',
      'info', null, 7000, true, null, 'bottom', 'right',
    )));

    yield put(setLoadingAsync(true));
    const adDraft = yield call(api.importGoogleSearchAd, data);

    // At this point, the creation was successfull
    if (onSave) { onSave(adDraft); }

    yield put(setLoadingAsync(false));
    yield put(showToaster(getToasterOptions(
      'The Google Search Ad was successfully imported...',
      'success', null, 3000, true,
    )));
  } catch (e) {
    yield put(showToaster(getToasterOptions(e?.response?.data.message || e?.message?.data || 'Failed to import the Google Search Ad. Try again in a few minutes.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'importMarketingGoogleSearchAd' },
      params: { payload },
    });
    if (onError) {
      onError();
    }
  }
}
