import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Pagination from '@material-ui/lab/Pagination';

import DrawerContent from '@targetable/targetable-web-framework/lib/react/containers/DrawerContent/DrawerContent';
import PageContentContainer from '@targetable/targetable-web-framework/lib/react/containers/PageContentContainer/PageContentContainer';
import ComponentLoading from '@targetable/targetable-web-framework/lib/react/components/ComponentLoading/ComponentLoading';
import SelectMultiTags from '@targetable/targetable-web-framework/lib/react/components/SelectMultiTags/SelectMultiTags';
import MarketingDatePicker from '@targetable/targetable-web-framework/lib/react/components/MarketingDatePicker/MarketingDatePicker';
import TextField from '@targetable/targetable-web-framework/lib/react/components/TextField/TextField';
import { ActivityLogType } from '@targetable/targetable-types/dist/types/activity-log/types';
import DetailDialog from './DetailDialog';

import { selectBusinessLoading } from '../../../selectors';
import dateService from '../../../services/date';
import api from '../../../services/api';
import logger from '../../../services/logger';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: '100%',
    margin: `${theme.spacing(2)}px auto`,
    '& .MuiCardActionArea-root:hover': {
      backgroundColor: '#E4E6EC',
    },
  },
  buttonLeft: {
    color: '#000',
    backgroundColor: theme.palette.secondary.contrastText,
    position: 'absolute',
    left: theme.spacing(2),
    border: '1px solid #D8D9DF',
  },
  buttonRight: {
    marginRight: theme.spacing(2),
  },
  typesWrapper: {
    marginTop: '10px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#0E1436',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
  },
  noAl: {
    marginTop: 25,
    minWidth: 450,
    textAlign: 'center',
  },
  tableHeader: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#0E1436',
  },
  pagination: {
    width: '100%',
    display: 'flex',
    '& .Mui-selected': {
      backgroundColor: '#007bff',
      color: '#fff',
      border: 'none',
    },
    justifyContent: 'right',
    background: '#FFFFFF',
    padding: '10px',
  },
  paginationWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    background: '#FFFFFF',
  },
  paginationItemsWrapper: {
    margin: '10px 0',
    padding: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemsSelectedWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '15px',
  },
  limitPerPageOptions: {
    margin: '0 15px 0 0 !important',
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 34px !important',
    },
  },
  itemDate: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    marginTop: 10,
    marginBottom: 5,
  },
  itemTitle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#007BFF',
    cursor: 'pointer',
  },
  activityLogsWrapper: {
    margin: '0 10px 10px 10px',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
  },
  container: {
    marginTop: 10,
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
}));

const FiltersDialog = ({
  onClose,
  setFiltersApplied,
  setFilters,
  filters,
  open,
  businessInfo,
}) => {
  const classes = useStyles();
  const businessLoading = useSelector(selectBusinessLoading) || false;
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [fromDateSelected, setFromDateSelected] = useState(null);
  const [toDateSelected, setToDateSelected] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [activityLogs, setActivityLogs] = React.useState(null);
  const [filtersDialog, setFiltersDialog] = useState(null);
  const [loadingActivityLogs, setLoadingActivityLogs] = React.useState(false);
  const [limitPerPage, setLimitPerPage] = React.useState(20);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [openDetailDialog, setOpenDetailDialog] = React.useState(false);
  const [activityLogSelected, setActivityLogSelected] = React.useState(null);

  const dialogTitle = businessInfo ? `${businessInfo.name} / Activity Logs` : 'Filters';

  useEffect(() => {
    if (!openDrawer) {
      setOpenDrawer(true);
    }
  }, [openDrawer]);

  const handleDates = (current, scope) => {
    if (scope === 'from') {
      if (_.isEmpty(toDateSelected)) {
        setToDateSelected(moment(current).add(1, 'day').format('YYYY-MM-DD'));
      }
      setFromDateSelected(current.format('YYYY-MM-DD'));
      if (!_.isEmpty(toDateSelected) && moment(current).isAfter(toDateSelected)) {
        setToDateSelected(moment(current).add(1, 'day').format('YYYY-MM-DD'));
      }
    }
    if (scope === 'to') {
      if (_.isEmpty(fromDateSelected)) {
        setFromDateSelected(moment(current).subtract(1, 'day').format('YYYY-MM-DD'));
      }
      setToDateSelected(current.format('YYYY-MM-DD'));
      if (!_.isEmpty(fromDateSelected) && moment(current).isBefore(fromDateSelected)) {
        setFromDateSelected(moment(current).subtract(1, 'day').format('YYYY-MM-DD'));
      }
    }
  };

  const handleClear = useCallback(() => {
    setSelectedTypes([]);
    setFromDateSelected(null);
    setToDateSelected(null);
    if (!businessInfo) {
      setFiltersApplied(false);
      setFilters(null);
    } else {
      setFiltersDialog(null);
    }
  }, [
    setSelectedTypes,
    setFromDateSelected,
    setToDateSelected,
    setFiltersApplied,
    setFilters,
    businessInfo,
  ]);

  const applyButtonDisabled = useCallback(() => {
    let disabled = true;
    const currentFilters = businessInfo ? filtersDialog : filters;
    if (_.isNil(currentFilters) && (
      !_.isEmpty(selectedTypes) || !_.isEmpty(fromDateSelected) || !_.isEmpty(toDateSelected)
    )) {
      disabled = false;
    }
    if (_.isObject(currentFilters)) {
      disabled = _.isEqual(currentFilters, { selectedTypes, fromDateSelected, toDateSelected });
    }
    return disabled;
  }, [
    selectedTypes,
    fromDateSelected,
    toDateSelected,
    filters,
    filtersDialog,
    businessInfo,
  ]);

  const handleApply = useCallback(() => {
    if (!businessInfo) {
      if (!_.isEmpty(selectedTypes) || !_.isEmpty(fromDateSelected) || !_.isEmpty(toDateSelected)) {
        setFiltersApplied(true);
      }
      if (_.isEmpty(selectedTypes) && _.isEmpty(fromDateSelected) && _.isEmpty(toDateSelected)) {
        setFiltersApplied(false);
      }
    }
    if (businessInfo) {
      setFiltersDialog({
        selectedTypes,
        fromDateSelected,
        toDateSelected,
      });
      setCurrentPage(1);
    } else {
      setFilters({
        selectedTypes,
        fromDateSelected,
        toDateSelected,
      });
      onClose();
    }
  }, [
    onClose,
    setFiltersApplied,
    setFilters,
    setFiltersDialog,
    selectedTypes,
    fromDateSelected,
    toDateSelected,
    businessInfo,
  ]);

  const buttons = useMemo(() => [
    {
      text: 'clear',
      classes: classes.buttonLeft,
      variant: 'outlined',
      color: 'primary',
      disabled: _.isEmpty(fromDateSelected)
        && _.isEmpty(toDateSelected)
        && _.isEmpty(selectedTypes),
      onClick: handleClear,
    },
    {
      text: 'apply',
      classes: classes.buttonRight,
      variant: 'contained',
      color: 'secondary',
      disabled: applyButtonDisabled(),
      onClick: handleApply,
    },
  ], [
    handleApply,
    handleClear,
    classes,
    fromDateSelected,
    toDateSelected,
    selectedTypes,
    applyButtonDisabled,
  ]);

  const onItemClicked = useCallback((activityLog) => {
    setOpenDetailDialog(true);
    setActivityLogSelected(activityLog);
  }, [setOpenDetailDialog]);

  const showCurrentPage = useCallback((pageIndex, list, changeCurrentPage = true) => {
    if (changeCurrentPage) {
      setCurrentPage(pageIndex + 1);
    }
  }, []);

  const goToPage = (event, page) => {
    showCurrentPage(page - 1, activityLogs);
  };

  const handleChangeLimitPerPage = (e) => {
    setLimitPerPage(e.target.value);
    setCurrentPage(1);
  };

  const orderLogs = (logs) => {
    const mappedLogs = logs.map((log) => ({
      ...log,
      sortKey: moment(log?.created || 1706051000),
    }));
    return _.orderBy(mappedLogs, ['sortKey'], ['desc']).map(({ sortKey, ...rest }) => rest);
  };

  const activityLogsQuery = useCallback(async (params) => {
    if (params?.businessId) {
      try {
        setLoadingActivityLogs(true);
        const res = await api.getAllActivityLogs(params);
        setActivityLogs(orderLogs(res));
        setLoadingActivityLogs(false);
        return res;
      } catch (e) {
        logger.error({
          error: e,
          context: { activityLogsTab: 'getAllActivityLogs' },
          params,
        });
      }
      setLoadingActivityLogs(false);
    }
    return [];
  }, []);

  const loadActivityLogs = useCallback(async () => {
    const params = {
      businessId: businessInfo?.id,
    };
    if (filtersDialog?.selectedTypes && !_.isEmpty(filtersDialog?.selectedTypes)) {
      params.types = filtersDialog?.selectedTypes.join(',').trim();
    }
    if (filtersDialog?.fromDateSelected && filtersDialog?.toDateSelected) {
      params.dateFrom = filtersDialog?.fromDateSelected;
      params.dateTo = filtersDialog?.toDateSelected;
    }
    return activityLogsQuery(params);
  }, [businessInfo, filtersDialog, activityLogsQuery]);

  useEffect(() => {
    if (_.isNil(activityLogs) && !_.isEmpty(businessInfo)) {
      loadActivityLogs();
    }
  }, [activityLogs, loadActivityLogs, businessInfo]);

  useEffect(() => {
    if (!_.isEmpty(businessInfo)) {
      loadActivityLogs();
    }
  }, [filtersDialog, businessInfo, loadActivityLogs]);

  return (
    <>
      <DrawerContent
        open={open ?? openDrawer}
        onClose={onClose}
        title={dialogTitle}
        buttons={buttons}
        invisibleBackdrop
      >
        <PageContentContainer className={classes.pageContainer}>
          <>
            <ComponentLoading
              loading={businessLoading}
              waitKey="loading_business"
            >
              <Grid
                container
                spacing={2}
                justifyContent="center"
                data-cy="filtersCampaignDialog_container"
              >
                {businessInfo && <Grid className={classes.title}>Activity Logs</Grid>}
                <Grid xs={12} className={classes.typesWrapper}>
                  <SelectMultiTags
                    onChange={(items) => setSelectedTypes(items.map((item) => item?.id))}
                    label="Type"
                    menuItems={_.map(ActivityLogType, (type) => type)?.map(
                      (key) => ({ id: key, value: _.startCase(key) }),
                    )}
                    selectedItems={selectedTypes}
                    dataCy="filtersActivityLogsDialog_types_dropdown"
                  />
                </Grid>
                <Grid xs={12} container>
                  <Grid item xs={6}>
                    <MarketingDatePicker
                      label="From Date"
                      placeholder="None"
                      onChange={(fromD) => handleDates(fromD, 'from')}
                      value={fromDateSelected ? dateService.formatDateTimeUTC(fromDateSelected, 'YYYY-MM-DD') : null}
                      minDate={dateService.formatDateTimeUTC(moment().subtract(10, 'years').format('YYYY-MM-DD'), 'YYYY-MM-DD')}
                      maxDate={dateService.formatDateTimeUTC(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')}
                      dataCy="filtersActivityLogsDialog_fromDate"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MarketingDatePicker
                      label="To Date"
                      placeholder="None"
                      onChange={(toD) => handleDates(toD, 'to')}
                      value={toDateSelected ? dateService.formatDateTimeUTC(toDateSelected, 'YYYY-MM-DD') : null}
                      minDate={dateService.formatDateTimeUTC(moment().subtract(10, 'years').format('YYYY-MM-DD'), 'YYYY-MM-DD')}
                      maxDate={dateService.formatDateTimeUTC(moment().add(1, 'day').format('YYYY-MM-DD'), 'YYYY-MM-DD')}
                      dataCy="filtersActivityLogsDialog_toDate"
                    />
                  </Grid>
                </Grid>
                {businessInfo && (
                  <div className={classes.container}>
                    <ComponentLoading
                      loading={loadingActivityLogs}
                      waitKey="loading_activity_logs_list"
                    >
                      <div className={classes.activityLogsWrapper}>
                        <TableContainer>
                          {!_.isEmpty(activityLogs) && (
                          <>
                            <Table stickyHeader aria-label="simple table">
                              <TableBody>
                                {activityLogs.slice(
                                  (currentPage - 1) * limitPerPage, currentPage * limitPerPage,
                                ).map((log) => (
                                  <div className={classes.itemCtn} key={log?.id}>
                                    <Typography className={classes.itemDate}>
                                      {dateService.formatDateTimeUTC(log?.created, 'MMM DD, YYYY h:mmA')}
                                    </Typography>
                                    <Typography
                                      className={classes.itemTitle}
                                      onClick={() => onItemClicked(log)}
                                    >
                                      {log?.meta?.title ?? 'N/A'}
                                    </Typography>
                                  </div>
                                ))}
                              </TableBody>
                            </Table>
                          </>
                          )}
                          {_.size(activityLogs) === 0 && (
                            <div className={classes.noAl}>
                              <Typography>No Activity Logs Found</Typography>
                            </div>
                          )}
                        </TableContainer>
                      </div>
                      {!_.isEmpty(activityLogs) && (
                      <div className={classes.paginationWrapper}>
                        <div className={classes.paginationItemsWrapper}>
                          <TextField
                            select
                            value={limitPerPage}
                            onChange={handleChangeLimitPerPage}
                            className={classes.limitPerPageOptions}
                            data-cy="limit_per_page"
                          >
                            <MenuItem
                              key={20}
                              value={20}
                              data-cy="limit_per_page_20_option"
                            >
                              20 Per Page
                            </MenuItem>
                            <MenuItem
                              key={50}
                              value={50}
                              data-cy="limit_per_page_50_option"
                            >
                              50 Per Page
                            </MenuItem>
                            <MenuItem
                              key={100}
                              value={100}
                              data-cy="limit_per_page_100_option"
                            >
                              100 Per Page
                            </MenuItem>
                          </TextField>
                          <Pagination
                            onChange={goToPage}
                            className={classes.stack}
                            count={Math.ceil(activityLogs?.length / limitPerPage)}
                            page={currentPage}
                            variant="outlined"
                            shape="rounded"
                          />
                        </div>
                      </div>
                      )}
                    </ComponentLoading>
                  </div>
                )}
              </Grid>
            </ComponentLoading>
          </>
        </PageContentContainer>
      </DrawerContent>
      <DetailDialog
        open={openDetailDialog}
        onClose={() => {
          setOpenDetailDialog(false);
        }}
        activityLogSelected={activityLogSelected}
      />
    </>
  );
};

FiltersDialog.defaultProps = {
  open: false,
  businessInfo: null,
  setFiltersApplied: null,
  setFilters: null,
  filters: null,
};

FiltersDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  setFiltersApplied: PropTypes.func,
  setFilters: PropTypes.func,
  filters: PropTypes.shape({}),
  businessInfo: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
};

export default FiltersDialog;
