import { call, put, all } from 'redux-saga/effects';
import { SlackTemplateType, ActivityLogType } from '@targetable/targetable-types/dist/types';
import { UserFeatureFlag } from '@targetable/targetable-types/dist/types/user/types';

import api from '../../services/api';
import logger from '../../services/logger';
import i18n from '../../services/i18n';
import { showToaster, closeToaster, setLoadingAsync } from '../../actions';
import { getToasterOptions } from '../helpers';
import env from '../../globals';
import auth from '../../services/auth';
import createActivityLog from '../createActivityLog/createActivityLog';

/**
 * Retrieves emailable users from the given array of users, by validating the
 * user's subscription flag to each type of email
 *
 * @param {Array<Object>} users - An array of user objects.
 * @param {String} subscriptionFlagType - The subscription flag type to be validated
 * @returns {Array<Object>} - An array containing only onboarded users.
 */
const getEmailableUsers = (users, subscriptionFlagType) => {
  /**
   * Filters out users who are not onboarded, or has not subscription to the
   * passed subscription Flag type.
   *
   * @param {Object} user - The user object to be checked.
   * @returns {boolean} - True if the user is onboarded and subscribed, false otherwise.
   */
  const emailableFilter = (user) => user?.onboarded && !user?.deletedOn
    && user?.featureFlags?.[subscriptionFlagType];

  return users.filter(emailableFilter);
};

export default function* sendTransactionalEmail({ payload }) {
  const {
    onError,
    businessId,
    businessName,
    slackTransactionalEmailChannel,
    subject,
    mainMessage,
    users,
    previewUrl,
  } = payload;

  const emailId = env.MANUAL_TRANSACTIONAL_EMAIL_ID;
  const authUser = auth.getUser();

  try {
    yield put(showToaster(getToasterOptions(
      'Sending transactional email...',
      'info', null, 7000, true, null, 'bottom', 'right',
    )));
    yield put(setLoadingAsync(true));
    const emailableUsers = getEmailableUsers(
      users, UserFeatureFlag.EnableContentNotificationEmails,
    );
    const sendEmailsCalls = (
      emailableUsers || []
    ).map((user) => {
      const customProperties = {
        firstname: user?.firstName,
        business_name: businessName,
        subject,
        main_message: mainMessage.replace(/\n/g, '<br>'),
        preview_url: previewUrl,
      };
      const uniqueEmailId = `${user.id}_${emailId}_${user.email}`;
      const data = {
        customProperties,
        emailId,
        businessId,
        userId: uniqueEmailId,
        email: user.email,
      };
      return user.email ? call(api.sendTransactionalEmail, data) : [];
    });

    if (emailableUsers?.length) {
      yield all(sendEmailsCalls);
      yield call(createActivityLog, {
        businessId,
        userId: authUser?.sub,
        type: ActivityLogType.TransactionalEmail,
        meta: {
          subject,
          mainMessage,
          previewUrl,
          businessName,
          users_email: emailableUsers.map((user) => user.email),
        },
        ui: {
          title: 'Manual Transactional Email Sent by Targetable Admin',
        },
        slack: {
          templateType: SlackTemplateType.ManualTransactionalEmailSent,
        },
      });
      yield put(setLoadingAsync(false));
      yield put(closeToaster());
      yield put(showToaster(getToasterOptions(i18n.t('emails_sent', 'Emails sent successfully!'), 'success', null, 5000)));
    } else {
      yield put(setLoadingAsync(false));
      yield put(closeToaster());
      yield put(showToaster(getToasterOptions(i18n.t('not_users_to_be_emailed'), 'warning', null, 15000)));
    }
  } catch (err) {
    logger.error({
      error: err,
      context: { saga: 'sendTransactionalEmail' },
      params: {
        businessId,
        businessName,
        slackTransactionalEmailChannel,
        subject,
        mainMessage,
        previewUrl,
      },
    });
    if (onError) onError();
    yield put(setLoadingAsync(false));
    yield put(showToaster(getToasterOptions('Error when trying to send transactional email', 'error')));
  }
}
