import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectConfirmationDialogInformation } from '../../selectors';
import { resultConfirmationDialog } from '../../actions';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';

export default () => {
  const information = useSelector(selectConfirmationDialogInformation);
  const dispatch = useDispatch();

  return information.isOpen ? (
    <ConfirmationDialog
      cancelText={information.cancelText}
      cancelDataCy={information.cancelDataCy}
      confirmText={information.confirmText}
      confirmDataCy={information.confirmDataCy}
      isOpen
      onClick={(result) => dispatch(resultConfirmationDialog(result))}
      text={information.text}
      textParams={information.textParams}
      title={information.title}
      resultOnClose={information.resultOnClose}
    />
  ) : false;
};
