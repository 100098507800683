import { put, call } from 'redux-saga/effects';

import api from '../../services/api';

import {
  setBusinesses,
  setBusinessesLoading,
} from '../../actions';
import logger from '../../services/logger';

export default function* getBusinessesSaga({ payload: cursor }) {
  yield put(setBusinessesLoading(true));

  try {
    // NOTE: as of 22/Sep/2021, the services Api does not receive a cursor.
    const businesses = yield call(api.getBusinesses, cursor);

    yield put(setBusinesses(businesses));
    yield put(setBusinessesLoading(false));
  } catch (e) {
    yield put(setBusinessesLoading(false));
    logger.error({
      error: e,
      context: { saga: 'getBusinessesSaga' },
      params: { cursor },
    });
  }
}
