import { put, call } from 'redux-saga/effects';

import api from '../../services/api';

import { showToaster, setLoadingAsync } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* updateSuggestedCampaignTemplate({ payload }) {
  const { onSave, scTemplate, onError } = payload;
  try {
    yield put(setLoadingAsync(true));
    yield call(api.updateSuggestedCampaignTemplate, scTemplate?.id, scTemplate);
    yield put(setLoadingAsync(false));

    if (onSave) {
      onSave();
    }
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to update the Suggested Campaign Template. Try again in a few minutes.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'updateSuggestedCampaignTemplate' },
      params: { payload },
    });
    if (onError) onError();
  }
}
