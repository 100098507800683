import {
  put, call, select, all,
} from 'redux-saga/effects';
import moment from 'moment';
import { INSIGHT_STATUS_PUBLISHED } from '@targetable/targetable-web-framework/lib/react/constants';

import api from '../../services/api';

import { showToaster, closeToaster, setLoadingAsync } from '../../actions';
import { selectBusinessIdQueried } from '../../selectors';
import logger from '../../services/logger';
import getInsightsByBusiness from '../getInsightsByBusiness/getInsightsByBusiness';
import { getToasterOptions } from '../helpers';

export default function* updateInsight({ payload }) {
  const {
    onSave, insight, onError, isReturnedToDraft,
  } = payload;
  const multipleInsights = payload?.insights;

  let toastMessage = isReturnedToDraft ? 'Returning insight to Draft...' : 'Saving your changes...';
  try {
    if (insight) {
      if (insight.status === INSIGHT_STATUS_PUBLISHED) {
        insight.published = moment.utc().valueOf();
        toastMessage = 'Publishing the Insight...';
      } else {
        insight.saved = moment.utc().valueOf();
      }
    }

    yield put(showToaster(getToasterOptions(
      toastMessage, 'info', null, 7000, true, null, 'bottom', 'right',
    )));

    // multiple Insights came only when returning to draft
    if (!multipleInsights && !isReturnedToDraft) {
      // Validate if URL exists
      if (insight?.url && insight?.url?.length) {
        yield put(setLoadingAsync(true));
        const siteExists = yield call(api.siteExists, insight.url);
        yield put(setLoadingAsync(false));
        if (!siteExists) {
          if (onError) onError('url');
          yield put(closeToaster());
          return;
        }
      }
    }

    // Allow deleting multiple insights
    if (multipleInsights && Array.isArray(multipleInsights)) {
      const operations = multipleInsights.map((item) => call(api.updateInsight, item.id, item));
      yield all(operations);
    } else {
      yield call(api.updateInsight, insight.id, insight);
    }

    const businessId = yield select(selectBusinessIdQueried);
    yield call(getInsightsByBusiness, { payload: { businessId } });

    yield put(closeToaster());

    onSave();
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to update insight. Try again in a few minutes.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'updateInsight' },
      params: { payload },
    });
  }
}
