import { call } from 'redux-saga/effects';

import api from '../../services/api';
import logger from '../../services/logger';

export default function* getStripeSubscriptions({
  payload: {
    onSuccess,
    onError,
    /** An array of coupon ids to ignore */
    ignoreCoupons,
    customerId,
    returnAll = false,
  },
}) {
  try {
    const results = yield call(api.getAllStripeSubscriptions, {
      returnAll,
      ignoreCoupons,
      customerId,
    });
    // eslint-disable-next-line no-unused-expressions
    onSuccess && onSuccess(results.subscriptions);
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getStripeSubscriptions' },
    });
    // eslint-disable-next-line no-unused-expressions
    onError && onError();
  }
}
