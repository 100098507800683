import { put, call } from 'redux-saga/effects';

import api from '../../services/api';

import { showToaster, setLoadingAsync } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* updateInsightTemplate({ payload }) {
  const {
    onSave, insightTemplate, onError, sendBulk,
  } = payload;
  try {
    yield put(setLoadingAsync(true));
    // Validate if URL exists
    if (insightTemplate?.insight?.url && insightTemplate?.insight?.url?.length) {
      const siteExists = yield call(api.siteExists, insightTemplate?.insight.url);
      if (!siteExists) {
        if (onError) onError('url');
        return;
      }
    }
    yield call(api.updateInsightTemplate, insightTemplate?.id, insightTemplate);

    if (sendBulk && sendBulk === true) {
      yield call(api.sendBulkInsightTemplate, insightTemplate?.id);
    }

    yield put(setLoadingAsync(false));

    if (onSave) {
      onSave();
      return;
    }
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to update insight template. Try again in a few minutes.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'updateInsightTemplate' },
      params: { payload },
    });
  }
}
