import React, { Suspense } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import MomentUtils from '@date-io/moment';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Waiter } from 'react-wait';
import { GlobalsProvider } from '@targetable/targetable-web-framework/lib/context/globals';
import lightTheme from '@targetable/targetable-web-framework/lib/themes/light';
import GoogleMapsLoadScript from '@targetable/targetable-web-framework/lib/react/components/GoogleMapsLoadScript/GoogleMapsLoadScript';
import { ReduxActionProvider } from '@targetable/targetable-web-framework/lib/context/actions';
import { ReduxSelectorProvider } from '@targetable/targetable-web-framework/lib/context/selectors';
import { getLoaderString } from '@targetable/targetable-web-framework/lib/services/loaders_copy';
import globals from '../../globals';
import history from '../../services/history';
import store from '../../store';
import Router from '../Router/Router';
import loadingImage from '../../static/images/component_loading_animation.gif';
import GeneralConfirmationDialog from '../GeneralConfirmationDialog/GeneralConfirmationDialog';
import ActivityLogNote from '../ActivityLogNote/ActivityLogNote';
import PublishMarketingDialog from '../PublishMarketingDialog/PublishMarketingDialog';
import FeedbackDialog from '../../pages/InsightTemplates/InsightTemplatePreviewComponents/FeedbackDialog';

import * as actions from '../../actions';
import * as selectors from '../../selectors';

history.setAdminMode?.(true);

const fallback = () => (
  <>
    <div style={{ width: '100%', textAlign: 'center' }}>
      <img src={loadingImage} alt="Loading" />
    </div>
    <Typography align="center" variant="h3" style={{ textTransform: 'uppercase' }}>
      {getLoaderString()}
    </Typography>
  </>
);

const mappedActions = {
  ...actions,
  setAdCardPresets: actions.setAdCardPresets,
  setCampaign: actions.setSuggestedCampaign,
  setCampaignLoading: actions.setSuggestedCampaignLoading,
  setCampaignAds: actions.setSuggestedCampaignAds,
  setCampaignName: actions.setSuggestedCampaignName,
  setCampaignGoal: actions.setSuggestedCampaignGoal,
  setCampaignObjective: actions.setSuggestedCampaignObjective,
  setCampaignOverrideAutoAging: actions.setSuggestedCampaignOverrideAutoAging,
  setCampaignDisplayDate: actions.setSuggestedCampaignDisplayDate,
  setCampaignDisplayTime: actions.setSuggestedCampaignDisplayTime,
  updateCampaignAds: actions.updateSuggestedCampaignAds,
  setCampaignPixelEventType: actions.setSuggestedCampaignPixelEventType,
  setCampaignOptimizationGoal: actions.setSuggestedCampaignOptimizationGoal,
  setCampaignAdType: actions.setSuggestedCampaignAdType,
  setCampaignEventId: actions.setSuggestedCampaignEventId,
};

const mappedSelectors = {
  ...selectors,
  selectAdCardPresets: selectors.selectAdCardPresets,
  selectAdCardPreset: selectors.selectAdCardPreset,
  selectUser: selectors.selectBusinessFirstUser,
  selectStores: selectors.selectBusinessStores,
  selectCampaignName: selectors.selectSuggestedCampaignName,
  selectCampaignGoal: selectors.selectSuggestedCampaignGoal,
  selectCampaignObjective: selectors.selectSuggestedCampaignObjective,
  selectCampaignOverrideAutoAging: selectors.selectSuggestedCampaignOverrideAutoAging,
  selectCampaignAdItem: selectors.selectSuggestedCampaignAdItem,
  selectCampaignAd: selectors.selectSuggestedCampaignAd,
  selectCampaignAds: selectors.selectSuggestedCampaignAds,
  selectCampaignStatus: selectors.selectSuggestedCampaignStatus,
  selectCampaignAdCards: selectors.selectSuggestedCampaignAdCards,
  selectCampaignBudget: selectors.selectSuggestedCampaignBudget,
  selectCampaignExisting: selectors.selectSuggestedCampaignExisting,
  selectCampaignGender: selectors.selectSuggestedCampaignGender,
  selectCampaignMinAge: selectors.selectSuggestedCampaignMinAge,
  selectCampaignMaxAge: selectors.selectSuggestedCampaignMaxAge,
  selectCampaignSchedule: selectors.selectSuggestedCampaignSchedule,
  selectCampaignSpecificTargets: selectors.selectSuggestedCampaignSpecificTargets,
  selectCampaignStoreCount: selectors.selectSuggestedCampaignStoreCount,
  selectCampaignStores: selectors.selectSuggestedCampaignStores,
  selectCampaignValid: selectors.selectSuggestedCampaignValid,
  selectCampaignTargeting: selectors.selectSuggestedCampaignTargeting,
  selectCampaignTrends: selectors.selectCampaignTrends,
  selectCampaignMetrics: selectors.selectCampaignMetrics,
  selectCampaignMetricsLoading: selectors.selectCampaignMetricsLoading,
  selectCampaignMetricsLoadingError: selectors.selectCampaignMetricsLoadingError,
  selectUrlPresets: selectors.selectUrlPresets,
  selectUrlPresetsLoading: selectors.selectUrlPresetsLoading,
  selectCustomAudiences: selectors.selectCustomAudiences,
  selectCampaignCustomAudiences: selectors.selectSuggestedCampaignCustomAudiences,
  selectPixelEventType: selectors.selectSuggestedCampaignPixelEventType,
  selectCampaignOptimizationGoal: selectors.selectSuggestedCampaigOptimizationGoal,
  selectCampaignAdType: selectors.selectSuggestedCampaignAdType,
  selectCampaignEventId: selectors.selectSuggestedCampaignEventId,
  selectCampaignDisplayDate: selectors.selectSuggestedCampaignDisplayDate,
  selectCampaignDisplayTime: selectors.selectSuggestedCampaignDisplayTime,
  selectCampaignExpiryDateTime: selectors.selectSuggestedCampaignExpiryDateTime,
  selectCampaign: selectors.selectSuggestedCampaign,
  selectCampaignAdPlacement: selectors.selectSuggestedCampaignAdPlacement,
  selectCampaignAdDisplayLink: selectors.selectSuggestedCampaignAdDisplayLink,
  selectCampaignAdCTA: selectors.selectSuggestedCampaignAdCTA,
  selectCampaignAdMainMessage: selectors.selectSuggestedCampaignAdMainMessage,
};

const App = () => (
  <GlobalsProvider value={globals}>
    <ReduxActionProvider value={mappedActions}>
      {/* Having undefined Actions?
      make sure pages/CampaignDetail.jsx also defines your action in the ReduxActionProvider! */}
      <ReduxSelectorProvider value={mappedSelectors}>
        <Provider store={store}>
          <MuiThemeProvider theme={lightTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Waiter>
                <Suspense fallback={fallback()}>
                  <CssBaseline />
                  <ConnectedRouter history={history}>
                    <GoogleMapsLoadScript fallback={fallback()}>
                      <Router />
                    </GoogleMapsLoadScript>
                    <GeneralConfirmationDialog />
                    <ActivityLogNote />
                    <FeedbackDialog />
                    <PublishMarketingDialog />
                  </ConnectedRouter>
                </Suspense>
              </Waiter>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </Provider>
      </ReduxSelectorProvider>
    </ReduxActionProvider>
  </GlobalsProvider>
);

export default hot(App);
