import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TodayIcon from '@material-ui/icons/Today';
import DrawerContent from '@targetable/targetable-web-framework/lib/react/containers/DrawerContent/DrawerContent';
import ComponentLoading from '@targetable/targetable-web-framework/lib/react/components/ComponentLoading/ComponentLoading';
import PageContentContainer from '@targetable/targetable-web-framework/lib/react/containers/PageContentContainer/PageContentContainer';
import CampaignFormTitle from '@targetable/targetable-web-framework/lib/react/campaignWizard/components/CampaignFormTitle/CampaignFormTitle';

import {
  FormControlLabel, Grid, RadioGroup, Radio, Typography,
} from '@material-ui/core';

import {
  pauseSubscriptionCollection,
  showConfirmationDialog,
} from '../../actions';
import {
  selectSubscriptionLoading,
  selectBusiness,
} from '../../selectors';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: '100%',
    paddingTop: 20,
  },
  backdrop: {
    zIndex: 1400,
  },
  progressColor: {
    color: '#fff',
  },
  noPresets: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
  subscriptionHeading: {
    textAlign: 'left',
    marginTop: theme.spacing(4),
    paddingLeft: '10%',
  },
  chipAmount: {
    color: '#007bff',
    border: '1px #007bff solid',
    fontSize: '0.75rem',
    fontWeight: 500,
    backgroundColor: 'white',
    lineheight: 1.33,
  },
  chipStoreCount: {
    fontSize: '0.75rem',
    color: '#2fc134',
    border: '1px #2fc134 solid',
    backgroundColor: 'white',
    lineheight: 1.33,
    fontWeight: 500,
  },
  buttonLeft: {
    color: '#000',
    backgroundColor: theme.palette.secondary.contrastText,
    position: 'absolute',
    left: theme.spacing(1),
  },
  buttonRight: {
    marginRight: theme.spacing(2),
  },
  checkBox: {
    paddingLeft: 0,
    paddingTop: '3px',
  },
  datePicker: {
    paddingTop: '7px',
    '& button': {
      padding: 0,
    },
    '& input': {
      textAlign: 'right',
    },
  },
}));

const PAUSE_DURATION_INDEFINITE = 'indefinite';
const PAUSE_DURATION_UNTIL_DATE = 'until_custom_date';

const PauseSubscriptionCollectionDialog = ({
  onClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const subscriptionLoading = useSelector(selectSubscriptionLoading) || false;
  const business = useSelector(selectBusiness) || {};
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openDatePicker, setOpenDatePicker] = React.useState(false);
  const [checkClose, setCheckClose] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [pauseIsoDate, setPauseIsoDate] = React.useState();
  const [pauseDurationType, setPauseDurationType] = React.useState(PAUSE_DURATION_INDEFINITE);
  const isUntilCustomDate = pauseDurationType === PAUSE_DURATION_UNTIL_DATE;

  useEffect(() => {
    if (!openDrawer) {
      setOpenDrawer(true);
    }
  }, [openDrawer]);

  const handlePauseCollection = useCallback(() => {
    dispatch(showConfirmationDialog({
      title: t('pause_subscription_collection'),
      text: t('pause_subscription_collection_confirmation', { date: pauseIsoDate ? `until ${moment(pauseIsoDate).format('MMM DD of YYYY')}` : 'Indefinitely' }),
      confirmText: 'Yes',
      confirmDataCy: 'cta-pause_subscription_collection_yes',
      cancelText: 'No',
      cancelDataCy: 'cta-pause_subscription_collection_no',
      onClick: (confirm) => {
        if (confirm) {
          setDisabled(true);
          dispatch(pauseSubscriptionCollection({
            pauseIsoDate,
            onCompleted: () => {
              setDisabled(false);
              onClose();
            },
            onError: () => {
              setDisabled(false);
            },
          }));
        }
      },
    }));
  }, [pauseIsoDate, dispatch, onClose, t]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const buttons = useMemo(() => [
    {
      text: 'close',
      classes: classes.buttonLeft,
      variant: 'text',
      color: 'primary',
      onClick: handleClose,
    },
    {
      text: t('pause', 'Pause'),
      classes: classes.buttonRight,
      variant: 'contained',
      color: 'secondary',
      onClick: handlePauseCollection,
    },
  ], [handlePauseCollection, handleClose, t, classes]);

  const handleOnTypeChange = useCallback((e) => {
    const val = e.target.value;

    let pauseDuration = PAUSE_DURATION_INDEFINITE;
    if (val === PAUSE_DURATION_UNTIL_DATE) {
      pauseDuration = PAUSE_DURATION_UNTIL_DATE;
      setOpenDatePicker(true);
    } else {
      setPauseIsoDate(null);
    }
    setPauseDurationType(pauseDuration);
  }, []);

  const handleOnAccept = (date) => {
    setPauseIsoDate(date.format('YYYY-MM-DD'));
  };

  useEffect(() => {
    if (checkClose && !pauseIsoDate) {
      setPauseDurationType(PAUSE_DURATION_INDEFINITE);
      setCheckClose(false);
    }
  }, [pauseIsoDate, checkClose]);

  useEffect(() => {
    if (isUntilCustomDate) {
      setPauseIsoDate(null);
    }
  }, [isUntilCustomDate]);

  function handleOnClose() {
    setCheckClose(true);
    setOpenDatePicker(false);
  }

  const handleOnOpen = () => {
    setCheckClose(false);
    setPauseIsoDate(null);
  };

  return (
    <>
      <DrawerContent
        open={openDrawer}
        onClose={handleClose}
        title={`Pause Collection - ${business?.name}`}
        buttons={buttons}
        invisibleBackdrop
      >
        <PageContentContainer className={classes.pageContainer}>
          <>
            <ComponentLoading
              loading={!!subscriptionLoading}
              waitKey="loading_business_subscriptions"
            >
              <Grid container>
                <Typography className={classes.noPresets} variant="h2">
                  Pause Payment Collection
                </Typography>
                <Typography className={classes.noPresets}>
                  Decide how long to pause payment collection
                </Typography>
                <Grid item xs={12}>
                  <CampaignFormTitle text={t('pause_duration', 'Pause Duration')} />
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    row
                    className={classes.radioContainer}
                    onChange={handleOnTypeChange}
                    value={pauseDurationType}
                    data-cy="pauseCollectionDialog_radioGroup"
                  >

                    <Grid container>
                      <Grid item xs={isUntilCustomDate ? 4 : 6}>
                        <FormControlLabel
                          className={classes.radioItem}
                          label={<Typography variant="body1">{t('pause_duration_indefinite', 'Indefinite')}</Typography>}
                          value={PAUSE_DURATION_INDEFINITE}
                          control={<Radio disabled={disabled} />}
                          data-cy="pauseCollectionDialog_indefinite_radio"
                        />
                      </Grid>
                      <Grid item xs={isUntilCustomDate ? 4 : 6}>
                        <FormControlLabel
                          className={classes.radioItem}
                          label={<Typography variant="body1">{t('pause_duration_until_custom_date', 'Until Custom Date')}</Typography>}
                          value={PAUSE_DURATION_UNTIL_DATE}
                          control={<Radio disabled={disabled} />}
                          data-cy="pauseCollectionDialog_customDate_radio"
                        />
                      </Grid>
                      {isUntilCustomDate ? (
                        <Grid item xs={4}>
                          <DatePicker
                            disabled={disabled}
                            open={openDatePicker}
                            margin="none"
                            minDate={moment().startOf('day')}
                            value={pauseIsoDate}
                            minDateMessage={t('date_picker_min_error_message')}
                            onOpen={handleOnOpen}
                            onChange={handleOnAccept}
                            onClose={handleOnClose}
                            className={classes.datePicker}
                            format="MM/DD/YYYY"
                            InputProps={{
                              classes: { input: classes.input },
                              disableUnderline: true,
                              endAdornment: (
                                <InputAdornment className={classes.adornment} position="end">
                                  <IconButton>
                                    <TodayIcon className={classes.datePickerIcon} data-cy="pauseCollection_selectDate_icon" />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </RadioGroup>
                </Grid>
              </Grid>
            </ComponentLoading>
          </>
        </PageContentContainer>
      </DrawerContent>
    </>
  );
};

PauseSubscriptionCollectionDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default PauseSubscriptionCollectionDialog;
