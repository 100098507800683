import { put, call } from 'redux-saga/effects';
import _ from 'lodash';

import i18n from '../../services/i18n';
import api from '../../services/api';
import {
  showToaster,
  setLoadingAsync,
  setStoreFacebookLocation,
} from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* refreshStoreFacebookLocation({ payload: store }) {
  try {
    yield put(showToaster(getToasterOptions(i18n.t('refresh_store_facebook_location_init'), 'info', null, 10000, true, null, 'bottom', 'right')));
    yield put(setLoadingAsync(true));
    const location = yield call(api.refreshStoreFacebookLocation, _.get(store, 'id'));
    const pageId = location?.id;
    delete location?.id;
    yield put(setStoreFacebookLocation({ storeId: store.id, location, pageId }));
    yield put(setLoadingAsync(false));

    if (pageId) {
      if (Object.keys(location).length && location?.latitude && location?.longitude) {
        yield put(showToaster(getToasterOptions(i18n.t('refresh_store_facebook_location_success'), 'success', null, 5000)));
      } else {
        yield put(showToaster(getToasterOptions(i18n.t('refresh_store_facebook_location_no_info'), 'error', null, 5000)));
      }
    } else {
      yield put(showToaster(getToasterOptions(i18n.t('refresh_store_facebook_location_no_tokens'), 'error')));
    }
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to refresh store facebook location due to an error in the server', 'error')));
    logger.error({
      error: e,
      context: { saga: 'refreshStoreFacebookLocation' },
      params: { store },
    });
  }
}
