import {
  call, put, select, take,
} from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment';
import { getDurationFromSchedule } from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/budgetSchedule/budgetSchedule';

import api from '../../services/api';
import history from '../../services/history';
import { setConfirmationDialog, setLoadingAsync, showToaster } from '../../actions';
import { getBusinessRoute, getSuggestedDraftRoute } from '../../routes';
import i18n from '../../services/i18n';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { selectBusinessIdQueried, selectBusinessStores } from '../../selectors';
import date from '../../services/date';
import { RESULT_CONFIRMATION_DIALOG } from '../../constants';

function buildRemovedTargetList(removedTargets) {
  let list = '';
  removedTargets.forEach((target) => {
    if (target?.value) {
      list += `<br>- ${target.value}`;
    }
  });
  return list;
}

export default function* duplicateCampaignSaga({ payload }) {
  try {
    const { campaign, onDone, copyMarketingRequests } = payload;
    let campaignToClone = campaign;
    yield put(showToaster(getToasterOptions('Creating a copy of the Campaign...',
      'info', null, 10000, true, null, 'bottom', 'right')));

    const stores = yield select(selectBusinessStores);

    // Algolia's object returns the 'isOptimized' property
    if (campaign?.isOptimized) {
      /*
      * We need to fetch the actual suggested campaign from DDB, becuse
      * Algolia's object is a partial one
      */
      campaignToClone = yield call(
        api.getCampaign, campaign.id, stores,
      );
    }

    const name = `${campaignToClone.name} - Copy`;
    const goal = _.get(campaignToClone, 'goal');
    const regulatedCategories = (goal === 'hiring') ? 'EMPLOYMENT' : 'NONE';

    const specificTargets = _.get(campaignToClone, ['ads', '0', 'targeting', 'specificTargets']);
    const validatedTargets = yield call(api.validateTargets, specificTargets, regulatedCategories);

    const originalAds = campaign?.ads;

    if (originalAds) {
      // Just copy active stores
      campaign.ads = _.filter(originalAds, (ad) => {
        const { storeId } = ad;
        const store = _.find(stores, { id: storeId });
        return !store || !store.disabled;
      });

      // When all the stores were disabled
      if (campaign.ads?.length === 0) {
        campaign.ads = _.map(originalAds, (adItem) => ({
          ...adItem,
          targeting: {
            ...adItem.targeting,
            cities: [],
            countries: [],
            zips: [],
            regions: [],
            geoMarkets: [],
            radius: 5,
          },
          storeId: null,
        }));
      }
    }

    const fieldsToOmit = ['id', 'suggestedCampaignId', 'userId', 'status', 'created', 'updated', 'integrations', 'saved', 'retryTimeout'];
    if (!copyMarketingRequests) {
      fieldsToOmit.push('requestInfo');
    }
    const duplicateCampaign = {
      ..._.omit(campaignToClone, fieldsToOmit),
      created: moment.utc().valueOf(),
      saved: moment.utc().valueOf(),
      status: 'draft',
      name,
      displayDate: date.startOfToday(),
      displayTime: { hour: 0, minute: 0 },
      ads: _.map(campaignToClone.ads, (adItem) => ({
        ..._.omit(adItem, ['integrations']),
        schedule: {
          ...adItem.schedule,
          startDate: date.startOfToday(),
          endDate: date.addDaysFromNow(getDurationFromSchedule(adItem.schedule) - 1),
        },
        targeting: {
          ...adItem.targeting,
          specificTargets: validatedTargets,
        },
      })),
    };

    const businessId = yield select(selectBusinessIdQueried);
    const createdSuggestedCampaign = yield call(api.createSuggestedCampaign, duplicateCampaign);

    // Unnecessary call because getting from algolia
    // yield fork(getSuggestedCampaignsByBusiness, { payload: { businessId } });

    // add this to history so the back button returns the user
    // to suggested campaigns instead of campaigns
    history.push(getBusinessRoute(businessId, 'suggested_campaigns'));
    history.push(getSuggestedDraftRoute(businessId, _.get(createdSuggestedCampaign, 'id')));

    if (onDone) {
      onDone();
    }

    const removedTargets = specificTargets.filter(
      (currentTarget) => !validatedTargets?.some((target) => currentTarget.id === target.id),
    ) || [];

    if (removedTargets.length) {
      yield put(setLoadingAsync(false));
      yield put(setConfirmationDialog({
        isOpen: true,
        cancelText: 'ok',
        cancelDataCy: 'duplicateSuggestedCampaign_ok_button',
        confirmText: '',
        text: 'removed_targeting_list',
        title: 'Removed keywords',
        textParams: { list: buildRemovedTargetList(removedTargets) },
      }));
      yield take(RESULT_CONFIRMATION_DIALOG);
      yield put(setConfirmationDialog({ isOpen: false }));
    }

    yield put(showToaster(getToasterOptions(i18n.t('campaigns_duplicate_created', { name }), 'info', null, 5000)));
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'duplicateCampaignSaga' },
      params: { campaign: payload.campaign },
    });
    yield put(showToaster(getToasterOptions('error_update_campaign')));
  }
}
