import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    fill: theme.palette.text.secondary,
  },
}));

const ChatIcon = ({ className, size, onClick }) => {
  const classes = useStyles();

  return (
    <svg width={size} height={size} viewBox="0 0 26 26" preserveAspectRatio="xMidYMid meet" className={classnames(classes.icon, className)} onClick={onClick}>
      <g clipPath="url(#clip0_15883_2097)">
        <path
          d="M20 2H4.01C2.91 2 2.01 2.9 2.01 4L2 22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM12 6C13.1 6 14 6.9 14 8C14 9.1 13.1 10 12 10C10.9 10 10 9.1 10 8C10 6.9 10.9 6 12 6ZM16 14H8V13.43C8 12.62 8.48 11.9 9.22 11.58C10.07 11.21 11.01 11 12 11C12.99 11 13.93 11.21 14.78 11.58C15.52 11.9 16 12.62 16 13.43V14Z"
          fill="#0E1436"
        />
      </g>
      <defs>
        <clipPath id="clip0_15883_2097">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

ChatIcon.defaultProps = {
  className: '',
  size: 24,
  onClick: () => {},
};

ChatIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default ChatIcon;
