import {
  call,
  put,
} from 'redux-saga/effects';
import {
  showFeedbackModal,
} from '../../actions';

import api from '../../services/api';

/**
 * Invoked when a insight template preview is approved/rejected.
 */
export default function* previewFeedbackDialog(reduxAction) {
  const { payload } = reduxAction;
  const {
    item,
  } = payload;

  if (item.questionnaireId) {
    // Fetch actual questions
    const questionnaireInfo = yield call(api.getQuestionnaireById, item.questionnaireId);
    item.questionnaire = questionnaireInfo;
  }

  // Avoid second validation on submitCampaign
  yield put(showFeedbackModal({
    ...payload,
    validatedCampaign: true,
  }));
}
