/* eslint-disable no-unused-expressions */
import { call, put, select } from 'redux-saga/effects';

import api from '../../services/api';
import {
  setBusinessUsers,
  showToaster,
} from '../../actions';
import logger from '../../services/logger';
import { selectBusinessUsers } from '../../selectors';
import { getToasterOptions } from '../helpers';

export default function* setAuth0Permissions({ payload }) {
  const { auth0Id, permissions, onComplete } = payload;
  try {
    yield call(api.setAuth0Permissions, auth0Id, permissions);
    const currentUsers = yield select(selectBusinessUsers);

    // Find the user matching the auth0Id and update the permissions
    const updatedUsers = currentUsers.map((user) => {
      if (user.auth0Id === auth0Id) {
        return {
          ...user,
          auth0Permissions: permissions,
        };
      }

      return user;
    });

    yield put(setBusinessUsers(updatedUsers));
    yield put(showToaster(getToasterOptions('Role successfully updated.', 'success', null, 3000)));
    onComplete && onComplete();
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'setAuth0Permissions' },
      params: { ...payload },
    });
    onComplete && onComplete();
  }
}
