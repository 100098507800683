import _ from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Pagination from '@material-ui/lab/Pagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import SvgIcon from '@material-ui/core/SvgIcon';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch } from 'react-redux';
import { isInvited } from '@targetable/targetable-web-framework/lib/utils/user';
import SearchBar from '@targetable/targetable-web-framework/lib/react/components/SearchBar/SearchBar';
import algoliasearch from 'algoliasearch'; // <== do NOT use '/lite'

import {
  StyledTableHeader,
} from '@targetable/targetable-web-framework/lib/react/components/Tables/Tables';
import ComponentLoading from '@targetable/targetable-web-framework/lib/react/components/ComponentLoading/ComponentLoading';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useGlobalsContext } from '@targetable/targetable-web-framework/lib/context/globals';
import classNames from 'classnames';
import FilterMenu from '@targetable/targetable-web-framework/lib/react/components/FilterMenu/FilterMenu';
import {
  Grid, MenuItem, Table, TableCell, TableContainer, TextField, Tooltip, Typography,
} from '@material-ui/core';
import PageContainer from '../../containers/PageContainer/PageContainer';
import { getAllUsers } from '../../actions';
import env from '../../globals';
import DrawerPreview from '../Businesses/DrawerPreview';
import UserDetailsDialog from './UserDetailsDialog';
import Permissions from '../../components/UsersTab/Permissions/Permissions';

const StyledTableCell = withStyles(() => ({
  root: {
    borderBottom: '1px solid #e4e6ec',
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 0,
    padding: 0,
  },
  root: {
    flexGrow: 1,
    marginTop: 24,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
    '& .MuiTableRow-root': {
      backgroundColor: '#FFFFFF',
    },
  },
  actionToolbarWrapper: {
    justifyContent: 'space-between',
    height: '52px',
    background: '#FFFFFF',
    boxShadow: '0px 2px 4px rgba(14, 20, 54, 0.12), 0px 4px 8px rgba(14, 20, 54, 0.02)',
  },
  totalItemsWrapper: {
    display: 'flex',
    justifySontent: 'space-between',
    alignItems: 'center',
    marginLeft: '20px',
    '& div': {
      background: '#007BFF',
      borderRadius: '99px',
      fontSize: '12px',
      padding: '3px 7px',
      color: '#FFFFFF',
    },
  },
  totalItemsText: {
    color: '#0E1436',
    fontSize: 12,
    fontWeight: 500,
    marginLeft: 10,
  },
  iconsToolbarWrapper: {
    display: 'flex',
    justifySontent: 'space-between',
    alignItems: 'center',
    marginRight: '20px',
    '& .MuiIconButton-root': {
      padding: '0 !important',
    },
  },
  iconToolbar: {
    fontSize: '22px',
    marginLeft: '25px',
    color: '#3E435E',
    cursor: 'pointer',
  },
  icon: {
    fontSize: '22px !important',
    color: '#3E435E !important',
  },
  tableWrapper: {
    overflowX: 'auto',
    width: '98%',
    height: 'calc(100vh - 220px)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 325px)',
    },
    boxShadow: '0px 2px 4px rgba(14, 20, 54, 0.12), 0px 4px 8px rgba(14, 20, 54, 0.02)',
    borderRadius: '4px',
    margin: '0 16px',
  },
  tableHeader: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#0E1436',
  },
  pagination: {
    width: '100%',
    display: 'flex',
    '& .Mui-selected': {
      backgroundColor: '#007bff',
      color: '#fff',
      border: 'none',
    },
    justifyContent: 'right',
    background: '#FFFFFF',
    boxShadow: '0px 2px 4px rgba(14, 20, 54, 0.12), 0px 4px 8px rgba(14, 20, 54, 0.02)',
    padding: '10px',
  },
  paginationWrapper: {
    width: '98%',
    display: 'flex',
    justifyContent: 'flex-end',
    background: '#FFFFFF',
    boxShadow: '0px 2px 4px rgba(14, 20, 54, 0.12), 0px 4px 8px rgba(14, 20, 54, 0.02)',
    margin: '0 16px',
  },
  paginationItemsWrapper: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noUsers: {
    textAlign: 'center',
  },
  searchbar: {
    maxWidth: '200px',
    marginLeft: '25px',
    '& input': {
      padding: '9px 0px 9px 12px !important',
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
}));

const MAX_RESULTS = 20;
const Filters = {
  All: 'all',
  FacebookToken: 'fb-token',
  Joined: 'joined',
};

/**
 * Sorts the list by the given prop.
 * @param {object[]} list The list to sort.
 * @param {string} prop The property to sort by.
 * @param {boolean} asc Whether to sort ascending or descending.
 * @returns {object[]} the same `list` ordered in place.
 * */
const sortListByProp = (list, property, isAsc) => {
  // Sorts list, puts empty strings at the end
  const orderedUsers = list.sort((a, b) => {
    if (isAsc) {
      return !a[property] - !b[property] || a[property]?.localeCompare(b[property]);
    }

    return !a[property] - !b[property] || b[property]?.localeCompare(a[property]);
  });

  return orderedUsers;
};

const ORDER_ASCENDING = 'asc';
const ORDER_DESCENDING = 'desc';

const searchClient = algoliasearch(env.ALGOLIA_APP_ID, env.ALGOLIA_API_SEARCH_KEY);
let algoliaIndex = searchClient.initIndex(`${env.ENVIRONMENT_NAME}_Businesses`);

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [paginatedUsers, setPaginatedUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { HUBSPOT_ACCOUNT_ID } = useGlobalsContext();
  const [orderBy, setOrderBy] = useState('givenName');
  const [order, setOrder] = useState(ORDER_DESCENDING);
  const [businessInfo, setBusinessInfo] = useState({ loading: false, info: null });
  const [updateUsers, setUpdateUsers] = useState(false);
  const [showSearchBar, setShowSearchBar] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [limitPerPage, setLimitPerPage] = React.useState(MAX_RESULTS);
  const [selectedUser, setSelectedUser] = React.useState({});
  const [showUserDetails, setShowUserDetails] = React.useState(false);
  const [showPermissions, setShowPermissions] = React.useState(false);

  const FilterOptions = [
    {
      id: Filters.All,
      text: t('user_list_filter_all', 'All'),
      checked: true,
      disableOthers: true,
    },
    {
      id: Filters.Joined,
      text: t('user_list_filter_joined', 'Joined'),
      checked: false,
    },
    {
      id: Filters.FacebookToken,
      text: t('user_list_filter_fb_token', 'Facebook token'),
      checked: false,
    },
  ];

  /**
   * Filters the list of users.
   * @param {Number} pageNumber the index (starting at 0) of the paginated results, where
   * 0 is the first 20 elements, 1 the next 20, etc.
   * */
  const paginateByPageIndex = useCallback((pageIndex, list) => {
    const startIndex = pageIndex * limitPerPage;
    const endIndex = startIndex + limitPerPage;
    const results = list.slice(startIndex, endIndex);

    setPaginatedUsers(results);
    setCurrentPage(pageIndex + 1);
  }, [limitPerPage]);

  const handleFiltersChange = useCallback((filters) => {
    const filtered = users.filter((user) => {
      const byFb = user.isConnectedToFacebook && filters.includes(Filters.FacebookToken);
      const byInvited = isInvited(user) && filters.includes(Filters.Joined);
      return byFb || byInvited || filters.includes(Filters.All) || !filters.length;
    });

    setFilteredUsers(filtered);
    paginateByPageIndex(0, filtered);
  }, [users, paginateByPageIndex]);

  // Sorts the list of users by the given property
  const createSortHandler = (property) => () => {
    const isAsc = orderBy === property && order === ORDER_ASCENDING;
    const ordering = isAsc ? ORDER_DESCENDING : ORDER_ASCENDING;
    setOrder(ordering);
    setOrderBy(property);

    const sortedUsers = sortListByProp(filteredUsers, property, isAsc);

    paginateByPageIndex(0, sortedUsers);
  };

  const goToPage = (event, page) => {
    paginateByPageIndex(page - 1, filteredUsers);
  };

  const searchHandler = useCallback((searchedValue) => {
    if (searchedValue !== '') {
      const filterByProps = ['givenName', 'familyName', 'hubSpotId', 'email'];
      const results = users.filter((row) => (
        filterByProps.some((prop) => {
          const included = String(row?.[prop]).toLowerCase().includes(searchedValue?.toLowerCase());
          return included;
        })
        || `${row?.givenName?.toLowerCase()} ${row?.familyName?.toLowerCase()}`
          .includes(searchedValue?.toLowerCase())
      ));

      setFilteredUsers(results);
      paginateByPageIndex(0, results);
    } else {
      setFilteredUsers(users);
      paginateByPageIndex(0, users);
    }
  }, [users, paginateByPageIndex]);

  const handleUserSuccess = useCallback((allUsers) => {
    // The first time, sort by default props
    const sorted = sortListByProp(allUsers, orderBy, true);
    setLoadingUsers(false);
    setUsers(sorted);
    setFilteredUsers(sorted);
    paginateByPageIndex(0, sorted);
  }, [paginateByPageIndex, orderBy]);

  const handleDrawerClose = () => {
    setBusinessInfo({
      loading: false,
      info: null,
    });
  };

  const handleOnSuccessSave = useCallback((business) => {
    setBusinessInfo({
      loading: false,
      info: business || null,
    });
    setUpdateUsers(true);
    searchClient.clearCache();
    algoliaIndex = searchClient.initIndex(`${env.ENVIRONMENT_NAME}_Businesses`);
  }, []);

  // Fetches the business info from algolia
  const fetchBusinessInfo = async (businessId) => {
    setBusinessInfo({ loading: true, info: null });
    let info;
    try {
      info = await algoliaIndex.getObject(businessId);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error fetching business info: ', e);
      info = { error: e };
    }

    setBusinessInfo({ loading: false, info });
  };

  const handleBusinessNameClick = (businessId) => {
    if (businessId) {
      fetchBusinessInfo(businessId);
    }
  };

  const handleUserNameClick = (userId) => {
    if (userId) {
      setSelectedUser(users.find((user) => user.id === userId));
    }
  };

  const handleCloseUserDetails = () => {
    setShowUserDetails(false);
    setSelectedUser({});
  };

  useEffect(() => {
    if (!users.length || updateUsers) {
      setLoadingUsers(true);
      setUpdateUsers(false);
      dispatch(getAllUsers({ onSuccess: handleUserSuccess }));
    }
  }, [dispatch, handleUserSuccess, users, updateUsers]);

  const handleChangeLimitPerPage = (e) => {
    setLimitPerPage(e.target.value);
    setCurrentPage(1);
  };

  const handleCancelPermissions = useCallback(() => {
    setShowPermissions(false);
  }, [setShowPermissions]);

  useEffect(() => {
    paginateByPageIndex(0, users);
  }, [limitPerPage, users, paginateByPageIndex]);

  useEffect(() => {
    if (!_.isEmpty(selectedUser)) {
      setShowUserDetails(true);
    }
  }, [selectedUser]);

  const FilterIcon = () => (
    <SvgIcon
      className={classes.iconToolbar}
      data-cy="filters_users_button"
      onClick={() => {}}
    >
      <path d="M4.25 5.61C6.27 8.2 10 13 10 13v6c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-6s3.72-4.8 5.74-7.39c.51-.66.04-1.61-.79-1.61H5.04c-.83 0-1.3.95-.79 1.61z" />
    </SvgIcon>
  );

  return (
    <PageContainer pageContainerClassName={classes.container} title="Users">
      <Grid container className={classes.actionToolbarWrapper}>
        <Grid className={classes.totalItemsWrapper}>
          <div>{filteredUsers.length}</div>
          <Typography className={classes.totalItemsText}>USERS</Typography>
        </Grid>
        <Grid className={classes.iconsToolbarWrapper}>
          <Tooltip title="Filter">
            <FilterMenu
              label=""
              onFiltersChanged={handleFiltersChange}
              options={FilterOptions}
              openIcon={FilterIcon}
              closeIcon={FilterIcon}
            />
          </Tooltip>
          {!showSearchBar && (
          <SearchIcon
            className={classes.iconToolbar}
            onClick={() => setShowSearchBar(true)}
          />
          )}
          {showSearchBar && (
          <SearchBar
            placeholder={t('users_searchbar')}
            onChange={searchHandler}
            onCancelSearch={() => setSearchTerm('')}
            value={searchTerm}
            classes={classes}
            dataCy="user_list_search_input"
          />
          )}
        </Grid>
      </Grid>
      <>
        <ComponentLoading
          loading={loadingUsers}
          waitKey="loading_businesses_list"
        >
          <div className={classes.root}>
            <TableContainer className={classes.tableWrapper}>
              {!_.isEmpty(paginatedUsers) && (
                <>
                  <Table stickyHeader aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <StyledTableHeader className={classes.tableHeader}>
                          <TableSortLabel
                            className={classes.sortIcon}
                            active={orderBy === 'givenName'}
                            direction={orderBy === 'givenName' ? order : ORDER_ASCENDING}
                            onClick={createSortHandler('givenName')}
                          >
                            {t('name', 'Name')}
                          </TableSortLabel>
                        </StyledTableHeader>
                        <StyledTableHeader className={classNames(
                          classes.tableHeader, classes.hubspotCol,
                        )}
                        >
                          {t('user_list_col_business_name', 'Business Name')}
                        </StyledTableHeader>
                        <StyledTableHeader
                          className={classNames(classes.tableHeader, classes.hubspotCol)}
                        >
                          {t('user_list_col_hubspot', 'Hubspot Record')}
                        </StyledTableHeader>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedUsers.map((user) => (
                        <TableRow key={user.id}>
                          <StyledTableCell component="th" scope="row">
                            <span
                              style={{ cursor: 'pointer', color: '#007BFF' }}
                              role="button"
                              tabIndex={0}
                              onKeyPress={() => (handleUserNameClick(user.id))}
                              onClick={() => (handleUserNameClick(user.id))}
                            >
                              {`${user.givenName} ${user.familyName}`}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <span
                              style={{ cursor: 'pointer', color: '#007BFF' }}
                              role="button"
                              tabIndex={0}
                              onKeyPress={() => (handleBusinessNameClick(user.businessId))}
                              onClick={() => (handleBusinessNameClick(user.businessId))}
                            >
                              {user.businessName}
                            </span>
                          </StyledTableCell>
                          {user?.hubSpotId ? (
                            <TableCell>
                              <a
                                href={`https://app.hubspot.com/contacts/${HUBSPOT_ACCOUNT_ID}/contact/${user.hubSpotId}/`}
                                style={{ color: '#007bff', textDecoration: 'none' }}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {user.hubSpotId}
                              </a>
                            </TableCell>
                          ) : (
                            <TableCell>N/A</TableCell>
                          )}

                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              )}
              {_.size(paginatedUsers) === 0 && (
                <div className={classes.noUsers}>
                  <Typography>No Users Found</Typography>
                </div>
              )}
            </TableContainer>
          </div>
          {!_.isEmpty(paginatedUsers) && (
            <div className={classes.paginationWrapper}>
              <div className={classes.paginationItemsWrapper}>
                <TextField
                  select
                  value={limitPerPage}
                  onChange={handleChangeLimitPerPage}
                  className={classes.limitPerPageOptions}
                  data-cy="limit_per_page"
                >
                  <MenuItem
                    key={20}
                    value={20}
                    data-cy="limit_per_page_20_option"
                  >
                    20 Per Page
                  </MenuItem>
                  <MenuItem
                    key={50}
                    value={50}
                    data-cy="limit_per_page_50_option"
                  >
                    50 Per Page
                  </MenuItem>
                  <MenuItem
                    key={100}
                    value={100}
                    data-cy="limit_per_page_100_option"
                  >
                    100 Per Page
                  </MenuItem>
                </TextField>
                <Pagination
                  onChange={goToPage}
                  className={classes.stack}
                  page={currentPage}
                  count={Math.ceil(filteredUsers.length / limitPerPage)}
                  variant="outlined"
                  shape="rounded"
                />
              </div>
            </div>
          )}
        </ComponentLoading>
      </>
      <DrawerPreview
        businessInfo={businessInfo.info}
        infoLoading={businessInfo.loading}
        onClose={(handleDrawerClose)}
        onSuccessSave={handleOnSuccessSave}
        fetchError={businessInfo.info && businessInfo.info.error && !businessInfo.loading}
      />
      <UserDetailsDialog
        openDrawer={showUserDetails}
        onClose={handleCloseUserDetails}
        user={selectedUser}
        onBusinessClick={handleBusinessNameClick}
        onPermissionsClick={() => setShowPermissions(true)}
      />

      {showPermissions && (
      <Permissions
        title="Permissions"
        onCancel={handleCancelPermissions}
        userId={selectedUser.id}
      />
      )}
    </PageContainer>
  );
};

export default UsersList;
