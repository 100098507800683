import _ from 'lodash';
import api from '../../services/api';

export default function* validateCampaignDisplayLink(businessId, campaign) {
  const { ad } = _.get(campaign, ['ads', '0'], {});
  const displayLink = _.get(ad, 'displayLink');

  let valid = true;

  if (!displayLink) {
    return { valid };
  }

  const res = yield Promise.resolve(api.siteExists(displayLink));
  valid = res;

  return { valid, error: !valid ? 'invalid_url' : false };
}
