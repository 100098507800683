import _ from 'lodash';
import { call, put, select } from 'redux-saga/effects';
import sanitizeString from '@targetable/targetable-web-framework/lib/utils/sanitizeString';

import api from '../../services/api';
import { setImagesMam, setIsSearchingMam, showToaster } from '../../actions';
import { selectSuggestedCampaignAdType } from '../../selectors';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

const isAVideoFile = (file) => _.has(file, 'duration') || _.has(file, 'aspectRatio');

export default function* searchImages({ payload }) {
  try {
    yield put(setIsSearchingMam(true));
    const cleanedString = sanitizeString(payload);

    if (!cleanedString) {
      yield put(setIsSearchingMam(false));
      yield put(setImagesMam([]));
    }

    let images = yield call(api.searchImages, cleanedString);
    const adType = yield select(selectSuggestedCampaignAdType);
    if (adType === 'video') {
      images = _.filter(images, isAVideoFile);
    }

    yield put(setIsSearchingMam(false));
    yield put(setImagesMam(images));
  } catch (e) {
    yield put(setIsSearchingMam(false));
    yield put(setImagesMam([]));
    yield put(showToaster(getToasterOptions('error_whoops')));
    logger.error({
      error: e,
      context: { saga: 'searchImages' },
      params: { payload },
    });
  }
}
