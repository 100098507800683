import { put, call, select } from 'redux-saga/effects';
import i18n from '../../services/i18n';

import api from '../../services/api';
import {
  showToaster,
  setLoadingAsync,
} from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import getUsersByBusiness from '../getUsersByBusiness/getUsersByBusiness';
import { selectBusiness } from '../../selectors';

export default function* updateUserName({ payload: { userId, givenName, familyName } }) {
  try {
    const currentBusiness = yield select(selectBusiness);
    yield put(setLoadingAsync(true));
    yield call(api.updateUser, userId, { givenName, familyName });
    yield put(setLoadingAsync(false));
    // Reload user list
    yield call(getUsersByBusiness, { payload: currentBusiness.id });
    yield put(showToaster(getToasterOptions(i18n.t('update_user_name_success'), 'success', null, 5000)));
  } catch (e) {
    yield put(showToaster(getToasterOptions(i18n.t('update_user_name_error'), 'error')));
    logger.error({
      error: e,
      context: { saga: 'updateUserName' },
      params: { userId, givenName, familyName },
    });
  }
}
