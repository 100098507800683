import {
  DATA_LOADING_ASYNC,
  DATA_BACKDROP,
} from '../constants';

export default {
  defaultState: {
    async: false,
  },
  reducers: {
    [DATA_LOADING_ASYNC]: (state, { payload }) => ({ ...state, async: payload }),
    [DATA_BACKDROP]: (state, { payload }) => ({ ...state, backdrop: payload }),
  },
};
