import { put, call } from 'redux-saga/effects';
import api from '../../services/api';
import { showToaster, setLoadingAsync } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* updateMetaLiveCampaign({
  payload: {
    campaignId,
    budget,
    endDate,
    endTime,
    mainMessage,
    destinationUrl,
    onDone,
  },
}) {
  yield put(setLoadingAsync(true));
  try {
    yield call(
      api.updateLiveMetaCampaign,
      campaignId,
      budget,
      endDate,
      endTime,
      mainMessage,
      destinationUrl,
    );
    yield put(showToaster(getToasterOptions('Changes succesfully queued', 'success', null, 5000)));
    onDone();
    yield put(setLoadingAsync(false));
  } catch (e) {
    yield put(showToaster(getToasterOptions('An error occurred while updating the campaign', 'error')));
    logger.error({
      error: e,
      context: { saga: 'updateStore' },
      params: {
        campaignId, budget, endDate, endTime,
      },
    });
    yield put(setLoadingAsync(false));
  }
}
