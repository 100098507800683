import _ from 'lodash';
import { put, call } from 'redux-saga/effects';
import i18n from '../../services/i18n';
import api from '../../services/api';
import { setBusinessStores, showToaster, setLoadingAsync } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* updateCooUrl({
  payload: {
    storeId, businessId, cooUrl, onDone,
  },
}) {
  try {
    yield put(setLoadingAsync(true));
    yield call(
      api.updateStore,
      storeId,
      {
        cooUrl,
      },
    );
    let stores = yield call(api.getStores, businessId);
    stores = _.sortBy(stores, 'name');
    yield put(setBusinessStores(stores));
    yield put(showToaster(getToasterOptions(i18n.t('update_store_coo_url', 'Chowly Online Ordering URL was updated successfully!'), 'success', null, 5000)));
    yield put(setLoadingAsync(false));
    onDone();
  } catch (e) {
    yield put(showToaster(getToasterOptions(i18n.t('update_store_coo_url_error', 'An error occurred updating store Chowly Online Ordering URL'), 'error')));
    yield put(setLoadingAsync(false));
    logger.error({
      error: e,
      context: { saga: 'updateCooUrl' },
      params: {
        storeId,
        businessId,
        cooUrl,
      },
    });
  }
}
