import React from 'react';
import * as PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  Typography,
  Divider,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: `${theme.spacing(4)}px 0`,
    width: '100%',
  },
  headin: {
    margin: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexWidth: {
    width: '50%',
  },
}));

const PermissionDetail = ({ permission }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h2">
        {`${permission?.id} - ${permission?.name}`}
      </Typography>
      {permission?.storePermissions?.map((storePermission) => (
        <List key={storePermission?.type}>
          <ListItem>
            <Typography className={classes.headin} variant="subtitle2">
              {'Permission Type: '}
            </Typography>
            <Typography>
              {storePermission?.type}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography className={classes.headin} variant="subtitle2">
              {'Permissions: '}
            </Typography>
            <Typography>
              {storePermission?.permissions?.toString()}
            </Typography>
          </ListItem>
        </List>
      ))}
      {permission?.missingStorePerms?.map((missingStorePerm) => (
        <List key={missingStorePerm?.type}>
          <ListItem>
            <Typography className={classes.headin} variant="subtitle2">
              {'Permission Type: '}
            </Typography>
            <Typography>
              {missingStorePerm?.type}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography className={classes.headin} variant="subtitle2">
              {'Permissions: '}
            </Typography>
            <Typography>
              {missingStorePerm?.msg}
            </Typography>
          </ListItem>
        </List>
      ))}
      <Divider className={classes.divider} />
    </div>
  );
};

PermissionDetail.propTypes = {
  permission: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    storePermissions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    missingStorePerms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

export default PermissionDetail;
