import { getCampaignAudiences } from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/campaign/campaign';
import _ from 'lodash';
import { call } from 'redux-saga/effects';

import getCustomAudiences from '../getCustomAudiences/getCustomAudiences';

export default function* validateCampaignCustomAudiences(businessId, campaign) {
  const campaignAudiences = _.get(campaign, 'ads.0.targeting.customAudiences', [{ id: 'NONE' }]);
  if (campaignAudiences[0] && campaignAudiences[0]?.id === 'NONE') {
    return true;
  }

  const customAudiences = yield call(getCustomAudiences, businessId, false);
  const campaignStores = campaign.ads.map(({ storeId }) => ({ id: storeId }));
  const opts = getCampaignAudiences(campaignStores, customAudiences);

  const selectedOptions = _.intersectionBy(campaignAudiences, opts, 'id');
  const validCustomAudiences = selectedOptions.length > 0 && !selectedOptions.some(
    (option) => option.disabled,
  );
  return validCustomAudiences;
}
