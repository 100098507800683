import { take, put } from 'redux-saga/effects';

import { setPublishMarketingDialog } from '../../actions';
import { RESULT_PUBLISH_MARKETING_DIALOG } from '../../constants';

export default function* showPublishMarketingDialog({ payload }) {
  const {
    confirmText,
    confirmDataCy,
    title,
    text,
    textParams,
    onClick,
  } = payload;

  yield put(setPublishMarketingDialog({
    isOpen: true,
    confirmText,
    confirmDataCy,
    title,
    text,
    textParams,
  }));

  const { payload: info } = yield take(RESULT_PUBLISH_MARKETING_DIALOG);
  yield put(setPublishMarketingDialog({
    isOpen: false,
  }));
  if (info === false) return;
  onClick({ payload: info });
}
