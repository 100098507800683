import {
  put,
  call,
  select,
} from 'redux-saga/effects';

import api from '../../services/api';

import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import getUsersByBusiness from '../getUsersByBusiness/getUsersByBusiness';
import { selectBusiness } from '../../selectors';
import createActivityLog from '../createActivityLog/createActivityLog';
import auth from '../../services/auth';

export default function* moveUserToBusiness({ payload }) {
  const { onDone, user, selectedBusiness } = payload;

  try {
    const currentBusiness = yield select(selectBusiness);
    yield put(showToaster(getToasterOptions(
      `Moving ${user.givenName} ${user.familyName} to ${selectedBusiness.name}...`,
      'info', null, 7000, true, null, 'bottom', 'right',
    )));

    yield call(api.updateUser, user.id, {
      businessId: selectedBusiness.id,
      businessName: selectedBusiness.name,
    });

    yield put(showToaster(getToasterOptions(
      `${user.givenName} ${user.familyName} was successfully moved to ${selectedBusiness.name}`,
      'success', null, 3000,
    )));

    // create activity log
    const authUser = auth.getUser();
    yield call(createActivityLog, {
      businessId: currentBusiness?.id,
      userId: user?.id,
      type: 'move_user_to_business',
      ui: {
        title: 'User Moved to Different Business by Targetable Admin',
        description: `Moving ${user.givenName} ${user.familyName} to ${selectedBusiness.name}`,
      },
      meta: {
        adminUserEmail: authUser?.email || authUser?.sub,
        adminUserName: authUser?.name,
        businessIdFrom: currentBusiness?.id,
        businessNameFrom: currentBusiness?.name,
        businessIdTo: selectedBusiness?.id,
        businessNameTo: selectedBusiness?.name,
        userId: user?.id,
        userName: `${user.givenName} ${user.familyName}`,
      },
    });
    // Reload user list
    yield call(getUsersByBusiness, { payload: currentBusiness.id });
    if (onDone) {
      onDone();
    }
  } catch (e) {
    yield put(showToaster(getToasterOptions('There was an error moving the user to the other business.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'moveUserToBusiness' },
      params: { payload },
    });
  }
}
