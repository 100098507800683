import {
  call, put,
} from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment';

import i18n from '../../services/i18n';
import api from '../../services/api';
import history from '../../services/history';
import date from '../../services/date';
import { showToaster } from '../../actions';
import { getSCTemplateDraftRoute } from '../../routes';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { SUGGESTED_CAMPAIGN_TEMPLATE_INACTIVE } from '../../constants';

export default function* duplicateSuggestedCampaignTemplate({ payload }) {
  try {
    const { scTemplate, onDone } = payload;
    const { suggestedCampaign } = scTemplate;

    const name = `${_.get(suggestedCampaign, 'name')} - Copy`;
    const goal = _.get(suggestedCampaign, 'goal');
    const regulatedCategories = (goal === 'hiring') ? 'EMPLOYMENT' : 'NONE';

    const specificTargets = _.get(suggestedCampaign, ['ads', '0', 'targeting', 'specificTargets']);
    const response = yield call(api.validateTargets, specificTargets, regulatedCategories);

    const duplicateCampaign = {
      ..._.omit(suggestedCampaign, ['id', 'status', 'created', 'updated', 'feedback', 'published', 'responded', 'saved']),
      created: moment.utc().valueOf(),
      saved: moment.utc().valueOf(),
      status: 'draft',
      name,
      displayDate: date.startOfToday(),
      displayTime: { hour: 0, minute: 0 },
      ads: _.map(suggestedCampaign.ads, (adItem) => ({
        ...adItem,
        targeting: {
          ...adItem.targeting,
          specificTargets: response,
        },
        schedule: {
          ...adItem.schedule,
        },
      })),
    };

    const createdSuggestedCampaignTemplate = yield call(
      api.createSuggestedCampaignTemplate, {
        ...scTemplate,
        suggestedCampaign: duplicateCampaign,
        status: SUGGESTED_CAMPAIGN_TEMPLATE_INACTIVE,
      },
    );
    history.push(getSCTemplateDraftRoute(_.get(createdSuggestedCampaignTemplate, 'id')));
    if (onDone) {
      onDone();
    }

    yield put(showToaster(getToasterOptions(i18n.t('campaigns_duplicate_created', { name }), 'info', null, 5000)));
  } catch (e) {
    yield put(showToaster(getToasterOptions('error_update_campaign')));
    logger.error({
      error: e,
      context: { saga: 'duplicateSuggestedCampaignTemplate' },
      params: { payload },
    });
  }
}
