import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    fill: theme.palette.text.secondary,
  },
}));

const TargetableIcon = ({ className, size }) => {
  const classes = useStyles();

  return (
    <svg width={size} height={size} viewBox="0 0 26 26" preserveAspectRatio="xMidYMid meet" className={classnames(classes.icon, className)}>
      <g>
        <mask fill="#fff">
          <path d="M0 0H26V26H0z" />
        </mask>
        <path d="M16.69 2.202c.61-.017 1.226.02 1.841.114.642.1 1.264.258 1.853.477.62.23 1.21.523 1.759.884.575.376 1.102.824 1.572 1.34.492.54.917 1.153 1.264 1.835.761 1.498 1.057 3.18.894 4.91-.168 1.81-.834 3.598-1.921 5.202-1.088 1.602-2.55 2.948-4.26 3.906-1.634.915-3.427 1.438-5.252 1.503-.828.03-1.625-.035-2.378-.185-.721-.144-1.4-.366-2.033-.658-.602-.278-1.16-.62-1.667-1.015-.484-.379-.923-.806-1.311-1.278l-.164-.202 3.63-.504c.252.1.51.185.776.254.27.07.548.123.833.16.29.038.586.058.888.058.306.001.619-.018.938-.054 1.257-.15 2.468-.589 3.56-1.27 1.129-.7 2.098-1.634 2.834-2.72.737-1.084 1.22-2.287 1.4-3.513.175-1.187.062-2.362-.36-3.44-.195-.501-.448-.958-.75-1.368-.293-.398-.63-.75-1.006-1.056-.363-.295-.76-.545-1.188-.749-.413-.197-.851-.35-1.311-.459-.444-.105-.896-.165-1.348-.182-.44-.018-.882.005-1.32.065-.428.057-.854.151-1.274.28-.412.125-.819.28-1.22.47-.214.101-.422.21-.625.324-.2.114-.396.235-.587.364-.19.125-.374.26-.554.401-.178.14-.352.287-.522.44L6.977 7.779l.127-.188c.289-.422.604-.827.939-1.208.338-.384.7-.748 1.082-1.087.388-.344.8-.667 1.232-.965.442-.303.908-.58 1.398-.83.503-.258 1.02-.48 1.542-.663.54-.189 1.09-.337 1.645-.443.577-.11 1.161-.176 1.748-.192zM13.078 13.44l3.077.877-2.643 2.229L3.31 18.349l.211-2.483 9.558-2.426zm2.67-5.007l3.284.329-2.663 2.338-16.31 4.692.32-1.942 15.37-5.417zm-3.664-2.08l2.418.091-2.123 2.017-8.505 3.24.157-1.836 8.053-3.512z" mask="url(#prefix__b)" />
      </g>
    </svg>
  );
};

TargetableIcon.defaultProps = {
  className: '',
  size: 24,
};

TargetableIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

export default TargetableIcon;
