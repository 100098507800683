import { call, put } from 'redux-saga/effects';
import api from '../../services/api';
import {
  setBusinessPixels,
  setBusinessPixelsLoading,
} from '../../actions';
import logger from '../../services/logger';

export default function* getStorePixels({ payload }) {
  yield put(setBusinessPixelsLoading(true));

  try {
    const pixels = yield call(api.getStorePixels, payload?.storeId, payload?.businessId);
    yield put(setBusinessPixels(pixels));
    yield put(setBusinessPixelsLoading(false));
  } catch (e) {
    yield put(setBusinessPixelsLoading(false));
    logger.error({
      error: e,
      context: { saga: 'getStorePixels' },
      params: { businessId: payload?.businessId },
    });
  }
}
