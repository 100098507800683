import { put, call, select } from 'redux-saga/effects';
import moment from 'moment';
import { INSIGHT_STATUS_PUBLISHED } from '@targetable/targetable-web-framework/lib/react/constants';

import api from '../../services/api';

import { showToaster, closeToaster, setLoadingAsync } from '../../actions';
import { selectBusinessIdQueried } from '../../selectors';
import logger from '../../services/logger';
import getInsightsByBusiness from '../getInsightsByBusiness/getInsightsByBusiness';
import { getToasterOptions } from '../helpers';

export default function* createInsight({ payload }) {
  const { onSave, insight, onError } = payload;

  try {
    yield put(showToaster(getToasterOptions(
      insight.status === INSIGHT_STATUS_PUBLISHED
        ? 'Creating and publishing the Insight...'
        : 'Creating the Insight...',
      'info', null, 7000, true, null, 'bottom', 'right',
    )));

    const businessId = yield select(selectBusinessIdQueried);
    if (insight.status === INSIGHT_STATUS_PUBLISHED) {
      insight.published = moment.utc().valueOf();
    }
    if (!insight.id) {
      insight.saved = moment.utc().valueOf();
    }

    // Validate if URL exists
    if (insight?.url && insight?.url?.length) {
      yield put(setLoadingAsync(true));
      const siteExists = yield call(api.siteExists, insight.url);
      yield put(setLoadingAsync(false));
      if (!siteExists) {
        if (onError) onError('url');
        yield put(closeToaster());
        return;
      }
    }

    yield call(api.createInsight, { ...insight, businessId });
    yield call(getInsightsByBusiness, { payload: { businessId } });
    yield put(closeToaster());

    onSave();
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to create insight. Try again in a few minutes.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'createInsight' },
      params: { payload },
    });
  }
}
