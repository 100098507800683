import * as campaignService from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/campaign/campaign';
import {
  CAMPAIGN_SUBMITTED,
  CAMPAIGN_SUBMITTED_WITH_ISSUES,
  CAMPAIGN_SUBMITTED_FAILED,
  CAMPAIGN_SUBMITTED_LIVE,
} from '@targetable/targetable-web-framework/lib/react/constants';
import { MarketingChannelTypes } from '@targetable/targetable-types/dist/types/marketing-channel/types';

import {
  call,
  fork,
  put,
  select,
  take,
} from 'redux-saga/effects';
import _ from 'lodash';

import {
  setCampaign,
  setCampaignLoading,
  setStoresCustomAudiences,
} from '../../actions';
import api from '../../services/api';
import { selectBusinessCampaigns, selectBusinessStores } from '../../selectors';
import { DATA_BUSINESS_STORES } from '../../constants';
import logger from '../../services/logger';
import getCampaignAdCardPresets from '../getCampaignAdCardPresets/getCampaignAdCardPresets';
import getCampaignMetrics from '../getCampaignMetrics/getCampaignMetrics';
import getCampaignUrlPresets from '../getCampaignUrlPresets/getCampaignUrlPresets';
import getAdMetrics from '../getAdMetrics/getAdMetrics';

export default function* getCampaign({ payload: campaignId }) {
  yield put(setCampaignLoading(true));

  try {
    const campaigns = yield select(selectBusinessCampaigns);
    let campaign = _.find(campaigns, ['id', campaignId]);

    let stores = yield select(selectBusinessStores);
    if (_.isEmpty(stores)) {
      yield take(DATA_BUSINESS_STORES);
      stores = yield select(selectBusinessStores);
    }

    // If campaign'prop isOptimized is present, it's because it came from Algolia
    // so we need to qury the actual DDB record
    if (_.isNil(campaign) || campaign?.isOptimized) {
      campaign = yield call(api.getCampaign, campaignId, stores);
      if (_.isEmpty(campaign)) {
        campaign = yield call(api.getAdById, campaignId);
      }
    }

    const campaignType = _.get(campaign, 'type', MarketingChannelTypes.Facebook);

    /* Campaigns with statuses: CAMPAIGN_SUBMITTED_WITH_ISSUES and CAMPAIGN_SUBMITTED_FAILED
    * will be temporary opened as read-only.
    */
    if (campaignType === MarketingChannelTypes.Google) {
      yield fork(getAdMetrics, campaign);
    }

    if (campaignType === MarketingChannelTypes.Facebook) {
      if (campaign.status === CAMPAIGN_SUBMITTED
        || campaign.status === CAMPAIGN_SUBMITTED_LIVE
        || campaign.status === CAMPAIGN_SUBMITTED_WITH_ISSUES
        || campaign.status === CAMPAIGN_SUBMITTED_FAILED) {
        yield fork(getCampaignMetrics, campaign);
        yield call(getCampaignUrlPresets, {
          payload: {
            businessId: campaign.businessId,
            reset: true,
          },
        });
        yield call(getCampaignAdCardPresets, campaign, false, true);
      } else {
        yield call(getCampaignUrlPresets, {
          payload: {
            businessId: campaign.businessId,
            filter: true,
            backfill: true,
            reset: true,
          },
        });
        yield call(getCampaignAdCardPresets, campaign, true, true);
      }

      // get custom audience options from what was used in the campaign
      // We don't actually need to fetch custom audiences for read only
      yield put(setStoresCustomAudiences(
        campaignService.getCampaignAudienceOptions(campaign),
      ));
    }

    yield put(setCampaign(campaign));
    yield put(setCampaignLoading(false));
  } catch (e) {
    yield put(setCampaignLoading(false));
    logger.error({
      error: e,
      context: { saga: 'getCampaign' },
      params: { campaignId },
    });
  }
}
