import { put, call } from 'redux-saga/effects';
import api from '../../services/api';
import { showToaster, closeToaster, setLoadingAsync } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { CAMPAIGN_SUBMITTED_SMARTFEED } from '../../constants';

export default function* revertMarketingSuggestedCampaignToDraft({ payload }) {
  const { onSave, suggestedCampaign, onError } = payload;

  try {
    // Only Ads with 'smartFeed' status can e returned to draft
    if (suggestedCampaign.status === CAMPAIGN_SUBMITTED_SMARTFEED) {
      yield put(showToaster(getToasterOptions('Returning the Ad to Draft...',
        'info', null, 7000, true, null, 'bottom', 'right')));

      yield put(setLoadingAsync(true));
      const adDraft = yield call(api.revertAdToDraft, suggestedCampaign?.id);

      // At this point, the revert was successfull
      if (onSave) { onSave(adDraft); }

      // Reload suggested campaigns - Unnecessary call because getting from algolia
      // yield call(getSuggestedCampaignsByBusiness, {
      //   payload: { businessId: suggestedCampaign?.businessId },
      // });

      yield put(setLoadingAsync(false));
      yield put(closeToaster());
    }
  } catch (e) {
    yield put(showToaster(getToasterOptions(e?.response?.data.message || e?.message?.data || 'Failed to create the Suggested Campaign Template. Try again in a few minutes.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'revertMarketingSuggestedCampaignToDraft' },
      params: { payload },
    });
    if (onError) {
      onError();
    }
  }
}
