import { put, call, select } from 'redux-saga/effects';

import api from '../../services/api';

import { setBusiness } from '../../actions';
import { selectBusiness } from '../../selectors';
import logger from '../../services/logger';

export default function* getBusinessSaga({ payload }) {
  const { onDone, onError, ...cleanPayload } = payload;
  const business = yield select(selectBusiness);

  try {
    yield call(api.updateBusiness, business.id, cleanPayload);
    yield put(setBusiness({
      ...business,
      ...cleanPayload,
      featureFlags: { ...business.featureFlags, ...cleanPayload.featureFlags },
    }));
    if (onDone) { onDone(); }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getBusinessSaga' },
      params: { payload },
    });
    if (onError) { onError(e); }
  }
}
