import { call, put } from 'redux-saga/effects';
import _ from 'lodash';
import deepMerge from 'deepmerge';

import api from '../../services/api';

import { setUrlPresets, showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* saveUrlPresets({ payload }) {
  const {
    businessId, presets, onError, onDone,
  } = payload;

  try {
    const cpPresets = _.cloneDeep(presets);

    // check urls
    const validationResults = yield Promise.all(cpPresets.map(
      async (p) => Promise.all(p.stores.map(async (store) => {
        if (store.urlChecked === true) return true;
        if (store.url) {
          const isValidUrl = await api.siteExists(store.url);
          store.urlError = !isValidUrl; // eslint-disable-line no-param-reassign
          store.urlChecked = true; // eslint-disable-line no-param-reassign
          return isValidUrl;
        }
        store.urlError = false; // eslint-disable-line no-param-reassign
        store.urlChecked = false; // eslint-disable-line no-param-reassign
        return true;
      })),
    ));

    if (!_.every(_.flatten(validationResults))) {
      yield put(setUrlPresets(cpPresets));
      if (onError) onError();
      return;
    }

    const serverPresets = yield call(api.getUrlPresets, businessId);

    // convert store arrays to objects
    // also merge any removed stores back into the data
    const updatedPresets = presets.map((preset) => deepMerge(
      _.find(serverPresets, { id: preset.id }) || {},
      {
        ...(preset.id ? { id: preset.id } : {}),
        ...(preset.created ? { created: preset.created } : {}),
        ...(preset.updated ? { updated: preset.updated } : {}),
        businessId,
        name: preset.name,
        removed: !!preset.removed,
        stores: _.keyBy(preset.stores.map(({ id, url }) => ({ id, url })), 'id'),
      },
    ));

    yield call(api.upsertUrlPresets, updatedPresets);

    if (onDone) onDone();
    yield put(setUrlPresets([]));
  } catch (e) {
    if (onError) onError();
    yield put(showToaster(getToasterOptions('Failed saving URL preset data. Try again in a few minutes.', 'error', null, 5000)));

    logger.error({
      error: e,
      context: { saga: 'saveUrlPresets' },
      params: {
        businessId, presets,
      },
    });
  }
}
