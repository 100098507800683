import React, {
  useState, useEffect, useCallback,
} from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import { Permissions as UserPermissions } from '@targetable/targetable-types/dist/types/permissions';
import DrawerContent from '@targetable/targetable-web-framework/lib/react/containers/DrawerContent/DrawerContent';
import { MenuItem } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setAuth0PermissionsAction } from '../../../actions';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 613,
    margin: `${theme.spacing(3)}px auto`,
  },
  buttonRight: {
    marginRight: theme.spacing(2),
  },
  roleSelect: {
    marginLeft: '39px',
    width: 257,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const roles = [
  { key: 'manager', value: 'Manager' },
  { key: 'editor', value: 'Editor' },
];

/**
 * Determines an equivalent role given a list of permissions
 * @param {string[]} permissions
 * @return {'manager' | 'editor'} the matching role.
 */
const getRoleByPermissions = (permissions) => {
  const isManager = (permissions || []).includes(UserPermissions.UserPortal.Billing.Read);
  const userRole = isManager ? 'manager' : 'editor';
  return userRole;
};

/**
 * Removes or adds to the given list of permissions those that are required for the given role.
 * @param {string[]} userPermissions a list of permissions currently assigned to the user
 * @param {'manager' | 'editor'} role the name of the role
 * @return {UserPermissions[]} the list of permissions
 */
const getPermissionsFromRole = (userPermissions, role) => {
  // Clean up all relevant permissions to start fresh
  const updatedPermissions = (userPermissions || []).filter((permission) => (
    permission !== UserPermissions.UserPortal.Billing.Write
    && permission !== UserPermissions.UserPortal.Billing.Read
    && permission !== UserPermissions.User.ReadAll
    && permission !== UserPermissions.Ad.WriteAll
  ));

  if (role === 'manager') {
    updatedPermissions.push(
      UserPermissions.UserPortal.Billing.Read,
      UserPermissions.User.ReadAll,
      UserPermissions.Ad.WriteAll,
    );
  }

  return updatedPermissions;
};

const RolePermissionsDialog = ({
  onUserUpdated,
  onCancel,
  title,
  user,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [updatingRole, setUpdatingRole] = useState(false);
  const userRole = getRoleByPermissions(user.auth0Permissions);
  const [selectedRole, setSelectedRole] = useState(userRole);

  const handleRoleChange = useCallback((event) => {
    setSelectedRole(event.target.value);
  }, [setSelectedRole]);

  const onSubmit = useCallback(() => {
    setUpdatingRole(true);
    const permissions = getPermissionsFromRole(user.auth0Permissions, selectedRole);
    dispatch(setAuth0PermissionsAction({
      auth0Id: user.auth0Id,
      permissions,
      onComplete: () => {
        setUpdatingRole(false);
        onUserUpdated({
          ...user,
          auth0Permissions: permissions,
        });
        onCancel();
      },
    }));
  }, [dispatch, user, selectedRole, onCancel, onUserUpdated]);

  useEffect(() => {
    if (!openDrawer) {
      setOpenDrawer(true);
    }
  }, [openDrawer]);

  return (
    <DrawerContent
      open={openDrawer}
      onClose={() => onCancel()}
      title={title}
      buttons={[{
        text: t('save'),
        classes: classes.buttonRight,
        variant: 'contained',
        color: 'secondary',
        disabled: !selectedRole || updatingRole || userRole === selectedRole,
        onClick: () => {
          onSubmit();
        },
      }]}
      invisibleBackdrop
    >
      <Box className={classes.root}>
        <TextField
          select
          label="Role"
          value={selectedRole}
          onChange={handleRoleChange}
          variant="outlined"
          margin="none"
          autoComplete="off"
          data-cy="roleSelect"
          className={classes.roleSelect}
          disabled={!user.auth0Id || updatingRole}
        >
          {
            roles.map((role) => (
              <MenuItem key={role.key} value={role.key}>
                {role.value}
              </MenuItem>
            ))
          }
        </TextField>
      </Box>
    </DrawerContent>
  );
};

RolePermissionsDialog.propTypes = {
  title: PropTypes.string.isRequired,
  onUserUpdated: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    auth0Id: PropTypes.string,
    auth0Permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default RolePermissionsDialog;
