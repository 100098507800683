import { call, put, select } from 'redux-saga/effects';
import api from '../../services/api';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { selectBusinessIdQueried } from '../../selectors';
import { showToaster } from '../../actions';
import auth from '../../services/auth';

export default function* requestFbToken({ payload }) {
  const { userId, onDone } = payload;
  const user = auth.getUser();

  try {
    yield put(showToaster(getToasterOptions('Requesting the User\'s Facebook Token...',
      'info', null, 7000, true, null, 'bottom', 'right')));

    const businessId = yield select(selectBusinessIdQueried);
    const res = yield call(api.requestFacebookToken, userId, businessId, user.sub);
    if (res) {
      yield put(showToaster(getToasterOptions('Success! Token requested.', 'success', null, 5000)));
    } else {
      yield put(showToaster(getToasterOptions('Whoops, something went wrong… Please try again.', 'error')));
    }
  } catch (e) {
    yield put(showToaster(getToasterOptions('Whoops, something went wrong… Please try again.', 'error')));
    logger.error({
      error: 'Error during fb token request',
      context: { saga: 'requestFbToken' },
      params: { userId, user },
    });
  }

  if (onDone) {
    onDone();
  }
}
