import { call, put } from 'redux-saga/effects';
import api from '../../services/api';
import { showToaster } from '../../actions';
import i18n from '../../services/i18n';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* pauseMailchimpCampaign({ payload }) {
  const {
    adId, marketingChannelId, onDone,
  } = payload;

  try {
    yield put(showToaster(getToasterOptions('Pausing mailchimp campaign...', 'info', null, 7000)));
    yield call(api.changeMailchimpAdStatus, adId, 'paused');
    yield put(showToaster(getToasterOptions(i18n.t('successfully_pause_resume_campaign', { action: 'paused' }), 'info', null, 5000)));
    if (onDone) { onDone(); }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'pauseMailchimpCampaign' },
      params: {
        adId,
        marketingChannelId,
      },
    });
    yield put(showToaster(getToasterOptions(i18n.t('error_pause_resume_campaign', { action: 'paused' }))));
  }
}
