import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import PageContentContainer from '@targetable/targetable-web-framework/lib/react/containers/PageContentContainer/PageContentContainer';
import FullScreenDialog from '@targetable/targetable-web-framework/lib/react/containers/FullScreenDialog/FullScreenDialog';
import ComponentLoading from '@targetable/targetable-web-framework/lib/react/components/ComponentLoading/ComponentLoading';

import { getAllPermissions } from '../../../actions';
import { selectTokenPermissions, selectUsersPermissionsLoading } from '../../../selectors';
import PermissionDetail from '../PermissionDetail/PermissionDetail';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: '100%',
    margin: `${theme.spacing(3)}px auto`,
  },
  root: {
    maxWidth: '100%',
    margin: `${theme.spacing(3)}px auto`,
  },
  divider: {
    margin: `${theme.spacing(4)}px 0`,
    width: '100%',
  },
}));

const PermissionsDialog = ({
  title,
  onCancel,
  userId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const permissions = useSelector(selectTokenPermissions);
  const permissionsLoading = useSelector(selectUsersPermissionsLoading);

  useEffect(() => {
    dispatch(getAllPermissions(userId));
  }, [dispatch, userId]);

  return (
    <FullScreenDialog
      open
      onClose={() => onCancel()}
      title={title}
    >
      <PageContentContainer className={classes.pageContainer}>
        <>
          <ComponentLoading waitKey="users_permissiones_loading" loading={permissionsLoading}>
            <Box className={classes.root}>
              {permissions?.map((permission) => (
                <PermissionDetail
                  key={permission?.id}
                  permission={permission}
                />
              ))}
            </Box>
          </ComponentLoading>
        </>
      </PageContentContainer>
    </FullScreenDialog>
  );
};

PermissionsDialog.propTypes = {
  title: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

export default PermissionsDialog;
