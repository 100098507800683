import { call, put, all } from 'redux-saga/effects';

import api from '../../services/api';
import {
  setBusinessUsers,
  setBusinessUsersLoading,
} from '../../actions';
import logger from '../../services/logger';

function* callPermsRequest(auth0Id) {
  try {
    const result = yield call(api.getAuth0Permissions, auth0Id);
    if (!Array.isArray(result)) throw new Error('Invalid');
    return { v: { permissions: result, auth0Id } };
  } catch (err) {
    return { e: err };
  }
}

export default function* getUsersByBusiness({ payload: businessId }) {
  yield put(setBusinessUsersLoading(true));

  try {
    const response = yield call(api.getUsers, businessId);
    let users = response?.users || [];

    // Add the auth0 permissions to each user
    const calls = (users || []).map((user) => (
      user.auth0Id ? call(callPermsRequest, user.auth0Id) : []
    ));

    // Add the auth0 email's verification flag
    const auth0UserCalls = (users || []).map((user) => (
      user.auth0Id ? call(api.getUser, user.id, true) : []
    ));

    const usersAuth0Permissions = {};
    try {
      const results = yield all(calls);
      if (results && results.length > 0) {
        results.forEach((result) => {
          const perms = !result?.e ? result?.v || [] : undefined;
          if (perms?.auth0Id) {
            usersAuth0Permissions[perms?.auth0Id] = perms.permissions;
          }
        });
      }
    } catch (e) {
      logger.error({
        error: e,
        context: { saga: 'getUsersByBusiness', method: 'userPermissions' },
        params: { businessId },
      });
    }
    const auth0Users = yield all(auth0UserCalls);

    users.forEach((user, index) => {
      // UPDATED on 07/Dec/2023: TGT user records now have a `permissions` property
      // that clashes with the auth0 `permissions` property added by this code.
      // users[index].permissions = permissions;   // <=== OLD
      if (user?.auth0Id) {
        users[index].auth0Permissions = usersAuth0Permissions[user.auth0Id]; // <=== NEW
      }
    });

    auth0Users.forEach((auth0User, index) => {
      if (users[index]) {
        users[index].emailVerified = auth0User?.email_verified || false;
      }
    });

    // Remove users deleted from Auth0
    users = users?.filter((user, index) => auth0Users?.[index] !== undefined);

    yield put(setBusinessUsers(users));
    yield put(setBusinessUsersLoading(false));
  } catch (e) {
    yield put(setBusinessUsersLoading(false));
    logger.error({
      error: e,
      context: { saga: 'getUsersByBusiness' },
      params: { businessId },
    });
  }
}
