import _ from 'lodash';
import { call, put } from 'redux-saga/effects';

import api from '../../services/api';
import {
  setBusinessStores,
  setBusinessStoresLoading,
} from '../../actions';
import logger from '../../services/logger';

export default function* getStoresByBusiness({ payload: businessId }) {
  yield put(setBusinessStoresLoading(true));

  try {
    let stores = yield call(api.getStores, businessId);
    stores = _.sortBy(stores, 'name');
    yield put(setBusinessStores(stores));
    yield put(setBusinessStoresLoading(false));
    return stores;
  } catch (e) {
    yield put(setBusinessStoresLoading(false));
    logger.error({
      error: e,
      context: { saga: 'getStoresByBusiness' },
      params: { businessId },
    });
    return false;
  }
}
