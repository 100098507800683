import {
  put, call, select, take, all,
} from 'redux-saga/effects';
import _ from 'lodash';
import i18n from '../../services/i18n';
import api from '../../services/api';
import {
  showToaster,
  setLoadingAsync,
} from '../../actions';
import { selectBusinessStores } from '../../selectors';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { DATA_BUSINESS_STORES } from '../../constants';

export default function* updateStorePresets({ payload }) {
  const {
    storeId, presets, onDone, newPresets,
  } = payload;
  try {
    yield put(setLoadingAsync(true));
    yield call(api.updateStore, storeId, { presets });
    // When a new Preset is created, create preset for all other stores,
    // but with blank location fields
    if (!_.isEmpty(newPresets)) {
      let stores = yield select(selectBusinessStores);
      if (_.isEmpty(stores)) {
        yield take(DATA_BUSINESS_STORES);
        stores = yield select(selectBusinessStores);
      }
      const groupedPresets = _.groupBy(newPresets, 'name');
      const presetsNames = Object.keys(groupedPresets);
      const emptyPresets = presetsNames.map((name) => ({
        name,
        location: { address: '' },
      }));
      const otherStores = _.filter(stores, (store) => store.id !== storeId);
      const operations = otherStores.map((st) => {
        const mergedPresets = _.concat(
          st?.presets?.locationTargeting || [],
          emptyPresets,
        );
        return call(api.updateStore, st.id, { presets: { locationTargeting: mergedPresets } });
      });
      yield all(operations);
    }
    yield put(showToaster(getToasterOptions(i18n.t('update_store_presets_success'), 'success', null, 5000)));
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to update store presets', 'error')));
    logger.error({
      error: e,
      context: { saga: 'updateStorePresets' },
      params: { payload },
    });
  }
  yield put(setLoadingAsync(false));
  if (onDone) {
    onDone();
  }
}
