import { put, call, take } from 'redux-saga/effects';
import _ from 'lodash';
/* eslint-disable no-unused-vars */
import { GenericAd } from '@targetable/targetable-types/dist/types/ad-service/types';
/* eslint-enable no-unused-vars */
import api from '../../services/api';
import { showToaster, setLoadingAsync, showActivityLogNoteDialog } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { RESULT_ACTIVITY_LOG_DIALOG } from '../../constants';
import i18n from '../../services/i18n';

export default function* publishMarketingAd({ payload }) {
  /** @type {{ campaign: GenericAd }} */
  const {
    onDone,
    onError,
    campaign,
    defaultNote,
  } = payload;

  if (campaign?.group?.ads && defaultNote) {
    yield put(showToaster(getToasterOptions(
      'Publishing group ad...', 'info', null, 20000, true, null, 'bottom', 'right',
    )));
  }

  try {
    let finalNote = defaultNote;
    if (!defaultNote) {
      yield put(showActivityLogNoteDialog({
        title: 'Add Note? (optional)',
        text: 'You can add any notes, descriptions, reasons for the action you are about to take. This may or may not be exposed to the customer.',
        confirmText: 'submit',
        cancelText: 'cancel',
        onClick: _.identity,
      }));

      const { payload: note } = yield take(RESULT_ACTIVITY_LOG_DIALOG);
      finalNote = note;
    }

    const questionnaireInfo = [
      { question: i18n.t('feedback_suggested_campaign_saved_question_1') },
      { question: i18n.t('feedback_suggested_campaign_saved_question_2') },
      { question: i18n.t('feedback_suggested_campaign_saved_question_3') },
    ];
    const feedback = questionnaireInfo.map((listItem) => ({
      sentiment: 'satisfied',
      question: listItem.question,
    }));
    feedback.push({
      question: i18n.t('feedback_additional_thoughts'),
      sentiment: 'satisfied',
      answer: finalNote || '',
    });

    yield call(api.setAdFeedback, campaign.id, feedback, 'approved');

    yield put(setLoadingAsync(true));
    if (campaign.group?.ads) {
      // Group publishing
      yield call(api.publishGroupAd, campaign?.id);
    } else {
      // Single ad publishing
      yield call(api.publishAd, campaign?.id);
    }

    // At this point, the publidhing was successful
    if (onDone) { onDone(); }

    yield put(setLoadingAsync(false));
    yield put(showToaster(getToasterOptions('Ad published successfully', 'success', null, 5000)));
  } catch (e) {
    yield put(showToaster(getToasterOptions(e?.response?.data.message || e?.message?.data || e?.response?.data || 'Failed to publish the Suggested Campaign. Try again in a few minutes.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'publishMarketingAd' },
      params: { payload },
    });
    if (onError) {
      onError();
    }
  }
}
