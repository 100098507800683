import { put, call } from 'redux-saga/effects';
import api from '../../services/api';
import { showToaster, closeToaster, setLoadingAsync } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { CAMPAIGN_SUBMITTED_SMARTFEED, CAMPAIGN_ARCHIVED } from '../../constants';

export default function* updateMarketingSuggestedCampaign({ payload }) {
  const {
    onSave, suggestedCampaign, onError, isReturnedToDraft, skipCloseToaster,
  } = payload;

  let toastMessage = isReturnedToDraft ? 'Returning insight to Draft...' : 'Saving your changes...';
  try {
    if (suggestedCampaign.status === CAMPAIGN_SUBMITTED_SMARTFEED) {
      toastMessage = 'Publishing the Ad...';
    }

    if (suggestedCampaign.status === CAMPAIGN_ARCHIVED) {
      toastMessage = 'Deleting the Ad...';
    }

    yield put(showToaster(getToasterOptions(
      toastMessage, 'info', null, 7000, true, null, 'bottom', 'right',
    )));

    yield put(setLoadingAsync(true));
    const adDraft = yield call(api.updateAdDraft, suggestedCampaign?.id, suggestedCampaign);

    // At this point, the updating was successfull
    if (onSave) { onSave(adDraft); }

    yield put(setLoadingAsync(false));
    if (!skipCloseToaster) {
      yield put(closeToaster());
    }
  } catch (e) {
    yield put(showToaster(getToasterOptions(e?.response?.data.message || e?.message?.data || 'Failed to update the Suggested Campaign Template. Try again in a few minutes.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'updateMarketingSuggestedCampaign' },
      params: { payload },
    });
    if (onError) {
      onError();
    }
  }
}
