import React from 'react';
import PropTypes from 'prop-types';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  listItemText: {
    padding: theme.spacing(0),
  },
}));

const ClickableListItem = ({
  dataAttribute,
  icon,
  onClick,
  primaryTypographyProps,
  selected,
  text,
}) => {
  const classes = useStyles();

  let iconComponent;
  if (icon) {
    iconComponent = (
      <ListItemIcon>
        {icon}
      </ListItemIcon>
    );
  }

  return (
    <ListItem button selected={selected} onClick={onClick} data-cy={dataAttribute}>
      {iconComponent}
      <ListItemText className={classes.listItemText} primary={text} primaryTypographyProps={{ ...primaryTypographyProps, variant: 'h4' }} />
    </ListItem>
  );
};

ClickableListItem.defaultProps = {
  dataAttribute: '',
  icon: undefined,
  primaryTypographyProps: {},
  selected: false,
};

ClickableListItem.propTypes = {
  dataAttribute: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  primaryTypographyProps: PropTypes.shape({}),
  selected: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

export default ClickableListItem;
