import { call, put } from 'redux-saga/effects';

import { getToasterOptions } from '../helpers';
import { showToaster as showToasterAction } from '../../actions';
import saveCampaign from '../saveCampaign/saveCampaign';

export default function* saveCampaignRetryTimer({ payload }) {
  const {
    onDone,
    onError,
    showToaster,
    campaign,
  } = payload;

  const savedCampaign = yield call(saveCampaign, {
    id: campaign.id,
    retryTimeout: campaign.retryTimeout,
    status: campaign.status,
  }, true);

  if (savedCampaign) {
    if (onDone) { onDone(); }
    if (showToaster) {
      yield put(showToasterAction(getToasterOptions('campaign_retry_timer_saved', 'success', null, 5000)));
    }
  } else {
    if (onError) {
      onError();
    }

    yield put(showToasterAction(getToasterOptions('error_update_campaign', 'error')));
  }
}
