import { all, call } from 'redux-saga/effects';
import moment from 'moment';
import algoliaSearch from 'algoliasearch/lite';
import { INSIGHT_STATUS_DRAFT, INSIGHT_STATUS_PUBLISHED } from '@targetable/targetable-web-framework/lib/react/constants';

import globals from '../../globals';
import api from '../../services/api';
import dateService from '../../services/date';
import logger from '../../services/logger';

const client = algoliaSearch(globals.ALGOLIA_APP_ID, globals.ALGOLIA_API_SEARCH_KEY);
const index = client.initIndex(`${globals.ENVIRONMENT_NAME}_InsightTemplates`);

export default function* createDefaultInsights({ businessId }) {
  try {
    const res = yield index.search('', {
      getRankingInfo: false,
      analytics: false,
      enableABTest: false,
      hitsPerPage: 100,
      attributesToRetrieve: ['autoPublish', 'insight'],
      responseFields: ['hits'],
      maxValuesPerFacet: 100,
      facets: ['*', 'facets'],
      facetFilters: [
        ['facets:Trigger~Onboarding'],
        ['facets:Vertical~All Businesses'],
        ['facets:status~active'],
        ['facets:type~insight'],
      ],
    });

    yield all(res.hits.map(({ autoPublish, insight }) => call(api.createInsight, {
      type: insight?.type,
      title: insight?.title,
      description: insight?.description,
      businessId,
      created: moment.utc().valueOf(),
      published: moment.utc().valueOf(),
      status: autoPublish ? INSIGHT_STATUS_PUBLISHED : INSIGHT_STATUS_DRAFT,
      displayDate: dateService.startOfToday(),
      displayTime: { hour: 0, minute: 0 },
    })));
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'createDefaultInsights' },
      params: { businessId },
    });
  }
}
