import {
  call,
  put,
  select,
  take,
  all,
} from 'redux-saga/effects';
import _ from 'lodash';
import {
  handleSuggestedCampaignFormData,
  hasInvalidCampaignStores,
  isCallNowAndHasAStoreWithoutAPhone,
} from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/campaign/campaign';
import { MarketingChannelTypes } from '@targetable/targetable-types/dist/types/marketing-channel/types';
import {
  RESULT_CONFIRMATION_DIALOG,
} from '../../constants';
import { getSuggestedDraftRoute } from '../../routes';
import history from '../../services/history';
import logger from '../../services/logger';

import saveSuggestedCampaign from '../saveSuggestedCampaign/saveSuggestedCampaign';
import { selectBusinessStores, selectRouterLocationPath } from '../../selectors';
import {
  setLoadingAsync,
  setSuggestedCampaign,
  showConfirmationDialog,
  showToaster,
  setConfirmationDialog,
} from '../../actions';
import { getToasterOptions } from '../helpers';
import validateCampaignCustomAudiences from '../validateCampaignCustomAudiences/validateCampaignCustomAudiences';
import validateCampaignUrlPresets from '../validateCampaignUrlPresets/validateCampaignUrlPresets';
import validateCampaignDisplayLink from '../validateCampaignDisplayLink/validateCampaignDisplayLink';

import api from '../../services/api';

export default function* handleSuggestedCampaign({ payload }) {
  const {
    onDone,
    onError,
    suggestedCampaign,
    publish,
    returnedToDraft,
  } = payload;

  const multipleMetaIdsToPublishOrReturnDraft = payload?.suggestedCampaigns;
  const multiMarketingIdsToRevertToDraft = payload?.suggestedCampaignsMktIds;
  const multiMarketingSCToPublish = payload?.suggestedCampaignsMkt;

  const stores = yield select(selectBusinessStores);

  // when publishing from the card, only arrives an array with sc ids
  // payload?.suggestedCampaigns => [{ id: 'sc id' }]
  if (publish && suggestedCampaign) {
    if (isCallNowAndHasAStoreWithoutAPhone(suggestedCampaign, stores)) {
      yield put(
        showToaster(getToasterOptions('campaign_invalid_store_cta', 'error', null, 5000, true)),
      );
      logger.error({
        error: 'Store missing a phone number with CTA Call Now',
        context: { saga: 'handleSuggestedCampaign' },
        params: { suggestedCampaign, publish },
      });
      if (onDone) {
        onDone();
      }
      return;
    }
  }

  try {
    let suggestedCampaignData;
    let businessId;

    const isMetaCampaign = ![
      MarketingChannelTypes.MailChimp,
      MarketingChannelTypes.Google].includes(suggestedCampaign?.type);
    const isMultipleOperation = (multiMarketingIdsToRevertToDraft?.length > 1
      || multiMarketingSCToPublish?.length > 1
      || multipleMetaIdsToPublishOrReturnDraft?.length > 1);
    let action = publish ? 'Publishing to SmartFeed' : 'Saving';
    if (returnedToDraft) {
      action = 'Returning to Draft';
    }
    const context = isMultipleOperation ? 'the selected Suggested Campaigns' : '';
    yield put(showToaster(getToasterOptions(`${action} ${context}...`,
      'info', null, 7000, true, null, 'bottom', 'right')));

    // Perform publishing validations for non-saved SC (from editor)
    // By using the whole redux SC object
    if (!multipleMetaIdsToPublishOrReturnDraft
      && !multiMarketingIdsToRevertToDraft
      && !multiMarketingSCToPublish
      && isMetaCampaign
      && !suggestedCampaign?.isOptimized // Algolia's object returns the 'isOptimized' property
    ) {
      // Init legacy publishing for only one object
      // clean up sc data
      suggestedCampaignData = handleSuggestedCampaignFormData(payload);
      suggestedCampaignData = {
        ..._.omit(suggestedCampaignData, ['businessName']),
      };
      businessId = suggestedCampaign?.businessId;

      if (publish) {
        if (hasInvalidCampaignStores(suggestedCampaignData, stores)) {
          yield put(
            showToaster(getToasterOptions('campaign_invalid_store_dialog', 'error', null, 5000, true)),
          );
          logger.error({
            error: 'Invalid Store for Campaign',
            context: { saga: 'handleSuggestedCampaign' },
            params: { suggestedCampaign, publish },
          });
          return;
        }

        const presetsValid = yield call(
          validateCampaignUrlPresets,
          businessId,
          suggestedCampaignData,
        );
        const campaignCTA = _.get(suggestedCampaignData, 'ads.0.ad.callToAction');
        if (!presetsValid.valid && campaignCTA !== 'CALL_NOW') {
          if (presetsValid.error === 'invalid_preset') {
            yield put(setLoadingAsync(false));
            const pathname = yield select(selectRouterLocationPath);
            const inFormView = pathname.includes('campaigns/suggested');

            // When backDrop is clicked, it should returns the 'cancel' string
            // in the RESULT_CONFIRMATION_DIALOG
            yield put(setConfirmationDialog({
              resultOnBackdropClick: 'cancel',
            }));

            yield put(showConfirmationDialog({
              text: 'url_preset_publish_missing_error',
              confirmText: 'edit',
              cancelText: 'continue',
              onClick: _.identity,
            }));

            // true is edit, false is continue
            const { payload: confirm } = yield take(RESULT_CONFIRMATION_DIALOG);

            // Cancel the publishing
            if (confirm === 'cancel') return;

            yield put(setLoadingAsync(true));

            if (confirm) {
              if (inFormView) {
                if (onError) {
                  onError('url');
                }
                return;
              }
              history.push(getSuggestedDraftRoute(businessId, suggestedCampaignData?.id));
              return;
            }
          } else {
            if (onError) {
              onError('url', presetsValid.validationList);
            }
            return;
          }
        }

        const displayLinkValid = yield call(
          validateCampaignDisplayLink,
          businessId,
          suggestedCampaignData,
        );

        if (!displayLinkValid.valid) {
          if (displayLinkValid.error === 'invalid_url') {
            if (onError) {
              onError('display_url');
            }
            return;
          }
        }

        const customAudienceValid = yield call(
          validateCampaignCustomAudiences, businessId, suggestedCampaignData,
        );

        if (!customAudienceValid) {
          yield put(setLoadingAsync(false));
          const pathname = yield select(selectRouterLocationPath);
          const inFormView = pathname.includes('campaigns/suggested');

          yield put(showConfirmationDialog({
            text: 'custom_audience_publish_without_audience',
            confirmText: 'edit',
            cancelText: 'continue',
            onClick: _.identity,
          }));

          // true is edit, false is continue
          const { payload: confirm } = yield take(RESULT_CONFIRMATION_DIALOG);
          yield put(setLoadingAsync(true));

          if (confirm) {
            if (inFormView) {
              if (onError) {
                onError();
              }
              return;
            }
            history.push(getSuggestedDraftRoute(businessId, suggestedCampaignData?.id));
            return;
          }

          suggestedCampaignData.ads.forEach((ad) => {
            _.set(ad, 'targeting.customAudiences', [{ id: 'NONE' }]);
          });
        }
      }

      // sc data sent
      const savedCampaign = yield call(saveSuggestedCampaign, { ...suggestedCampaignData });
      // we omit data that was sent keep previous sc in redux
      yield put(setSuggestedCampaign({ ...savedCampaign, ...suggestedCampaign }));
    }

    // Allow publishing/returning to draft multiple Meta suggested campaigns
    if (multipleMetaIdsToPublishOrReturnDraft
      && Array.isArray(multipleMetaIdsToPublishOrReturnDraft)) {
      const operations = multipleMetaIdsToPublishOrReturnDraft.map((item) => {
        let scSanitized = handleSuggestedCampaignFormData({
          suggestedCampaign: item,
          publish,
        });
        scSanitized = {
          ..._.omit(scSanitized, ['questionnaireId', 'businessName']),
        };
        // TODO: Validate urlpresets/custom audiences for each one?
        return call(
          api.updateSuggestedCampaign,
          scSanitized.id,
          scSanitized,
        );
      });
      yield all(operations);
    }

    // Allow reverting to draft multiple suggested campaigns mkt (return to draft case)
    if (multiMarketingIdsToRevertToDraft && Array.isArray(multiMarketingIdsToRevertToDraft)) {
      const operationsSCIds = multiMarketingIdsToRevertToDraft.map(
        (id) => call(api.revertAdToDraft, id),
      );
      yield all(operationsSCIds);
    }

    // Allow updating multiple suggested campaigns mkt (publish case)
    if (multiMarketingSCToPublish && Array.isArray(multiMarketingSCToPublish)) {
      const operationsSCs = multiMarketingSCToPublish.map(
        (item) => call(api.updateAdDraft, item?.id, item),
      );
      yield all(operationsSCs);
    }

    if (onDone) {
      onDone();
    }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'handleSuggestedCampaign' },
      params: { suggestedCampaign, publish },
    });

    if (onError) {
      onError();
    }
  }
}
