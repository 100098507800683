import _ from 'lodash';
import {
  put, call, take, select,
} from 'redux-saga/effects';
import { SlackTemplateType, ActivityLogType } from '@targetable/targetable-types/dist/types';

import api from '../../services/api';
import {
  showToaster, closeToaster, setLoadingAsync, showActivityLogNoteDialog,
} from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { RESULT_ACTIVITY_LOG_DIALOG } from '../../constants';
import createActivityLog from '../createActivityLog/createActivityLog';
import { selectBusiness } from '../../selectors';
import auth from '../../services/auth';

export default function* returnCampaignToDraft({ payload }) {
  const {
    onSave, campaign, onError,
  } = payload;

  const business = yield select(selectBusiness);

  try {
    yield put(showActivityLogNoteDialog({
      title: 'Add Note? (optional)',
      text: 'You can add any notes, descriptions, reasons for the action you are about to take. This may or may not be exposed to the customer.',
      confirmText: 'submit',
      cancelText: 'cancel',
      onClick: _.identity,
    }));
    const { payload: note } = yield take(RESULT_ACTIVITY_LOG_DIALOG);

    if (note === false) return;
    if (note) {
      const authUser = auth.getUser();
      yield call(createActivityLog, {
        businessId: business.id,
        userId: authUser?.email,
        type: ActivityLogType.ReturnFailedGoogleCampaignToDraft,
        slack: {
          templateType: SlackTemplateType.ReturnFailedGoogleCampaignToDraft,
        },
        ui: {
          title: 'Google Search Ad moved from Error to Admin Draft',
        },
        meta: {
          entity: campaign,
          note,
        },
      });

      yield put(showToaster(getToasterOptions(
        'Returning Campaign to Draft...', 'info', null, 7000, true, null, 'bottom', 'right',
      )));
      yield put(setLoadingAsync(true));
      const adDraft = yield call(api.updateAdDraft, campaign?.id, campaign);
      yield put(setLoadingAsync(false));

      if (onSave) { onSave(adDraft); }

      yield put(closeToaster());
    }
  } catch (e) {
    yield put(showToaster(getToasterOptions(e?.response?.data.message || e?.message?.data || 'Failed to update the Campaign. Try again in a few minutes.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'returnCampaignToDraft' },
      params: { payload },
    });
    if (onError) {
      onError();
    }
  }
}
