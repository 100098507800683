import { call, put } from 'redux-saga/effects';

import { getToasterOptions } from '../helpers';
import { showToaster as showToasterAction } from '../../actions';
import api from '../../services/api';

export default function* updateCampaignUser({ payload }) {
  const {
    onDone,
    onError,
    updatedCampaign,
  } = payload;

  try {
    yield call(api.updateCampaign, updatedCampaign.id, updatedCampaign);
    if (onDone) { onDone(); }

    yield put(showToasterAction(getToasterOptions('The user token has been successfully updated!', 'success', null, 5000)));
  } catch (error) {
    if (onError) {
      onError(error);
    }

    yield put(showToasterAction(getToasterOptions('error_update_campaign', 'error')));
  }
}
