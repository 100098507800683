import {
  call,
  put,
} from 'redux-saga/effects';
import api from '../../services/api';
import { setLoadingAsync, showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* deleteUser({ payload }) {
  const { userId, hardDelete, onComplete } = payload;
  yield put(setLoadingAsync(false));
  const action = hardDelete ? 'delete' : 'archive';
  yield put(showToaster(getToasterOptions(
    `${action === 'delete' ? 'Deleting' : 'Archiving'} user...`, 'info', null, 7000,
  )));
  try {
    yield call(api.deleteUser, userId, null, hardDelete);
    yield put(showToaster(getToasterOptions(
      `User ${action === 'delete' ? 'deleted' : 'archived'} successfully!!!`, 'success', null, 5000,
    )));
    if (onComplete) {
      onComplete();
    }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'deleteUser' },
      params: {
        userId,
        hardDelete,
      },
    });
    yield put(showToaster(getToasterOptions('Failed trying to delete/archive user!!!')));
  }
}
