import {
  put, call, select,
} from 'redux-saga/effects';
import _ from 'lodash';
import api from '../../services/api';
import {
  showToaster,
  setMarketingChannels,
} from '../../actions';
import logger from '../../services/logger';
import { selectMarketingChannels } from '../../selectors';
import { getToasterOptions } from '../helpers';

export default function* setMarketingChannelQATools({
  payload: {
    marketingChannel, onDone,
  },
}) {
  try {
    yield put(showToaster(getToasterOptions('Saving changes...', 'info', null, 5000, true, null, 'bottom', 'right')));
    const updatedChannel = yield call(api.updateMarketingChannel, marketingChannel?.id, { ...(_.omit(marketingChannel, ['id'])) });

    const currentChannels = yield select(selectMarketingChannels);

    yield put(setMarketingChannels(currentChannels.map((channel) => {
      if (channel.id === marketingChannel.id) {
        return updatedChannel;
      }
      return channel;
    })));

    if (onDone) onDone();
    yield put(showToaster(getToasterOptions('Done!', 'success', null, 2000)));
  } catch (e) {
    yield put(showToaster(getToasterOptions('An error occurred updating the Marketing Channel test account property', 'error')));
    logger.error({
      error: e,
      context: { saga: 'setMarketingChannelQATools' },
      params: { marketingChannel },
    });
  }
}
