import { put, call, fork } from 'redux-saga/effects';
import { getUserFacebookIntegration } from '@targetable/targetable-web-framework/lib/services/user';
import i18n from '../../services/i18n';
import api from '../../services/api';
import {
  showToaster,
  setLoadingAsync,
} from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* updateStoreIntegrations({ payload }) {
  const {
    storeId, integrations, onDone, manualModeInfo,
  } = payload;
  try {
    yield put(setLoadingAsync(true));
    let info = integrations;
    if (manualModeInfo) { // Case when enter facebook info manually
      const user = yield call(api.getUser, integrations.userId, false);
      const userFbIntegration = getUserFacebookIntegration(user);
      const accessToken = userFbIntegration?.accessToken;
      const page = yield call(api.getPagesBySearch, null, integrations.pageId, accessToken);
      info = {
        adAccountId: integrations.adAccountId,
        businessId: page?.business?.id,
        location: page?.location,
        avatar: page?.picture?.data?.url,
        type: 'facebook',
        pageId: integrations.pageId,
        userId: integrations.userId,
      };
    }
    yield call(api.updateStore, storeId, { integrations: [info] });
    yield fork(api.updateStoreHubspotRecord, storeId, { initialFacebookFail: true });
    yield put(showToaster(getToasterOptions(i18n.t('update_store_integrations_success'), 'success', null, 5000)));
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to update store integrations', 'error')));
    logger.error({
      error: e,
      context: { saga: 'updateStoreIntegrations' },
      params: { payload },
    });
  }
  yield put(setLoadingAsync(false));
  if (onDone) {
    onDone();
  }
}
