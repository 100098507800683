import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import RestoreIcon from '@material-ui/icons/Restore';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

import DrawerContent from '@targetable/targetable-web-framework/lib/react/containers/DrawerContent/DrawerContent';
import PageContentContainer from '@targetable/targetable-web-framework/lib/react/containers/PageContentContainer/PageContentContainer';
import ComponentLoading from '@targetable/targetable-web-framework/lib/react/components/ComponentLoading/ComponentLoading';

import { selectBusinessLoading, selectBusiness } from '../../selectors';
import { updateBusiness, showToaster } from '../../actions';

import { getToasterOptions } from '../../sagas/helpers';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: '100%',
    margin: `${theme.spacing(2)}px auto`,
    '& .MuiCardActionArea-root:hover': {
      backgroundColor: '#E4E6EC',
    },
  },
  buttonLeft: {
    marginRight: theme.spacing(2),
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    background: '#FFF9F0',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 10,
    color: '#3E435E',
    borderRadius: 4,
    padding: '5px 15px',
    margin: '10px 0 0 0',
    '& svg': {
      color: '#FF9800',
    },
    '& p': {
      marginLeft: 15,
    },
  },
  sectionWrapper: {
    marginTop: 20,
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  restoreIcon: {
    fontSize: 20,
    color: '#007BFF',
    cursor: 'pointer',
  },
  deleteIcon: {
    fontSize: 20,
    color: '#EE3427',
    cursor: 'pointer',
  },
  sectionTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
  },
  contentWrapper: {
    marginTop: 12,
  },
  content: {
    fontSize: 12,
  },
}));

const ReviewResponseCHFDialog = ({
  onClose,
  open,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const businessLoading = useSelector(selectBusinessLoading) || false;
  const businessInfo = useSelector(selectBusiness);
  const [openDrawer, setOpenDrawer] = useState(false);

  const additionalContextValue = t('review_response_additional_default_context');
  const [contextValue, setContextValue] = useState(
    businessInfo?.reviewResponse?.context
    || `${t('review_response_default_context')} ${additionalContextValue}`,
  );
  const [headerValue, setHeaderValue] = useState(businessInfo?.reviewResponse?.header || '');
  const [footerValue, setFooterValue] = useState(businessInfo?.reviewResponse?.footer || '');

  useEffect(() => {
    if (!openDrawer) {
      setOpenDrawer(true);
    }
  }, [openDrawer]);

  const getDialogTitle = () => {
    let title = `${businessInfo.name.trim()} / Review Response / Context, Header & Footer`;
    const long = 30;
    if (businessInfo.name?.length > long) {
      title = `${businessInfo.name} / Review... / Context, Header & Footer`;
    }
    if (businessInfo.name?.length > (long + 10)) {
      title = `${businessInfo.name.substring(0, long)?.trim()}... / Review... / Context, Header & Footer`;
    }
    return title;
  };

  const handleSave = useCallback(() => {
    const onDone = () => {
      dispatch(showToaster(getToasterOptions('Business was updated successfully!!!', 'success', null, 5000)));
    };
    dispatch(updateBusiness({
      reviewResponse: {
        context: contextValue,
        header: headerValue,
        footer: footerValue,
      },
      onDone,
    }));
    onClose();
  }, [contextValue, dispatch, footerValue, headerValue, onClose]);

  const handleChangeContext = (event) => {
    let { value } = event.target;
    if ((_.isEmpty(value.trim()) || (value.trim().length < additionalContextValue.length))
      && contextValue === additionalContextValue
    ) {
      return;
    }
    if (value.endsWith(additionalContextValue)) {
      setContextValue(value);
    } else {
      value = `${value}${additionalContextValue}`;
      value = _.trim(_.replace(value, new RegExp(_.escapeRegExp(additionalContextValue), 'g'), (match, index) => (index === value.indexOf(additionalContextValue) ? match : '')));
      if (_.includes(contextValue, additionalContextValue) && value !== additionalContextValue) {
        return;
      }
      setContextValue(value);
    }
  };

  const handleChangeHeader = (event) => {
    setHeaderValue(event.target.value);
  };

  const handleChangeFooter = (event) => {
    setFooterValue(event.target.value);
  };

  const handleClearContent = (scope) => {
    const setFunctions = {
      context: () => setContextValue(`${t('review_response_default_context')} ${additionalContextValue}`),
      header: () => setHeaderValue(''),
      footer: () => setFooterValue(''),
    };
    setFunctions[scope]();
  };

  const buttons = useMemo(() => [
    {
      text: 'save',
      classes: classes.buttonLeft,
      variant: 'contained',
      color: 'secondary',
      onClick: handleSave,
    },
  ], [handleSave, classes]);

  return (
    <>
      <DrawerContent
        open={open ?? openDrawer}
        onClose={onClose}
        title={getDialogTitle()}
        tooltipTitle={`${businessInfo.name.trim()} / Review Response / Context, Header & Footer`}
        buttons={buttons}
        invisibleBackdrop
      >
        <PageContentContainer className={classes.pageContainer}>
          <>
            <ComponentLoading
              loading={businessLoading}
              waitKey="loading_business"
            >
              <Grid
                container
                spacing={2}
                justifyContent="center"
                data-cy="ReviewResponseCHFDialog_container"
              >
                <Grid className={classes.headerBox}>
                  <ErrorIcon />
                  <p>{t('review_response_header_box')}</p>
                </Grid>
                <Grid container className={classes.sectionWrapper}>
                  <Grid className={classes.sectionHeader}>
                    <Typography variant="h3" className={classes.sectionTitle}>
                      Context
                    </Typography>
                    <RestoreIcon
                      onClick={() => handleClearContent('context')}
                      className={classes.restoreIcon}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.contentWrapper}>
                    <TextField
                      variant="outlined"
                      multiline
                      rows={8}
                      fullWidth
                      onChange={handleChangeContext}
                      value={contextValue}
                      InputProps={{
                        style: {
                          fontSize: 12,
                        },
                      }}
                      className={classes.content}
                      dataCy="reviewResponse_context_textarea"
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.sectionWrapper}>
                  <Grid className={classes.sectionHeader}>
                    <Typography variant="h3" className={classes.sectionTitle}>
                      Header
                    </Typography>
                    <DeleteIcon
                      onClick={() => handleClearContent('header')}
                      className={classes.deleteIcon}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.contentWrapper}>
                    <TextField
                      variant="outlined"
                      multiline
                      rows={8}
                      fullWidth
                      onChange={handleChangeHeader}
                      value={headerValue}
                      inputProps={{
                        style: {
                          fontSize: 12,
                        },
                      }}
                      className={classes.content}
                      dataCy="reviewResponse_context_textarea"
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.sectionWrapper}>
                  <Grid className={classes.sectionHeader}>
                    <Typography variant="h3" className={classes.sectionTitle}>
                      Footer
                    </Typography>
                    <DeleteIcon
                      onClick={() => handleClearContent('footer')}
                      className={classes.deleteIcon}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.contentWrapper}>
                    <TextField
                      variant="outlined"
                      multiline
                      rows={8}
                      fullWidth
                      onChange={handleChangeFooter}
                      value={footerValue}
                      inputProps={{
                        style: {
                          fontSize: 12,
                        },
                      }}
                      className={classes.content}
                      dataCy="reviewResponse_context_textarea"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </ComponentLoading>
          </>
        </PageContentContainer>
      </DrawerContent>
    </>
  );
};

ReviewResponseCHFDialog.defaultProps = {
  open: false,
};

ReviewResponseCHFDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default ReviewResponseCHFDialog;
