import {
  call,
} from 'redux-saga/effects';

import api from '../../services/api';
import logger from '../../services/logger';

export default function* getGoogleLocationsApi({ payload: { locationNames, onSuccess, onError } }) {
  try {
    const locations = yield call(api.getGoogleGeoTargetConstants, { locationNames });

    if (onSuccess) { onSuccess(locations.geoTargetConstantSuggestions || []); }

    return locations.geoTargetConstantSuggestions || [];
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getGoogleLocationsApi' },
      params: { locationNames },
    });
    if (onError) { onError(); }
    return [];
  }
}
