import { URL_PRESET_CUSTOM_ID } from '@targetable/targetable-web-framework/lib/react/constants';
import { put, select, call } from 'redux-saga/effects';
import _ from 'lodash';
import { setAdCardPresets } from '../../actions';
import { selectUrlPresets, selectAdCardPresets, selectBusinessIdQueried } from '../../selectors';
import getUrlPresets from '../getUrlPresets/getUrlPresets';

export default function* getCampaignAdCardPresets(campaign, useCurrent, reset) {
  if (reset) {
    yield put(setAdCardPresets({}));
  }

  const businessId = yield select(selectBusinessIdQueried);
  let presets = yield select(selectUrlPresets);
  if (_.isEmpty(presets)) {
    yield call(getUrlPresets, {
      payload: {
        businessId,
        filter: true,
        prependCustom: true,
        backfill: true,
      },
    });
    presets = yield select(selectUrlPresets);
  }
  const currentAdCardPresets = yield select(selectAdCardPresets);
  const adCardPresets = {};

  const currentCampaign = !campaign?.ads
    ? campaign?.payload?.campaign
    : campaign;

  currentCampaign?.ads[0]?.ad?.adCards.forEach((adCard) => {
    if (_.includes(['image', 'video'], adCard.type)) {
      let preset = _.find(presets, { id: adCard.urlPresetId });

      // default back to custom preset if the preset is not found
      // this could happen if the preset was removed
      if (!preset) {
        preset = _.find(presets, { id: URL_PRESET_CUSTOM_ID });
      }

      // if a current preset is available,
      // merge it due to it contains the latest changes made to the Ad
      if (currentAdCardPresets && currentAdCardPresets[adCard.id] && useCurrent) {
        preset = currentAdCardPresets[adCard.id];
      }

      adCardPresets[adCard.id] = {
        ...preset,
        stores: preset.stores.map((presetStore) => {
          if (adCard.urlPresetId === URL_PRESET_CUSTOM_ID) {
            return { ...presetStore, url: adCard.link };
          }

          // use the current url preset store url
          if (useCurrent) {
            // Store preset might not be pointing to the correct url (if a new store was created
            // AFTER the preset was saved), in such cases use the one typed in by the user.
            return {
              ...presetStore,
              ...(presetStore.url === '' ? { url: adCard.link } : undefined),
            };
          }

          // use the ad link ignoring the latest url preset store url
          return { ...presetStore, url: adCard.link };
        }),
      };
    }
  });
  yield put(setAdCardPresets(adCardPresets));
}
