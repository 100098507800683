import _ from 'lodash';
import { call, fork, put } from 'redux-saga/effects';
import { showToaster } from '../../actions';
import globals from '../../globals';
import auth from '../../services/auth';
import logger from '../../services/logger';
import api from '../../services/api';
import createDefaultInsights from '../createDefaultInsights/createDefaultInsights';
import createDefaultSuggestedCampaigns from '../createDefaultSuggestedCampaigns/createDefaultSuggestedCampaigns';
import { getToasterOptions } from '../helpers';
import { EXISTING_USER_INVITE, NEW_USER_INVITE } from '../../constants';

export default function* createBusiness({ payload }) {
  let businessId;
  let storeId;

  yield put(showToaster(getToasterOptions(
    'Creating business...', 'info', null, 20000, true, null, 'bottom', 'right',
  )));

  try {
    const businessData = payload?.business;
    const storeData = payload?.store;
    const userData = payload?.user;

    // We only set internalTraffic to true for dev and stage environments
    const { ENVIRONMENT_NAME } = globals;
    if (_.includes(['stage', 'dev', 'local'], ENVIRONMENT_NAME)) {
      _.set(businessData, 'internalTraffic', true);
    }

    // Add the coupon if was set
    if (_.isNil(payload?.couponCode) === false) {
      _.set(businessData, 'stripeCoupon', payload?.couponCode);
    }

    _.set(businessData, 'onboarded', true);
    const business = yield call(api.createBusiness, businessData);
    businessId = business?.id;
    if (_.has(business, 'hubSpotId') === false) {
      yield fork(api.createBusinessHubspotRecord, business?.id);
    }

    // create the business store
    _.set(storeData, 'businessId', businessId);
    _.set(storeData, 'defaultRadius', 5);
    _.set(storeData, 'defaultRadiusEmployment', 15);
    _.set(storeData, 'defaultRadiusHousing', 15);
    const store = yield call(api.createStore, storeData);
    storeId = store?.id;
    yield fork(api.createStoreHubspotRecord, storeId);
    yield fork(
      api.updateStoreHubspotRecord,
      storeId,
      { storeOnboardingCompleted: true, storeLocationCompleted: false },
    );

    const dataProperties = {
      businessId,
      businessName: businessData?.name,
      givenName: userData?.givenName,
      familyName: userData?.familyName,
      email: userData?.email,
      invitingUserId: auth.getUserId(),
    };

    let user;
    if (payload?.inviteType === NEW_USER_INVITE) {
      user = yield call(api.inviteUser, businessId, dataProperties);
    } else if (payload?.inviteType === EXISTING_USER_INVITE) {
      user = yield call(api.createUser, {
        ...dataProperties,
        auth0Id: userData?.auth0Id,
        onboarded: true,
        invitingUserId: userData?.id, // use existing user's token
        copyIntegrations: true,
      });
    }

    yield call(api.createUserHubspotRecord, user?.id);

    yield call(api.associateUserHubspotRecordToBusinessHubspotRecord, user?.id);

    yield call(api.updateBusiness, businessId, {
      id: businessId,
      onboarded: true,
    });

    yield call(createDefaultInsights, { businessId });
    yield call(createDefaultSuggestedCampaigns, { businessId, storeId });

    yield fork(api.updateBusinessHubspotRecord, businessId, { businessOnboardingCompleted: true });

    yield put(showToaster(getToasterOptions(`Successfully created business: ${businessData?.name}`, 'success', null, 5000)));
    if (payload?.onDone) payload?.onDone();
  } catch (e) {
    yield put(showToaster(getToasterOptions('Unable to create a business, please double check if the customer or business already exists.')));
    logger.error({
      error: e,
      context: { saga: 'createBusiness' },
      params: { businessId },
    });

    if (payload?.onError) payload?.onError();
  }
}
