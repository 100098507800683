import _ from 'lodash';
import { put, call } from 'redux-saga/effects';
import i18n from '../../services/i18n';
import api from '../../services/api';
import { setBusinessStores, showToaster, setLoadingAsync } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* updateChowlyPartnerId({
  payload: {
    storeId, businessId, chowlyPartnerId, onDone,
  },
}) {
  try {
    yield put(setLoadingAsync(true));
    yield call(
      api.updateStore,
      storeId,
      {
        chowlyPartnerId,
      },
    );
    let stores = yield call(api.getStores, businessId);
    stores = _.sortBy(stores, 'name');
    yield put(setBusinessStores(stores));
    yield put(showToaster(getToasterOptions(i18n.t('update_store_partner_id', 'Chowly Partner ID was updated successfully!'), 'success', null, 5000)));
    yield put(setLoadingAsync(false));
    onDone();
  } catch (e) {
    yield put(showToaster(getToasterOptions(i18n.t('update_store_partner_id_error', 'An error occurred updating store Chowly Partner ID'), 'error')));
    yield put(setLoadingAsync(false));
    logger.error({
      error: e,
      context: { saga: 'updateChowlyPartnerId' },
      params: {
        storeId,
        businessId,
        chowlyPartnerId,
      },
    });
  }
}
