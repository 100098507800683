import {
  all,
  call,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import * as constants from '../../constants';
import { setLoadingAsync } from '../../actions';

// Marketing
import getGoogleCampaignsByChannelId from '../getGoogleCampaignsByChannelId/getGoogleCampaignsByChannelId';
import getGoogleAdGroupsByChannelId from '../getGoogleAdGroupsByChannelId/getGoogleAdGroupsByChannelId';
import getGoogleLocations from '../getGoogleLocations/getGoogleLocations';
import createMarketingSuggestedCampaign from '../createMarketingSuggestedCampaign/createMarketingSuggestedCampaign';
import updateMarketingSuggestedCampaign from '../updateMarketingSuggestedCampaign/updateMarketingSuggestedCampaign';
import duplicateMarketingSuggestedCampaign from '../duplicateMarketingSuggestedCampaign/duplicateMarketingSuggestedCampaign';
import returnCampaignToDraft from '../returnCampaignToDraft/returnCampaignToDraft';
import revertMarketingSuggestedCampaignToDraft from '../revertMarketingSuggestedCampaignToDraft/revertMarketingSuggestedCampaignToDraft';

import createInsight from '../createInsight/createInsight';
import deleteUrlPreset from '../deleteUrlPreset/deleteUrlPreset';
import getUrlPresets from '../getUrlPresets/getUrlPresets';
import createBusiness from '../createBusiness/createBusiness';
import initBusiness from '../initBusiness/initBusiness';
import getBusiness from '../getBusiness/getBusiness';
import getBusinesses from '../getBusinesses/getBusinesses';
import getFacebookCampaignLanguages from '../getFacebookCampaignLanguages/getFacebookCampaignLanguages';
import getUsersByBusiness from '../getUsersByBusiness/getUsersByBusiness';
import getInsightsByBusiness from '../getInsightsByBusiness/getInsightsByBusiness';
import getStoresByBusiness from '../getStoresByBusiness/getStoresByBusiness';
import getSuggestedCampaignsByBusiness from '../getSuggestedCampaignsByBusiness/getSuggestedCampaignsByBusiness';
import getCampaignsByBusinessAndStatus from '../getCampaignsByBusinessAndStatus/getCampaignsByBusinessAndStatus';
import getStripeSubscriptionByCustomer from '../getStripeSubscriptionByCustomer/getStripeSubscriptionByCustomer';
import goToStripeCustomerPortal from '../goToStripeCustomerPortal/goToStripeCustomerPortal';
import getImages from '../getImages/getImages';
import getMoreImages from '../getMoreImages/getMoreImages';
import getSuggestedCampaign from '../getSuggestedCampaign/getSuggestedCampaign';
import handleSuggestedCampaign from '../handleSuggestedCampaign/handleSuggestedCampaign';
import inviteUser from '../inviteUser/inviteUser';
import reinviteUser from '../reinviteUser/reinviteUser';
import copyInviteLinkToClipboard from '../copyInviteLinkToClipboard/copyInviteLinkToClipboard';
import saveUrlPresets from '../saveUrlPresets/saveUrlPresets';
import searchImages from '../searchImages/searchImages';
import updateInsight from '../updateInsight/updateInsight';
import deleteInsight from '../deleteInsight/deleteInsight';
import getAllUsers from '../getAllUsers/getAllUsers';
import getAdImage from '../getAdImage/getAdImage';
import getAdImageUi from '../getAdImageUi/getAdImageUi';
import getCampaign from '../getCampaign/getCampaign';
import deleteSuggestedCampaign from '../deleteSuggestedCampaign/deleteSuggestedCampaign';
import duplicateCampaign from '../duplicateCampaign/duplicateCampaign';
import duplicateSuggestedCampaign from '../duplicateSuggestedCampaign/duplicateSuggestedCampaign';
import pauseOrUnpauseCampaign from '../pauseOrUnpauseCampaign/pauseOrUnpauseCampaign';
import openSuggestedCampaignDraft from '../openSuggestedCampaignDraft/openSuggestedCampaignDraft';
import updateBusiness from '../updateBusiness/updateBusiness';
import handleEditBusinessName from '../handleEditBusinessName/handleEditBusinessName';
import showConfirmationDialog from '../showConfirmationDialog/showConfirmationDialog';
import showActivityLogNoteDialog from '../showActivityLogNoteDialog/showActivityLogNoteDialog';
import showPublishMarketingDialog from '../showPublishMarketingDialog/showPublishMarketingDialog';
import updateStoreDefaultRadius from '../updateStoreDefaultRadius/updateStoreDefaultRadius';
import updateStoreLocation from '../updateStoreLocation/updateStoreLocation';
import updateStoreIntegrations from '../updateStoreIntegrations/updateStoreIntegrations';
import updateStoreMarketingChannels from '../updateStoreMarketingChannels/updateStoreMarketingChannels';
import updateStorePresets from '../updateStorePresets/updateStorePresets';
import updateStoreName from '../updateStoreName/updateStoreName';
import updatePhoneNumber from '../updatePhoneNumber/updatePhoneNumber';
import updateCooUrl from '../updateCooUrl/updateCooUrl';
import updateChowlyPartnerId from '../updateChowlyPartnerId/updateChowlyPartnerId';
import updateDefaultPublishingUser from '../updateDefaultPublishingUser/updateDefaultPublishingUser';
import refreshStoreLocation from '../refreshStoreLocation/refreshStoreLocation';
import refreshStoreFacebookLocation from '../refreshStoreFacebookLocation/refreshStoreFacebookLocation';
import requestFbToken from '../requestFbToken/requestFbToken';
import updateUserEmail from '../updateUserEmail/updateUserEmail';
import updateStorePixel from '../updateStorePixel/updateStorePixel';
import updateUserName from '../updateUserName/updateUserName';
import updateUser from '../updateUser/updateUser';
import removeUser from '../removeUser/removeUser';
import deleteUser from '../deleteUser/deleteUser';
import saveEClincherUrl from '../saveEClincherUrl/saveEClincherUrl';
import updateStorePublishAsPaused from '../updateStorePublishAsPaused/updateStorePublishAsPaused';
import cancelStripeSubscription from '../cancelStripeSubscription/cancelStripeSubscription';
import saveCampaignRetryTimer from '../saveCampaignRetryTimer/saveCampaignRetryTimer';
import saveManagedLandingPages from '../saveManagedLandingPages/saveManagedLandingPages';
import openTokenDebugger from '../openTokenDebugger/openTokenDebugger';
import getAllPermissions from '../getAllPermissions/getAllPermissions';
import getStripeProducts from '../getStripeProducts/getStripeProducts';
import getStripeSubscriptions from '../getStripeSubscriptions/getStripeSubscriptions';
import updateInsightTemplate from '../updateInsightTemplate/updateInsightTemplate';
import deleteInsightTemplate from '../deleteInsightTemplate/deleteInsightTemplate';
import createInsightTemplate from '../createInsightTemplate/createInsightTemplate';
import createQuestionnaire from '../createQuestionnaire/createQuestionnaire';
import updateQuestionnaire from '../updateQuestionnaire/updateQuestionnaire';
import deleteQuestionnaire from '../deleteQuestionnaire/deleteQuestionnaire';
import deleteStripeSubscriptionItem from '../deleteStripeSubscriptionItem/deleteStripeSubscriptionItem';
import deleteStore from '../deleteStore/deleteStore';
import removeImages from '../removeImages/removeImages';
import updateImageTags from '../updateImageTags/updateImageTags';
import deleteSuggestedCampaignTemplate from '../deleteSuggestedCampaignTemplate/deleteSuggestedCampaignTemplate';
import updateSuggestedCampaignTemplate from '../updateSuggestedCampaignTemplate/updateSuggestedCampaignTemplate';
import createSuggestedCampaignTemplate from '../createSuggestedCampaignTemplate/createSuggestedCampaignTemplate';
import initSuggestedCampaignTemplate from '../initSuggestedCampaignTemplate/initSuggestedCampaignTemplate';
import duplicateSuggestedCampaignTemplate from '../duplicateSuggestedCampaignTemplate/duplicateSuggestedCampaignTemplate';
import handleAddStore from '../handleAddStore/handleAddStore';
import ensureSubscription from '../ensureSubscription/ensureSubscription';
import pauseStripeSubscriptionCollection from '../pauseStripeSubscriptionCollection/pauseStripeSubscriptionCollection';
import unpauseStripeSubscriptionCollection from '../unpauseStripeSubscriptionCollection/unpauseStripeSubscriptionCollection';
import saveMarketingChannel from '../saveMarketingChannel/saveMarketingChannel';
import getMarketingChannels from '../getMarketingChannels/getMarketingChannels';
import deleteMarketingChannel from '../deleteMarketingChannel/deleteMarketingChannel';
import updateMarketingChannel from '../updateMarketingChannel/updateMarketingChannel';
import getStoreDateReports from '../getStoreDateReports/getStoreDateReports';
import sendMailChimpTestEmail from '../sendMailChimpTestEmail/sendMailChimpTestEmail';
import testMarketingChannelConnection from '../testMarketingChannelConnection/testMarketingChannelConnection';
import testFacebookConnectionByStores from '../testFacebookConnectionByStores/testFacebookConnectionByStores';
import getSilectisGlobalSubscriptions from '../getSilectisGlobalSubscriptions/getSilectisGlobalSubscriptions';
import getSilectisFacebookMetrics from '../getSilectisFacebookMetrics/getSilectisFacebookMetrics';
import getSilectisGoogleMetrics from '../getSilectisGoogleMetrics/getSilectisGoogleMetrics';
import getSilectisMailchimpMetrics from '../getSilectisMailchimpMetrics/getSilectisMailchimpMetrics';
import updateStripeSubscription from '../updateStripeSubscription/updateStripeSubscription';
import setAuth0Permissions from '../setAuth0Permissions/setAuth0Permissions';
import verifyAuth0Email from '../verifyAuth0Email/verifyAuth0Email';
import enableDisableStore from '../enableDisableStore/enableDisableStore';
import getGoogleAssets from '../getGoogleAssets/getGoogleAssets';
import getSearchAdCombinations from '../getSearchAdCombinations/getSearchAdCombinations';
import moveUserToBusiness from '../moveUserToBusiness/moveUserToBusiness';
import setMarketingChannelQATools from '../setMarketingChannelQATools/setMarketingChannelQATools';
import getInvoiceHistory from '../getInvoiceHistory/getInvoiceHistory';
import sendTransactionalEmail from '../sendTransactionalEmail/sendTransactionalEmail';
import retryCampaign from '../retryCampaign/retryCampaign';
import pauseMailchimpCampaign from '../pauseMailchimpCampaign/pauseMailchimpCampaign';
import saveCampaignForm from '../saveCampaignForm/saveCampaignForm';
import submitCampaignForm from '../submitCampaignForm/submitCampaignForm';
import createCustomField from '../createCustomField/createCustomField';
import updateCustomField from '../updateCustomField/updateCustomField';
import reactivateStripeSubscription from '../reactivateStripeSubscription/reactivateStripeSubscription';
import updateAdRequest from '../updateAdRequest/updateAdRequest';
import unlinkMetaConnection from '../unlinkMetaConnection/unlinkMetaConnection';
import importMarketingGoogleSearchAd from '../importMarketingGoogleSearchAd/importMarketingGoogleSearchAd';
import retryUpdateLiveInsight from '../retryUpdateLiveInsight/retryUpdateLiveInsight';
import updateCampaignUser from '../updateCampaignUser/updateCampaignUser';
import previewFeedbackDialog from '../previewFeedbackDialog/previewFeedbackDialog';
import translateAddressToCoordinates from '../translateAddressToCoordinates/translateAddressToCoordinates';
import getStorePixels from '../getStorePixels/getStorePixels';
import publishMarketingAd from '../publishMarketingAd/publishMarketingAd';
import createUpdateGoogleSearchAdsBulk from '../createUpdateGoogleSearchAdsBulk/createUpdateGoogleSearchAdsBulk';
import getComments from '../getComments/getComments';
import postComment from '../postComment/postComment';
import saveRccUrl from '../saveRccUrl/saveRccUrl';
import saveSalesforceId from '../saveSalesforceId/saveSalesforceId';
import updateMetaLiveCampaign from '../updateMetaLiveCampaign/updateMetaLiveCampaign';
import getAllLeadGenForms from '../getAllLeadGenForms/getAllLeadGenForms';
import getCampaignAdCardPresets from '../getCampaignAdCardPresets/getCampaignAdCardPresets';
import createMarketingEvent from '../createMarketingEvent/createMarketingEvent';
import updateGoogleLiveCampaign from '../updateGoogleLiveCampaign/updateGoogleLiveCampaign';
import getVideoThumbnail from '../getVideoThumbnail/getVideoThumbnail';

export function* handleAsyncSaga(saga, action) {
  yield put(setLoadingAsync(true));
  yield call(saga, action);
  yield put(setLoadingAsync(false));
}

export default function* rootSaga() {
  yield all([
    takeLatest(constants.CREATE_INSIGHT, handleAsyncSaga, createInsight),
    takeLatest(constants.DELETE_INSIGHT, handleAsyncSaga, deleteInsight),
    takeLatest(constants.RETRY_UPDATE_LIVE_INSIGHT, handleAsyncSaga, retryUpdateLiveInsight),
    takeLatest(constants.DELETE_SUGGESTED_CAMPAIGN, handleAsyncSaga, deleteSuggestedCampaign),
    takeLatest(constants.DELETE_URL_PRESET, handleAsyncSaga, deleteUrlPreset),
    // this has async actions that might take time to complete,
    // so we don't want to kill previous saga invocations
    takeEvery(constants.INIT_BUSINESS, initBusiness),
    takeEvery(constants.INVITE_USER, handleAsyncSaga, inviteUser),
    takeEvery(constants.REINVITE_USER, handleAsyncSaga, reinviteUser),
    takeLatest(constants.COPY_INVITE_LINK_TO_CLIPBOARD, copyInviteLinkToClipboard),
    takeLatest(constants.GET_ALL_USERS, getAllUsers),
    takeLatest(constants.GET_AD_IMAGE, getAdImage),
    takeLatest(constants.GET_AD_IMAGE_UI, getAdImageUi),
    takeLatest(constants.GET_BUSINESS, getBusiness),
    takeLatest(constants.GET_BUSINESSES, getBusinesses),
    takeLatest(constants.GET_BUSINESS_USERS, getUsersByBusiness),
    takeLatest(constants.GET_BUSINESS_INSIGHTS, getInsightsByBusiness),
    takeLatest(constants.GET_BUSINESS_STORES, getStoresByBusiness),
    takeLatest(constants.GET_BUSINESS_SUGGESTED_CAMPAIGNS, getSuggestedCampaignsByBusiness),
    takeLatest(constants.GET_BUSINESS_CAMPAIGNS, getCampaignsByBusinessAndStatus),
    takeLatest(constants.GET_BILLING_SUBSCRIPTIONS, getStripeSubscriptionByCustomer),
    takeLatest(constants.GET_STRIPE_PRODUCTS, getStripeProducts),
    takeLatest(constants.GET_STRIPE_SUBSCRIPTIONS, getStripeSubscriptions),
    takeLatest(constants.CANCEL_STRIPE_SUBSCRIPTION, handleAsyncSaga, cancelStripeSubscription),
    takeLatest(
      constants.PAUSE_STRIPE_COLLECTION, handleAsyncSaga, pauseStripeSubscriptionCollection,
    ),
    takeLatest(
      constants.UNPAUSE_STRIPE_COLLECTION, handleAsyncSaga, unpauseStripeSubscriptionCollection,
    ),
    takeLatest(constants.GOTO_STRIPE_CUSTOMER_PORTAL, handleAsyncSaga, goToStripeCustomerPortal),
    takeLatest(
      constants.DELETE_STRIPE_SUBSCRIPTION_ITEM, handleAsyncSaga, deleteStripeSubscriptionItem,
    ),
    takeLatest(
      constants.UPDATE_STRIPE_COLLECTION, handleAsyncSaga, updateStripeSubscription,
    ),
    takeLatest(constants.UPDATE_BUSINESS, updateBusiness),
    takeLatest(constants.CONFIRM_EDIT_BUSINESS_NAME, handleEditBusinessName),
    takeLatest(constants.GET_CAMPAIGN, getCampaign),
    takeLatest(constants.GET_IMAGES, handleAsyncSaga, getImages),
    takeLatest(constants.REMOVE_IMAGES, handleAsyncSaga, removeImages),
    takeLatest(constants.UPDATE_TAG_IMAGES, handleAsyncSaga, updateImageTags),
    takeLatest(constants.GET_MORE_IMAGES, getMoreImages),
    takeLatest(constants.GET_FACEBOOK_CAMPAIGN_LANGUAGES, getFacebookCampaignLanguages),
    takeLatest(constants.GET_GOOGLE_CAMPAIGNS, getGoogleCampaignsByChannelId),
    takeLatest(constants.GET_GOOGLE_AD_GROUPS, getGoogleAdGroupsByChannelId),
    takeLatest(constants.GET_GOOGLE_LOCATIONS, getGoogleLocations),
    takeLatest(constants.GET_SUGGESTED_CAMPAIGN, getSuggestedCampaign),
    takeLatest(constants.GET_URL_PRESETS, getUrlPresets),
    takeLatest(constants.SAVE_SUGGESTED_CAMPAIGN, handleAsyncSaga, handleSuggestedCampaign),
    takeLatest(constants.SAVE_URL_PRESETS, handleAsyncSaga, saveUrlPresets),
    takeLatest(constants.SEARCH_IMAGES, searchImages),
    takeLatest(constants.UPDATE_INSIGHT, handleAsyncSaga, updateInsight),
    takeLatest(constants.DUPLICATE_CAMPAIGN, handleAsyncSaga, duplicateCampaign),
    takeLatest(constants.DUPLICATE_SUGGESTED_CAMPAIGN, handleAsyncSaga, duplicateSuggestedCampaign),
    takeLatest(constants.PAUSE_UNPAUSE_CAMPAIGN, pauseOrUnpauseCampaign),
    takeLatest(
      constants.DUPLICATE_MARKETING_SUGGESTED_CAMPAIGN,
      handleAsyncSaga,
      duplicateMarketingSuggestedCampaign,
    ),
    takeLatest(
      constants.REVERT_MARKETING_SUGGESTED_CAMPAIGN,
      handleAsyncSaga,
      revertMarketingSuggestedCampaignToDraft,
    ),
    takeLatest(constants.OPEN_SUGGESTED_CAMPAIGN_CARD, handleAsyncSaga, openSuggestedCampaignDraft),
    takeLatest(constants.SHOW_CONFIRMATION_DIALOG, showConfirmationDialog),
    takeLatest(constants.UPDATE_STORE_LOCATION, updateStoreLocation),
    takeLatest(constants.UPDATE_STORE_INTEGRATIONS, updateStoreIntegrations),
    takeLatest(constants.UPDATE_STORE_MARKETING_CHANNELS, updateStoreMarketingChannels),
    takeLatest(constants.UPDATE_STORE_PRESETS, updateStorePresets),
    takeLatest(constants.UNLINK_META_CONNECTION, unlinkMetaConnection),
    takeLatest(constants.UPDATE_STORE_DEFAULT_RADIUS, updateStoreDefaultRadius),
    takeLatest(constants.UPDATE_STORE_NAME, updateStoreName),
    takeLatest(constants.UPDATE_PHONE_NUMBER, updatePhoneNumber),
    takeLatest(constants.UPDATE_COO_URL, updateCooUrl),
    takeLatest(constants.UPDATE_CHOWLY_PARTNER_ID, updateChowlyPartnerId),
    takeLatest(constants.UPDATE_DEFAULT_PUBLISHING_USER, updateDefaultPublishingUser),
    takeLatest(constants.REFRESH_STORE_LOCATION, refreshStoreLocation),
    takeLatest(constants.REFRESH_STORE_FACEBOOK_LOCATION, refreshStoreFacebookLocation),
    takeLatest(constants.REQUEST_FB_TOKEN, requestFbToken),
    takeLatest(constants.UPDATE_USER_EMAIL, updateUserEmail),
    takeLatest(constants.UPDATE_STORE_PIXEL, updateStorePixel),
    takeLatest(constants.UPDATE_USER_NAME, updateUserName),
    takeLatest(constants.UPDATE_USER, updateUser),
    takeLatest(constants.MOVE_USER_TO_BUSINESS, moveUserToBusiness),
    takeLatest(constants.REMOVE_USER, removeUser),
    takeLatest(constants.DELETE_USER, deleteUser),
    takeLatest(
      constants.REACTIVATE_STRIPE_SUBSCRIPTION,
      handleAsyncSaga,
      reactivateStripeSubscription,
    ),
    takeLatest(constants.SAVE_ECLINCHER_URL, saveEClincherUrl),
    takeLatest(constants.UPDATE_STORE_PUBLISHED_AS_PAUSED, updateStorePublishAsPaused),
    takeLatest(constants.ENABLE_DISABLE_STORE, enableDisableStore),
    takeLatest(constants.SAVE_CAMPAIGN_RETRY_TIMER, handleAsyncSaga, saveCampaignRetryTimer),
    takeLatest(constants.SAVE_MANAGED_LANDING_PAGES, handleAsyncSaga, saveManagedLandingPages),
    takeLatest(constants.DELETE_MANAGED_LANDING_PAGES, handleAsyncSaga, saveManagedLandingPages),
    takeLatest(constants.OPEN_TOKEN_DEBUGGER, handleAsyncSaga, openTokenDebugger),
    takeLatest(constants.GET_ALL_PERMISSIONS, getAllPermissions),
    takeLatest(constants.DELETE_INSIGHT_TEMPLATE, handleAsyncSaga, deleteInsightTemplate),
    takeLatest(constants.UPDATE_INSIGHT_TEMPLATE, handleAsyncSaga, updateInsightTemplate),
    takeLatest(constants.CREATE_INSIGHT_TEMPLATE, handleAsyncSaga, createInsightTemplate),
    takeLatest(constants.UPDATE_CUSTOM_FIELD, handleAsyncSaga, updateCustomField),
    takeLatest(constants.CREATE_CUSTOM_FIELD, handleAsyncSaga, createCustomField),
    takeLatest(constants.CREATE_QUESTIONNAIRE_TEMPLATE, handleAsyncSaga, createQuestionnaire),
    takeLatest(constants.UPDATE_QUESTIONNAIRE_TEMPLATE, handleAsyncSaga, updateQuestionnaire),
    takeLatest(constants.DELETE_QUESTIONNAIRE_TEMPLATE, handleAsyncSaga, deleteQuestionnaire),
    takeLatest(
      constants.DELETE_SUGGESTED_CAMPAIGN_TEMPLATE,
      handleAsyncSaga,
      deleteSuggestedCampaignTemplate,
    ),
    takeLatest(
      constants.UPDATE_SUGGESTED_CAMPAIGN_TEMPLATE,
      handleAsyncSaga,
      updateSuggestedCampaignTemplate,
    ),
    takeLatest(
      constants.CREATE_SUGGESTED_CAMPAIGN_TEMPLATE,
      handleAsyncSaga,
      createSuggestedCampaignTemplate,
    ),
    takeLatest(constants.INIT_SUGGESTED_CAMPAIGN_TEMPLATE, initSuggestedCampaignTemplate),
    takeLatest(
      constants.DUPLICATE_SUGGESTED_CAMPAIGN_TEMPLATE,
      handleAsyncSaga,
      duplicateSuggestedCampaignTemplate,
    ),
    takeLatest(
      constants.CREATE_MARKETING_SUGGESTED_CAMPAIGN,
      handleAsyncSaga,
      createMarketingSuggestedCampaign,
    ),
    takeLatest(
      constants.UPDATE_MARKETING_SUGGESTED_CAMPAIGN,
      handleAsyncSaga,
      updateMarketingSuggestedCampaign,
    ),
    takeLatest(
      constants.IMPORT_MARKETING_GSA_CAMPAIGN,
      handleAsyncSaga,
      importMarketingGoogleSearchAd,
    ),
    takeLatest(constants.DELETE_STORE, deleteStore),
    takeLatest(constants.CREATE_BUSINESS, createBusiness),
    takeLatest(constants.MULTI_STORE_ADD_STORE, ensureSubscription, handleAddStore),
    takeLatest(constants.SAVE_MARKETING_CHANNEL, saveMarketingChannel),
    takeLatest(constants.GET_MARKETING_CHANNELS, getMarketingChannels),
    takeLatest(constants.DELETE_MARKETING_CHANNEL, deleteMarketingChannel),
    takeLatest(constants.UPDATE_MARKETING_CHANNEL, updateMarketingChannel),
    takeLatest(constants.UPDATE_MARKETING_CHANNEL_QA_TOOLS, setMarketingChannelQATools),
    takeLatest(constants.GET_STORE_DATE_REPORTS, getStoreDateReports),
    takeLatest(constants.SEND_MAIL_CHIMP_TEST_EMAIL, sendMailChimpTestEmail),
    takeLatest(constants.TEST_MARKETING_CONNECTION, testMarketingChannelConnection),
    takeLatest(constants.TEST_FACEBOOK_CONNECTION_BY_STORES, testFacebookConnectionByStores),
    takeLatest(constants.GET_SILECTIS_GLOBAL_SUBSCRIPTIONS, getSilectisGlobalSubscriptions),
    takeLatest(constants.GET_SILECTIS_FACEBOOK_METRICS, getSilectisFacebookMetrics),
    takeLatest(constants.GET_SILECTIS_GOOGLE_METRICS, getSilectisGoogleMetrics),
    takeLatest(constants.GET_SILECTIS_MAILCHIMP_METRICS, getSilectisMailchimpMetrics),
    takeLatest(constants.GET_GOOGLE_ASSETS, getGoogleAssets),
    takeLatest(constants.SEND_TRANSACTIONAL_EMAIL, sendTransactionalEmail),
    takeLatest(constants.RETRY_CAMPAIGN, retryCampaign),

    takeLatest(constants.SET_AUTH0_PERMISSIONS, setAuth0Permissions),
    takeLatest(constants.VERIFY_AUTH0_EMAIL, verifyAuth0Email),
    takeLatest(constants.GET_GOOGLE_SEARCH_AD_COMBINATIONS, getSearchAdCombinations),
    takeLatest(constants.GET_INVOICE_HISTORY, getInvoiceHistory),
    takeLatest(constants.RETURN_CAMPAIGN_TO_DRAFT, returnCampaignToDraft),
    takeLatest(constants.SHOW_ACTIVITY_LOG_DIALOG, showActivityLogNoteDialog),
    takeLatest(constants.SHOW_PUBLISH_MARKETING_DIALOG, showPublishMarketingDialog),
    takeLatest(constants.PAUSE_MAILCHIMP_CAMPAIGN, pauseMailchimpCampaign),
    takeLatest(constants.UPDATE_AD_REQUEST, updateAdRequest),

    // campaigns
    takeLatest(constants.SAVE_CAMPAIGN_FORM, handleAsyncSaga, saveCampaignForm),
    takeLatest(constants.UPDATE_CAMPAIGN_USER, updateCampaignUser),
    takeLatest(
      constants.SUBMIT_CAMPAIGN_FORM,
      ensureSubscription,
      handleAsyncSaga,
      submitCampaignForm,
    ),
    takeLatest(constants.PREVIEW_SHOW_FEEDBACK_DIALOG, previewFeedbackDialog),
    takeLatest(constants.DATA_COORDINATES, translateAddressToCoordinates),
    takeLatest(constants.GET_STORE_PIXELS, getStorePixels),
    takeLatest(constants.PUBLISH_MARKETING_AD, publishMarketingAd),
    takeLatest(constants.CREATE_UPDATE_GOOGLE_SEARCH_ADS_BULK, createUpdateGoogleSearchAdsBulk),
    takeLatest(constants.DATA_COMMENTS, getComments),
    takeLatest(constants.POST_COMMMENT, postComment),
    takeLatest(constants.SAVE_RCC_URL, saveRccUrl),
    takeLatest(constants.SAVE_SALESFORCE_ID, saveSalesforceId),
    takeLatest(constants.UPDATE_META_LIVE_CAMPAIGN, updateMetaLiveCampaign),
    takeLatest(constants.GET_ALL_LEAD_GEN_FORMS, getAllLeadGenForms),
    takeLatest(constants.GET_AD_CARD_PRESETS, getCampaignAdCardPresets),
    takeLatest(constants.CREATE_MARKETING_EVENT, createMarketingEvent),
    takeLatest(constants.UPDATE_GOOGLE_LIVE_CAMPAIGN, updateGoogleLiveCampaign),
    takeLatest(constants.GET_VIDEO_THUMBNAIL, getVideoThumbnail),
  ]);
}
