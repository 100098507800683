import { take, put } from 'redux-saga/effects';

import { setConfirmationDialog } from '../../actions';
import { RESULT_CONFIRMATION_DIALOG } from '../../constants';

export default function* showConfirmationDialog({ payload }) {
  const {
    cancelText,
    cancelDataCy,
    confirmText,
    confirmDataCy,
    title,
    text,
    textParams,
    onClick,
  } = payload;

  yield put(setConfirmationDialog({
    isOpen: true,
    cancelText,
    cancelDataCy,
    confirmText,
    confirmDataCy,
    title,
    text,
    textParams,
  }));

  const { payload: result } = yield take(RESULT_CONFIRMATION_DIALOG);
  yield put(setConfirmationDialog({
    isOpen: false,
  }));
  onClick(result);
}
