import {
  DATA_BILLING_SUBSCRIPTION,
  DATA_BILLING_SUBSCRIPTION_LOADING,
  DATA_INVOICE_HISTORY,
  DATA_INVOICE_HISTORY_LOADING,
} from '../constants';

const defaultState = {
  subscription: null,
  subscriptionLoading: false,
  invoiceHistory: {
    loading: false,
    invoices: [],
  },
};

export default {
  defaultState,
  reducers: {
    [DATA_BILLING_SUBSCRIPTION]: (state, { payload: subscription }) => ({
      ...state,
      subscription: { ...state.subscription, ...subscription },
    }),
    [DATA_BILLING_SUBSCRIPTION_LOADING]: (state, { payload: subscriptionLoading }) => ({
      ...state,
      subscriptionLoading,
    }),
    [DATA_INVOICE_HISTORY]: (state, { payload }) => ({
      ...state,
      invoiceHistory: {
        ...state.invoiceHistory,
        invoices: payload,
      },
    }),
    [DATA_INVOICE_HISTORY_LOADING]: (state, { payload }) => ({
      ...state,
      invoiceHistory: {
        ...state.invoiceHistory,
        loading: payload,
      },
    }),
  },
};
