/* eslint-disable no-unused-expressions */
import { call, put, select } from 'redux-saga/effects';

import api from '../../services/api';
import {
  setBusinessUsers,
  showToaster,
} from '../../actions';
import logger from '../../services/logger';
import { selectBusinessUsers } from '../../selectors';
import { getToasterOptions } from '../helpers';

export default function* verifyAuth0Email({ payload }) {
  const { user, onComplete } = payload;
  try {
    yield put(showToaster(getToasterOptions('Verifying the email in Auth0...', 'info', null, 5000)));

    const verifiedUser = yield call(api.verifyUserEmail, user.id, { email: user.email });
    const currentUsers = yield select(selectBusinessUsers);

    // Find the user matching the id and update the permissions
    const updatedUsers = currentUsers.map((businessUser) => {
      if (businessUser.id === user.id) {
        return { ...user, emailVerified: true, auth0Id: verifiedUser?.auth0Id };
      }

      return businessUser;
    });

    yield put(setBusinessUsers(updatedUsers));
    yield put(showToaster(getToasterOptions('Email successfully verified.', 'success', null, 3000)));
    onComplete && onComplete();
  } catch (e) {
    const error = e?.response?.status === 404 ? 'The user must accept the invitation link first and Sign Up in Targetable before setting the email as verified' : e?.message;
    yield put(showToaster(getToasterOptions(`An error has occurred when trying to verify the email in Auth0.\nError: ${e?.response?.data}\n${JSON.stringify(error || e, null, 2)}`, 'error', null, 10000)));
    logger.error({
      error: e,
      context: { saga: 'verifyAuth0Email' },
      params: { ...payload },
    });
    onComplete && onComplete();
  }
}
