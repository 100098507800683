import { put, call } from 'redux-saga/effects';

import api from '../../services/api';

import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* deleteSuggestedCampaignTemplate({ payload }) {
  const { onDelete, onError, scTemplateId } = payload;

  try {
    yield call(api.deleteSuggestedCampaignTemplate, scTemplateId);

    if (onDelete) {
      onDelete();
    }
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to delete the Suggested Campaign Template. Try again in a few minutes.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'deleteSuggestedCampaignTemplate' },
      params: { payload },
    });

    if (onError) {
      onError();
    }
  }
}
