import { put, select } from 'redux-saga/effects';
import _ from 'lodash';
import { getFullName, getInvitationLink } from '@targetable/targetable-web-framework/lib/utils/user';

import { showToaster } from '../../actions';
import i18n from '../../services/i18n';
import auth from '../../services/auth';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { selectBusiness } from '../../selectors';
import globals from '../../globals';

export default function* copyInviteLinkToClipboard(action) {
  const currentBusiness = yield select(selectBusiness);
  const user = action.payload;
  const name = getFullName(user);
  const email = _.get(user, 'email');
  try {
    if (!navigator?.clipboard) {
      // Clipboard not supported
      return;
    }

    navigator.clipboard.writeText(getInvitationLink(user, globals.ENVIRONMENT_NAME));
    yield put(showToaster(getToasterOptions(i18n.t('copied_to_clipboard'), 'success', null, 2000)));
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'copyInviteLinkToClipboard' },
      params: {
        userId: auth.getUserId(),
        businessId: currentBusiness.id,
        user,
        name,
        email,
      },
    });
    yield put(showToaster(getToasterOptions(i18n.t('error_copy_to_clipboard'))));
  }
}
