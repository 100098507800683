import {
  DATA_MARKETING_CHANNELS,
  DATA_MARKETING_CHANNELS_LOADING,
} from '../constants';

const defaultState = {
  marketingChannels: [],
  marketingChannelsLoading: false,
};

export default {
  defaultState,
  reducers: {
    [DATA_MARKETING_CHANNELS]: (state, { payload: marketingChannels }) => ({
      ...state,
      marketingChannels,
    }),
    [DATA_MARKETING_CHANNELS_LOADING]: (state, { payload: marketingChannelsLoading }) => ({
      ...state,
      marketingChannelsLoading,
    }),
  },
};
