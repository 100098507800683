import { put, call } from 'redux-saga/effects';
import api from '../../services/api';
import { showToaster, setLoadingAsync } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* updateGoogleLiveCampaign({
  payload: {
    adId,
    data,
    onDone,
  },
}) {
  yield put(setLoadingAsync(true));
  try {
    yield call(
      api.updateLiveGoogleAdCampaignAsset,
      adId,
      data,
    );
    yield put(showToaster(getToasterOptions('Changes successfully updated', 'success', null, 5000)));
    onDone();
    yield put(setLoadingAsync(false));
  } catch (e) {
    yield put(showToaster(getToasterOptions('An error occurred while updating the ad', 'error')));
    logger.error({
      error: e,
      context: { saga: 'updateGoogleLiveCampaign' },
      params: {
        adId, data,
      },
    });
    yield put(setLoadingAsync(false));
  }
}
