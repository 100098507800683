import { put, call } from 'redux-saga/effects';
import _ from 'lodash';

import api from '../../services/api';
import logger from '../../services/logger';
import {
  showToaster,
  setLoadingAsync,
  setStoreLocation,
} from '../../actions';
import { getToasterOptions } from '../helpers';

export default function* refreshStoreLocation({ payload: store }) {
  try {
    const storeId = _.get(store, 'id');
    const placeId = _.get(store, 'locations.result.place_id');
    if (placeId) {
      yield put(setLoadingAsync(true));
      const { location: locations } = yield call(api.refreshStoreGoogleLocation, storeId);
      yield put(setLoadingAsync(false));
      yield put(setStoreLocation({ storeId, locations }));
    }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'refreshStoreLocation' },
      params: { store },
    });
    yield put(showToaster(getToasterOptions('Failed to refresh store location', 'error')));
  }
}
