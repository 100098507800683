import _ from 'lodash';
import { call, select } from 'redux-saga/effects';
import { selectAdCardPresets, selectUrlPresets } from '../../selectors';
import api from '../../services/api';

import getCampaignAdCardPresets from '../getCampaignAdCardPresets/getCampaignAdCardPresets';
import getCampaignUrlPresets from '../getCampaignUrlPresets/getCampaignUrlPresets';

export default function* validateCampaignUrlPresets(businessId, campaign) {
  yield call(getCampaignUrlPresets, {
    payload: {
      businessId: campaign.businessId,
      filter: true,
      backfill: true,
      reset: false,
    },
  });
  yield call(getCampaignAdCardPresets, campaign, true, false);
  const adCardPresets = yield select(selectAdCardPresets);
  const presets = yield select(selectUrlPresets);

  const { ad } = _.get(campaign, ['ads', '0'], {});
  const adCardsWithLink = _.filter(_.get(ad, 'adCards', []), (card) => card.type === 'image');

  // verify preset still exists
  let valid = _.every(
    adCardsWithLink.map((adCard) => !!_.find(presets, { id: adCard.urlPresetId })),
  );

  if (!valid) {
    return { valid: false, error: 'invalid_preset' };
  }

  const storeIds = campaign.ads.map(({ storeId }) => storeId);

  const res = yield Promise.all([
    /* checkSiteExists(_.get(ad, 'seeMoreLink')),
    // optional link
    ...(_.get(ad, 'displayLink') ? [checkSiteExists(_.get(ad, 'displayLink'))] : []), */
    // ...adCardsWithLink.map((card) => checkSiteExists(_.get(card, 'link', ''))),
    ...adCardsWithLink.map(({ id }) => Promise.all(
      _.get(adCardPresets, [id, 'stores'], []).map((store) => !storeIds.includes(store.id) || api.siteExists(store.url)),
    )),
  ]);

  const flattenList = _.flatten(res);
  valid = _.every(flattenList);

  if (!valid) {
    _.keys(adCardPresets).forEach((key, index) => {
      adCardPresets[key].stores.forEach((store, index2) => {
        if (!res[index][index2]) _.set(adCardPresets, `${key}.stores[${index2}].urlError`, true);
      });
    });
  }

  return {
    valid,
    error: !valid ? 'invalid_url' : false,
    // Return the list of urls with errors
    validationList: flattenList,
  };
}
