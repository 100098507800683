import {
  put, call, all,
} from 'redux-saga/effects';
import i18n from '../../services/i18n';
import api from '../../services/api';
import {
  showToaster,
  setLoadingAsync,
} from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import getBusiness from '../getBusiness/getBusiness';

export default function* handleEditBusinessName({
  payload: {
    businessId, name, onDone, exists,
  },
}) {
  try {
    if (exists) {
      throw new Error('business_name_exists');
    } else {
      yield put(setLoadingAsync(true));
      const { users } = yield call(api.getUsers, businessId);
      const calls = [
        call(api.updateBusiness, businessId, { name }),
        ...users.map((user) => (
          call(api.updateUser, user.id, { businessName: name })
        )),
      ];
      yield all(calls);
      yield put(setLoadingAsync(false));
      yield call(getBusiness, { payload: businessId });
      yield put(showToaster(getToasterOptions(i18n.t('update_business_name', 'Business name was updated successfully!'), 'success', null, 5000)));
      onDone();
    }
  } catch (e) {
    if (e && e.message === 'business_name_exists') {
      yield put(showToaster(getToasterOptions(i18n.t('update_business_name_already_exists', `Business name already exists - businessId: ${businessId}`), 'error')));
    } else {
      yield put(showToaster(getToasterOptions(i18n.t('update_business_name_error', 'An error occurred updating business name'), 'error')));
    }
    logger.error({
      error: e,
      context: { saga: 'handleEditBusinessName' },
      params: { businessId, name },
    });
  }
}
