import {
  call, put, select, take,
} from 'redux-saga/effects';
import _ from 'lodash';

import {
  SUGGEST_CAMPAIGN_STATUS_DRAFT,
  SUGGEST_CAMPAIGN_STATUS_PUBLISHED,
  SUGGEST_CAMPAIGN_STATUS_SCHEDULED,
} from '@targetable/targetable-web-framework/lib/react/constants';
import { getInitialAdState } from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/adPreview/adPreview';
import { getInitialBudgetState } from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/budgetSchedule/budgetSchedule';
import { getInitialTargetingState } from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/targeting/targeting';
import { getInitialScheduleState, getExpandedStatus } from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/suggestedCampaigns/suggestedCampaigns';
import * as campaignService from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/campaign/campaign';
import { FacebookCampaignTargetingType } from '@targetable/targetable-types/dist/types/facebook-campaign/types';
import {
  setBusinessCampaigns, setStoresCustomAudiences,
  setSuggestedCampaign,
  setSuggestedCampaignLoading,
} from '../../actions';
import api from '../../services/api';
import {
  selectBusiness,
  selectBusinessStores,
  selectBusinessSuggestedCampaigns,
} from '../../selectors';
import date from '../../services/date';
import { DATA_BUSINESS, DATA_BUSINESS_STORES } from '../../constants';
import logger from '../../services/logger';
import getCampaignAdCardPresets from '../getCampaignAdCardPresets/getCampaignAdCardPresets';
import getCampaignUrlPresets from '../getCampaignUrlPresets/getCampaignUrlPresets';
import getCustomAudiences from '../getCustomAudiences/getCustomAudiences';

export default function* getSuggestedCampaign({ payload: { suggestedCampaignId, ads } }) {
  yield put(setSuggestedCampaignLoading(true));

  let business = yield select(selectBusiness);
  if (!business) {
    yield take(DATA_BUSINESS);
    business = yield select(selectBusiness);
  }

  let stores = yield select(selectBusinessStores);
  if (_.isEmpty(stores)) {
    yield take(DATA_BUSINESS_STORES);
    stores = yield select(selectBusinessStores);
  }

  let suggestedCampaign;
  try {
    if (suggestedCampaignId === SUGGEST_CAMPAIGN_STATUS_DRAFT) {
      const campaignStores = campaignService.getCampaignStores(
        stores,
        stores.map((store) => ({ storeId: store.id })),
      );

      const storesWithoutFacebook = campaignService.getStoresWithoutFacebook(campaignStores);
      const hasFacebookInAllStores = campaignService.hasFacebookInAllStores(storesWithoutFacebook);

      const storesWithoutInstagram = campaignService.getStoresWithoutInstagram(campaignStores);
      const hasInstagramInAllStores = campaignService.hasInstagramInAllStores(
        storesWithoutInstagram,
      );

      suggestedCampaign = {
        businessId: _.get(business, 'id'),
        name: '',
        goal: 'standard',
        objective: 'reach',
        displayDate: date.startOfToday(),
        displayTime: { hour: 0, minute: 0 },
        status: SUGGEST_CAMPAIGN_STATUS_DRAFT,
        ads: _.map(stores, (store) => ({
          storeId: store.id,
          ad: getInitialAdState({
            ad: {
              adPlacement: {
                facebook: hasFacebookInAllStores,
                instagram: hasInstagramInAllStores,
              },
            },
          }),
          budget: getInitialBudgetState(),
          schedule: getInitialScheduleState(),
          targeting: getInitialTargetingState(
            null,
            store.defaultRadius,
            FacebookCampaignTargetingType.Radius,
          ),
        })),
      };
    } else {
      const suggestedCampaigns = yield select(selectBusinessSuggestedCampaigns);
      suggestedCampaign = _.find(suggestedCampaigns, ['id', suggestedCampaignId]);
      if (_.isNil(suggestedCampaign)) {
        suggestedCampaign = yield call(
          api.getSuggestedCampaign, suggestedCampaignId, stores, null, true,
        );
      }
      if (_.isEmpty(suggestedCampaign)) {
        suggestedCampaign = yield call(api.getAdById, suggestedCampaignId);
      }

      if (suggestedCampaign.type === 'google') {
        yield put(setSuggestedCampaign(suggestedCampaign));
        yield put(setSuggestedCampaignLoading(false));
        return;
      }
      // when ads are passed in, use those instead so they can be overwritten from outside the saga
      if (ads) {
        suggestedCampaign = {
          ...suggestedCampaign,
          ads,
        };
      }

      const campaigns = yield call(api.getCampaigns, stores, null, null, suggestedCampaignId);
      yield put(setBusinessCampaigns(_.get(campaigns, 'campaigns', [])));
    }

    // handle draft or scheduled
    // handle published, saved, dismissed
    const status = getExpandedStatus(suggestedCampaign);
    if ([
      SUGGEST_CAMPAIGN_STATUS_DRAFT,
      SUGGEST_CAMPAIGN_STATUS_SCHEDULED,
      SUGGEST_CAMPAIGN_STATUS_PUBLISHED,
    ].includes(status)) {
      yield call(getCampaignUrlPresets, {
        payload: {
          businessId: suggestedCampaign.businessId,
          filter: true,
          backfill: true,
          reset: true,
        },
      });
      yield call(getCampaignAdCardPresets, suggestedCampaign, true, true);
      yield call(getCustomAudiences, suggestedCampaign.businessId, true);
    } else {
      yield call(getCampaignUrlPresets, {
        payload: { businessId: suggestedCampaign.businessId, reset: true },
      });
      yield call(getCampaignAdCardPresets, suggestedCampaign, false, true);

      // get custom audience options from what was used in the campaign
      // We don't actually need to fetch custom audiences for read only
      yield put(setStoresCustomAudiences(
        campaignService.getCampaignAudienceOptions(suggestedCampaign),
      ));
    }

    yield put(setSuggestedCampaign(suggestedCampaign));
    yield put(setSuggestedCampaignLoading(false));
  } catch (e) {
    yield put(setSuggestedCampaignLoading(false));
    logger.error({
      error: e,
      context: { saga: 'getSuggestedCampaign' },
      params: { suggestedCampaignId, ads },
    });
  }
}
