import { put, call } from 'redux-saga/effects';

import i18n from '../../services/i18n';
import api from '../../services/api';
import {
  showToaster,
  setLoadingAsync,
} from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* updateStoreMarketingChannels({ payload }) {
  const { storeId, marketingChannels, onDone } = payload;
  try {
    yield put(setLoadingAsync(true));
    yield call(api.updateStore, storeId, { marketingChannels });
    yield put(showToaster(getToasterOptions(i18n.t('update_store_marketing_channels_success'), 'success', null, 5000)));
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to update store marketing channels', 'error')));
    logger.error({
      error: e,
      context: { saga: 'updateStoreMarketingChannels' },
      params: { payload },
    });
  }
  yield put(setLoadingAsync(false));
  if (onDone) {
    onDone();
  }
}
