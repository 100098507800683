import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import { showToaster } from '../../actions';
import { EXISTING_USER_INVITE, NEW_USER_INVITE } from '../../constants';
import api from '../../services/api';
import auth from '../../services/auth';
import logger from '../../services/logger';
import { selectBusiness } from '../../selectors';
import getUsersByBusiness from '../getUsersByBusiness/getUsersByBusiness';
import { getToasterOptions } from '../helpers';

export default function* inviteUser({ payload: invite }) {
  const currentBusiness = yield select(selectBusiness);

  let newUserId;
  try {
    if (invite.type === NEW_USER_INVITE) {
      const createdUser = yield call(api.inviteUser, currentBusiness.id, {
        businessId: currentBusiness.id,
        businessName: currentBusiness.name,
        familyName: invite.data.familyName,
        givenName: invite.data.givenName,
        email: invite.data.email,
        invitingUserId: auth.getUserId(),
      });
      const userId = _.get(createdUser, 'id');
      if (userId) {
        yield call(api.createActivityLog, {
          businessId: currentBusiness.id,
          type: 'user_logs_pii_invite_user',
          meta: {
            timestamp: new Date().valueOf(),
            userId: auth.getUserId(),
            viewedUrl: window.location.href,
            invitedUser: userId,
          },
        });
      }
    } else if (invite.type === EXISTING_USER_INVITE) {
      const createdUser = yield call(api.createUser, {
        auth0Id: invite.data.auth0Id,
        businessId: currentBusiness.id,
        businessName: currentBusiness.name,
        email: invite.data.email,
        familyName: invite.data.familyName,
        givenName: invite.data.givenName,
        onboarded: true,
        invitingUserId: invite.data?.id, // use existing user's token
        copyIntegrations: true,
      });

      newUserId = _.get(createdUser, 'id');

      yield call(api.associateUserHubspotRecordToBusinessHubspotRecord, newUserId);
    }

    yield call(getUsersByBusiness, { payload: currentBusiness.id });

    const message = invite.type === NEW_USER_INVITE ? 'Invitation has been sent!' : 'User has been added!';
    yield put(showToaster(getToasterOptions(message, 'success', null, 5000)));
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'inviteUser' },
      params: { invite, business: currentBusiness, newUserId },
    });
    const message = invite.type === NEW_USER_INVITE ? 'Error inviting user' : 'Error adding user';
    yield put(showToaster(getToasterOptions(message, 'error', null, 5000)));
  }

  invite.onDone();
}
