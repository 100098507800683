import _ from 'lodash';
import {
  call, put, all, select,
} from 'redux-saga/effects';
import { getUserFacebookIntegration } from '@targetable/targetable-web-framework/lib/services/user';
import api from '../../services/api';
import {
  showToaster, setUsersPermissionsLoading, setUsersPermissions, setBusinessStores,
} from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { selectBusinessStores } from '../../selectors';
import getStoresByBusiness from '../getStoresByBusiness/getStoresByBusiness';

export default function* getAllPermissions({ payload: userId }) {
  try {
    const user = yield call(api.getUser, userId, false);
    const userFbIntegration = getUserFacebookIntegration(user);
    const accessToken = userFbIntegration?.accessToken;
    const facebookUserId = userFbIntegration?.userId;
    let stores = yield select(selectBusinessStores) || [];
    let resetStoresOnFinish = true;

    if (!stores.length) {
      resetStoresOnFinish = true;
      yield call(getStoresByBusiness, { payload: user.businessId });
      stores = yield select(selectBusinessStores) || [];
    }

    yield put(setUsersPermissionsLoading(true));

    const promises = [];
    const missingPermissions = [];
    _.forEach(stores, (store) => {
      const storeFacebookIntegration = _.find(_.get(store, 'integrations'), ['type', 'facebook']);
      const facebookPageId = _.get(storeFacebookIntegration, 'pageId');
      const facebookBusinessId = _.get(storeFacebookIntegration, 'businessId');
      const facebookAdAccountId = _.get(storeFacebookIntegration, 'adAccountId');

      if (facebookBusinessId && facebookUserId) {
        promises.push(call(
          api.getBusinessPermissions, store?.id, facebookBusinessId, facebookUserId, accessToken,
        ));
      } else {
        missingPermissions.push({
          id: store.id,
          type: 'business',
          msg: 'No facebookBusinessId or facebookUserId',
        });
      }
      if (facebookBusinessId && facebookUserId && facebookAdAccountId) {
        promises.push(call(
          api.getAdAccountPermissions,
          store?.id,
          facebookBusinessId,
          facebookAdAccountId,
          facebookUserId,
          accessToken,
        ));
      } else {
        missingPermissions.push({
          id: store.id,
          type: 'adAccount',
          msg: 'No nofacebookBusinessId, facebookUserId or facebookAdAccountId',
        });
      }
      if (facebookBusinessId && facebookUserId && facebookPageId) {
        promises.push(call(
          api.getPagePermissions,
          store?.id,
          facebookBusinessId,
          facebookPageId,
          facebookUserId,
          accessToken,
        ));
      } else {
        missingPermissions.push({
          id: store.id,
          type: 'page',
          msg: 'No nofacebookBusinessId, facebookUserId or facebookAdAccountId',
        });
      }
    });

    const result = yield all(promises);
    const permissionsType = ['business', 'adAccount', 'page'];
    const parsedResult = _.map(stores, (store) => {
      const missingStorePerms = _.filter(missingPermissions, ({ id }) => store.id === id);
      const storeResults = result.splice(0, 3 - missingStorePerms.length);
      const availableTypes = permissionsType.filter(
        (type) => !missingStorePerms.some((missing) => missing.type === type),
      );

      return {
        id: store.id,
        name: store.name,
        missingStorePerms,
        storePermissions: storeResults?.map((storeResult, index) => ({
          ...storeResult,
          type: availableTypes[index],
        })),
      };
    });

    yield put(setUsersPermissions(parsedResult));
    yield put(setUsersPermissionsLoading(false));
    if (resetStoresOnFinish) {
      yield put(setBusinessStores([]));
    }

    return result;
  } catch (e) {
    yield put(setUsersPermissionsLoading(false));
    logger.error({
      error: e,
      context: { saga: 'getAllPermissions' },
      params: { userId },
    });
    yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
    return undefined;
  }
}
