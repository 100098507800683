import { call, put } from 'redux-saga/effects';

import api from '../../services/api';

import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* deleteUrlPreset({ payload }) {
  const {
    businessId, presetId, onError, onDone,
  } = payload;

  try {
    yield call(api.deleteUrlPreset, businessId, presetId);
    if (onDone) onDone();
  } catch (e) {
    if (onError) onError();
    yield put(showToaster(getToasterOptions('Failed to delete URL preset data. Try again in a few minutes.', 'error', null, 5000)));

    logger.error({
      error: e,
      context: { saga: 'deleteUrlPreset' },
      params: {
        businessId, presetId,
      },
    });
  }
}
