import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import AsyncMarketingAutocomplete from '@targetable/targetable-web-framework/lib/react/components/AsyncMarketingAutocomplete/AsyncMarketingAutocomplete';
import DrawerContent from '@targetable/targetable-web-framework/lib/react/containers/DrawerContent/DrawerContent';
import PageContentContainer from '@targetable/targetable-web-framework/lib/react/containers/PageContentContainer/PageContentContainer';
import ComponentLoading from '@targetable/targetable-web-framework/lib/react/components/ComponentLoading/ComponentLoading';

import algoliasearch from 'algoliasearch/lite';
import { getToasterOptions } from '../../sagas/helpers';
import { updateBusiness, showConfirmationDialog, showToaster } from '../../actions';
import { selectBusiness, selectBusinessLoading } from '../../selectors';
import env from '../../globals';

const searchClient = algoliasearch(env.ALGOLIA_APP_ID, env.ALGOLIA_API_SEARCH_KEY);
const index = searchClient.initIndex(`${env.ENVIRONMENT_NAME}_Businesses`);

/**
 * Fetches all results from Algolia using the given term and fields.
 * @param {string} searchTerm the keyword to search for
 * @param {string[]} fields the fields to include in the results
 * @returns {Promise<object[]>} the results from Algolia
 */
const searchAll = async (searchTerm, fields) => {
  const searchNext = async (page) => {
    const results = await index.search(searchTerm, {
      page,
      hitsPerPage: 100,
      attributesToRetrieve: fields,
    });

    return results;
  };

  let results = [];
  let searchResult;
  let lastPage = 0;

  do {
    // eslint-disable-next-line no-await-in-loop
    searchResult = await searchNext(lastPage);
    results = results.concat(searchResult.hits);
    lastPage += 1;
  } while (searchResult.nbPages > lastPage);

  // Remove unwanted props
  results = results.map((result) => {
    const { _highlightResult, ...rest } = result;
    return rest;
  });

  if (_.includes(_.lowerCase('N/A'), _.lowerCase(searchTerm))) {
    results.push({
      id: '-',
      name: 'N/A',
    });
  }

  if (_.isEqual(_.lowerCase('N/A'), _.lowerCase(searchTerm))) {
    results = results.filter((item) => item.name === 'N/A');
  }

  return results;
};

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: '100%',
    margin: `${theme.spacing(2)}px auto`,
  },
  backdrop: {
    zIndex: 1400,
  },
  buttonRight: {
    marginRight: theme.spacing(2),
  },
  partnerBody: {
    margin: '0 12px',
  },
  businessDetails: {
    fontSize: '10px',
    color: '#8c8f9f',
  },
}));

const BusinessAddPartner = ({
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const businessData = useSelector(selectBusiness);

  const businessLoading = useSelector(selectBusinessLoading) || false;

  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedPartner, setSelectedPartner] = React.useState(businessData?.partner || {});

  const fetchBusinesses = useCallback(async (query) => searchAll(query, ['name', 'id']), []);

  useEffect(() => {
    if (!openDrawer) {
      setOpenDrawer(true);
    }
  }, [openDrawer]);

  useEffect(() => {
    const handlePreselected = async () => {
      if (businessData?.partner?.id) {
        const biz = await fetchBusinesses(businessData?.partner?.id);
        if (biz && biz[0]) {
          setSelectedPartner(biz[0]);
        }
      } else {
        setSelectedPartner({});
      }
    };
    handlePreselected();
  }, [businessData, fetchBusinesses]);

  const handleChangePartner = useCallback((partner) => {
    setSelectedPartner(partner);
  }, []);

  const handleSave = useCallback(async () => {
    dispatch(updateBusiness({
      partner: selectedPartner?.id !== '-' ? { id: selectedPartner?.id } : {},
      onDone: () => {
        dispatch(showToaster(getToasterOptions('Partner was updated successfully!!!', 'success', null, 5000)));
      },
    }));
    onClose();
  }, [dispatch, onClose, selectedPartner]);

  const handleClose = useCallback(() => {
    if (!_.isEmpty(selectedPartner) && (businessData?.partner?.id !== selectedPartner?.id)) {
      dispatch(showConfirmationDialog({
        title: 'Unsaved Changes',
        text: 'Do you want to discard changes?',
        confirmText: 'cancel',
        confirmDataCy: 'cta-add_partner_cancel',
        cancelText: 'discard',
        cancelDataCy: 'cta-add_partner_discard',
        onClick: (confirm) => {
          if (!confirm) {
            onClose();
          }
        },
      }));
    } else {
      onClose();
    }
  }, [
    onClose,
    dispatch,
    selectedPartner,
    businessData?.partner?.id,
  ]);

  const validBusinesses = (business) => businessData?.id !== business?.id;

  return (
    <>
      <DrawerContent
        open={openDrawer}
        onClose={handleClose}
        title={`${businessData?.name} / ${t('add_partner')}`}
        buttons={[
          {
            text: 'save',
            classes: classes.buttonRight,
            variant: 'contained',
            color: 'secondary',
            disabled: _.isEmpty(selectedPartner)
              || (businessData?.partner?.id === selectedPartner?.id),
            onClick: async () => {
              await handleSave();
            },
          },
        ]}
        invisibleBackdrop
      >
        <PageContentContainer className={classes.pageContainer}>
          <>
            <ComponentLoading
              loading={businessLoading}
              waitKey="loading_business_add_partner"
            >
              <Grid
                container
                spacing={2}
                justifyContent="center"
                data-cy="businessAddPartnerDialog_container"
              >
                <Typography variant="body2" className={classes.partnerBody}>
                  {t('add_partner_shelf_body', { businessName: businessData?.name })}
                </Typography>
                <Grid item xs={12}>
                  <AsyncMarketingAutocomplete
                    onChange={handleChangePartner}
                    label="Partner"
                    placeholder="Search by Name or Id"
                    value={selectedPartner}
                    getOptionLabel={(option) => (!_.isEmpty(option) ? option.name : '')}
                    getOptionSelected={(option, value) => option?.id === value?.id}
                    getOptionDisabled={(option) => !validBusinesses(option)}
                    allSuggestionsOnOpen
                    fetchPromise={fetchBusinesses}
                    renderOption={(option) => {
                      if (!option?.id || !option?.name) return null;
                      return (
                        <Grid container spacing={0.5} justifyContent="left" data-cy="addPartner_businesses_item">
                          <Grid item xs={12} md={12} lg={12}>
                            <Typography
                              variant="body2"
                              data-cy="addPartner_businesses_label"
                            >
                              {option?.name}
                            </Typography>
                            <Typography className={classes.businessDetails} component="div" variant="caption">
                              Business ID:
                              {' '}
                              {option?.id}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    }}
                    dataCy="addPartner_businesses_autocomplete"
                  />
                </Grid>
              </Grid>
            </ComponentLoading>
          </>
        </PageContentContainer>
      </DrawerContent>
    </>
  );
};

BusinessAddPartner.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default BusinessAddPartner;
