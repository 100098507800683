export const specialCharactersRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;

export const hasSpecialCharacters = (valueToInspect) => specialCharactersRegex.test(
  valueToInspect,
);

/**
 * Removes comma values between numbers, 12,456 => 12456
 * @param {String} stringValue
 * @returns {String}
 */
export const sanitizeCSVValue = (stringValue) => stringValue?.replace(/(\d),(\d)/g, '$1$2') || '';
