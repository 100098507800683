import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PageHeader from '../../components/PageHeader/PageHeader';
import PageWrapper from '../PageWrapper/PageWrapper';
import { getBusinessesRoute } from '../../routes';
import { selectBusiness } from '../../selectors';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(2)}px auto 0`,
    padding: theme.spacing(0, 2),
  },
}));

const PageContainer = ({ children, pageContainerClassName, variant }) => {
  const classes = useStyles();
  const location = useLocation();
  const business = useSelector(selectBusiness);
  const businessName = _.get(business, 'name', '');
  let pageTitle = 'Admin Portal';
  if (businessName) {
    pageTitle += `: ${businessName}`;
  }

  if (location?.pathname === getBusinessesRoute()) pageTitle = 'Businesses';

  return (
    <PageWrapper variant={variant}>
      <PageHeader title={pageTitle} />
      <div className={classnames(classes.container, pageContainerClassName)}>
        {children}
      </div>
    </PageWrapper>
  );
};

PageContainer.defaultProps = {
  pageContainerClassName: '',
  variant: 'gray',
};

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  pageContainerClassName: PropTypes.string,
  variant: PropTypes.oneOf(['white', 'gray']),
};

export default PageContainer;
