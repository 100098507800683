import {
  SHOW_ADD_STORE_MODAL,
} from '../constants';

export default {
  defaultState: {
    showAddStore: { show: false },
  },
  reducers: {
    [SHOW_ADD_STORE_MODAL]: (state, { payload }) => ({
      ...state,
      showAddStore: { show: payload },
    }),
  },
  excludeFromReset: true,
};
