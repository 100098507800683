import React, {
  useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import DrawerContent from '@targetable/targetable-web-framework/lib/react/containers/DrawerContent/DrawerContent';
import PageContentContainer from '@targetable/targetable-web-framework/lib/react/containers/PageContentContainer/PageContentContainer';
import ComponentLoading from '@targetable/targetable-web-framework/lib/react/components/ComponentLoading/ComponentLoading';
import {
  Typography,
} from '@material-ui/core';
import ActiveResults from '@targetable/targetable-web-framework/lib/react/components/ActiveResults/ActiveResults';
import {
  getMarketingChannels,
} from '../../actions';
import {
  selectMarketingChannelsLoading,
} from '../../selectors';

const useStyles = makeStyles((theme) => ({
  shadowless: {
    boxShadow: 'unset !important;',
  },
  pageContainer: {
    width: '100%',
    margin: `${theme.spacing(2)}px auto`,
  },
  backdrop: {
    zIndex: 1400,
  },
  title: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),
  },
  subtitle2: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  labelControl: {
    position: 'relative',
    marginLeft: 0,
    width: '100%',
    cursor: 'default',
  },
  labelContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  labelCaption: {
    marginTop: theme.spacing(1),
    color: '#86899A',
  },
}));

const SmartfeedOverviewWidgetDrawer = ({
  onClose,
  businessInfo,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const marketingChannelsLoading = useSelector(selectMarketingChannelsLoading);
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (!openDrawer) {
      setOpenDrawer(true);
    }
  }, [openDrawer]);

  useEffect(() => {
    dispatch(getMarketingChannels(businessInfo?.id));
  }, [dispatch, businessInfo]);

  return (
    <>
      <DrawerContent
        open={openDrawer}
        onClose={onClose}
        title={`${businessInfo?.name} Reporting Tools`}
        invisibleBackdrop
        buttons={[
          {
            text: 'close',
            classes: classes.buttonRight,
            variant: 'contained',
            color: 'secondary',
            disabled: marketingChannelsLoading,
            onClick: onClose,
          },
        ]}
      >
        <PageContentContainer className={classes.pageContainer}>
          <>
            <ComponentLoading
              loading={Boolean(marketingChannelsLoading)}
              waitKey="loading_marketing_channels"
            >
              <Typography variant="h2" className={classes.title}>{t('dashboard_drawer_overview_widget', 'Smartfeed Overview Widget')}</Typography>
              <ActiveResults
                businessId={businessInfo.id}
                updateLayout={() => {}}
                containerClass={classes.shadowless}
                widgetContainerClass={classes.shadowless}
              />
            </ComponentLoading>
          </>
        </PageContentContainer>
      </DrawerContent>
    </>
  );
};

SmartfeedOverviewWidgetDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  businessInfo: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
};

export default SmartfeedOverviewWidgetDrawer;
