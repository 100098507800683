import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@targetable/targetable-web-framework/lib/react/components/TextField/TextField';
import DrawerContent from '@targetable/targetable-web-framework/lib/react/containers/DrawerContent/DrawerContent';
import PageContentContainer from '@targetable/targetable-web-framework/lib/react/containers/PageContentContainer/PageContentContainer';
import ComponentLoading from '@targetable/targetable-web-framework/lib/react/components/ComponentLoading/ComponentLoading';
import _ from 'lodash';
import algoliasearch from 'algoliasearch/lite';
import { confirmEditBusinessName } from '../../actions';
import { selectBusiness, selectBusinessLoading } from '../../selectors';
import env from '../../globals';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: '100%',
    margin: `${theme.spacing(2)}px auto`,
  },
  backdrop: {
    zIndex: 1400,
  },
  progressColor: {
    color: '#fff',
  },
  buttonRight: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),
  },
}));

const searchClient = algoliasearch(env.ALGOLIA_APP_ID, env.ALGOLIA_API_SEARCH_KEY);
const index = searchClient.initIndex(`${env.ENVIRONMENT_NAME}_Businesses`);

/**
 * Fetches all results from Algolia using the given term and fields.
 * @param {string} searchTerm the keyword to search for
 * @param {string[]} fields the fields to include in the results
 * @returns {Promise<object[]>} the results from Algolia
 */
const searchAll = async (searchTerm, fields) => {
  const searchNext = async (page) => {
    const results = await index.search(searchTerm, {
      page,
      hitsPerPage: 100,
      attributesToRetrieve: fields,
    });

    return results;
  };

  let results = [];
  let searchResult;
  let lastPage = 0;

  do {
    // eslint-disable-next-line no-await-in-loop
    searchResult = await searchNext(lastPage);
    results = results.concat(searchResult.hits);
    lastPage += 1;
  } while (searchResult.nbPages > lastPage);

  // Remove unwanted props
  results = results.map((result) => {
    const { _highlightResult, ...rest } = result;
    return rest;
  });

  return results;
};

const businessNameExists = async (name) => {
  const results = await searchAll(name, ['name', 'id']);
  const exists = results.filter((info) => info.name?.toLowerCase() === name?.toLowerCase());
  if (exists.length > 0) return true;
  return false;
};

const BusinessEditNameDialog = ({
  onClose,
  businessInfo,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const businessData = useSelector(selectBusiness);

  let businessId;
  let businessName;
  if (businessInfo) {
    businessId = businessInfo.id;
    businessName = businessInfo.name;
  } else {
    businessId = businessData.id;
    businessName = businessData.name;
  }

  const businessLoading = useSelector(selectBusinessLoading) || false;

  const [name, setName] = useState(businessName);

  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (!openDrawer) {
      setOpenDrawer(true);
    }
  }, [openDrawer]);

  const handleSave = useCallback(async () => {
    const onDone = () => { onClose(_.trim(name)); };
    dispatch(confirmEditBusinessName({
      businessId,
      name: _.trim(name),
      onDone,
      exists: await businessNameExists(_.trim(name)),
    }));
  }, [businessId, name, dispatch, onClose]);

  const handleChange = (event) => {
    setName(event.target.value);
  };

  return (
    <>
      <DrawerContent
        open={openDrawer}
        onClose={onClose}
        title={businessName}
        buttons={[
          {
            text: 'save',
            classes: classes.buttonRight,
            variant: 'contained',
            color: 'secondary',
            disabled: !name?.length || businessName === name,
            onClick: handleSave,
          },
        ]}
        invisibleBackdrop
      >
        <PageContentContainer className={classes.pageContainer}>
          <>
            <ComponentLoading
              loading={businessLoading}
              waitKey="loading_business_edit_name"
            >
              <Typography variant="h2" className={classes.title}>Edit Business Name</Typography>
              <TextField
                fullWidth
                onChange={handleChange}
                label="Business Name"
                variant="outlined"
                value={name}
                data-cy="businessName"
                InputLabelProps={{ shrink: true }}
              />
            </ComponentLoading>
          </>
        </PageContentContainer>
      </DrawerContent>
    </>
  );
};

BusinessEditNameDialog.defaultProps = {
  businessInfo: null,
};

BusinessEditNameDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  businessInfo: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    stripeCustomerId: PropTypes.string,
  }),
};

export default BusinessEditNameDialog;
