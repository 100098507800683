import _ from 'lodash';
import { put, call } from 'redux-saga/effects';

import api from '../../services/api';
import { setBusinessStores, showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* updateStoreDefaultRadius({ payload: store }) {
  try {
    yield call(
      api.updateStore,
      store.id,
      {
        defaultRadius: store.defaultRadius,
        defaultRadiusEmployment: store.defaultRadiusEmployment,
      },
    );

    let stores = yield call(api.getStores, store.businessId);
    stores = _.sortBy(stores, 'name');
    yield put(setBusinessStores(stores));
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to update store', 'error')));
    logger.error({
      error: e,
      context: { saga: 'updateStore' },
      params: { store },
    });
  }
}
