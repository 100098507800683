import {
  DATA_BUSINESSES,
  DATA_BUSINESSES_LOADING,
} from '../constants';

const defaultState = {
  businesses: [],
  businessesLoading: false,
};

export default {
  defaultState,
  reducers: {
    [DATA_BUSINESSES]: (state, { payload: businesses }) => ({ ...state, businesses }),
    [DATA_BUSINESSES_LOADING]: (state, { payload: businessesLoading }) => ({
      ...state,
      businessesLoading,
    }),
  },
};
