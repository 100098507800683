import { put, call, select } from 'redux-saga/effects';
import i18n from '../../services/i18n';

import api from '../../services/api';
import {
  showToaster,
  setLoadingAsync,
  setBusinessUsers,
} from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { selectBusinessUsers } from '../../selectors';

/**
 * Updates any property in the user record
 * @param {Object} param0
 * @param {Object} param0.payload
 * @param {string} param0.payload.userId the id of the user to update
 * @param {string} param0.payload.properties a partial list of user properties to update
 * @param {string} param0.payload.onComplete a callback function to call when the update is complete
 */
export default function* updateUser({ payload }) {
  const { userId, properties, onComplete } = payload;
  try {
    const { id, ...cleanProperties } = properties;
    const currentUsers = yield select(selectBusinessUsers);
    yield put(setLoadingAsync(true));
    yield call(api.updateUser, userId, { ...cleanProperties });
    // Update the user in the redux store
    const updatedUsers = currentUsers.map((user) => {
      if (user.id === userId) {
        return {
          ...user,
          ...cleanProperties,
        };
      }

      return user;
    });
    yield put(setBusinessUsers(updatedUsers));
    yield put(setLoadingAsync(false));
    yield put(showToaster(getToasterOptions(i18n.t('update_user_success'), 'success', null, 5000)));
    if (onComplete) { onComplete(); }
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to update user properties', 'error')));
    logger.error({
      error: e,
      context: { saga: 'updateUser' },
      params: { userId, properties },
    });
  }
}
