import { call, put, select } from 'redux-saga/effects';
import { MarketingChannelTypes } from '@targetable/targetable-types/dist/types/marketing-channel/types';
import api from '../../services/api';
import { showToaster } from '../../actions';
import i18n from '../../services/i18n';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { getBusinessRoute } from '../../routes';
import { selectBusinessIdQueried } from '../../selectors';

import history from '../../services/history';
import {
  LIVE_TAB,
  HISTORY_TAB,
  CAMPAIGN_STATUS_PAUSED,
} from '../../constants';
import auth from '../../services/auth';

export default function* pauseOrUnpauseCampaign({ payload }) {
  const { campaign, onDone } = payload;
  const currentUser = yield auth.getUser();
  const isGoogleCampaign = campaign?.type && campaign?.type === MarketingChannelTypes.Google;
  let paused = false;
  if (isGoogleCampaign && ['live', 'submitted'].includes(campaign?.status)) {
    paused = true;
  }
  const action = campaign?.status === CAMPAIGN_STATUS_PAUSED ? 'resumed' : 'paused';
  const initAction = campaign?.status === CAMPAIGN_STATUS_PAUSED ? 'Resuming' : 'Pausing';
  try {
    yield put(showToaster(getToasterOptions(i18n.t('pause_resume_campaign', { initAction }), 'info', null, 7000)));
    let result;
    if (isGoogleCampaign) {
      result = yield call(api.pauseOrUnpauseAd, campaign.id, paused);
    } else {
      result = yield call(api.pauseOrUnpauseCampaign, campaign.id);
    }

    if (result?.failed?.length === 0 || result?.google) {
      yield put(showToaster(getToasterOptions(i18n.t('successfully_pause_resume_campaign', { action }), 'info', null, 5000)));

      const businessId = yield select(selectBusinessIdQueried);
      history.push(getBusinessRoute(businessId, 'campaigns', action === 'resumed' ? LIVE_TAB : HISTORY_TAB));
    } else {
      yield put(showToaster(getToasterOptions(i18n.t('error_pause_resume_campaign', { action }))));
    }

    if (onDone) { onDone(); }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'pauseOrUnpauseCampaign' },
      params: {
        userId: currentUser?.sub,
        campaignId: campaign?.id,
      },
    });
    yield put(showToaster(getToasterOptions(i18n.t('error_pause_resume_campaign', { action }))));
  }
}
