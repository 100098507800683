import {
  call,
  put,
  select,
  take,
} from 'redux-saga/effects';
import _ from 'lodash';
import { getFullName } from '@targetable/targetable-web-framework/lib/utils/user';
import api from '../../services/api';
import { setLoadingAsync, showActivityLogNoteDialog, showToaster } from '../../actions';
import i18n from '../../services/i18n';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { selectBusiness } from '../../selectors';
import auth from '../../services/auth';
import getUsersByBusiness from '../getUsersByBusiness/getUsersByBusiness';
import { RESULT_ACTIVITY_LOG_DIALOG } from '../../constants';

export default function* removeUser(action) {
  yield put(setLoadingAsync(false));
  yield put(showActivityLogNoteDialog({
    title: 'Add Note? (optional)',
    text: 'You can add any notes, descriptions, reasons for the action you are about to take. This may or may not be exposed to the customer.',
    confirmText: 'submit',
    cancelText: 'cancel',
    onClick: _.identity,
  }));

  const { payload: activityLogNote } = yield take(RESULT_ACTIVITY_LOG_DIALOG);
  if (activityLogNote === false) return;

  const currentUser = yield auth.getUser();
  const currentBusiness = yield select(selectBusiness);
  const user = action.payload;
  const name = getFullName(user);
  const email = _.get(user, 'email');
  try {
    yield call(api.deleteUser, user.id, {
      businessId: currentBusiness?.id,
      businessName: currentBusiness?.name,
      activityLogNote,
    });

    yield call(api.createActivityLog, {
      businessId: currentBusiness?.id,
      type: 'user_logs_pii_remove_user',
      meta: {
        timestamp: new Date().valueOf(),
        userId: currentUser.sub,
        viewedUrl: window.location.href,
        deletedUser: _.get(user, 'id'),
      },
    });
    yield put(showToaster(getToasterOptions(i18n.t('user_removed_successfully', { name, email }), 'success', null, 5000)));
    yield call(getUsersByBusiness, { payload: currentBusiness.id });
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'removeUser' },
      params: {
        userId: currentUser.sub, user, name, email,
      },
    });
    yield put(showToaster(getToasterOptions(i18n.t('error_remove_user', { name, email }))));
  }
}
