import { put, call } from 'redux-saga/effects';
import i18n from '../../services/i18n';
import api from '../../services/api';
import {
  showToaster,
  setLoadingAsync,
} from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* unlinkMetaConnection({ payload }) {
  const { storeId, onDone } = payload;
  try {
    yield put(setLoadingAsync(true));
    yield call(api.updateStore, storeId, { integrations: [] });
    yield put(showToaster(getToasterOptions(i18n.t('unlink_meta_connection_success'), 'success', null, 5000)));
    if (onDone) {
      onDone();
    }
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to unlink meta connection', 'error')));
    logger.error({
      error: e,
      context: { saga: 'unlinkMetaConnection' },
      params: { payload },
    });
  }
  yield put(setLoadingAsync(false));
  if (onDone) {
    onDone();
  }
}
