import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import OpenAIMenu from '@targetable/targetable-web-framework/lib/react/components/OpenAIMenu/OpenAIMenu';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import ChatIcon from '../Images/ChatIcon/ChatIcon';
import TargetableIcon from '../Images/TargetableIcon/TargetableIcon';
import SideMenu from '../SideMenu/SideMenu';
import auth from '../../services/auth';
import api from '../../services/api';

/**
 * @typedef {import('@targetable/targetable-types/dist/types/user').TargetableUser} TargetableUser
 */

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menu: {
    cursor: 'pointer',
    marginRight: theme.spacing(2),
  },
  typography: {
    color: theme.palette.primary.contrastText,
  },
  drawerIcon: {
    '& path': {
      fill: '#ffff',
    },
    '&.active path': {
      fill: '#007BFF',
    },

  },
  pointer: {
    cursor: 'pointer',
  },
  avatarContainer: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const PageHeader = ({
  title,
}) => {
  const classes = useStyles();
  const [showOpenAiMenu, setShowOpenAiMenu] = useState(false);
  const userInfo = auth?.getUserInfo();
  const [{ menuOpen }, setOpen] = useState(
    { menuOpen: false },
  );

  function setMenu(isOnClose) {
    if (isOnClose && menuOpen === false) {
      return;
    }

    setOpen({ menuOpen: !menuOpen });
  }

  return (
    <div className={classes.grow}>
      <AppBar color="primary" position="fixed">
        <Toolbar>
          <MenuIcon
            className={classes.menu}
            data-cy="menu"
            onClick={() => setMenu(false)}
          />
          <Typography variant="overline" className={classnames(classes.grow, classes.typography)}>
            {title}
          </Typography>

          <div className={classes.avatarContainer}>
            <ChatIcon
              size={40}
              onClick={() => setShowOpenAiMenu(!showOpenAiMenu)}
              className={classnames(classes.pointer, classes.drawerIcon, showOpenAiMenu && 'active')}
            />
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <SideMenu isOpen={menuOpen} onClose={() => setMenu(true)} />
      <OpenAIMenu
        isOpen={showOpenAiMenu}
        onClose={() => setShowOpenAiMenu(false)}
        userName={userInfo?.given_name || 'X'}
        assistantIcon={<TargetableIcon size={24} />}
        api={api}
      />
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageHeader;
