const { AIRBRAKE_ID } = process.env;
const { AIRBRAKE_KEY } = process.env;
const { API_BASE_URL } = process.env;
const { AUTH0_CLIENT_ID } = process.env;
const { AUTH0_DOMAIN } = process.env;
const { CLOUDINARY_KEY } = process.env;
// const { DATADOG_KEY } = process.env;
// const { DATADOG_REGION } = process.env;
const { ENVIRONMENT_NAME } = process.env;
const { CIRCLE_BUILD_NUM } = process.env;
const { STATIC_BASE_URL } = process.env;
const { HUBSPOT_ACCOUNT_ID } = process.env;
const { GOOGLE_MCC_ACCOUNT_IDS } = process.env;

// Stripe products
const { STRIPE_PRODUCT_PAID_AD } = process.env;
const { STRIPE_PRODUCT_FB_IG_TW } = process.env;
const { STRIPE_PRODUCT_LINKEDIN } = process.env;
const { STRIPE_PRODUCT_PINTEREST } = process.env;
const { STRIPE_PRODUCT_YOUTUBE } = process.env;
const { STRIPE_PRODUCT_GOOGLE_BS } = process.env;
const { STRIPE_PRODUCT_TGT_CM } = process.env;
const { STRIPE_PRODUCT_AA_PLUS } = process.env;
const { STRIPE_PRODUCT_MANAGED_LP_BASE } = process.env;
const { STRIPE_PRODUCT_ADDITIONAL_LP } = process.env;
const { STRIPE_PRODUCT_DISCOVERABILITY } = process.env;
const { STRIPE_PRODUCT_GOOGLE_AD_SEARCH } = process.env;
const { STRIPE_PRODUCT_MANAGED_EMAIL_MARKETING } = process.env;
const { STRIPE_PRODUCT_MANAGED_EMAIL_MARKETING_PLUS } = process.env;
const { STRIPE_PRODUCT_MARKETING_ADVISORY_SERVICES } = process.env;
const { STRIPE_PRODUCT_DIGITAL_DISCOVERY_ENABLEMENT } = process.env;
const { STRIPE_PRODUCT_PRESENCE } = process.env;
const { STRIPE_PRODUCT_PRESENCE_PLUS } = process.env;
const { STRIPE_PRODUCT_AA_GOOGLE_SEARCH_ADS } = process.env;
const { STRIPE_PRODUCT_AA_PLUS_GOOGLE_SEARCH_ADS } = process.env;
const { STRIPE_PRODUCT_DISCOVERABILITY_PLUS } = process.env;
const { ALGOLIA_API_SEARCH_KEY } = process.env;
const { ALGOLIA_APP_ID } = process.env;
const { MANUAL_TRANSACTIONAL_EMAIL_ID } = process.env;

export default {
  AIRBRAKE_ID,
  AIRBRAKE_KEY,
  ALGOLIA_API_SEARCH_KEY,
  ALGOLIA_APP_ID,
  API_BASE_URL,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  CLOUDINARY_KEY,
  // DATADOG_KEY,
  // DATADOG_REGION,
  ENVIRONMENT_NAME,
  BUILD_VERSION: CIRCLE_BUILD_NUM || 'local',
  STATIC_BASE_URL,
  GOOGLE_MCC_ACCOUNT_IDS: (GOOGLE_MCC_ACCOUNT_IDS || '').split(',').map((id) => String(id).trim()).filter(Boolean),
  HUBSPOT_ACCOUNT_ID,
  STRIPE_PRODUCT_PAID_AD,
  STRIPE_PRODUCT_FB_IG_TW,
  STRIPE_PRODUCT_LINKEDIN,
  STRIPE_PRODUCT_PINTEREST,
  STRIPE_PRODUCT_YOUTUBE,
  STRIPE_PRODUCT_GOOGLE_BS,
  STRIPE_PRODUCT_TGT_CM,
  STRIPE_PRODUCT_AA_PLUS,
  STRIPE_PRODUCT_MANAGED_LP_BASE,
  STRIPE_PRODUCT_ADDITIONAL_LP,
  STRIPE_PRODUCT_DISCOVERABILITY,
  STRIPE_PRODUCT_GOOGLE_AD_SEARCH,
  STRIPE_PRODUCT_MANAGED_EMAIL_MARKETING,
  STRIPE_PRODUCT_MANAGED_EMAIL_MARKETING_PLUS,
  STRIPE_PRODUCT_MARKETING_ADVISORY_SERVICES,
  STRIPE_PRODUCT_DIGITAL_DISCOVERY_ENABLEMENT,
  STRIPE_PRODUCT_PRESENCE,
  STRIPE_PRODUCT_PRESENCE_PLUS,
  STRIPE_PRODUCT_AA_GOOGLE_SEARCH_ADS,
  STRIPE_PRODUCT_DISCOVERABILITY_PLUS,
  MANUAL_TRANSACTIONAL_EMAIL_ID,
  STRIPE_PRODUCT_AA_PLUS_GOOGLE_SEARCH_ADS,
};
