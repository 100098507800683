import {
  put,
  call,
  select,
  all,
} from 'redux-saga/effects';

import api from '../../services/api';

import { showToaster, closeToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import getInsightsByBusiness from '../getInsightsByBusiness/getInsightsByBusiness';
import { selectBusinessIdQueried } from '../../selectors';

export default function* deleteInsight({ payload }) {
  const { onDelete, insightId } = payload;
  const multipleIds = payload?.insightIds;

  try {
    yield put(showToaster(getToasterOptions(
      `Deleting the selected Insight${multipleIds ? 's' : ''}...`,
      'info', null, 7000, true, null, 'bottom', 'right',
    )));

    // Allow deleting multiple insights
    if (multipleIds && Array.isArray(multipleIds)) {
      const operations = multipleIds.map((id) => call(api.deleteInsight, id));
      yield all(operations);
    } else {
      yield call(api.deleteInsight, insightId);
    }
    const businessId = yield select(selectBusinessIdQueried);
    yield call(getInsightsByBusiness, { payload: { businessId } });

    yield put(closeToaster());
    if (onDelete) {
      onDelete();
    }
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to delete insight draft. Try again in a few minutes.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'deleteInsight' },
      params: { payload },
    });
  }
}
