import { put, call } from 'redux-saga/effects';

import api from '../../services/api';

import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

/**
 * @param {object} params
 * @param {object} params.payload
 * @param {string} params.payload.questionnaireId
 * @param {function} params.payload.onSuccess
 * @param {function} params.payload.onError
 */
export default function* deleteQuestionnaire({ payload }) {
  const { onSuccess, onError, questionnaireId } = payload;

  try {
    const deletedItem = yield call(api.deleteQuestionnaire, questionnaireId);
    yield put(showToaster(getToasterOptions('Questionnaire deleted successfully.', 'success')));
    if (onSuccess) onSuccess(deletedItem);
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to delete the Questionnaire. Try again in a few minutes.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'deleteQuestionnaire' },
      params: { payload },
    });

    if (onError) {
      onError(e);
    }
  }
}
