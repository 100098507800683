import { call, put, select } from 'redux-saga/effects';
import moment from 'moment';
import _ from 'lodash';

import api from '../../services/api';

import { setSearchAdCombinations, setSearchAdCombinationsLoading, setSearchAdCombinationsLoadingError } from '../../actions';
import logger from '../../services/logger';
import { selectCampaign } from '../../selectors';

export default function* getSearchAdCombinations() {
  const campaign = yield select(selectCampaign);
  try {
    yield put(setSearchAdCombinations([]));
    yield put(setSearchAdCombinationsLoading(true));
    yield put(setSearchAdCombinationsLoadingError(false));

    const startDate = campaign?.startDate;
    const endDate = campaign?.endDate;
    const adGroupId = _.last(campaign?.google?.group?.id?.split('/'));
    const campaignId = _.last(campaign?.google?.campaign?.id?.split('/'));
    const marketingChannelId = campaign?.marketingChannelId;
    const adCombinations = yield call(
      api.getSearchAdCombinations,
      // from
      moment(startDate).format('YYYY-MM-DD'),
      // to
      endDate ? moment(endDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'), // if evergreen => no endDate
      adGroupId,
      campaignId,
      marketingChannelId,
    );

    yield put(setSearchAdCombinations(adCombinations));
    yield put(setSearchAdCombinationsLoading(false));

    return adCombinations;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getSearchAdCombinations' },
      params: { campaign },
    });

    yield put(setSearchAdCombinationsLoading(false));
    yield put(setSearchAdCombinationsLoadingError(true));

    return undefined;
  }
}
