import { put, call } from 'redux-saga/effects';

import api from '../../services/api';

import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

/**
 * @param {object} params
 * @param {object} params.payload
 * @param {import(
 *  '@targetable/targetable-web-framework/lib/services/apis/questionnaire/typedefs.js'
 * ).Questionnaire} params.payload.questionnaire
 * @param {function} params.payload.onSuccess
 * @param {function} params.payload.onError
 */
export default function* updateQuestionnaire({ payload }) {
  const { onSuccess, onError, questionnaire } = payload;

  try {
    const updatedItem = yield call(api.updateQuestionnaire, questionnaire.id, questionnaire);

    if (onSuccess) onSuccess(updatedItem);
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to update the Questionnaire. Try again in a few minutes.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'updateQuestionnaire' },
      params: { payload },
    });

    if (onError) {
      onError(e);
    }
  }
}
