import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useList from 'react-use/lib/useList';
import useBoolean from 'react-use/lib/useBoolean';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Block from '@material-ui/icons/Block';
import Button from '@material-ui/core/Button';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';

import FeedbackHeader from '@targetable/targetable-web-framework/lib/react/campaignWizard/components/FeedbackHeader/FeedbackHeader';
import FeedbackSentimentQuestion from '@targetable/targetable-web-framework/lib/react/campaignWizard/components/FeedbackSentimentQuestion/FeedbackSentimentQuestion';
import TextField from '@targetable/targetable-web-framework/lib/react/components/TextField/TextField';
import { InsightTypes, InsightCampaignUpdateTypes } from '@targetable/targetable-types/dist/types/insight';
import {
  LEGACY_CAMPAIGN,
} from '../../../constants';
import {
  closeFeedbackDialog,
} from '../../../actions';
import { selectFeedback } from '../../../selectors';
import i18n from '../../../services/i18n';

/* eslint-disable max-len */
/**
 * @typedef {import('@targetable/targetable-types/dist/types').UserFeedback} UserFeedback
 */
/* eslint-enable max-len */

const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('sm')]: {
      padding: `0 ${theme.spacing(4)}px`,
    },
  },
  actions: {
    margin: `${theme.spacing(2)}px 0`,
  },
  approvedIcon: {
    color: '#2fc600',
  },
  rejectedIcon: {
    color: '#ed2a2c',
  },
  googleIcon: {
    fontSize: '17px',
    color: '#000000',
    position: 'relative',
    top: '2px',
  },
}));

function getCardTitle(item, campaign) {
  if (campaign) { return _.get(campaign, 'name'); }
  return _.get(item, 'title') || _.get(item, 'name');
}

function getFeedbackQuestions(type, action, insightType) {
  if (type === 'SMARTFEED_TYPE_INSIGHT') {
    if (insightType === InsightTypes.ManagedSocialMedia) {
      return [
        { question: i18n.t('feedback_insight_managed_social_question_1') },
        { question: i18n.t('feedback_insight_managed_social_question_2') },
        { question: i18n.t('feedback_insight_managed_social_question_3') },
      ];
    }

    if (insightType === InsightTypes.ManagedLandingPages) {
      return [
        { question: i18n.t('feedback_insight_managed_landing_question_1') },
        { question: i18n.t('feedback_insight_managed_landing_question_2') },
        { question: i18n.t('feedback_insight_managed_landing_question_3') },
      ];
    }

    if (insightType === InsightTypes.AcquisitionAndAwarenessPlus) {
      return [
        { question: i18n.t('feedback_insight_aaaplus_question_1') },
        { question: i18n.t('feedback_insight_aaaplus_question_2') },
        { question: i18n.t('feedback_insight_aaaplus_question_3') },
      ];
    }

    if (action === 'SMARTFEED_ACTION_APPROVED') {
      return [
        { question: i18n.t('feedback_insight_approved_question_1') },
        { question: i18n.t('feedback_insight_approved_question_2') },
        { question: i18n.t('feedback_insight_approved_question_3') },
      ];
    }

    if (action === 'SMARTFEED_ACTION_REJECTED') {
      return [
        { question: i18n.t('feedback_insight_rejected_question_1') },
        { question: i18n.t('feedback_insight_rejected_question_2') },
        { question: i18n.t('feedback_insight_rejected_question_3') },
      ];
    }
  }

  if (action === 'SMARTFEED_ACTION_APPROVED') {
    return [
      { question: i18n.t('feedback_suggested_campaign_approved_question_1') },
      { question: i18n.t('feedback_suggested_campaign_approved_question_2') },
      { question: i18n.t('feedback_suggested_campaign_approved_question_3') },
    ];
  }

  if (action === 'SMARTFEED_ACTION_APPROVED_DRAFT') {
    return [
      { question: i18n.t('feedback_suggested_campaign_saved_question_1') },
      { question: i18n.t('feedback_suggested_campaign_saved_question_2') },
      { question: i18n.t('feedback_suggested_campaign_saved_question_3') },
    ];
  }

  if (action === 'SMARTFEED_ACTION_REJECTED') {
    return [
      { question: i18n.t('feedback_suggested_campaign_rejected_question_1') },
      { question: i18n.t('feedback_suggested_campaign_rejected_question_2') },
      { question: i18n.t('feedback_suggested_campaign_rejected_question_3') },
    ];
  }

  return [];
}

const getHeaderTitleParams = (item) => {
  let text = '';
  switch (item?.campaignUpdate?.updateType) {
    case InsightCampaignUpdateTypes.Budget:
      text = 'Budget';
      break;
    case InsightCampaignUpdateTypes.HeadlinesAndDescriptions:
      text = 'Headlines and Descriptions';
      break;
    case InsightCampaignUpdateTypes.Keywords:
      text = 'Keywords';
      break;
    case InsightCampaignUpdateTypes.EndDate:
      text = 'End Date';
      break;
    case InsightCampaignUpdateTypes.Locations:
      text = 'Locations';
      break;
    default:
      break;
  }
  return text;
};

function getHeaderTitle(type, action, item) {
  if (type === 'SMARTFEED_TYPE_INSIGHT') {
    if (item?.type === InsightTypes.CampaignUpdate) {
      if (action === 'SMARTFEED_ACTION_APPROVED') {
        return 'insight_approved_campaign_update';
      }
      return 'insight_rejected_campaign_update';
    }
    if (action === 'SMARTFEED_ACTION_APPROVED') {
      return 'insight_approved';
    }
    return 'insight_rejected';
  }

  if (action === 'SMARTFEED_ACTION_APPROVED') {
    return 'suggested_campaign_approved';
  }

  if (action === 'SMARTFEED_ACTION_APPROVED_DRAFT') {
    return 'suggested_campaign_saved';
  }

  if (type === 'SUBSCRIPTION_CANCELLATION') {
    return 'cancel_subscription';
  }

  return 'suggested_campaign_dismissed';
}

function getButtonText(type, action) {
  if (type === 'SMARTFEED_TYPE_INSIGHT') {
    if (action === 'SMARTFEED_ACTION_APPROVED') {
      return i18n.t('feedback_button_insight_approve');
    }
    return i18n.t('feedback_button_insight_reject');
  }
  if (action === 'SMARTFEED_ACTION_APPROVED') {
    return i18n.t('feedback_button_campaign_publish');
  }
  if (action === 'SMARTFEED_ACTION_APPROVED_DRAFT') {
    return i18n.t('feedback_button_campaign_save');
  }
  if (type === 'SUBSCRIPTION_CANCELLATION') {
    return i18n.t('business_cancel_button');
  }
  return i18n.t('feedback_button_campaign_dismiss');
}

const GoogleIcon = () => (
  <SvgIcon>
    <path fillRule="evenodd" clipRule="evenodd" d="M0 12C0 5.373 5.373 0 12 0C18.627 0 24 5.373 24 12C24 18.627 18.627 24 12 24C5.373 24 0 18.627 0 12ZM12 13.4V11H18.61C18.67 11.35 18.721 11.7 18.721 12.16C18.721 16.16 16.04 19 12 19C8.13 19 5 15.87 5 12C5 8.13 8.13 5 12 5C13.89 5 15.47 5.69 16.69 6.83L14.79 8.66C14.27 8.16 13.36 7.58 12 7.58C9.61 7.58 7.66 9.56 7.66 12C7.66 14.441 9.61 16.42 12 16.42C14.77 16.42 15.81 14.429 15.97 13.4H12Z" fill="#9FA1AF" />
  </SvgIcon>
);

const FeedbackDialog = ({
  closeFeedbackDialogAction,
  feedback,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isSubmitting, setSubmitting] = useBoolean(false);
  const [list, { set }] = useList([]);
  const [textArea, setTextArea] = useState('');
  const itemType = feedback?.item?.type || LEGACY_CAMPAIGN;
  const feedbackQuestionnaire = feedback?.item?.questionnaire;

  // reset dialog after close
  // initialize list on open
  useEffect(() => {
    if (!feedback.open && list.length) {
      set([]);
      setTextArea('');
      setSubmitting(false);
    } else if (feedback.open && !list.length) {
      // Check the questionnaire Questions if provided, otherwise, use default hard-coded
      if (feedbackQuestionnaire) {
        set(feedbackQuestionnaire.questions);
      } else {
        set(getFeedbackQuestions(feedback.type, feedback.action, itemType));
      }
    }
  }, [
    feedback.open, feedbackQuestionnaire, feedback.action,
    feedback.type, list, set, setSubmitting, itemType,
  ]);

  // prevent prop type warnings and bugs when data is null
  if (!feedback.open) {
    return null;
  }

  let ActionIcon;
  switch (feedback.action) {
    case 'SUBSCRIPTION_CANCELLATION':
    case 'SMARTFEED_ACTION_APPROVED':
    case 'SMARTFEED_ACTION_APPROVED_DRAFT':
      ActionIcon = <CheckCircleOutline className={classes.approvedIcon} variant="small" />;
      break;
    case 'SMARTFEED_ACTION_REJECTED':
      ActionIcon = <Block className={classes.rejectedIcon} variant="small" />;
      break;
    default:
  }

  const typeIcon = () => {
    if (feedback.type === 'SMARTFEED_TYPE_INSIGHT') {
      if (feedback.item?.type === InsightTypes.CampaignUpdate) {
        return GoogleIcon;
      }
      return NewReleasesIcon;
    }
    return CheckBoxIcon;
  };

  const handleCancel = () => {
    closeFeedbackDialogAction();
  };

  return (
    <Dialog
      onClose={handleCancel}
      maxWidth="sm"
      fullWidth
      open={feedback.open}
      classes={{
        paper: classes.dialog,
      }}
      data-cy="feedbackDialog_modal"
    >
      <FeedbackHeader
        actionIcon={ActionIcon}
        cancelCTA
        disableDismiss={isSubmitting}
        onCancel={handleCancel}
        title={getHeaderTitle(feedback.type, feedback.action, feedback.item)}
        titleParams={
          feedback.item?.type === InsightTypes.CampaignUpdate
            ? { typeName: getHeaderTitleParams(feedback.item) } : undefined
        }
        typeIcon={typeIcon()}
      />
      <DialogTitle disableTypography>
        <Typography variant="h2">
          {getCardTitle(feedback.item, feedback.campaign)}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {list.map((feedbackQuestion) => (
          <FeedbackSentimentQuestion
            key={feedbackQuestion.question}
            question={feedbackQuestion.question}
            disabled
          />
        ))}
        <TextField
          margin="normal"
          variant="outlined"
          placeholder={t('feedback_additional_thoughts')}
          rows={8}
          multiline
          fullWidth
          disabled
          value={textArea}
          onChange={(e) => setTextArea(e.target.value)}
          data-cy="feedbackDialog_additionalFeedback_textarea"
        />
        <Grid container className={classes.actions}>
          <Grid item xs={6} sm={4}>
            <Button
              variant="contained"
              color="primary"
              disabled
              data-cy="feedbackDialog_approve_button"
              fullWidth
            >
              {getButtonText(feedback.type, feedback.action)}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

FeedbackDialog.propTypes = {
  closeFeedbackDialogAction: PropTypes.func.isRequired,
  feedback: PropTypes.shape({
    action: PropTypes.string,
    campaign: PropTypes.shape({}),
    item: PropTypes.shape({
      type: PropTypes.string,
      onCompleted: PropTypes.func,
      onError: PropTypes.func,
      subscriptionItems: PropTypes.arrayOf(PropTypes.shape({})),
      questionnaire: PropTypes.shape({
        questions: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
    open: PropTypes.bool,
    type: PropTypes.string,
    onCompleted: PropTypes.func,
    validatedCampaign: PropTypes.bool,
    onError: PropTypes.func,
    hasUnSavedChanges: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  feedback: selectFeedback(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeFeedbackDialogAction: closeFeedbackDialog,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackDialog);
