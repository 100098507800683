import { take, put } from 'redux-saga/effects';
import _ from 'lodash';
import { setLoadingAsync } from '../actions';

// eslint-disable-next-line import/prefer-default-export
export function getToasterOptions(
  message, type = 'error', link = undefined, duration = null, open = true, externalLink = undefined, verticalPos = 'top', horizontalPos = 'center', messageParams = undefined,
) {
  return {
    duration,
    message,
    open,
    type,
    link,
    externalLink,
    verticalPos,
    horizontalPos,
    messageParams,
  };
}

export function* takeAsyncAction(pattern) {
  yield put(setLoadingAsync(false));
  const payload = yield take(pattern);
  yield put(setLoadingAsync(true));
  return payload;
}

export const applyNameToCampaignAndGroupFields = (requestData) => {
  const name = requestData?.base?.name;
  _.set(requestData, 'base.google.campaign.fields.name', name);
  _.set(requestData, 'base.google.group.fields.name', name);
  _.forEach(requestData.ads, (ad) => {
    _.set(ad, 'google.group.fields.name', name);
    _.set(ad, 'google.campaign.fields.name', name);
  });
};
