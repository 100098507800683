import { call, put } from 'redux-saga/effects';

import api from '../../services/api';
import { setSubscription, setSubscriptionLoading } from '../../actions';
import logger from '../../services/logger';

/**
 * Fetches the details of a stripe customer
 * @param {String} action.payload the stripe customer Id to fetch information for.
 * @returns {void}
 */
export default function* getStripeSubscriptionByCustomer({ payload }) {
  const stripeCustomerId = payload?.stripeCustomerId;
  try {
    yield put(setSubscriptionLoading(true));

    const subscriptions = yield call(api.getStripeSubscriptionStatus, stripeCustomerId);

    yield put(setSubscription(subscriptions));
    yield put(setSubscriptionLoading(false));
  } catch (e) {
    yield put(setSubscriptionLoading(false));
    logger.error({
      error: e,
      context: { saga: 'getStripeSubscriptionByCustomer' },
      params: { stripeCustomerId },
    });
  }
}
