import { call, put } from 'redux-saga/effects';
import { getUserFacebookIntegration } from '@targetable/targetable-web-framework/lib/services/user';
import api from '../../services/api';
import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { CURRENT_FACEBOOK_VERSION } from '../../constants';

export default function* openTokenDebugger({ payload: userId }) {
  try {
    const user = yield call(api.getUser, userId, false);
    const userFbIntegration = getUserFacebookIntegration(user);
    const accessToken = userFbIntegration?.accessToken;
    window.open(`https://developers.facebook.com/tools/debug/accesstoken?access_token=${accessToken}&version=${CURRENT_FACEBOOK_VERSION}`, '_blank');
    return user;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getUser' },
      params: { userId },
    });
    yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
    return undefined;
  }
}
