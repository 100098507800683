// CAMPAIGN TABS
export const LIVE_TAB = 'live';
export const UPCOMING_TAB = 'upcoming';
export const DRAFT_TAB = 'draft';
export const HISTORY_TAB = 'history';
export const ERRORS_TAB = 'errors';

// CAMPAIGN STATUSES
export const CAMPAIGN_SUBMITTED_FAILED = 'failed';
export const CAMPAIGN_SUBMITTED_WITH_ISSUES = 'with-issues';
export const CAMPAIGN_SUBMITTED_SMARTFEED = 'smartFeed';
export const CAMPAIGN_SUBMITTED_REJECTED = 'draftRejected';
export const CAMPAIGN_SUBMITTED_APPROVED = 'draftApproved';
export const CAMPAIGN_ARCHIVED = 'archived';
export const CAMPAIGN_STATUS_PAUSED = 'paused';
export const CAMPAIGN_STATUS_LIVE = 'live';
export const CAMPAIGN_STATUS_SUBMITTED = 'submitted';

// INSIGHT TRANSLATION KEYS
export const INSIGHT_APPROVED = 'saved';
export const INSIGHT_REJECTED = 'dismissed';

// BUSINESS SAGAS
export const DATA_BUSINESS = 'DATA_BUSINESS';
export const DATA_BUSINESS_ID_QUERIED = 'DATA_BUSINESS_ID_QUERIED';
export const DATA_BUSINESS_LOADING = 'DATA_BUSINESS_LOADING';
export const DATA_BUSINESS_CAMPAIGNS = 'DATA_BUSINESS_CAMPAIGNS';
export const DATA_BUSINESS_CAMPAIGNS_LOADING = 'DATA_BUSINESS_CAMPAIGNS_LOADING';
export const DATA_BUSINESS_INSIGHTS = 'DATA_BUSINESS_INSIGHTS';
export const DATA_BUSINESS_INSIGHTS_LOADING = 'DATA_BUSINESS_INSIGHTS_LOADING';
export const DATA_CAMPAIGN_METRICS = 'DATA_CAMPAIGN_METRICS';
export const DATA_CAMPAIGN_TRENDS = 'DATA_CAMPAIGN_TRENDS';
export const DATA_CAMPAIGN_METRICS_LOADING = 'DATA_CAMPAIGN_METRICS_LOADING';
export const DATA_CAMPAIGN_METRICS_LOADING_ERROR = 'DATA_CAMPAIGN_METRICS_LOADING_ERROR';
export const DATA_BUSINESS_STORES = 'DATA_BUSINESS_STORES';
export const DATA_BUSINESS_STORES_LOADING = 'DATA_BUSINESS_STORES_LOADING';
export const DATA_BUSINESS_SUGGESTED_CAMPAIGNS = 'DATA_BUSINESS_SUGGESTED_CAMPAIGNS';
export const DATA_BUSINESS_SUGGESTED_CAMPAIGNS_LOADING = 'DATA_BUSINESS_SUGGESTED_CAMPAIGNS_LOADING';
export const DATA_BUSINESS_USERS = 'DATA_BUSINESS_USERS';
export const DATA_BUSINESS_USERS_LOADING = 'DATA_BUSINESS_USERS_LOADING';
export const DATA_BUSINESS_LEAD_FORMS = 'DATA_BUSINESS_LEAD_FORMS';
export const DATA_BUSINESSES_LOADING = 'DATA_BUSINESSES_LOADING';
export const DATA_BUSINESSES = 'DATA_BUSINESSES';
export const DATA_BUSINESS_MANAGED_LANDING_PAGES = 'DATA_BUSINESS_MANAGED_LANDING_PAGES';
export const SAVE_MANAGED_LANDING_PAGES = 'SAVE_MANAGED_LANDING_PAGES';
export const DELETE_MANAGED_LANDING_PAGES = 'DELETE_MANAGED_LANDING_PAGES';
export const SET_AUTH0_PERMISSIONS = 'SET_AUTH0_PERMISSIONS';

// Billing SAGAS
export const DATA_BILLING_SUBSCRIPTION = 'DATA_BILLING_SUBSCRIPTION';
export const DATA_BILLING_SUBSCRIPTION_LOADING = 'DATA_BILLING_SUBSCRIPTION_LOADING';
export const GET_BILLING_SUBSCRIPTIONS = 'GET_BILLING_SUBSCRIPTIONS';
export const CANCEL_STRIPE_SUBSCRIPTION = 'CANCEL_STRIPE_SUBSCRIPTION';
export const DELETE_STRIPE_SUBSCRIPTION_ITEM = 'DELETE_STRIPE_SUBSCRIPTION_ITEM';
export const PAUSE_STRIPE_COLLECTION = 'PAUSE_STRIPE_COLLECTION';
export const REACTIVATE_STRIPE_SUBSCRIPTION = 'REACTIVATE_STRIPE_SUBSCRIPTION';
export const UNPAUSE_STRIPE_COLLECTION = 'UNPAUSE_STRIPE_COLLECTION';

export const GET_STRIPE_PRODUCTS = 'GET_STRIPE_PRODUCTS';
export const DATA_STRIPE_PRODUCTS = 'DATA_STRIPE_PRODUCTS';
export const DATA_STRIPE_PRODUCTS_LOADING = 'DATA_STRIPE_PRODUCTS_LOADING';
export const GOTO_STRIPE_CUSTOMER_PORTAL = 'GOTO_STRIPE_CUSTOMER_PORTAL';
export const GET_STRIPE_SUBSCRIPTIONS = 'GET_STRIPE_SUBSCRIPTIONS';
export const UPDATE_STRIPE_COLLECTION = 'UPDATE_STRIPE_COLLECTION';

// Silectis
export const GET_SILECTIS_GLOBAL_SUBSCRIPTIONS = 'GET_SILECTIS_GLOBAL_SUBSCRIPTIONS';
export const GET_SILECTIS_FACEBOOK_METRICS = 'GET_SILECTIS_FACEBOOK_METRICS';
export const GET_SILECTIS_GOOGLE_METRICS = 'GET_SILECTIS_GOOGLE_METRICS';
export const GET_SILECTIS_MAILCHIMP_METRICS = 'GET_SILECTIS_MAILCHIMP_METRICS';

export const DATA_GOOGLE_PROMOTIONS_BY_MARKETING_CHANNEL_ID = 'DATA_GOOGLE_PROMOTIONS_BY_MARKETING_CHANNEL_ID';
export const DATA_GOOGLE_SITELINKS_BY_MARKETING_CHANNEL_ID = 'DATA_GOOGLE_SITELINKS_BY_MARKETING_CHANNEL_ID';
export const GET_GOOGLE_ASSETS = 'GET_GOOGLE_ASSETS';

// Business
export const CREATE_BUSINESS = 'CREATE_BUSINESS';
export const GET_BUSINESS = 'GET_BUSINESS';
export const GET_BUSINESSES = 'GET_BUSINESSES';
export const GET_BUSINESS_CAMPAIGNS = 'GET_BUSINESS_CAMPAIGNS';
export const GET_BUSINESS_INSIGHTS = 'GET_BUSINESS_INSIGHTS';
export const GET_BUSINESS_STORES = 'GET_BUSINESS_STORES';
export const GET_BUSINESS_SUGGESTED_CAMPAIGNS = 'GET_BUSINESS_SUGGESTED_CAMPAIGNS';
export const GET_BUSINESS_USERS = 'GET_BUSINESS_USERS';
export const UPDATE_BUSINESS = 'UPDATE_BUSINESS';
export const DATA_BUSINESS_PIXELS = 'DATA_BUSINESS_PIXELS';
export const DATA_BUSINESS_PIXELS_LOADING = 'DATA_BUSINESS_PIXELS_LOADING';
export const UPDATE_STORE_PIXEL = 'UPDATE_STORE_PIXEL';
export const CONFIRM_EDIT_BUSINESS_NAME = 'CONFIRM_EDIT_BUSINESS_NAME';

// CAMPAIGN SAGAS
export const DATA_CAMPAIGN = 'DATA_CAMPAIGN';
export const DATA_CAMPAIGN_LOADING = 'DATA_CAMPAIGN_LOADING';
export const DATA_CAMPAIGN_ADS = 'DATA_CAMPAIGN_ADS';
export const DATA_CAMPAIGN_AD_TYPE = 'DATA_CAMPAIGN_AD_TYPE';
export const DATA_CAMPAIGN_NAME = 'DATA_CAMPAIGN_NAME';
export const DATA_CAMPAIGN_GOAL = 'DATA_CAMPAIGN_GOAL';
export const DATA_CAMPAIGN_OBJECTIVE = 'DATA_CAMPAIGN_OBJECTIVE';
export const DATA_CAMPAIGN_UPDATE_ADS = 'DATA_CAMPAIGN_UPDATE_ADS';
export const DATA_CAMPAIGN_PIXEL_EVENT_TYPE = 'DATA_CAMPAIGN_PIXEL_EVENT_TYPE';
export const DATA_CAMPAIGN_OPTIMIZATION_GOAL = 'DATA_CAMPAIGN_OPTIMIZATION_GOAL';
export const DATA_CAMPAIGN_REQUEST_INFO = 'DATA_CAMPAIGN_REQUEST_INFO';

export const GET_CAMPAIGN = 'GET_CAMPAIGN';
export const DATA_FACEBOOK_CAMPAIGN_LANGUAGES = 'DATA_FACEBOOK_CAMPAIGN_LANGUAGES';
export const DATA_FACEBOOK_CAMPAIGN_TARGETS = 'DATA_FACEBOOK_CAMPAIGN_TARGETS';
export const GET_FACEBOOK_CAMPAIGN_LANGUAGES = 'GET_FACEBOOK_CAMPAIGN_LANGUAGES';
export const SAVE_CAMPAIGN_RETRY_TIMER = 'SAVE_CAMPAIGN_RETRY_TIMER';
export const RETURN_CAMPAIGN_TO_DRAFT = 'RETURN_CAMPAIGN_TO_DRAFT';
export const RETRY_CAMPAIGN = 'RETRY_CAMPAIGN';
export const SUBMIT_CAMPAIGN_FORM = 'SUBMIT_CAMPAIGN_FORM';
export const SAVE_CAMPAIGN_FORM = 'SAVE_CAMPAIGN_FORM';
export const DATA_CAMPAIGN_OVERRIDE_AUTO_AGING = 'DATA_CAMPAIGN_OVERRIDE_AUTO_AGING';
export const DATA_CAMPAIGN_EVENT_ID = 'DATA_CAMPAIGN_EVENT_ID';
export const UPDATE_CAMPAIGN_USER = 'UPDATE_CAMPAIGN_USER';
export const UPDATE_META_LIVE_CAMPAIGN = 'UPDATE_META_LIVE_CAMPAIGN';

// INIT SAGAS
export const INIT_BUSINESS = 'INIT_BUSINESS';

// INSIGHT SAGAS
export const CREATE_INSIGHT = 'CREATE_INSIGHT';
export const DELETE_INSIGHT = 'DELETE_INSIGHT';
export const UPDATE_INSIGHT = 'UPDATE_INSIGHT';
export const RETRY_UPDATE_LIVE_INSIGHT = 'RETRY_UPDATE_LIVE_INSIGHT';

// LOADING SAGAS
export const DATA_LOADING_ASYNC = 'DATA_LOADING_ASYNC';

// MAM SAGAS
export const DATA_AD_IMAGE = 'DATA_AD_IMAGE';
export const DATA_AD_IMAGE_UI = 'DATA_AD_IMAGE_UI';
export const DATA_IS_SEARCHING_MAM = 'DATA_IS_SEARCHING_MAM';
export const DATA_INITIAL_IMAGES = 'DATA_INITIAL_IMAGES';
export const DATA_INITIAL_IMAGES_CURSOR = 'DATA_INITIAL_IMAGES_CURSOR';
export const DATA_IMAGES_MAM = 'DATA_IMAGES_MAM';
export const GET_AD_IMAGE = 'GET_AD_IMAGE';
export const GET_AD_IMAGE_UI = 'GET_AD_IMAGE_UI';
export const GET_IMAGES = 'GET_IMAGES';
export const REMOVE_IMAGES = 'REMOVE_IMAGES';
export const UPDATE_TAG_IMAGES = 'UPDATE_TAG_IMAGES';
export const GET_MORE_IMAGES = 'GET_MORE_IMAGES';
export const SEARCH_IMAGES = 'SEARCH_IMAGES';

// TOASTER SAGAS
export const CLOSE_TOASTER = 'CLOSE_TOASTER';
export const SHOW_TOASTER = 'SHOW_TOASTER';

// SUGGESTED CAMPAIGN / DRAFT SAGAS
export const DATA_SUGGESTED_CAMPAIGN_ADS = 'DATA_CAMPAIGN_ADS';
export const DATA_SUGGESTED_CAMPAIGN_NAME = 'DATA_SUGGESTED_CAMPAIGN_NAME';
export const DATA_SUGGESTED_CAMPAIGN_GOAL = 'DATA_SUGGESTED_CAMPAIGN_GOAL';
export const DATA_SUGGESTED_CAMPAIGN_OBJECTIVE = 'DATA_SUGGESTED_CAMPAIGN_OBJECTIVE';
export const DATA_SUGGESTED_CAMPAIGN_DISPLAY_DATE = 'DATA_SUGGESTED_CAMPAIGN_DISPLAY_DATE';
export const DATA_SUGGESTED_CAMPAIGN_DISPLAY_TIME = 'DATA_SUGGESTED_CAMPAIGN_DISPLAY_TIME';
export const DATA_SUGGESTED_CAMPAIGN_EXPIRY_DATETIME = 'DATA_SUGGESTED_CAMPAIGN_EXPIRY_DATETIME';
export const DATA_SUGGESTED_CAMPAIGN_QUESTIONNAIRE = 'DATA_SUGGESTED_CAMPAIGN_QUESTIONNAIRE';
export const DATA_SUGGESTED_CAMPAIGN = 'DATA_SUGGESTED_CAMPAIGN';
export const DATA_SUGGESTED_CAMPAIGN_LOADING = 'DATA_SUGGESTED_CAMPAIGN_LOADING';
export const DATA_SUGGESTED_CAMPAIGN_UPDATE_ADS = 'DATA_SUGGESTED_CAMPAIGN_UPDATE_ADS';
export const DELETE_SUGGESTED_CAMPAIGN = 'DELETE_SUGGESTED_CAMPAIGN';
export const GET_SUGGESTED_CAMPAIGN = 'GET_SUGGESTED_CAMPAIGN';
export const SAVE_SUGGESTED_CAMPAIGN = 'SAVE_SUGGESTED_CAMPAIGN';
export const DUPLICATE_CAMPAIGN = 'DUPLICATE_CAMPAIGN';
export const PAUSE_UNPAUSE_CAMPAIGN = 'PAUSE_UNPAUSE_CAMPAIGN';
export const DUPLICATE_SUGGESTED_CAMPAIGN = 'DUPLICATE_SUGGESTED_CAMPAIGN';
export const OPEN_SUGGESTED_CAMPAIGN_CARD = 'OPEN_SUGGESTED_CAMPAIGN_CARD';
export const DATA_SUGGESTED_CAMPAIGN_AD_TYPE = 'DATA_SUGGESTED_CAMPAIGN_AD_TYPE';
export const DATA_SUGGESTED_CAMPAIGN_EVENT_ID = 'DATA_SUGGESTED_CAMPAIGN_EVENT_ID';

// MARKETING SUGGESTED CAMPAIGNS
export const UPDATE_MARKETING_SUGGESTED_CAMPAIGN = 'UPDATE_MARKETING_SUGGESTED_CAMPAIGN';
export const CREATE_MARKETING_SUGGESTED_CAMPAIGN = 'CREATE_MARKETING_SUGGESTED_CAMPAIGN';
export const DUPLICATE_MARKETING_SUGGESTED_CAMPAIGN = 'DUPLICATE_MARKETING_SUGGESTED_CAMPAIGN';
export const REVERT_MARKETING_SUGGESTED_CAMPAIGN = 'REVERT_MARKETING_SUGGESTED_CAMPAIGN';
export const IMPORT_MARKETING_GSA_CAMPAIGN = 'IMPORT_MARKETING_GSA_CAMPAIGN';
export const PUBLISH_MARKETING_AD = 'PUBLISH_MARKETING_AD';

// GOOGLE MARKETING
export const DATA_GOOGLE_CAMPAIGNS = 'DATA_GOOGLE_CAMPAIGNS';
export const DATA_GOOGLE_CAMPAIGNS_LOADING = 'DATA_GOOGLE_CAMPAIGNS_LOADING';
export const GET_GOOGLE_CAMPAIGNS = 'GET_GOOGLE_CAMPAIGNS';
export const DATA_GOOGLE_AD_GROUPS = 'DATA_GOOGLE_AD_GROUPS';
export const GET_GOOGLE_AD_GROUPS = 'GET_GOOGLE_AD_GROUPS';
export const DATA_GOOGLE_AD_GROUPS_LOADING = 'DATA_GOOGLE_AD_GROUPS_LOADING';
export const GET_GOOGLE_LOCATIONS = 'GET_GOOGLE_LOCATIONS';
export const CREATE_UPDATE_GOOGLE_SEARCH_ADS_BULK = 'CREATE_UPDATE_GOOGLE_SEARCH_ADS_BULK';

// CONFIRMATION DIALOG
export const DATA_CONFIRMATION_DIALOG = 'DATA_CONFIRMATION_DIALOG';
export const SHOW_CONFIRMATION_DIALOG = 'SHOW_CONFIRMATION_DIALOG';
export const RESULT_CONFIRMATION_DIALOG = 'RESULT_CONFIRMATION_DIALOG';

// ACTIVITY LOG NOTE
export const DATA_ACTIVITY_LOG_DIALOG = 'DATA_ACTIVITY_LOG_DIALOG';
export const SHOW_ACTIVITY_LOG_DIALOG = 'SHOW_ACTIVITY_LOG_DIALOG';
export const RESULT_ACTIVITY_LOG_DIALOG = 'RESULT_ACTIVITY_LOG_DIALOG';

// PUBLISH MARKETING DIALOG
export const DATA_PUBLISH_MARKETING_DIALOG = 'DATA_PUBLISH_MARKETING_DIALOG';
export const SHOW_PUBLISH_MARKETING_DIALOG = 'SHOW_PUBLISH_MARKETING_DIALOG';
export const RESULT_PUBLISH_MARKETING_DIALOG = 'RESULT_PUBLISH_MARKETING_DIALOG';

// STORE SAGAS
export const UPDATE_STORE_DEFAULT_RADIUS = 'UPDATE_STORE_DEFAULT_RADIUS';
export const UPDATE_STORE_LOCATION = 'UPDATE_STORE_LOCATION';
export const UPDATE_STORE_INTEGRATIONS = 'UPDATE_STORE_INTEGRATIONS';
export const UPDATE_STORE_MARKETING_CHANNELS = 'UPDATE_STORE_MARKETING_CHANNELS';
export const UPDATE_STORE_PRESETS = 'UPDATE_STORE_PRESETS';
export const UPDATE_STORE_NAME = 'UPDATE_STORE_NAME';
export const UPDATE_PHONE_NUMBER = 'UPDATE_PHONE_NUMBER';
export const UPDATE_COO_URL = 'UPDATE_COO_URL';
export const UPDATE_CHOWLY_PARTNER_ID = 'UPDATE_CHOWLY_PARTNER_ID';
export const UPDATE_DEFAULT_PUBLISHING_USER = 'UPDATE_DEFAULT_PUBLISHING_USER';
export const REFRESH_STORE_LOCATION = 'REFRESH_STORE_LOCATION';
export const REFRESH_STORE_FACEBOOK_LOCATION = 'REFRESH_STORE_FACEBOOK_LOCATION';
export const SET_STORE_LOCATION = 'SET_STORE_LOCATION';
export const SET_STORE_STATUS = 'SET_STORE_STATUS';
export const SET_STORE_FACEBOOK_LOCATION = 'SET_STORE_FACEBOOK_LOCATION';
export const UPDATE_STORE_PUBLISHED_AS_PAUSED = 'UPDATE_STORE_PUBLISHED_AS_PAUSED';
export const ENABLE_DISABLE_STORE = 'ENABLE_DISABLE_STORE';
export const UNLINK_META_CONNECTION = 'UNLINK_META_CONNECTION';
export const GET_STORE_PIXELS = 'GET_STORE_PIXELS';

// USER SAGAS
export const NEW_USER_INVITE = 'NEW_USER_INVITE';
export const EXISTING_USER_INVITE = 'EXISTING_USER_INVITE';
export const INVITE_USER = 'INVITE_USER';
export const REINVITE_USER = 'REINVITE_USER';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';
export const UPDATE_USER_NAME = 'UPDATE_USER_NAME';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const REQUEST_FB_TOKEN = 'REQUEST_FB_TOKEN';
export const REMOVE_USER = 'REMOVE_USER';
export const DELETE_USER = 'DELETE_USER';
export const VERIFY_AUTH0_EMAIL = 'VERIFY_AUTH0_EMAIL';
export const MOVE_USER_TO_BUSINESS = 'MOVE_USER_TO_BUSINESS';

// CAMPAIGN REPORTS
export const REPORTS_LOADING = 'REPORTS_LOADING';
export const DATA_CAMPAIGN_REPORT = 'DATA_CAMPAIGN_REPORT';
export const DATA_CAMPAIGN_REPORTS = 'DATA_CAMPAIGN_REPORTS';
export const DATA_STORE_DATE_REPORTS = 'DATA_STORE_DATE_REPORTS';
export const GET_STORE_DATE_REPORTS = 'GET_STORE_DATE_REPORTS';

// URL PRESETS
export const GET_URL_PRESETS = 'GET_URL_PRESETS';
export const DATA_URL_PRESETS = 'DATA_URL_PRESETS';
export const DATA_URL_PRESETS_LOADING = 'DATA_URL_PRESETS_LOADING';
export const SAVE_URL_PRESETS = 'SAVE_URL_PRESETS';
export const DELETE_URL_PRESET = 'DELETE_URL_PRESET';

export const DATA_AD_CARD_PRESETS = 'DATA_AD_CARD_PRESETS';
export const GET_AD_CARD_PRESETS = 'GET_AD_CARD_PRESETS';

// CUSTOM AUDIENCE
export const DATA_STORES_CUSTOM_AUDIENCES = 'DATA_STORES_CUSTOM_AUDIENCES';

// ECLINCHER URL
export const SET_ECLINCHER_URL = 'SET_ECLINCHER_URL';
export const SAVE_ECLINCHER_URL = 'SAVE_ECLINCHER_URL';

// RCC URL
export const SAVE_RCC_URL = 'SAVE_RCC_URL';

// SALESFORCE ID
export const SAVE_SALESFORCE_ID = 'SAVE_SALESFORCE_ID';

// PIXEL EVENT TYPE
export const DATA_SUGGESTED_CAMPAIGN_PIXEL_EVENT_TYPE = 'DATA_SUGGESTED_CAMPAIGN_PIXEL_EVENT_TYPE';
export const DATA_SUGGESTED_CAMPAIGN_OPTIMIZATION_GOAL = 'DATA_SUGGESTED_CAMPAIGN_OPTIMIZATION_GOAL';

// Business Tabs
export const CAMPAIGNS_LIST_VIEW_KEY = 'campaigns_list_view';

export const SUGGESTED_CAMPAIGNS_TAB_PATH = 'suggested_campaigns';
export const CAMPAIGNS_TAB_PATH = 'campaigns';
export const SUGGESTED_CAMPAIGNS_LIST_VIEW_KEY = 'business_view_sc_status';
export const ACTIVITY_LOG_TAB_PATH = 'activity_logs';

export const REPORTING_TOOLS_TAB_PATH = 'reporting-tools';
export const ADS_HEALTH_SUMMARY_PATH = 'ads-health-summary';
export const INSIGHTS_TAB_PATH = 'insights';
export const INSIGHTS_LIST_VIEW_KEY = 'business_view_insights_status';

export const DATA_SUGGESTED_CAMPAIGN_OVERRIDE_AUTO_AGING = 'DATA_SUGGESTED_CAMPAIGN_OVERRIDE_AUTO_AGING';
export const COPY_INVITE_LINK_TO_CLIPBOARD = 'COPY_INVITE_LINK_TO_CLIPBOARD';

export const CURRENT_FACEBOOK_VERSION = 'v14.0';
export const OPEN_TOKEN_DEBUGGER = 'OPEN_TOKEN_DEBUGGER';
export const GET_ALL_PERMISSIONS = 'GET_ALL_PERMISSIONS';
export const SET_ALL_PERMISSIONS = 'SET_ALL_PERMISSIONS';
export const DATA_USERS_PERMISSIONS_LOADING = 'DATA_USERS_PERMISSIONS_LOADING';

// INSIGHT TEMPLATES TRANSLATION KEYS
export const INSIGHT_TEMPLATE_ACTIVE = 'active';
export const INSIGHT_TEMPLATE_INACTIVE = 'inactive';
export const INSIGHT_TEMPLATE_DRAFT = 'draft';

// INSIGHT TEMPLATES SAGAS
export const CREATE_INSIGHT_TEMPLATE = 'CREATE_INSIGHT_TEMPLATE';
export const DELETE_INSIGHT_TEMPLATE = 'DELETE_INSIGHT_TEMPLATE';
export const UPDATE_INSIGHT_TEMPLATE = 'UPDATE_INSIGHT_TEMPLATE';

// CUSTOM FIELDS SAGAS
export const CREATE_CUSTOM_FIELD = 'CREATE_CUSTOM_FIELD';
export const UPDATE_CUSTOM_FIELD = 'UPDATE_CUSTOM_FIELD';

// Questionnaire Sagas
export const CREATE_QUESTIONNAIRE_TEMPLATE = 'CREATE_QUESTIONNAIRE_TEMPLATE';
export const DELETE_QUESTIONNAIRE_TEMPLATE = 'DELETE_QUESTIONNAIRE_TEMPLATE';
export const UPDATE_QUESTIONNAIRE_TEMPLATE = 'UPDATE_QUESTIONNAIRE_TEMPLATE';

// SUGGESTED CAMPAIGN TEMPLATES SAGAS
export const DELETE_SUGGESTED_CAMPAIGN_TEMPLATE = 'DELETE_SUGGESTED_CAMPAIGN_TEMPLATE';
export const UPDATE_SUGGESTED_CAMPAIGN_TEMPLATE = 'UPDATE_SUGGESTED_CAMPAIGN_TEMPLATE';
export const CREATE_SUGGESTED_CAMPAIGN_TEMPLATE = 'CREATE_SUGGESTED_CAMPAIGN_TEMPLATE';
export const INIT_SUGGESTED_CAMPAIGN_TEMPLATE = 'INIT_SUGGESTED_CAMPAIGN_TEMPLATE';
export const DUPLICATE_SUGGESTED_CAMPAIGN_TEMPLATE = 'DUPLICATE_SUGGESTED_CAMPAIGN_TEMPLATE';

// SUGGESTED_CAMPAIGN TEMPLATES TRANSLATION KEYS
export const SUGGESTED_CAMPAIGN_TEMPLATE_ACTIVE = 'active';
export const SUGGESTED_CAMPAIGN_TEMPLATE_INACTIVE = 'inactive';
export const SUGGESTED_CAMPAIGN_TEMPLATE_DRAFT = 'draft';

export const DELETE_STORE = 'DELETE_STORE';

export const DATA_BUSINESS_VERTICALS = 'DATA_BUSINESS_VERTICALS';

// TYPES FOR INSIGHTS AND SC
export const TYPE_INSIGHT = 'insight';
export const TYPE_PAID_SOCIAL = 'paid_social';
export const TYPE_MANAGED_SOCIAL_MEDIA = 'managed_social_media';
export const TYPE_MANAGED_LANDING_PAGES = 'managed_landing_pages';
export const TYPE_MANAGED_EMAIL_MARKETING = 'managed_email_marketing';
export const TYPE_DISCOVERABILITY = 'discoverability';
export const TYPE_COMMUNITY_MANAGEMENT = 'community_management';
export const TYPE_AA_PLUS = 'acquisition_and_awareness_plus';
export const TYPE_AA = 'acquisition_and_awareness';
export const TYPE_CAMPAIGN_UPDATE = 'campaign_update';

// ADD STORE SAGAS
export const ADD_STORE_CONFIRM_PAYMENT = 'ADD_STORE_CONFIRM_PAYMENT';
export const SHOW_ADD_STORE_MODAL = 'SHOW_ADD_STORE_MODAL';
export const DATA_BACKDROP = 'DATA_BACKDROP';
export const MULTI_STORE_ADD_STORE = 'MULTI_STORE_ADD_STORE';
export const HANDLE_CANCELLED_SUBSCRIPTION = 'HANDLE_CANCELLED_SUBSCRIPTION';
export const MULTI_BUSINESS_ADD_BUSINESS = 'MULTI_BUSINESS_ADD_BUSINESS';
export const SMARTFEED_TYPE_INSIGHT = 'SMARTFEED_TYPE_INSIGHT';
export const SUBSCRIPTION_CANCELLATION = 'SUBSCRIPTION_CANCELLATION';

// MARKETING CHANNEL
export const SAVE_MARKETING_CHANNEL = 'SAVE_MARKETING_CHANNEL';
export const GET_MARKETING_CHANNELS = 'GET_MARKETING_CHANNELS';
export const DATA_MARKETING_CHANNELS_LOADING = 'DATA_MARKETING_CHANNELS_LOADING';
export const DATA_MARKETING_CHANNELS = 'DATA_MARKETING_CHANNELS';
export const DELETE_MARKETING_CHANNEL = 'DELETE_MARKETING_CHANNEL';
export const UPDATE_MARKETING_CHANNEL = 'UPDATE_MARKETING_CHANNEL';
export const UPDATE_MARKETING_CHANNEL_QA_TOOLS = 'UPDATE_MARKETING_CHANNEL_QA_TOOLS';
export const TEST_MARKETING_CONNECTION = 'TEST_MARKETING_CONNECTION';
export const TEST_FACEBOOK_CONNECTION_BY_STORES = 'TEST_FACEBOOK_CONNECTION_BY_STORES';

// RADIO BUTTON LABELS
export const TARGETABLE_PORTAL = 'TARGETABLE_PORTAL';
export const EVERYTHING = 'EVERYTHING';

// SC CAMPAIGN CARDS
export const LEGACY_CAMPAIGN = 'legacyCampaign';

// MAILCHIMPS
export const SEND_MAIL_CHIMP_TEST_EMAIL = 'SEND_MAIL_CHIMP_TEST_EMAIL';
export const PAUSE_MAILCHIMP_CAMPAIGN = 'PAUSE_MAILCHIMP_CAMPAIGN';

// GOOGLE METRICS
export const DATA_GOOGLE_AD_METRICS = 'DATA_GOOGLE_AD_METRICS';
export const DATA_GOOGLE_AD_METRICS_LOADING = 'DATA_GOOGLE_AD_METRICS_LOADING';
export const DATA_GOOGLE_AD_METRICS_LOADING_ERROR = 'DATA_GOOGLE_AD_METRICS_LOADING_ERROR';

// Google Search Ad Combinations
export const DATA_GOOGLE_SEARCH_AD_COMBINATIONS = 'DATA_GOOGLE_SEARCH_AD_COMBINATIONS';
export const DATA_GOOGLE_SEARCH_AD_COMBINATIONS_LOADING = 'DATA_GOOGLE_SEARCH_AD_COMBINATIONS_LOADING';
export const DATA_GOOGLE_SEARCH_AD_COMBINATIONS_LOADING_ERROR = 'DATA_GOOGLE_SEARCH_AD_COMBINATIONS_LOADING_ERROR';
export const GET_GOOGLE_SEARCH_AD_COMBINATIONS = 'GET_GOOGLE_SEARCH_AD_COMBINATIONS';

// Invoices History
export const GET_INVOICE_HISTORY = 'GET_INVOICE_HISTORY';
export const DATA_INVOICE_HISTORY = 'DATA_INVOICE_HISTORY';
export const DATA_INVOICE_HISTORY_LOADING = 'DATA_INVOICE_HISTORY_LOADING';

// Google Detail Metrics
export const LIFETIME = 'lifetime';
export const TRENDS = 'trends';

// TRANSACTIONAL EMAIL
export const SEND_TRANSACTIONAL_EMAIL = 'SEND_TRANSACTIONAL_EMAIL';

// AD REQUEST
export const UPDATE_AD_REQUEST = 'UPDATE_AD_REQUEST';

export const SHOW_FEEDBACK_DIALOG = 'SHOW_FEEDBACK_DIALOG';
export const CLOSE_FEEDBACK_DIALOG = 'CLOSE_FEEDBACK_DIALOG';
export const PREVIEW_SHOW_FEEDBACK_DIALOG = 'PREVIEW_SHOW_FEEDBACK_DIALOG';

// LOCATIONS
export const SET_COORDINATES = 'SET_COORDINATES';
export const DATA_LOADING_COORDINATES = 'DATA_LOADING_COORDINATES';
export const DATA_COORDINATES = 'DATA_COORDINATES';

// COMMENTS
export const DATA_COMMENTS = 'DATA_COMMENTS';
export const DATA_LOADING_COMMENTS = 'DATA_LOADING_COMMENTS';
export const SET_COMMENTS = 'SET_COMMENTS';
export const POST_COMMMENT = 'POST_COMMMENT';

// ALL GEN FORMS
export const GET_ALL_LEAD_GEN_FORMS = 'GET_ALL_LEAD_GEN_FORMS';

// MARKETING EVENT
export const CREATE_MARKETING_EVENT = 'CREATE_MARKETING_EVENT';

export const UPDATE_GOOGLE_LIVE_CAMPAIGN = 'UPDATE_GOOGLE_LIVE_CAMPAIGN';

export const GET_VIDEO_THUMBNAIL = 'GET_VIDEO_THUMBNAIL';
