import * as constants from './constants';

function actionCreator(type) {
  return (payload) => ({
    type,
    payload,
  });
}

// BUSINESS ACTIONS
export const createBusiness = actionCreator(constants.CREATE_BUSINESS);
export const getBusinessCampaigns = actionCreator(constants.GET_BUSINESS_CAMPAIGNS);
export const getBusinessStores = actionCreator(constants.GET_BUSINESS_STORES);
export const getBusinessInsights = actionCreator(constants.GET_BUSINESS_INSIGHTS);
export const getBusinessSuggestedCampaigns = actionCreator(
  constants.GET_BUSINESS_SUGGESTED_CAMPAIGNS,
);
export const getBusiness = actionCreator(
  constants.GET_BUSINESS,
);
export const updateBusiness = actionCreator(constants.UPDATE_BUSINESS);
export const confirmEditBusinessName = actionCreator(constants.CONFIRM_EDIT_BUSINESS_NAME);

export const setBusiness = actionCreator(constants.DATA_BUSINESS);
export const setBusinessIdQueried = actionCreator(constants.DATA_BUSINESS_ID_QUERIED);
export const setBusinessLoading = actionCreator(constants.DATA_BUSINESS_LOADING);
export const setBusinessCampaigns = actionCreator(constants.DATA_BUSINESS_CAMPAIGNS);
export const setBusinessCampaignsLoading = actionCreator(constants.DATA_BUSINESS_CAMPAIGNS_LOADING);
export const setBusinessInsights = actionCreator(constants.DATA_BUSINESS_INSIGHTS);
export const setBusinessInsightsLoading = actionCreator(constants.DATA_BUSINESS_INSIGHTS_LOADING);
export const setBusinessStores = actionCreator(constants.DATA_BUSINESS_STORES);
export const setBusinessStoresLoading = actionCreator(constants.DATA_BUSINESS_STORES_LOADING);

export const setBusinesses = actionCreator(constants.DATA_BUSINESSES);
export const setBusinessesLoading = actionCreator(constants.DATA_BUSINESSES_LOADING);
export const getBusinesses = actionCreator(constants.GET_BUSINESSES);

export const setBusinessPixels = actionCreator(constants.DATA_BUSINESS_PIXELS);
export const setBusinessPixelsLoading = actionCreator(constants.DATA_BUSINESS_PIXELS_LOADING);
export const updateStorePixel = actionCreator(constants.UPDATE_STORE_PIXEL);

export const setSubscription = actionCreator(constants.DATA_BILLING_SUBSCRIPTION);
export const setSubscriptionLoading = actionCreator(constants.DATA_BILLING_SUBSCRIPTION_LOADING);
export const cancelStripeSubscription = actionCreator(constants.CANCEL_STRIPE_SUBSCRIPTION);
export const pauseSubscriptionCollection = actionCreator(constants.PAUSE_STRIPE_COLLECTION);
export const reactivateStripeSubscription = actionCreator(constants.REACTIVATE_STRIPE_SUBSCRIPTION);
export const unpauseSubscriptionCollection = actionCreator(constants.UNPAUSE_STRIPE_COLLECTION);
export const updateStripeSubscription = actionCreator(constants.UPDATE_STRIPE_COLLECTION);
export const deleteStripeSubscriptionItem = actionCreator(
  constants.DELETE_STRIPE_SUBSCRIPTION_ITEM,
);

export const setBusinessSuggestedCampaigns = actionCreator(
  constants.DATA_BUSINESS_SUGGESTED_CAMPAIGNS,
);
export const setBusinessSuggestedCampaignsLoading = actionCreator(
  constants.DATA_BUSINESS_SUGGESTED_CAMPAIGNS_LOADING,
);
export const setBusinessUsers = actionCreator(constants.DATA_BUSINESS_USERS);
export const setBusinessUsersLoading = actionCreator(constants.DATA_BUSINESS_USERS_LOADING);
export const setAuth0PermissionsAction = actionCreator(constants.SET_AUTH0_PERMISSIONS);
export const setManagedLandingPages = actionCreator(constants.DATA_BUSINESS_MANAGED_LANDING_PAGES);
export const saveManagedLandingPages = actionCreator(constants.SAVE_MANAGED_LANDING_PAGES);
export const deleteManagedLandingPages = actionCreator(constants.DELETE_MANAGED_LANDING_PAGES);

// CAMPAIGN ACTIONS
export const getFacebookCampaignLanguages = actionCreator(
  constants.GET_FACEBOOK_CAMPAIGN_LANGUAGES,
);

export const getCampaign = actionCreator(constants.GET_CAMPAIGN);
export const setCampaign = actionCreator(constants.DATA_CAMPAIGN);
export const setCampaignLoading = actionCreator(constants.DATA_CAMPAIGN_LOADING);
export const setCampaignAds = actionCreator(constants.DATA_CAMPAIGN_ADS);
export const setCampaignName = actionCreator(constants.DATA_CAMPAIGN_NAME);
export const setCampaignGoal = actionCreator(constants.DATA_CAMPAIGN_GOAL);
export const setCampaignObjective = actionCreator(constants.DATA_CAMPAIGN_OBJECTIVE);
export const setCampaignRequestInfo = actionCreator(constants.DATA_CAMPAIGN_REQUEST_INFO);
export const setCampaignLeadForms = actionCreator(constants.DATA_BUSINESS_LEAD_FORMS);
export const updateCampaignAds = actionCreator(
  constants.DATA_CAMPAIGN_UPDATE_ADS,
);
export const setCampaignPixelEventType = actionCreator(constants.DATA_CAMPAIGN_PIXEL_EVENT_TYPE);
export const setCampaignOptimizationGoal = actionCreator(constants.DATA_CAMPAIGN_OPTIMIZATION_GOAL);
export const setCampaignAdType = actionCreator(constants.DATA_CAMPAIGN_AD_TYPE);
export const setCampaignEventId = actionCreator(constants.DATA_CAMPAIGN_EVENT_ID);
export const setCampaignOverrideAutoAging = actionCreator(
  constants.DATA_CAMPAIGN_OVERRIDE_AUTO_AGING,
);
export const saveCampaignRetryTimer = actionCreator(constants.SAVE_CAMPAIGN_RETRY_TIMER);

export const setFacebookCampaignLanguages = actionCreator(
  constants.DATA_FACEBOOK_CAMPAIGN_LANGUAGES,
);
export const setFacebookCampaignTargets = actionCreator(constants.DATA_FACEBOOK_CAMPAIGN_TARGETS);
export const saveCampaignForm = actionCreator(constants.SAVE_CAMPAIGN_FORM);
export const submitCampaignForm = actionCreator(constants.SUBMIT_CAMPAIGN_FORM);
export const updateCampaignUser = actionCreator(constants.UPDATE_CAMPAIGN_USER);
export const updateMetaLiveCampaign = actionCreator(constants.UPDATE_META_LIVE_CAMPAIGN);

// INIT ACTIONS
export const initBusiness = actionCreator(constants.INIT_BUSINESS);

// INSIGHT ACTIONS
export const createInsight = actionCreator(constants.CREATE_INSIGHT);
export const deleteInsight = actionCreator(constants.DELETE_INSIGHT);
export const updateInsight = actionCreator(constants.UPDATE_INSIGHT);
export const retryUpdateLiveInsight = actionCreator(constants.RETRY_UPDATE_LIVE_INSIGHT);

// INSIGHT TEMPLATES ACTIONS
export const createInsightTemplate = actionCreator(constants.CREATE_INSIGHT_TEMPLATE);
export const deleteInsightTemplate = actionCreator(constants.DELETE_INSIGHT_TEMPLATE);
export const updateInsightTemplate = actionCreator(constants.UPDATE_INSIGHT_TEMPLATE);

// CUSTOM FIELDS ACTIONS
export const createCustomField = actionCreator(constants.CREATE_CUSTOM_FIELD);
export const updateCustomField = actionCreator(constants.UPDATE_CUSTOM_FIELD);

// Questionnaire Actions
export const createQuestionnaire = actionCreator(constants.CREATE_QUESTIONNAIRE_TEMPLATE);
export const deleteQuestionnaire = actionCreator(constants.DELETE_QUESTIONNAIRE_TEMPLATE);
export const updateQuestionnaire = actionCreator(constants.UPDATE_QUESTIONNAIRE_TEMPLATE);

// LOADING ACTIONS
export const setLoadingAsync = actionCreator(constants.DATA_LOADING_ASYNC);

// MAM ACTIONS
export const getImages = actionCreator(constants.GET_IMAGES);
export const removeImages = actionCreator(constants.REMOVE_IMAGES);
export const updateImageTags = actionCreator(constants.UPDATE_TAG_IMAGES);
export const getMoreImages = actionCreator(constants.GET_MORE_IMAGES);
export const searchImages = actionCreator(constants.SEARCH_IMAGES);
export const setAdImage = actionCreator(constants.DATA_AD_IMAGE);
export const setAdImageUi = actionCreator(constants.DATA_AD_IMAGE_UI);
export const setImages = actionCreator(constants.DATA_INITIAL_IMAGES);
export const setInitialImages = actionCreator(constants.DATA_INITIAL_IMAGES);
export const setInitialImagesCursor = actionCreator(constants.DATA_INITIAL_IMAGES_CURSOR);
export const setImagesMam = actionCreator(constants.DATA_IMAGES_MAM);
export const setIsSearchingMam = actionCreator(constants.DATA_IS_SEARCHING_MAM);

// TOASTER ACTIONS
export const closeToaster = actionCreator(constants.CLOSE_TOASTER);
export const showToaster = actionCreator(constants.SHOW_TOASTER);

// SUGGESTED CAMPAIGN / DRAFT ACTIONS
export const deleteSuggestedCampaign = actionCreator(constants.DELETE_SUGGESTED_CAMPAIGN);
export const getSuggestedCampaign = actionCreator(constants.GET_SUGGESTED_CAMPAIGN);
export const saveSuggestedCampaign = actionCreator(constants.SAVE_SUGGESTED_CAMPAIGN);
export const setSuggestedCampaign = actionCreator(constants.DATA_SUGGESTED_CAMPAIGN);
export const setSuggestedCampaignLoading = actionCreator(constants.DATA_SUGGESTED_CAMPAIGN_LOADING);
export const setSuggestedCampaignAds = actionCreator(constants.DATA_SUGGESTED_CAMPAIGN_ADS);
export const setSuggestedCampaignName = actionCreator(constants.DATA_SUGGESTED_CAMPAIGN_NAME);
export const setSuggestedCampaignGoal = actionCreator(constants.DATA_SUGGESTED_CAMPAIGN_GOAL);
export const setSuggestedCampaignObjective = actionCreator(
  constants.DATA_SUGGESTED_CAMPAIGN_OBJECTIVE,
);
export const setSuggestedCampaignDisplayDate = actionCreator(
  constants.DATA_SUGGESTED_CAMPAIGN_DISPLAY_DATE,
);
export const setSuggestedCampaignDisplayTime = actionCreator(
  constants.DATA_SUGGESTED_CAMPAIGN_DISPLAY_TIME,
);
export const setSuggestedCampaignExpiryDateTime = actionCreator(
  constants.DATA_SUGGESTED_CAMPAIGN_EXPIRY_DATETIME,
);
export const setSuggestedCampaignQuestionnaire = actionCreator(
  constants.DATA_SUGGESTED_CAMPAIGN_QUESTIONNAIRE,
);
export const updateSuggestedCampaignAds = actionCreator(
  constants.DATA_SUGGESTED_CAMPAIGN_UPDATE_ADS,
);
export const duplicateCampaign = actionCreator(constants.DUPLICATE_CAMPAIGN);
export const duplicateSuggestedCampaign = actionCreator(constants.DUPLICATE_SUGGESTED_CAMPAIGN);
export const pauseOrUnpauseCampaign = actionCreator(constants.PAUSE_UNPAUSE_CAMPAIGN);
export const openSuggestedCampaignDraft = actionCreator(constants.OPEN_SUGGESTED_CAMPAIGN_CARD);
export const retryCampaign = actionCreator(constants.RETRY_CAMPAIGN);

// SUGGESTED CAMPAIGN TEMPLATES ACTIONS
export const deleteSuggestedCampaignTemplate = actionCreator(
  constants.DELETE_SUGGESTED_CAMPAIGN_TEMPLATE,
);
export const updateSuggestedCampaignTemplate = actionCreator(
  constants.UPDATE_SUGGESTED_CAMPAIGN_TEMPLATE,
);
export const createSuggestedCampaignTemplate = actionCreator(
  constants.CREATE_SUGGESTED_CAMPAIGN_TEMPLATE,
);
export const initSuggestedCampaignTemplate = actionCreator(
  constants.INIT_SUGGESTED_CAMPAIGN_TEMPLATE,
);

export const duplicateSuggestedCampaignTemplate = actionCreator(
  constants.DUPLICATE_SUGGESTED_CAMPAIGN_TEMPLATE,
);

// GOOGLE MARKETING
export const setGoogleCampaigns = actionCreator(constants.DATA_GOOGLE_CAMPAIGNS);
export const setGoogleCampaignsLoading = actionCreator(constants.DATA_GOOGLE_CAMPAIGNS_LOADING);

export const setGoogleAdGroups = actionCreator(constants.DATA_GOOGLE_AD_GROUPS);
export const setGoogleAdGroupsLoading = actionCreator(constants.DATA_GOOGLE_AD_GROUPS_LOADING);

export const getGoogleCampaignsByChannelId = actionCreator(constants.GET_GOOGLE_CAMPAIGNS);
export const getGoogleAdGroupsByChannelId = actionCreator(constants.GET_GOOGLE_AD_GROUPS);
export const getGoogleLocationsApi = actionCreator(constants.GET_GOOGLE_LOCATIONS);

export const createUpdateGoogleSearchAdsBulk = actionCreator(
  constants.CREATE_UPDATE_GOOGLE_SEARCH_ADS_BULK,
);

export const createMarketingSuggestedCampaign = actionCreator(
  constants.CREATE_MARKETING_SUGGESTED_CAMPAIGN,
);
export const updateMarketingSuggestedCampaign = actionCreator(
  constants.UPDATE_MARKETING_SUGGESTED_CAMPAIGN,
);
export const duplicateMarketingSuggestedCampaign = actionCreator(
  constants.DUPLICATE_MARKETING_SUGGESTED_CAMPAIGN,
);
export const revertMarketingSuggestedCampaignToDraft = actionCreator(
  constants.REVERT_MARKETING_SUGGESTED_CAMPAIGN,
);
export const returnCampaignToDraft = actionCreator(
  constants.RETURN_CAMPAIGN_TO_DRAFT,
);
export const importMarketingGoogleSearchAd = actionCreator(
  constants.IMPORT_MARKETING_GSA_CAMPAIGN,
);

// CONFIRMATION DIALOG ACTIONS
export const setConfirmationDialog = actionCreator(constants.DATA_CONFIRMATION_DIALOG);
export const showConfirmationDialog = actionCreator(constants.SHOW_CONFIRMATION_DIALOG);
export const resultConfirmationDialog = actionCreator(constants.RESULT_CONFIRMATION_DIALOG);

// ACTIVITY LOG NOTE ACTIONS
export const setActivityLogNoteDialog = actionCreator(constants.DATA_ACTIVITY_LOG_DIALOG);
export const showActivityLogNoteDialog = actionCreator(constants.SHOW_ACTIVITY_LOG_DIALOG);
export const resultActivityLogNoteDialog = actionCreator(constants.RESULT_ACTIVITY_LOG_DIALOG);

// PUBLISH MARKETING DIALOG ACTIONS
export const setPublishMarketingDialog = actionCreator(constants.DATA_PUBLISH_MARKETING_DIALOG);
export const showPublishMarketingDialog = actionCreator(constants.SHOW_PUBLISH_MARKETING_DIALOG);
export const resultPublishMarketingDialog = actionCreator(
  constants.RESULT_PUBLISH_MARKETING_DIALOG,
);
// Marketing Suggested Campaigns
export const publishMarketingAd = actionCreator(
  constants.PUBLISH_MARKETING_AD,
);

// STORE ACTIONS
export const updateStoreDefaultRadius = actionCreator(constants.UPDATE_STORE_DEFAULT_RADIUS);
export const updateStoreLocation = actionCreator(constants.UPDATE_STORE_LOCATION);
export const updateStoreIntegrations = actionCreator(constants.UPDATE_STORE_INTEGRATIONS);
export const updateStoreMarketingChannels = actionCreator(
  constants.UPDATE_STORE_MARKETING_CHANNELS,
);
export const updateStorePresets = actionCreator(
  constants.UPDATE_STORE_PRESETS,
);
export const updateStoreName = actionCreator(constants.UPDATE_STORE_NAME);
export const updatePhoneNumber = actionCreator(constants.UPDATE_PHONE_NUMBER);
export const updateStoreCooUrl = actionCreator(constants.UPDATE_COO_URL);
export const updateStoreChowlyPartnerId = actionCreator(constants.UPDATE_CHOWLY_PARTNER_ID);
export const updateStoreDefaultPublishingUser = actionCreator(
  constants.UPDATE_DEFAULT_PUBLISHING_USER,
);
export const refreshGoogleLocation = actionCreator(constants.REFRESH_STORE_LOCATION);
export const refreshFacebookLocation = actionCreator(constants.REFRESH_STORE_FACEBOOK_LOCATION);
export const setStoreLocation = actionCreator(constants.SET_STORE_LOCATION);
export const setStoreStatus = actionCreator(constants.SET_STORE_STATUS);
export const setStoreFacebookLocation = actionCreator(constants.SET_STORE_FACEBOOK_LOCATION);
export const updateStorePublishedAsPaused = actionCreator(
  constants.UPDATE_STORE_PUBLISHED_AS_PAUSED,
);
export const enableDisableStore = actionCreator(constants.ENABLE_DISABLE_STORE);
export const unlinkMetaConnection = actionCreator(constants.UNLINK_META_CONNECTION);
export const getStorePixels = actionCreator(constants.GET_STORE_PIXELS);

// USER ACTIONS
export const getAllUsers = actionCreator(constants.GET_ALL_USERS);
export const inviteUser = actionCreator(constants.INVITE_USER);
export const reinviteUser = actionCreator(constants.REINVITE_USER);
export const updateUserEmail = actionCreator(constants.UPDATE_USER_EMAIL);
export const setUserEmail = actionCreator(constants.SET_USER_EMAIL);
export const requestFbToken = actionCreator(constants.REQUEST_FB_TOKEN);
export const updateUserName = actionCreator(constants.UPDATE_USER_NAME);
export const updateUser = actionCreator(constants.UPDATE_USER);
export const removeUser = actionCreator(constants.REMOVE_USER);
export const deleteUser = actionCreator(constants.DELETE_USER);
export const verifyEmailAction = actionCreator(constants.VERIFY_AUTH0_EMAIL);
export const moveUserToBusiness = actionCreator(constants.MOVE_USER_TO_BUSINESS);

// CAMPAIGN REPORTING
export const reportsLoading = actionCreator(constants.REPORTS_LOADING);
export const setCampaignReport = actionCreator(constants.DATA_CAMPAIGN_REPORT);
export const setCampaignReports = actionCreator(constants.DATA_CAMPAIGN_REPORTS);
export const getStoreDateReports = actionCreator(constants.GET_STORE_DATE_REPORTS);
export const setStoreDateReports = actionCreator(constants.DATA_STORE_DATE_REPORTS);
export const setCampaignMetrics = actionCreator(constants.DATA_CAMPAIGN_METRICS);
export const setCampaignTrends = actionCreator(constants.DATA_CAMPAIGN_TRENDS);
export const setCampaignMetricsLoading = actionCreator(constants.DATA_CAMPAIGN_METRICS_LOADING);
export const setCampaignMetricsLoadingError = actionCreator(
  constants.DATA_CAMPAIGN_METRICS_LOADING_ERROR,
);

// URL PRESETS
export const getUrlPresets = actionCreator(constants.GET_URL_PRESETS);
export const saveUrlPresets = actionCreator(constants.SAVE_URL_PRESETS);
export const setUrlPresets = actionCreator(constants.DATA_URL_PRESETS);
export const setUrlPresetsLoading = actionCreator(constants.DATA_URL_PRESETS_LOADING);
export const deleteUrlPreset = actionCreator(constants.DELETE_URL_PRESET);

export const setAdCardPresets = actionCreator(constants.DATA_AD_CARD_PRESETS);
export const getAdCardPresets = actionCreator(constants.GET_AD_CARD_PRESETS);

// CUSTOM AUDIENCE
export const setStoresCustomAudiences = actionCreator(constants.DATA_STORES_CUSTOM_AUDIENCES);

// ECLINCHER URL
export const saveEClincherUrl = actionCreator(constants.SAVE_ECLINCHER_URL);

// RCC URL
export const saveRccUrl = actionCreator(constants.SAVE_RCC_URL);

// SALESFORCE ID
export const saveSalesforceId = actionCreator(constants.SAVE_SALESFORCE_ID);

// PIXEL EVENT TYPE
export const setSuggestedCampaignPixelEventType = actionCreator(
  constants.DATA_SUGGESTED_CAMPAIGN_PIXEL_EVENT_TYPE,
);
export const setSuggestedCampaignOptimizationGoal = actionCreator(
  constants.DATA_SUGGESTED_CAMPAIGN_OPTIMIZATION_GOAL,
);

export const setSuggestedCampaignAdType = actionCreator(
  constants.DATA_SUGGESTED_CAMPAIGN_AD_TYPE,
);

export const setSuggestedCampaignEventId = actionCreator(
  constants.DATA_SUGGESTED_CAMPAIGN_EVENT_ID,
);

export const setSuggestedCampaignOverrideAutoAging = actionCreator(
  constants.DATA_SUGGESTED_CAMPAIGN_OVERRIDE_AUTO_AGING,
);

export const copyInviteLinkToClipboard = actionCreator(constants.COPY_INVITE_LINK_TO_CLIPBOARD);

export const openTokenDebugger = actionCreator(constants.OPEN_TOKEN_DEBUGGER);
export const getAllPermissions = actionCreator(constants.GET_ALL_PERMISSIONS);
export const setUsersPermissionsLoading = actionCreator(constants.DATA_USERS_PERMISSIONS_LOADING);
export const setUsersPermissions = actionCreator(constants.SET_ALL_PERMISSIONS);

export const getStripeProducts = actionCreator(constants.GET_STRIPE_PRODUCTS);
export const setStripeProducts = actionCreator(constants.DATA_STRIPE_PRODUCTS);
export const setStripeProductsLoading = actionCreator(constants.DATA_STRIPE_PRODUCTS_LOADING);
export const goToStripeCustomerPortal = actionCreator(constants.GOTO_STRIPE_CUSTOMER_PORTAL);
export const getStripeSubscriptions = actionCreator(constants.GET_STRIPE_SUBSCRIPTIONS);

// SILECTIS
export const getSilectisGlobalSubscriptions = actionCreator(
  constants.GET_SILECTIS_GLOBAL_SUBSCRIPTIONS,
);

export const getSilectisFacebookMetrics = actionCreator(
  constants.GET_SILECTIS_FACEBOOK_METRICS,
);

export const getSilectisGoogleMetrics = actionCreator(
  constants.GET_SILECTIS_GOOGLE_METRICS,
);

export const getSilectisMailchimpMetrics = actionCreator(
  constants.GET_SILECTIS_MAILCHIMP_METRICS,
);

export const deleteStore = actionCreator(constants.DELETE_STORE);

export const setBusinessVerticals = actionCreator(constants.DATA_BUSINESS_VERTICALS);

export const addStore = actionCreator(constants.MULTI_STORE_ADD_STORE);
export const showAddStoreModal = actionCreator(constants.SHOW_ADD_STORE_MODAL);
export const showBackdrop = actionCreator(constants.DATA_BACKDROP);
export const addStoreConfirmPayment = actionCreator(constants.ADD_STORE_CONFIRM_PAYMENT);

// MARKETING CHANNEL
export const saveMarketingChannel = actionCreator(constants.SAVE_MARKETING_CHANNEL);
export const getMarketingChannels = actionCreator(constants.GET_MARKETING_CHANNELS);
export const setMarketingChannels = actionCreator(constants.DATA_MARKETING_CHANNELS);
export const setMarketingChannelsLoading = actionCreator(constants.DATA_MARKETING_CHANNELS_LOADING);
export const deleteMarketingChannel = actionCreator(constants.DELETE_MARKETING_CHANNEL);
export const updateMarketingChannel = actionCreator(constants.UPDATE_MARKETING_CHANNEL);
export const testMarketingChannelConnection = actionCreator(constants.TEST_MARKETING_CONNECTION);
export const testFacebookConnectionByStores = actionCreator(
  constants.TEST_FACEBOOK_CONNECTION_BY_STORES,
);
export const setMarketingChannelQATools = actionCreator(
  constants.UPDATE_MARKETING_CHANNEL_QA_TOOLS,
);
// MAILCHIMP
export const sendMailChimpTestEmail = actionCreator(constants.SEND_MAIL_CHIMP_TEST_EMAIL);
export const pauseMailchimpCampaign = actionCreator(constants.PAUSE_MAILCHIMP_CAMPAIGN);

export const setAdMetrics = actionCreator(constants.DATA_GOOGLE_AD_METRICS);
export const setAdMetricsLoading = actionCreator(constants.DATA_GOOGLE_AD_METRICS_LOADING);
export const setAdMetricsLoadingError = actionCreator(
  constants.DATA_GOOGLE_AD_METRICS_LOADING_ERROR,
);

export const setGoogleSitelinks = actionCreator(
  constants.DATA_GOOGLE_SITELINKS_BY_MARKETING_CHANNEL_ID,
);
export const setGooglePromotions = actionCreator(
  constants.DATA_GOOGLE_PROMOTIONS_BY_MARKETING_CHANNEL_ID,
);
export const getGoogleAssets = actionCreator(constants.GET_GOOGLE_ASSETS);

// Google Search Ad Combinations
export const getSearchAdCombinations = actionCreator(constants.GET_GOOGLE_SEARCH_AD_COMBINATIONS);
export const setSearchAdCombinations = actionCreator(constants.DATA_GOOGLE_SEARCH_AD_COMBINATIONS);
export const setSearchAdCombinationsLoading = actionCreator(
  constants.DATA_GOOGLE_SEARCH_AD_COMBINATIONS_LOADING,
);
export const setSearchAdCombinationsLoadingError = actionCreator(
  constants.DATA_GOOGLE_SEARCH_AD_COMBINATIONS_LOADING_ERROR,
);

// Invoices History
export const getInvoiceHistory = actionCreator(constants.GET_INVOICE_HISTORY);
export const setInvoiceHistory = actionCreator(constants.DATA_INVOICE_HISTORY);
export const setInvoiceHistoryLoading = actionCreator(constants.DATA_INVOICE_HISTORY_LOADING);

// TRANSACTIONAL EMAIL
export const sendTransactionalEmail = actionCreator(constants.SEND_TRANSACTIONAL_EMAIL);

// AD REQUEST
export const updateAdRequest = actionCreator(constants.UPDATE_AD_REQUEST);

export const showFeedbackModal = actionCreator(constants.SHOW_FEEDBACK_DIALOG);
export const closeFeedbackDialog = actionCreator(constants.CLOSE_FEEDBACK_DIALOG);
export const showPreviewFeedbackDialog = actionCreator(
  constants.PREVIEW_SHOW_FEEDBACK_DIALOG,
);

// LOCATIONS
export const setCoordinates = actionCreator(constants.SET_COORDINATES);
export const setLoadingCoordinates = actionCreator(constants.DATA_LOADING_COORDINATES);
export const translateAddressToCoordinates = actionCreator(constants.DATA_COORDINATES);

// COMMENTS
export const setComments = actionCreator(constants.SET_COMMENTS);
export const setLoadingComments = actionCreator(constants.DATA_LOADING_COMMENTS);
export const getComments = actionCreator(constants.DATA_COMMENTS);
export const postComment = actionCreator(constants.POST_COMMMENT);

// ALL GEN FORMS
export const getAllLeadGenForms = actionCreator(constants.GET_ALL_LEAD_GEN_FORMS);

// MARKETING EVENT
export const createMarketingEvent = actionCreator(constants.CREATE_MARKETING_EVENT);

export const updateGoogleLiveCampaign = actionCreator(constants.UPDATE_GOOGLE_LIVE_CAMPAIGN);

export const getVideoThumbnail = actionCreator(constants.GET_VIDEO_THUMBNAIL);
