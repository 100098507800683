import { call } from 'redux-saga/effects';
import _ from 'lodash';

import api from '../../services/api';
import logger from '../../services/logger';

export default function* getAllUsers({ payload: { onSuccess } }) {
  try {
    let { users, cursor } = yield call(api.getAllUsers);

    let tmpUsers = users;
    let tmpCursor = cursor;

    while (tmpCursor) {
      ({ users, cursor } = yield call(api.getAllUsers, null, tmpCursor));
      tmpUsers = [...tmpUsers, ...users];
      tmpCursor = cursor;
    }

    tmpUsers = _.sortBy(tmpUsers.map((user) => {
      let name = '(no name)';
      if (user.givenName || user.familyName) {
        if (user.givenName) {
          name = user.givenName;
        }
        if (user.givenName && user.familyName) {
          name += ' ';
        }
        if (user.familyName) {
          name += user.familyName;
        }
      }

      return { ...user, name };
    }), 'name');

    onSuccess(tmpUsers);
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getAllUsers' },
    });
  }
}
