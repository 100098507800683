import { call, put } from 'redux-saga/effects';

import api from '../../services/api';
import { setAdImage } from '../../actions';
import logger from '../../services/logger';

export default function* getAdImage({ payload }) {
  try {
    const image = yield call(api.getImage, payload.publicId, '');
    yield put(setAdImage(image));
    return image;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getAdImage' },
      params: { payload },
    });
    return undefined;
  }
}
