import { put, call } from 'redux-saga/effects';
import api from '../../services/api';
import { showToaster, closeToaster, setLoadingAsync } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { CAMPAIGN_SUBMITTED_SMARTFEED } from '../../constants';

export default function* createMarketingSuggestedCampaign({ payload }) {
  const { onSave, suggestedCampaign, onError } = payload;

  try {
    yield put(showToaster(getToasterOptions(
      suggestedCampaign.status === CAMPAIGN_SUBMITTED_SMARTFEED
        ? 'Creating and publishing the Ad...'
        : 'Creating the Ad...',
      'info', null, 7000, true, null, 'bottom', 'right',
    )));

    yield put(setLoadingAsync(true));
    const adDraft = yield call(api.createAdDraft, suggestedCampaign);

    // At this point, the creation was successfull
    if (onSave) { onSave(adDraft); }

    // Reload suggested campaigns -
    // Unnecessary call because getting from algolia
    // yield call(getSuggestedCampaignsByBusiness, {
    //   payload: { businessId: suggestedCampaign?.businessId },
    // });

    yield put(setLoadingAsync(false));
    yield put(closeToaster());
  } catch (e) {
    yield put(showToaster(getToasterOptions(e?.response?.data.message || e?.message?.data || 'Failed to create the Suggested Campaign Template. Try again in a few minutes.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'createMarketingSuggestedCampaign' },
      params: { payload },
    });
    if (onError) {
      onError();
    }
  }
}
