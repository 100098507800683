import {
  put,
  call,
  select,
  take,
  all,
} from 'redux-saga/effects';

import _ from 'lodash';

import api from '../../services/api';

import { RESULT_CONFIRMATION_DIALOG } from '../../constants';

import {
  showToaster, showConfirmationDialog, setLoadingAsync, closeToaster,
} from '../../actions';
import { selectBusinessSuggestedCampaigns, selectBusiness } from '../../selectors';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import getStoresByBusiness from '../getStoresByBusiness/getStoresByBusiness';
import removeStoreFromSubscription from '../removeStoreFromSubscription/removeStoreFromSubscription';
import createActivityLog from '../createActivityLog/createActivityLog';
import getStripeSubscription from '../getStripeSubscription/getStripeSubscription';
import auth from '../../services/auth';

export default function* deleteStore(action) {
  const {
    store, setShowBackdrop, onCompleted, onError,
  } = action.payload;
  const stores = action.payload?.stores;
  let deleteMultipleStores;
  if (stores && Array.isArray(stores)) {
    deleteMultipleStores = true;
  }
  yield put(showToaster(getToasterOptions(
    deleteMultipleStores ? 'checking_stores_campaigns' : 'checking_store_campaigns',
    'info', null, 20000, true, null, 'bottom', 'right',
  )));
  const { id: businessId, featureFlags } = yield select(selectBusiness);
  const disableAutomaticBillingIncrement = featureFlags?.disableAutomaticBillingIncrement;

  const { campaigns } = yield call(
    api.getCampaigns,
    [store],
    'submitted',
    businessId,
    null,
    'history',
  );

  const campaign = _.find(campaigns, ({ ads, status }) => {
    const storeIds = _.map(ads, 'storeId');
    return storeIds.length && _.includes(storeIds, store.id) && status === 'submitted';
  });

  if (campaign) {
    const storeName = store.name;
    const campaignId = campaign.id;
    const campaignName = campaign.name;
    yield put(closeToaster());
    yield put(setLoadingAsync(false));
    yield put(showConfirmationDialog({
      title: 'Unable to Delete Store',
      text: 'delete_store_unavailable',
      textParams: { storeName, campaignName, campaignId },
      confirmText: 'close',
      cancelText: '',
      cancelDataCy: 'ConfirmationDialog_cancel_button',
      onClick: _.identity,
    }));
    yield take(RESULT_CONFIRMATION_DIALOG);
    return;
  }

  const suggestedCampaigns = yield select(selectBusinessSuggestedCampaigns);
  const associatedSC = _.find(suggestedCampaigns, ({ ads, status }) => {
    const storeIds = _.map(ads, 'storeId');
    if (deleteMultipleStores) {
      return storeIds.length && _.intersection(storeIds, stores.map((st) => st.id)).length > 0 && status === 'submitted';
    }

    return storeIds.length && _.includes(storeIds, store.id) && status === 'submitted';
  });

  const associatedText = deleteMultipleStores
    ? 'Unable to remove some of the stores. Some store is associated to Suggested Campaign(s) and will need to be unassociated in order to remove the store record'
    : `Unable to remove store: ${store.name}. Store is associated to Suggested Campaign(s) and will need to be unassociated in order to remove the store record`;

  if (!_.isEmpty(associatedSC)) {
    yield put(closeToaster());
    yield put(setLoadingAsync(false));
    yield put(showConfirmationDialog({
      title: 'Unable to Delete Store',
      text: associatedText,
      confirmText: '',
      cancelText: 'close',
      onClick: _.identity,
    }));
    yield take(RESULT_CONFIRMATION_DIALOG);
    return;
  }

  const user = auth.getUser();

  try {
    yield put(closeToaster());
    yield put(setLoadingAsync(false));
    const title = deleteMultipleStores ? 'Delete selected stores' : 'Delete Store';
    const deleteText = deleteMultipleStores
      ? 'Are you sure you want to delete selected stores?'
      : `Are you sure you want to delete ${_.get(store, 'name')} record?`;
    yield put(showConfirmationDialog({
      title,
      text: deleteText,
      confirmText: 'delete',
      confirmDataCy: 'store_detail_delete_cancel',
      cancelText: 'cancel',
      cancelDataCy: 'store_detail_delete_confirmation',
      onClick: _.identity,
    }));
    const { payload: confirm } = yield take(RESULT_CONFIRMATION_DIALOG);
    yield put(setLoadingAsync(true));
    if (confirm) {
      setShowBackdrop(true);
      if (deleteMultipleStores) {
        const operations = stores.map((st) => call(api.deleteStore, st?.id));
        yield all(operations);
        const operationsActivityLog = stores.map((st) => call(
          createActivityLog, {
            businessId,
            userId: user?.email,
            type: 'delete_store',
            title: `${user?.email || user?.sub} deleted Store ${st.id}`,
            description: `Store deleted in Admin Portal by ${user?.email || user?.sub}. Store: ${st.id} - Store Name: ${st?.name}`,
          },
        ));
        yield all(operationsActivityLog);
        if (!disableAutomaticBillingIncrement) {
          const operationsSubscription = stores.map((st) => call(
            removeStoreFromSubscription, st?.id,
          ));
          yield all(operationsSubscription);
        }
      } else {
        yield call(api.deleteStore, store.id);
        yield call(createActivityLog, {
          businessId,
          userId: user?.email,
          type: 'delete_store',
          title: `${user?.email || user?.sub} deleted Store ${store.id}`,
          description: `Store deleted in Admin Portal by ${user?.email || user?.sub}. Store: ${store.id} - Store Name: ${store?.name}`,
        });
        if (!disableAutomaticBillingIncrement) {
          yield call(removeStoreFromSubscription, store.id);
        }
      }
      setShowBackdrop(false);
      if (!deleteMultipleStores) {
        yield call(getStoresByBusiness, { payload: businessId });
      }
      // load subscriptions with updated quantities, unless nothing was updated
      if (!disableAutomaticBillingIncrement) {
        yield call(getStripeSubscription);
      }
      yield put(showToaster(getToasterOptions(`Store${deleteMultipleStores ? 's' : ''} deletion completed`, 'success', null, 2000)));
    }
    yield put(setLoadingAsync(false));
    if (onCompleted) { onCompleted(); }
  } catch (e) {
    setShowBackdrop(false);
    yield put(showToaster(getToasterOptions('Failed to delete store. Try again in a few minutes.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'deleteStore' },
      params: { action },
    });
    if (onError) { onError(); }
  }
}
