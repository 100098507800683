import {
  DATA_STRIPE_PRODUCTS,
  DATA_STRIPE_PRODUCTS_LOADING,
} from '../constants';

const defaultState = {
  products: [],
  productsLoading: false,
};

export default {
  defaultState,
  reducers: {
    [DATA_STRIPE_PRODUCTS]: (state, { payload: products }) => ({
      ...state,
      products,
    }),
    [DATA_STRIPE_PRODUCTS_LOADING]: (state, { payload: productsLoading }) => ({
      ...state,
      productsLoading,
    }),
  },
};
