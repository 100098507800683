import {
  call, put, select, all, take,
} from 'redux-saga/effects';
import _ from 'lodash';
import api from '../../services/api';
import { selectBusiness, selectSubscription } from '../../selectors';
import {
  showToaster,
  setSubscriptionLoading,
  showActivityLogNoteDialog,
  setLoadingAsync,
} from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import getStripeSubscriptionByCustomer from '../getStripeSubscriptionByCustomer/getStripeSubscriptionByCustomer';
import { RESULT_ACTIVITY_LOG_DIALOG } from '../../constants';

const mapProductProperties = (product, currentSubscription) => {
  const prevProduct = _.find(currentSubscription?.stripeProducts, {
    subscriptionItemId: product.subscriptionItemId,
  });

  return {
    id: product.subscriptionItemId,
    quantity: product.quantity,
    prevQuantity: Number(prevProduct?.quantity || 0),
    subscriptionTypeKey: product.subscriptionTypeKey,
  };
};

/**
 * Resumes a subscriptions collection that was paused.
 * @param {Function} onCompleted a function to invoke once the saga has completed successfully.
 * @param {Function} onError a function to invoke if the saga has not completed successfully.
 */
export default function* updateStripeSubscription({ payload }) {
  const {
    mappedProducts,
  } = payload;

  yield put(setLoadingAsync(false));
  yield put(showActivityLogNoteDialog({
    title: 'Add Note? (optional)',
    text: 'You can add any notes, descriptions, reasons for the action you are about to take. This may or may not be exposed to the customer.',
    confirmText: 'submit',
    cancelText: 'cancel',
    onClick: _.identity,
  }));

  const { payload: activityLogNote } = yield take(RESULT_ACTIVITY_LOG_DIALOG);
  if (activityLogNote === false) return;

  yield put(setLoadingAsync(true));

  const business = yield select(selectBusiness);
  const subscription = yield select(selectSubscription);

  const removedItems = mappedProducts?.filter((p) => p.removed);
  const removedItemsInfo = removedItems?.map((p) => mapProductProperties(p, subscription));
  const items = mappedProducts
    ?.filter((p) => !p.removed)?.map((p) => mapProductProperties(p, subscription));

  try {
    yield put(setSubscriptionLoading(true));
    if (!_.isEmpty(removedItems)) {
      const calls = [];
      _.forEach(removedItems, ({ subscriptionId, subscriptionItemId }) => {
        calls.push(call(
          api.deleteStripeSubscriptionItem,
          business?.stripeCustomerId,
          subscriptionId,
          subscriptionItemId,
        ));
      });
      yield all(calls);
    }

    yield call(
      api.updateStripeSubscription,
      business?.stripeCustomerId,
      mappedProducts?.[0]?.subscriptionId,
      {
        items,
        removedItems: removedItemsInfo,
        activityLogNote,
        businessId: business?.id,
      },
    );
    yield put(setSubscriptionLoading(false));
    // reload subscriptions info
    yield call(getStripeSubscriptionByCustomer, {
      payload: {
        businessId: business?.id,
        stripeCustomerId: business?.stripeCustomerId,
      },
    });
    yield put(showToaster(getToasterOptions('Subscription updated!', 'success', null, 3000)));
  } catch (ex) {
    yield put(setSubscriptionLoading(false));
    logger.error({
      error: ex,
      context: { saga: 'updateSubscription' },
      params: { mappedProducts },
    });
    yield put(showToaster(getToasterOptions('error_unpause_subscription_collection')));
  }
}
