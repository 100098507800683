import { call, put } from 'redux-saga/effects';
import {
  showToaster,
} from '../../actions';
import { getToasterOptions } from '../helpers';

import api from '../../services/api';
import logger from '../../services/logger';

export default function* goToStripeCustomerPortal({ payload }) {
  const { stripeCustomerId } = payload;

  try {
    yield put(
      showToaster(getToasterOptions('Generating Customer Portal session link. Please wait a moment...', 'info', null, 3000, true)),
    );
    const sessionUrl = yield call(
      api.getCustomerPortalSession, stripeCustomerId, window.location.href,
    );

    if (sessionUrl) {
      window.open(sessionUrl, '_blank');
    } else {
      yield put(
        showToaster(getToasterOptions('An error has occurred trying to generate the Customer Portal link.Please, try again later', 'error', null, 3000, true)),
      );
    }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'goToStripeCustomerPortal' },
      params: { stripeCustomerId },
    });
    yield put(
      showToaster(getToasterOptions('An error has occurred trying to generate the Customer Portal link.Please, try again later', 'error', null, 3000, true)),
    );
  }
}
