import {
  call,
} from 'redux-saga/effects';

import api from '../../services/api';
import logger from '../../services/logger';

export default function* getGoogleAdGroupsByChannelId({
  payload: { marketingChannelId, onSuccess, onError },
}) {
  try {
    const adGroups = yield call(api.getGoogleSearchAdGroups, marketingChannelId);
    if (onSuccess) { onSuccess(adGroups?.adGroups || []); }

    return adGroups?.adGroups || [];
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getGoogleAdGroupsByChannelId' },
      params: { marketingChannelId },
    });
    if (onError) { onError(); }
    return [];
  }
}
