import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import DrawerContent from '@targetable/targetable-web-framework/lib/react/containers/DrawerContent/DrawerContent';
import PageContentContainer from '@targetable/targetable-web-framework/lib/react/containers/PageContentContainer/PageContentContainer';
import ComponentLoading from '@targetable/targetable-web-framework/lib/react/components/ComponentLoading/ComponentLoading';
import DatePicker from '@targetable/targetable-web-framework/lib/react/campaignWizard/components/DatePicker/DatePicker';
import { EVERYTHING, TARGETABLE_PORTAL } from '../../constants';
import {
  getMarketingChannels, getStoreDateReports, setStoreDateReports, getBusinessStores,
} from '../../actions';
import {
  selectMarketingChannels,
  selectMarketingChannelsLoading,
  selectReportsLoading,
  selectStoreDateReports,
  selectBusinessStores,
  selectBusinessStoresLoading,
} from '../../selectors';

import auth from '../../services/auth';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: '100%',
    margin: `${theme.spacing(2)}px auto`,
  },
  backdrop: {
    zIndex: 1400,
  },
  title: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),
  },
  subtitle2: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  labelControl: {
    position: 'relative',
    marginLeft: 0,
    width: '100%',
    cursor: 'default',
  },
  labelContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  labelCaption: {
    marginTop: theme.spacing(1),
    color: '#86899A',
  },
}));

const getFacebookAdAccountId = (mk) => {
  const index = _.findIndex(mk?.integrations, { type: 'facebook' });
  return mk?.integrations?.[index]?.fbAdAccountId;
};

const CampaignReportingToolsDrawer = ({
  onClose,
  businessInfo,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const businessStores = useSelector(selectBusinessStores);
  let marketingChannels = useSelector(selectMarketingChannels);
  // TODO BUGFIX, marketing channel doesn't have integrations
  marketingChannels = _.filter(marketingChannels, (mkCh) => {
    const mkChIntegration = _.find(mkCh?.integrations, { type: 'facebook' });
    return Boolean(mkChIntegration?.fbAdAccountId);
  });
  const storeDateReports = useSelector(selectStoreDateReports);
  const marketingChannelsLoading = useSelector(selectMarketingChannelsLoading);
  const reportsLoading = useSelector(selectReportsLoading);
  const businessStoresLoading = useSelector(selectBusinessStoresLoading);

  const [startDateMoment, setStartDateMoment] = useState(
    moment().startOf('day'),
  );
  const [openDrawer, setOpenDrawer] = useState(false);
  const [origin, setOrigin] = useState(TARGETABLE_PORTAL);
  const [facebookAdAccountIds, setFacebookAdAccountIds] = useState([]);
  const [storesSelected, setStoresSelected] = useState([]);

  const user = auth.getUser();
  const userName = user ? user.name?.replace(' ', '_') : '';

  useEffect(() => {
    if (!openDrawer) {
      setOpenDrawer(true);
    }
  }, [openDrawer]);

  useEffect(() => {
    dispatch(getMarketingChannels(businessInfo?.id));
    dispatch(getBusinessStores(businessInfo?.id));
  }, [dispatch, businessInfo]);

  const elRef = useCallback((node) => {
    if (!_.isEmpty(storeDateReports) && !_.isEmpty(node)) {
      node.link.click();
      dispatch(setStoreDateReports([]));
    }
  }, [storeDateReports, dispatch]);

  const handleExport = useCallback(() => {
    // TODO fix getStoreDateReports, shouldn't get data from DB, use FB instead
    dispatch(getStoreDateReports({
      facebookAdAccountIds,
      origin,
      startDate: startDateMoment.format('YYYY-MM-DD'),
      businessInfo,
      storesSelected: _.filter(businessStores, ({ id }) => _.includes(storesSelected, id)),
    }));
  }, [
    facebookAdAccountIds,
    origin,
    startDateMoment,
    dispatch,
    businessInfo,
    storesSelected,
    businessStores,
  ]);

  const handleStartDateCallback = useCallback((selectedDate) => {
    setStartDateMoment(selectedDate);
  }, []);

  const onChangeRadio = useCallback((event, val) => {
    setOrigin(val);
  }, []);

  const handleChangeCheckbox = useCallback((event) => {
    const value = event?.target?.value;
    if (event?.target?.checked) {
      setFacebookAdAccountIds((previous) => previous.concat(value));
    } else {
      setFacebookAdAccountIds(_.filter(
        facebookAdAccountIds,
        (facebookAdAccountId) => facebookAdAccountId !== value,
      ));
    }
  }, [facebookAdAccountIds]);

  const handleChangeCheckboxStores = useCallback((event) => {
    const value = event?.target?.value;
    if (event.target?.checked) {
      setStoresSelected((previous) => previous.concat(value));
    } else {
      setStoresSelected(_.filter(
        storesSelected,
        (storeSelected) => storeSelected !== value,
      ));
    }
  }, [storesSelected]);

  return (
    <>
      <DrawerContent
        open={openDrawer}
        onClose={onClose}
        title={`${businessInfo?.name} Reporting Tools`}
        buttons={[
          {
            text: 'export',
            classes: classes.buttonRight,
            variant: 'contained',
            color: 'secondary',
            disabled:
              (!marketingChannels?.length && !businessStores?.length)
                || (!facebookAdAccountIds?.length && !storesSelected?.length)
                || reportsLoading
                || marketingChannelsLoading
                || businessStoresLoading,
            onClick: handleExport,
          },
        ]}
        invisibleBackdrop
      >
        <PageContentContainer className={classes.pageContainer}>
          <>
            <ComponentLoading
              loading={marketingChannelsLoading || reportsLoading || businessStoresLoading}
              waitKey="loading_download_csv"
            >
              <CSVLink
                data={storeDateReports || []}
                filename={`targetable_${userName}_${moment().format('MMDDyyyyhhmmss')}.csv`}
                target="_blank"
                ref={elRef}
              />
              <Typography variant="h2" className={classes.title}>{t('dashboard_drawer_campaign_performance')}</Typography>
              <Typography variant="subtitle2" className={classes.subtitle2}>{t('campaign_reporting_tools_date_start')}</Typography>
              <DatePicker
                value={startDateMoment}
                onChange={handleStartDateCallback}
                disabled={false} // TODO
                label=""
                dataCy="schedule_datePicker_campaignStarts_input"
              />
              <Typography variant="subtitle2" className={classes.subtitle2}>{t('campaign_reporting_tools_origin')}</Typography>
              <RadioGroup
                row
                className={classes.radioContainer}
                onChange={onChangeRadio}
                value={origin}
                data-cy="campaign_reporting_tools_radioGroup"
              >
                <FormControlLabel
                  className={classes.radioItem}
                  label={<Typography variant="body1">{t('campaign_reporting_tools_targetable_portal')}</Typography>}
                  value={TARGETABLE_PORTAL}
                  control={<Radio />}
                  data-cy="targetable_portal_radio"
                />
                <FormControlLabel
                  className={classes.radioItem}
                  label={<Typography variant="body1">{t('campaign_reporting_tools_everything')}</Typography>}
                  value={EVERYTHING}
                  control={<Radio />}
                  data-cy="everything_radio"
                />
              </RadioGroup>
              <Typography variant="subtitle2" className={classes.subtitle2}>{t('campaign_reporting_tools_marketing_channels')}</Typography>
              <FormGroup row>
                {!_.isEmpty(marketingChannels) && _.map(marketingChannels, (marketingChannel) => (
                  <FormControlLabel
                    key={marketingChannel?.id}
                    className={classes.labelControl}
                    classes={{ label: classes.labelContainer }}
                    labelPlacement="end"
                    control={(
                      <>
                        <Checkbox
                          checked={_.includes(
                            facebookAdAccountIds, getFacebookAdAccountId(marketingChannel),
                          )}
                          onChange={handleChangeCheckbox}
                          value={getFacebookAdAccountId(marketingChannel)}
                          data-cy="adPlacement_facebook_checkbox"
                        />
                      </>
                    )}
                    label={(
                      <>
                        <Typography variant="body1" display="inline">{marketingChannel?.name}</Typography>
                        <Typography variant="caption" className={classes.labelCaption} display="inline">{getFacebookAdAccountId(marketingChannel)}</Typography>
                      </>
                    )}
                  />
                ))}
                {!_.isEmpty(businessStores) && _.map(businessStores, (businessStore) => (
                  <FormControlLabel
                    key={businessStore?.id}
                    className={classes.labelControl}
                    classes={{ label: classes.labelContainer }}
                    labelPlacement="end"
                    control={(
                      <>
                        <Checkbox
                          checked={_.includes(
                            storesSelected, businessStore?.id,
                          )}
                          onChange={handleChangeCheckboxStores}
                          value={businessStore?.id}
                          data-cy="adPlacement_facebook_checkbox"
                        />
                      </>
                    )}
                    label={(
                      <>
                        <Typography variant="body1" display="inline">{businessStore?.name}</Typography>
                        <Typography variant="caption" className={classes.labelCaption} display="inline">{businessStore?.id}</Typography>
                      </>
                    )}
                  />
                ))}
                {_.isEmpty(marketingChannels)
                  && _.isEmpty(businessStores)
                  && (<Typography>No Marketing Channels Found</Typography>)}
              </FormGroup>
            </ComponentLoading>
          </>
        </PageContentContainer>
      </DrawerContent>
    </>
  );
};

CampaignReportingToolsDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  businessInfo: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
};

export default CampaignReportingToolsDrawer;
