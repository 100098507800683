import {
  put,
} from 'redux-saga/effects';
import {
  setSuggestedCampaignLoading,
  setSuggestedCampaign,
  setBusiness,
  setBusinessStores,
} from '../../actions';
import logger from '../../services/logger';

export default function* initSuggestedCampaignTemplate({ payload: { suggestedCampaign } }) {
  yield put(setSuggestedCampaignLoading(true));

  try {
    yield put(setSuggestedCampaign(suggestedCampaign));
    yield put(setBusiness(null));
    yield put(setBusinessStores([]));
    yield put(setSuggestedCampaignLoading(false));
  } catch (e) {
    yield put(setSuggestedCampaignLoading(false));
    logger.error({
      error: e,
      context: { saga: 'initSuggestedCampaignTemplate' },
      params: { suggestedCampaign },
    });
  }
}
