/* eslint-disable max-len */
import _ from 'lodash';
import {
  validateCampaign, getCampaignStores,
} from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/campaign/campaign';
import { MarketingChannelTypes } from '@targetable/targetable-types/dist/types/marketing-channel/types';
// eslint-disable-next-line no-unused-vars
import { TargetableBusiness } from '@targetable/targetable-types/dist/types/business';

export const selectAdCardPresets = (state) => state.adCardPresets.cards;
export const selectAdCardPreset = (state, id) => _.get(state, ['adCardPresets', 'cards', id], {});

/**
 * @returns {TargetableBusiness}
 */
export const selectBusiness = (state) => state.business.business;

/**
 * @returns {Business[]}
 */
export const selectBusinesses = (state) => state.businesses.businesses;
/**
 * @returns {boolean}
 */
export const selectBusinessesLoading = (state) => state.businesses.businessesLoading;

export const selectSubscription = (state) => state.billing.subscription;
export const selectSubscriptionLoading = (state) => state.billing.subscriptionLoading;

export const selectBusinessIdQueried = (state) => state.business.businessIdQueried;
export const selectBusinessLoading = (state) => state.business.businessLoading;

export const selectBusinessCampaigns = (state) => state.business.campaigns;
export const selectBusinessCampaignsLoading = (state) => state.business.campaignsLoading;

export const selectBusinessInsights = (state) => state.business.insights;
export const selectBusinessInsightsLoading = (state) => state.business.insightsLoading;

export const selectBusinessStores = (state) => state.business.stores;
export const selectBusinessStoresLoading = (state) => state.business.storesLoading;

export const selectBusinessSuggestedCampaigns = (state) => state.business.suggestedCampaigns;
export const selectBusinessSuggestedCampaignsLoading = (state) => state.business.suggestedCampaignsLoading;

export const selectBusinessUsers = (state) => state.business.users;
export const selectBusinessUsersLoading = (state) => state.business.usersLoading;

export const selectUrlPresets = (state) => state.urlPresets.presets;
export const selectUrlPresetsLoading = (state) => state.urlPresets.presetsLoading;

export const selectCustomAudiences = (state) => state.storesCustomAudiences.stores;

export const selectCampaign = (state) => state.campaign.campaign;
export const selectCampaignDisplayDate = (state) => _.get(state, 'campaign.campaign.displayDate');
export const selectCampaignDisplayTime = (state) => _.get(state, 'campaign.campaign.displayTime');
export const selectCampaignExpiryDateTime = (state) => _.get(state, 'campaign.campaign.expiryDate');

export const selectCampaignName = (state) => _.get(state, 'campaign.campaign.name');
export const selectCampaignGoal = (state) => _.get(state, 'campaign.campaign.goal');
export const selectCampaignObjective = (state) => _.get(state, 'campaign.campaign.objective');
export const selectCampaignOverrideAutoAging = (state) => _.get(state, 'campaign.campaign.overrideAutoAging');
export const selectCampaignAdItem = (state) => _.get(state, 'campaign.campaign.ads.0');
export const selectCampaignAd = (state) => _.get(state, 'campaign.campaign.ads.0.ad');
export const selectCampaignAds = (state) => _.get(state, 'campaign.campaign.ads');
export const selectCampaignStatus = (state) => _.get(state, 'campaign.campaign.status');
export const selectLoadedCampaignType = (state) => _.get(state, 'campaign.campaign.type', MarketingChannelTypes.Facebook);
export const selectCampaignAdCards = (state) => _.get(state, 'campaign.campaign.ads.0.ad.adCards');
export const selectCampaignAdPlacement = (state) => _.get(state, 'campaign.campaign.ads.0.ad.adPlacement');
export const selectCampaignAdMainMessage = (state) => _.get(state, 'campaign.campaign.ads.0.ad.mainMessage');
export const selectCampaignAdDisplayLink = (state) => _.get(state, 'campaign.campaign.ads.0.ad.displayLink');
export const selectCampaignRequestInfo = (state) => _.get(state, 'campaign.campaign.requestInfo');
export const selectCampaignAdCTA = (state) => _.get(state, 'campaign.campaign.ads.0.ad.callToAction');

export const selectCampaignBudget = (state) => _.get(state, 'campaign.campaign.ads.0.budget');
export const selectCampaignExisting = (state) => !!_.get(state, 'campaign.campaign.id');
export const selectCampaignCustomAudiences = (state) => _.get(state, 'campaign.campaign.ads.0.targeting.customAudiences');
export const selectCampaignGender = (state) => _.get(state, 'campaign.campaign.ads.0.targeting.gender');
export const selectCampaignMinAge = (state) => _.get(state, 'campaign.campaign.ads.0.targeting.minAge');
export const selectCampaignMaxAge = (state) => _.get(state, 'campaign.campaign.ads.0.targeting.maxAge');
export const selectCampaignSchedule = (state) => _.get(state, 'campaign.campaign.ads.0.schedule');
export const selectCampaignSpecificTargets = (state) => _.get(state, 'campaign.campaign.ads.0.targeting.specificTargets');
export const selectCampaignStoreCount = (state) => _.get(state, 'campaign.campaign.ads', []).length;
export const selectCampaignStores = (state) => getCampaignStores(
  _.get(state, 'business.stores', []),
  _.get(state, 'campaign.campaign.ads', []),
);
export const selectCampaignValid = (state) => validateCampaign(
  state.campaign.campaign,
  selectCampaignStores(state),
);
export const selectCampaignTargeting = (state) => _.get(state, 'campaign.ads.0.targeting');
export const selectCampaignLoading = (state) => state.campaign.campaignLoading;
export const selectCampaignMetrics = (state) => state.facebookMetrics.campaign;
export const selectCampaignTrends = (state) => state.facebookMetrics.trends;
export const selectCampaignMetricsLoading = (state) => state.facebookMetrics.campaignLoading;
export const selectCampaignMetricsLoadingError = (state) => state.facebookMetrics.campaignLoadingError; // eslint-disable-line max-len

export const selectInitialImages = (state) => state.images.initialImages;
export const selectInitialImagesCursor = (state) => state.images.initialCursor;
export const selectIsLoadingAccountUploads = (state) => state.images.loading;
export const selectImagesMam = (state) => state.imagesMam.imageList;
export const selectIsSearchingMam = (state) => state.imagesMam.isSearching;
export const selectSearchTermMam = (state) => state.imagesMam.searchTerm;

export const selectIntegrations = (state) => state.integrations;

export const selectSuggestedCampaign = (state) => state.suggestedCampaign.suggestedCampaign;
export const selectSuggestedCampaignDisplayDate = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.displayDate');
export const selectSuggestedCampaignDisplayTime = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.displayTime');
export const selectSuggestedCampaignExpiryDateTime = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.expiryDate');
export const selectSuggestedCampaignLoading = (state) => state.suggestedCampaign.suggestedCampaignLoading;

export const selectBusinessFirstUser = (state) => _.get(state, 'business.users.0');
export const selectSuggestedCampaignName = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.name');
export const selectSuggestedCampaignGoal = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.goal');
export const selectSuggestedCampaignObjective = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.objective');
export const selectSuggestedCampaignOverrideAutoAging = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.overrideAutoAging');
export const selectSuggestedCampaignAdItem = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.ads.0');
export const selectSuggestedCampaignAd = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.ads.0.ad');
export const selectSuggestedCampaignAds = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.ads');
export const selectSuggestedCampaignStatus = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.status');
export const selectSuggestedCampaignAdCards = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.ads.0.ad.adCards');
export const selectSuggestedCampaignAdPlacement = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.ads.0.ad.adPlacement');
export const selectSuggestedCampaignAdMainMessage = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.ads.0.ad.mainMessage');
export const selectSuggestedCampaignAdDisplayLink = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.ads.0.ad.displayLink');
export const selectSuggestedCampaignAdCTA = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.ads.0.ad.callToAction');

export const selectSuggestedCampaignBudget = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.ads.0.budget');
export const selectSuggestedCampaignExisting = (state) => !!_.get(state, 'suggestedCampaign.suggestedCampaign.id');
export const selectSuggestedCampaignCustomAudiences = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.ads.0.targeting.customAudiences');
export const selectSuggestedCampaignGender = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.ads.0.targeting.gender');
export const selectSuggestedCampaignMinAge = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.ads.0.targeting.minAge');
export const selectSuggestedCampaignMaxAge = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.ads.0.targeting.maxAge');
export const selectSuggestedCampaignSchedule = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.ads.0.schedule');
export const selectSuggestedCampaignSpecificTargets = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.ads.0.targeting.specificTargets');
export const selectSuggestedCampaignStoreCount = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.ads', []).length;
export const selectSuggestedCampaignStores = (state) => getCampaignStores(
  _.get(state, 'business.stores', []),
  _.get(state, 'suggestedCampaign.suggestedCampaign.ads', []),
);
export const selectSuggestedCampaignValid = (state) => validateCampaign(state.campaign, selectSuggestedCampaignStores(state));
export const selectSuggestedCampaignTargeting = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.ads.0.targeting');

export const selectToaster = (state) => state.toaster;
export const selectRouterLocationState = (state) => state.router.location.state || {};
export const selectRouterLocationPath = (state) => state.router.location.pathname || {};

export const selectConfirmationDialogInformation = (state) => state.confirmationDialog;
export const selectActivityNoteDialogInformation = (state) => state.activityLogNote;
export const selectPublishMarketingDialogInformation = (state) => state.publishMarketingDialog;
export const selectAsyncLoading = (state) => state.loading.async;

export const selectReportsLoading = (state) => state.reports.loading;
export const selectCampaignReport = (state, id) => state.reports.campaignReports[id];
export const selectCampaignReports = (state) => state.reports.campaignReports;
export const selectStoreDateReports = (state) => state.reports.storeDateReports;
export const selectBusinessPixels = (state) => state.business.pixels;
export const selectBusinessPixelsLoading = (state) => state.business.pixelsLoading;

export const selectPixelEventType = (state) => _.get(state, 'campaign.campaign.pixelEventType');
export const selectCampaignOptimizationGoal = (state) => _.get(state, 'campaign.campaign.optimizationGoal');
export const selectSuggestedCampaignPixelEventType = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.pixelEventType');
export const selectSuggestedCampaigOptimizationGoal = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.optimizationGoal');

export const selectCampaignAdType = (state) => _.get(state, 'campaign.campaign.ads.0.ad.type');
export const selectSuggestedCampaignAdType = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.ads.0.ad.type');
export const selectCampaignEventId = (state) => _.get(state, 'campaign.campaign.eventId');
export const selectCampaignLeadForms = (state) => _.get(state, 'business.leadForms');
export const selectSuggestedCampaignEventId = (state) => _.get(state, 'suggestedCampaign.suggestedCampaign.eventId');

export const selectManagedLandingPages = (state) => state.business.business.managedLandingPages;

export const selectTokenPermissions = (state) => state.business.permissions;
export const selectUsersPermissionsLoading = (state) => state.business.permissionsLoading;

export const selectStripeProducts = (state) => state.stripe.products;
export const selectStripeProductsLoading = (state) => state.stripe.productsLoading;

export const selectShowAddStoreModal = (state) => state.modals.showAddStore;

export const selectMarketingChannelsLoading = (state) => state.marketingChannels.marketingChannelsLoading;
export const selectMarketingChannels = (state) => state.marketingChannels.marketingChannels;

export const selectGoogleMetrics = (state) => state.googleMetrics.adMetrics;
export const selectGoogleMetricsLoading = (state) => state.googleMetrics.adMetricsLoading;
export const selectGoogleMetricsLoadingError = (state) => state.googleMetrics.adMetricsLoadingError; // eslint-disable-line max-len
export const selectCampaignType = (state) => _.get(state, 'campaign.type', MarketingChannelTypes.Facebook);

export const selectGoogleSitelinks = (marketingChannelId) => (state) => _.get(state, ['googleAssets', 'sitelinks', marketingChannelId, 'data'], {});
export const selectGoogleSitelinksLoading = (marketingChannelId) => (state) => _.get(state, ['googleAssets', 'sitelinks', marketingChannelId, 'loading'], {});
export const selectGooglePromotions = (marketingChannelId) => (state) => _.get(state, ['googleAssets', 'promotions', marketingChannelId, 'data'], {});
export const selectGooglePromotionsLoading = (marketingChannelId) => (state) => _.get(state, ['googleAssets', 'promotions', marketingChannelId, 'loading'], {});
export const selectGoogleAssets = (state) => _.get(state, 'googleAssets', {});

export const selectSearchAdCombinations = (state) => state.searchAdCombinations.adCombinations;
export const selectSearchAdCombinationsLoading = (state) => state.searchAdCombinations.adCombinationsLoading;
export const selectSearchAdCombinationsLoadingError = (state) => state.searchAdCombinations.adCombinationsLoadingError; // eslint-disable-line max-len

/**
 * Selects the user invoice history
 * @returns {import ('@targetable/targetable-web-framework/src/services/apis/billing/typedefs.js').StripeInvoice[]} a list of invoices
 */
export const selectInvoiceHistory = (state) => state.billing.invoiceHistory.invoices;

/**
 * @returns {boolean}
 */
export const selectInvoiceHistoryLoading = (state) => state.billing.invoiceHistory.loading;

export const selectFeedback = (state) => state.feedback;

export const selectCoordinates = (state) => state.locations.coordinates;
export const selectLoadingCoordinates = (state) => state.locations.loadingCoordinates;

export const selectComments = (state) => state.comments.comments;
export const selectLoadingComments = (state) => state.comments.loadingComments;
