import { call, put } from 'redux-saga/effects';
import api from '../../services/api';
import {
  showToaster,
  setLoadingAsync,
} from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import getMarketingChannels from '../getMarketingChannels/getMarketingChannels';

export default function* deleteMarketingChannel(action) {
  const marketingChannel = action.payload;
  try {
    yield put(setLoadingAsync(true));
    yield call(api.deleteMarketingChannel, marketingChannel.id);
    yield put(setLoadingAsync(false));

    yield call(getMarketingChannels, { payload: marketingChannel.businessId });
    yield put(showToaster(getToasterOptions('Marketing Channel removed successfully', 'success', null, 5000)));
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'deleteMarketingChannel' },
      params: {
        marketingChannel,
      },
    });
    yield put(showToaster(getToasterOptions('Error removing marketing channel')));
  }
}
