import _ from 'lodash';
import {
  put, call, select, take,
} from 'redux-saga/effects';

import api from '../../services/api';

import {
  setBusinessSuggestedCampaigns,
  setBusinessSuggestedCampaignsLoading,
} from '../../actions';
import { selectBusiness, selectBusinessStores } from '../../selectors';
import { DATA_BUSINESS, DATA_BUSINESS_STORES } from '../../constants';
import logger from '../../services/logger';

// { payload: businessId }
export default function* getSuggestedCampaignsByBusiness({ payload }) {
  const {
    expired,
    status,
    businessId,
    currentDateTime,
  } = payload;

  yield put(setBusinessSuggestedCampaignsLoading(true));

  const business = yield select(selectBusiness);
  if (!business) {
    yield take(DATA_BUSINESS);
  }

  let stores = yield select(selectBusinessStores);
  if (_.isEmpty(stores)) {
    yield take(DATA_BUSINESS_STORES);
    stores = yield select(selectBusinessStores);
  }

  try {
    const suggestedCampaigns = yield call(
      api.getSuggestedCampaigns,
      businessId, stores, status || null,
      true, expired,
      // TGT-5316: forcing ALL insights to be returned to handle custom expired logic
      currentDateTime || '1970-01-01',
    );
    const sortedCampaigns = _.sortBy(suggestedCampaigns, ['created']).reverse();
    yield put(setBusinessSuggestedCampaigns(sortedCampaigns));
    yield put(setBusinessSuggestedCampaignsLoading(false));
  } catch (e) {
    yield put(setBusinessSuggestedCampaignsLoading(false));
    logger.error({
      error: e,
      context: { saga: 'getSuggestedCampaignsByBusiness' },
      params: { businessId },
    });
  }
}
