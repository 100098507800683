import { put, call } from 'redux-saga/effects';

import api from '../../services/api';

import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

/**
 * @param {object} params
 * @param {object} params.payload
 * @param {object} params.payload.questionnaire
 * @param {boolean} params.payload.questionnaire.active
 * @param {string} params.payload.questionnaire.title
 * @param {object[]} params.payload.questionnaire.questions
 * @param {string} params.payload.questionnaire.type
 * @param {function} params.payload.onSuccess
 * @param {function} params.payload.onError
 */
export default function* createQuestionnaire({ payload }) {
  const { onSuccess, onError, questionnaire } = payload;

  try {
    const createdItem = yield call(api.createQuestionnaire, questionnaire);

    if (onSuccess) onSuccess(createdItem);
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to create the Questionnaire. Try again in a few minutes.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'createQuestionnaire' },
      params: { payload },
    });

    if (onError) {
      onError(e);
    }
  }
}
