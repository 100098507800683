import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import _ from 'lodash';

import adCardPresetsReducers from './adCardPresets';
import businessReducers from './business';
import businessesReducers from './businesses';
import billingReducers from './billing';
import campaignReducers from './campaign';
import confirmationDialogReducers from './confirmationDialog';
import facebookMetricsReducers from './facebookMetrics';
import imagesReducers from './images';
import imagesMamReducers from './imagesMam';
import integrationsReducers from './integrations';
import loadingReducers from './loading';
import reportsReducers from './reports';
import storesCustomAudiencesReducers from './storesCustomAudiences';
import suggestedCampaign from './suggestedCampaign';
import toasterReducers from './toaster';
import urlPresetsReducers from './urlPresets';
import stripeReducers from './stripe';
import modalsReducers from './modals';
import marketingChannelReducers from './marketingChannel';
import googleMetrics from './googleMetrics';
import searchAdCombinations from './searchAdCombinations';
import activityLogNote from './activityLogNote';
import publishMarketingDialog from './publishMarketingDialog';
import feedbackReducers from './feedback';
import locationsReducers from './locations';
import comments from './comments';
import googleAssets from './googleAssets';

// From redux
// The reducers may never return undefined for any action. Instead, they should return their
// initial state if the state passed to them was undefined, and the current
// state for any unrecognized action.
export const createReducer = ({ defaultState, reducers }) => (state, action) => {
  if (_.isUndefined(state)) {
    return defaultState;
  }

  const reducer = reducers[action.type];
  return _.isNil(reducer) ? state : reducer(state, action);
};

// https://github.com/supasate/connected-react-router#step-1
export default (history) => combineReducers({
  activityLogNote: createReducer(activityLogNote),
  adCardPresets: createReducer(adCardPresetsReducers),
  billing: createReducer(billingReducers),
  business: createReducer(businessReducers),
  businesses: createReducer(businessesReducers),
  campaign: createReducer(campaignReducers),
  confirmationDialog: createReducer(confirmationDialogReducers),
  facebookMetrics: createReducer(facebookMetricsReducers),
  googleMetrics: createReducer(googleMetrics),
  googleAssets: createReducer(googleAssets),
  images: createReducer(imagesReducers),
  imagesMam: createReducer(imagesMamReducers),
  integrations: createReducer(integrationsReducers),
  loading: createReducer(loadingReducers),
  marketingChannels: createReducer(marketingChannelReducers),
  modals: createReducer(modalsReducers),
  reports: createReducer(reportsReducers),
  router: connectRouter(history),
  searchAdCombinations: createReducer(searchAdCombinations),
  storesCustomAudiences: createReducer(storesCustomAudiencesReducers),
  stripe: createReducer(stripeReducers),
  suggestedCampaign: createReducer(suggestedCampaign),
  toaster: createReducer(toasterReducers),
  urlPresets: createReducer(urlPresetsReducers),
  publishMarketingDialog: createReducer(publishMarketingDialog),
  feedback: createReducer(feedbackReducers),
  locations: createReducer(locationsReducers),
  comments: createReducer(comments),
});
