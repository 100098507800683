import { put, call } from 'redux-saga/effects';
import i18n from '../../services/i18n';

import api from '../../services/api';
import {
  showToaster,
  setLoadingAsync,
} from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import getBusiness from '../getBusiness/getBusiness';

export default function* saveEClincherUrl({
  payload: {
    businessId, eClincherUrl, onDone,
  },
}) {
  try {
    yield put(setLoadingAsync(true));
    yield call(api.updateBusiness, businessId, { eClincherUrl });
    yield put(setLoadingAsync(false));
    yield call(getBusiness, { payload: businessId });
    yield put(showToaster(getToasterOptions(i18n.t('update_eClincherUrl'), 'success', null, 5000)));
    onDone();
  } catch (e) {
    yield put(showToaster(getToasterOptions(i18n.t('update_eClincherUrl_error'), 'error')));
    logger.error({
      error: e,
      context: { saga: 'saveEClincherUrl' },
      params: { eClincherUrl },
    });
  }
}
