/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import LoggingErrorBoundary from '@targetable/logging-react-error-boundary/lib/LoggingErrorBoundary';
import { init as initEnvVars } from '@targetable/targetable-web-framework/lib/services/environment';
import globals from './globals';
import { init } from './services/i18n';
import logger from './services/logger';

import ErrorPage from './pages/ErrorPage/ErrorPage';

import './static/css/App.scss';

logger.log('App Initializing');

const render = () => {
  // eslint-disable-next-line global-require
  const App = require('./components/App/App').default;
  ReactDOM.render(
    <LoggingErrorBoundary logger={logger} fallback={ErrorPage}>
      <App />
    </LoggingErrorBoundary>,
    document.getElementById('root'),
  );
};

initEnvVars(globals);

init('en-US')
  .then(() => {
    render();
    if (module.hot) {
      module.hot.accept('./components/App/App', () => {
        setTimeout(render);
      });
    }
  })
  .catch((err) => {
    logger.error({ error: err, params: { info: 'error getting lokalise file' } });
    ReactDOM.render(<ErrorPage error={err} />, document.getElementById('root'));
  });
