import {
  call, put, all,
} from 'redux-saga/effects';
import api from '../../services/api';
import { showToaster, setStoreStatus } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import createActivityLog from '../createActivityLog/createActivityLog';
import auth from '../../services/auth';
import getStripeSubscription from '../getStripeSubscription/getStripeSubscription';

/**
 * Enable/Disable a specified Store
 * @param {Object} store The store data
 * @param {Function} onCompleted a function to invoke once the saga has completed successfully.
 * @param {Function} onError a function to invoke if the saga has not completed successfully.
 */
export default function* enableDisableStore({ payload }) {
  const {
    onCompleted, onError, store,
  } = payload;
  const stores = payload?.stores;
  const action = payload?.action;

  let toastText = (stores && action === 'activate') ? 'Activating the selected stores' : 'Deactivating the selected stores';

  if (store) {
    toastText = store.disabled ? 'Activating the store...' : 'Deactivating the store...';
  }

  yield put(showToaster(getToasterOptions(
    toastText,
    'info', null, 7000, true, null, 'bottom', 'right',
  )));

  const user = auth.getUser();
  try {
    if (stores && Array.isArray(stores)) {
      const operations = stores.map((st) => call(api.enableDisableStore, st?.id));
      yield all(operations);

      const operationsActivityLog = stores.map((st) => call(
        createActivityLog, {
          businessId: st.businessId,
          userId: user?.sub,
          type: 'store_status',
          entityId: st.id,
          meta: {
            action: !st.disabled ? 'DISABLE' : 'ENABLE',
            subscriptionItem: st?.subscriptionItem,
            store: {
              id: st.id,
              name: st.name,
              integrations: st.integrations,
              disabled: !st.disabled,
            },
          },
        },
      ));

      yield all(operationsActivityLog);

      yield put(showToaster(getToasterOptions(
        `You've successfully ${action}d selected stores.`,
        'success', null, 3000,
      )));

      if (onCompleted) { onCompleted(); }
    } else {
      // this updates the stripe quantity in the backend.
      const { result } = yield call(api.enableDisableStore, store.id);
      const { data } = result;

      if (data.success) {
        yield put(showToaster(getToasterOptions(
          `You've successfully ${data?.store.disabled ? 'deactivated' : 'activated'} ${store.name}.`,
          'success', null, 3000,
        )));
      } else {
        yield put(showToaster(getToasterOptions(
          `The Store: '${store.name}' was not ${data?.store.disabled ? 'deactivated' : 'activated'} due to an internal error. Please, try again later`,
          'error', null, 3000,
        )));
      }

      yield call(createActivityLog, {
        businessId: store.businessId,
        userId: user?.sub,
        type: 'store_status',
        entityId: store.id,
        meta: {
          action: data?.store.disabled ? 'DISABLE' : 'ENABLE',
          subscriptionItem: data?.subscriptionItem,
          store: {
            id: store.id,
            name: store.name,
            integrations: store.integrations,
            disabled: data?.store.disabled,
          },
        },
      });

      // reload store's status info
      yield put(setStoreStatus({ storeId: store.id, disabled: data?.store.disabled }));

      // load subscriptions with updated quantities
      yield call(getStripeSubscription);

      if (onCompleted) { onCompleted(); }
    }
  } catch (ex) {
    logger.error({
      error: ex,
      context: { saga: 'enableDisableStoreSaga' },
      params: { store },
    });
    yield put(showToaster(getToasterOptions('error_update_store')));
    if (onError) { onError(); }
  }
}
