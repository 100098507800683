import _ from 'lodash';
import { put, call } from 'redux-saga/effects';
import i18n from '../../services/i18n';
import api from '../../services/api';
import { setBusinessStores, showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* updateStoreName({
  payload: {
    storeId, businessId, name, onDone, exists,
  },
}) {
  try {
    if (exists) {
      throw new Error('store_name_exists');
    } else {
      yield call(
        api.updateStore,
        storeId,
        {
          name,
        },
      );
      let stores = yield call(api.getStores, businessId);
      stores = _.sortBy(stores, 'name');
      yield put(setBusinessStores(stores));
      yield put(showToaster(getToasterOptions(i18n.t('update_store_name', 'Store name was updated successfully!'), 'success', null, 5000)));
      onDone();
    }
  } catch (e) {
    if (e && e.message === 'store_name_exists') {
      yield put(showToaster(getToasterOptions(i18n.t('update_store_name_already_exists', `Store name already exists - storeId: ${storeId}`), 'error')));
    } else {
      yield put(showToaster(getToasterOptions(i18n.t('update_store_name_error', 'An error occurred updating store name'), 'error')));
    }
    logger.error({
      error: e,
      context: { saga: 'updateStore' },
      params: {
        storeId,
        businessId,
        name,
      },
    });
  }
}
