import { put, select } from 'redux-saga/effects';

import { selectCampaign } from '../../selectors';
import { getToasterOptions } from '../helpers';
import { setCampaign, showToaster as showToasterAction } from '../../actions';
import history from '../../services/history';
import { getCampaignDetailRoute } from '../../routes';
import submitCampaign from '../submitCampaign/submitCampaign';
import loadingAsync from '../loadingAsync/loadingAsync';
import { CAMPAIGN_STATUS_SUBMITTED } from '../../constants';

export default function* submitCampaignForm({ payload }) {
  const {
    onDone,
    onError,
    campaignObj,
    userId,
    note,
    fromSuggestedCampaign,
  } = payload;

  let campaign = campaignObj || (yield select(selectCampaign));

  if (!campaign?.userId && userId) {
    campaign = { ...campaign, userId };
  }

  const { submittedCampaign, error } = yield loadingAsync(
    submitCampaign,
    campaign,
    onError,
    null,
    fromSuggestedCampaign,
    note,
  );

  if (error) {
    if (['canceled'].includes(error)) {
      if (onDone) {
        onDone();
      }
      return;
    }

    if (!['invalid_preset', 'invalid_url'].includes(error)) {
      yield put(showToasterAction(getToasterOptions('error_publish_campaign', 'error')));
    }

    if (onError) {
      onError(['invalid_preset', 'invalid_url'].includes(error) ? 'url' : null);
    }

    if (onError) {
      onError(['display_url'].includes(error) ? 'display_url' : null);
    }

    return;
  }

  yield put(setCampaign({
    ...submittedCampaign,
    status: CAMPAIGN_STATUS_SUBMITTED,
  }));
  history.push(getCampaignDetailRoute(submittedCampaign.businessId, submittedCampaign.id));

  if (onDone) {
    onDone();
  }
}
