import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import { getFullName } from '@targetable/targetable-web-framework/lib/utils/user';
import { showToaster } from '../../actions';
import api from '../../services/api';
import i18n from '../../services/i18n';
import auth from '../../services/auth';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { selectBusiness } from '../../selectors';
import getUsersByBusiness from '../getUsersByBusiness/getUsersByBusiness';

export default function* reinviteUser({ payload: invite }) {
  const currentBusiness = yield select(selectBusiness);
  const { user, onDone } = invite;
  const name = getFullName(user);
  const email = _.get(user, 'email');

  try {
    yield call(api.reinviteUser, currentBusiness?.id, user.id);
    yield call(api.createActivityLog, {
      businessId: currentBusiness.id,
      type: 'user_logs_pii_reinvite_user',
      meta: {
        timestamp: new Date().valueOf(),
        userId: auth.getUserId(),
        viewedUrl: window.location.href,
        invitedUser: user.id,
      },
    });
    yield put(showToaster(getToasterOptions(i18n.t('user_reinvited_successfully', { name, email }), 'info', null, 5000)));

    // Update user with the new inviteCode
    yield call(getUsersByBusiness, { payload: currentBusiness.id });
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'reinviteUser' },
      params: {
        userId: auth.getUserId(),
        businessId: currentBusiness.id,
        user,
        name,
        email,
      },
    });
    yield put(showToaster(getToasterOptions(i18n.t('error_reinvite_user', { name, email }))));
  }
  if (onDone) {
    onDone();
  }
}
