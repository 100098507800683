import _ from 'lodash';
import { call, put } from 'redux-saga/effects';

import api from '../../services/api';
import { setAdImageUi } from '../../actions';
import logger from '../../services/logger';

export default function* adImageUi({ payload }) {
  try {
    const width = _.isNil(payload.width) ? '' : payload.width;
    const image = yield call(api.getImage, payload.publicId, width);
    yield put(setAdImageUi(image));
    return image;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'adImageUi' },
      params: { payload },
    });
    return undefined;
  }
}
