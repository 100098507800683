import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  TextareaAutosize,
  IconButton,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import SendIcon from '@material-ui/icons/Send';
import FilterNoneOutlinedIcon from '@material-ui/icons/FilterNoneOutlined';
import { makeStyles } from '@material-ui/core/styles';

import DrawerContent from '@targetable/targetable-web-framework/lib/react/containers/DrawerContent/DrawerContent';
import PageContentContainer from '@targetable/targetable-web-framework/lib/react/containers/PageContentContainer/PageContentContainer';
import ComponentLoading from '@targetable/targetable-web-framework/lib/react/components/ComponentLoading/ComponentLoading';
import { clearConversation, sendMessage } from '@targetable/targetable-web-framework/lib/react/components/OpenAIMenu/openAiApi';

import loadingImage from '../../static/images/component_loading_animation.gif';

import { selectBusinessLoading, selectBusiness } from '../../selectors';

import TargetableIcon from '../Images/TargetableIcon/TargetableIcon';

import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: '100%',
    margin: `${theme.spacing(2)}px auto`,
    '& .MuiCardActionArea-root:hover': {
      backgroundColor: '#E4E6EC',
    },
  },
  titlesWrapper: {
    margin: '10px 0',
    textAlign: 'left',
  },
  title: {
    color: '#0E1436',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
  },
  subtitle: {
    color: '#6E7286',
    fontSize: 10,
    fontStyle: 'normal',
    fontWeight: 400,
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    background: '#FFF9F0',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 10,
    color: '#3E435E',
    borderRadius: 4,
    padding: '5px 15px',
    margin: '10px 0 0 0',
    '& svg': {
      color: '#FF9800',
    },
    '& p': {
      marginLeft: 15,
    },
  },
  inputWrapper: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    marginTop: 10,
    padding: 3,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    display: 'flex',
  },
  inputText: {
    width: '100%',
    padding: theme.spacing(1),
    border: 'none',
    resize: 'none',
    maxHeight: 400,
    overflowY: 'scroll !important',
    '&:focus': {
      outline: 'none',
    },
  },
  btnWrapper: {
    position: 'absolute',
    right: 8,
    bottom: 8,
    background: '#CFD0D7',
    padding: 6,
    borderRadius: 4,
    '&:hover': {
      background: '#CFD0D7',
    },
  },
  sendBtn: {
    fontSize: 20,
    color: '#FFFFFF',
  },
  messagesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    position: 'relative',
    marginBottom: 56,
    overflow: 'hidden',
    '& textarea': {
      border: 'none',
      resize: 'none',
    },
  },
  warning: {
    color: '#3E435E',
    fontFamily: 'Montserrat',
    fontSize: 10,
    fontStyle: 'normal',
    fontWeight: 400,
    '& a': {
      color: '#007BFF',
      textDecorationLine: 'underline',
    },
  },
  output: {
    maxHeight: 'calc(100% - 82px)',
    flexGrow: 1,
    minHeight: 0,
    padding: 1,
  },
  copyButton: {
    borderRadius: 4,
    marginLeft: -3,
    padding: 6,
    marginTop: 10,
    background: '#ECEDEF',
    transform: 'rotate(90deg)',
    '& svg': {
      fill: '#6E7286',
      fontSize: 20,
    },
  },
  message: {
    borderRadius: 4,
    backgroundColor: '#f5f6f7',
    padding: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 15,
  },
  messageText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: '#0E1436',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    whiteSpace: 'pre-line',
  },
  scrollingOutput: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  avatarContainer: {
    marginTop: 0,
    marginRight: theme.spacing(2),
  },
  userAvatar: {
    width: 24,
    height: 24,
    fontSize: 20.5,
    backgroundColor: '#9E040D',
  },
  chatAvatar: {
    marginTop: 0,
    marginLeft: theme.spacing(2),
    width: 24,
    height: 24,
  },
}));

const ReviewResponseChatGPTDialog = ({
  onClose,
  open,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const businessLoading = useSelector(selectBusinessLoading) || false;
  const businessInfo = useSelector(selectBusiness);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  /**
  * @type {React.MutableRefObject<HTMLTextAreaElement>}
  */
  const inputTextRef = useRef(null);
  /**
  * @type {[Message[], (messages: Message[]) => void]}
  */
  const [messages, setMessages] = useState([]);

  const defaultContext = `${t('review_response_default_context')} ${t('review_response_additional_default_context')}`;
  let context = businessInfo?.reviewResponse?.context || defaultContext;
  context += ' Your job is to add one space after header response and another space before footer response';
  const header = businessInfo?.reviewResponse?.header || '';
  const footer = businessInfo?.reviewResponse?.footer || '';

  useEffect(() => {
    if (!openDrawer) {
      setOpenDrawer(true);
      clearConversation();
      setMessages([]);
    }
  }, [openDrawer]);

  const getDialogTitle = () => {
    let title = `${businessInfo.name.trim()} / Review Response / ChatGPT`;
    const long = 30;
    if (businessInfo.name?.length > long) {
      title = `${businessInfo.name} / Review... / ChatGPT`;
    }
    if (businessInfo.name?.length > (long + 10)) {
      title = `${businessInfo.name.substring(0, long)?.trim()}... / Review... / ChatGPT`;
    }
    return title;
  };

  const buildChatGPTInput = useCallback((customerReview) => `Context: ${context}\nHeader: ${header}\nFooter: ${footer}\nTask: Write a response to a customer review\nCustomer Review: ${customerReview}\r\n`, [context, footer, header]);

  const handleCopyOutput = useCallback((event) => {
    const { messageIndex } = event.currentTarget.dataset;
    const message = messages.filter((m) => m.isResponse)[messageIndex];
    navigator.clipboard.writeText(message.text);
  }, [messages]);

  const handleSubmit = useCallback(async () => {
    try {
      const inputText = inputTextRef.current.value.trim();
      if (!inputText) {
        return;
      }

      inputTextRef.current.value = '';

      setRequestInProgress(true);
      const response = await sendMessage(api, buildChatGPTInput(inputText));
      setMessages((current) => [
        ...current,
        {
          text: response,
          isResponse: true,
          timestamp: new Date().toISOString(),
        },
      ]);
    } catch (e) {
      setMessages((current) => [
        ...current,
        {
          text: 'Sorry, I couldn\'t process that. Please try again.',
          isResponse: true,
          timestamp: new Date().toISOString(),
        },
      ]);
    }

    setRequestInProgress(false);
  }, [buildChatGPTInput]);

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey && !event.metaKey) {
      event.preventDefault();
      handleSubmit();
    }
  }, [handleSubmit]);

  return (
    <>
      <DrawerContent
        open={open ?? openDrawer}
        onClose={onClose}
        title={getDialogTitle()}
        tooltipTitle={`${businessInfo.name.trim()} / Review Response / ChatGPT`}
        invisibleBackdrop
      >
        <PageContentContainer className={classes.pageContainer}>
          <>
            <ComponentLoading
              loading={businessLoading}
              waitKey="loading_business"
            >
              <Grid
                container
                spacing={2}
                justifyContent="center"
                data-cy="ReviewResponseChatGPTDialog_container"
              >
                <Grid xs={12} className={classes.titlesWrapper}>
                  <Typography variant="h3" className={classes.title}>
                    {t('open-ai-title')}
                    : Review Response
                  </Typography>
                  <Typography variant="h3" className={classes.subtitle}>
                    {t('open-ai-subtitle')}
                  </Typography>
                </Grid>
                <Grid className={classes.headerBox}>
                  <ErrorIcon />
                  <p>{t('review_response_header_box')}</p>
                </Grid>
                <Grid className={classes.inputWrapper}>
                  <TextareaAutosize
                    aria-label="user prompt"
                    minRows={10}
                    data-gramm="false"
                    className={classes.inputText}
                    placeholder="Copy and paste review here..."
                    ref={inputTextRef}
                    disabled={requestInProgress}
                    onKeyDown={handleKeyPress}
                  />
                  <IconButton
                    onClick={handleSubmit}
                    color="primary"
                    aria-label="send prompt"
                    component="span"
                    size="small"
                    disabled={requestInProgress}
                    anima
                    className={classes.btnWrapper}
                  >
                    <SendIcon className={classes.sendBtn} />
                  </IconButton>
                </Grid>
                <Grid className={classes.messagesWrapper}>
                  {requestInProgress && (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <img src={loadingImage} alt="Loading" />
                    </div>
                  )}
                  {messages.sort(
                    (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
                  ).filter((m) => m.isResponse).map((message, index) => (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${message.timestamp}-${index}`}
                      className={classes.message}
                    >
                      <div className={classes.avatarContainer}>
                        <TargetableIcon size={24} />
                      </div>
                      <Typography variant="body2">
                        <span className={classes.messageText}>
                          <>
                            {message.text}
                            <IconButton
                              className={classes.copyButton}
                              data-message-index={index}
                              onClick={handleCopyOutput}
                            >
                              <FilterNoneOutlinedIcon />
                            </IconButton>
                          </>
                        </span>
                      </Typography>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </ComponentLoading>
          </>
        </PageContentContainer>
      </DrawerContent>
    </>
  );
};

ReviewResponseChatGPTDialog.defaultProps = {
  open: false,
};

ReviewResponseChatGPTDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default ReviewResponseChatGPTDialog;
