import {
  call, put, select, take,
} from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment';
import { getDurationFromSchedule } from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/budgetSchedule/budgetSchedule';
import { isCallNowAndWithoutPhone } from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/campaign/campaign';

import i18n from '../../services/i18n';
import api from '../../services/api';
import history from '../../services/history';
import date from '../../services/date';
import { setConfirmationDialog, setLoadingAsync, showToaster } from '../../actions';
import { getSuggestedDraftRoute } from '../../routes';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { selectBusinessIdQueried, selectBusinessStores } from '../../selectors';
import { RESULT_CONFIRMATION_DIALOG } from '../../constants';

function buildRemovedTargetList(removedTargets) {
  let list = '';
  removedTargets.forEach((target) => {
    if (target?.value) {
      list += `<br>- ${target.value}`;
    }
  });
  return list;
}

export default function* duplicateSuggestedCampaignSaga({ payload }) {
  try {
    const { suggestedCampaign, onDone, copyMarketingRequests } = payload;
    let suggestedCampaignToClone = suggestedCampaign;
    yield put(showToaster(getToasterOptions('Creating a copy of the Suggested Campaign...',
      'info', null, 15000, true, null, 'bottom', 'right')));

    const stores = yield select(selectBusinessStores);

    // Algolia's object returns the 'isOptimized' property
    if (suggestedCampaign?.isOptimized) {
      /*
      * We need to fetch the actual suggested campaign from DDB, becuse
      * Algolia's object is a partial one
      */
      suggestedCampaignToClone = yield call(
        api.getSuggestedCampaign, suggestedCampaign.id, stores, true,
      );
    }
    const name = `${_.get(suggestedCampaignToClone, 'name')} - Copy`;
    const goal = _.get(suggestedCampaignToClone, 'goal');
    const regulatedCategories = (goal === 'hiring') ? 'EMPLOYMENT' : 'NONE';

    const specificTargets = _.get(suggestedCampaignToClone, ['ads', '0', 'targeting', 'specificTargets']);
    const validatedTargets = yield call(api.validateTargets, specificTargets, regulatedCategories);
    const originalAds = suggestedCampaignToClone?.ads;

    if (originalAds) {
      // Just copy active stores, and if cta is CALL_NOW copy the ones with a phone number
      suggestedCampaignToClone.ads = _.filter(originalAds, (ad) => {
        const { storeId } = ad;
        const store = _.find(stores, { id: storeId });
        if (isCallNowAndWithoutPhone(store, _.get(ad, 'ad.callToAction'))) return false;
        return (!store || !store.disabled);
      });

      // When all the stores were disabled
      if (suggestedCampaignToClone.ads?.length === 0) {
        suggestedCampaignToClone.ads = _.map(originalAds, (adItem) => ({
          ...adItem,
          targeting: {
            ...adItem.targeting,
            cities: [],
            countries: [],
            zips: [],
            regions: [],
            geoMarkets: [],
            radius: 5,
          },
          storeId: null,
        }));
      }
    }

    const fieldsToOmit = ['id', 'status', 'created', 'updated', 'feedback', 'published', 'responded', 'saved', 'publishedAsPaused', 'questionnaireId', 'businessName'];
    if (!copyMarketingRequests) {
      fieldsToOmit.push('requestInfo');
    }
    const duplicateCampaign = {
      ..._.omit(suggestedCampaignToClone, fieldsToOmit),
      created: moment.utc().valueOf(),
      saved: moment.utc().valueOf(),
      status: 'draft',
      name,
      displayDate: date.startOfToday(),
      displayTime: { hour: 0, minute: 0 },
      ads: _.map(suggestedCampaignToClone.ads, (adItem) => ({
        ...adItem,
        targeting: {
          ...adItem.targeting,
          specificTargets: validatedTargets,
        },
        schedule: {
          ...adItem.schedule,
          startDate: date.startOfToday(),
          ...(adItem.schedule.endDate && {
            endDate: date.addDaysFromNow(getDurationFromSchedule(adItem.schedule) - 1),
          }),
        },
      })),
    };

    const businessId = yield select(selectBusinessIdQueried);
    const createdSuggestedCampaign = yield call(api.createSuggestedCampaign, duplicateCampaign);
    // Unnecessary call because getting from algolia
    // yield fork(getSuggestedCampaignsByBusiness, { payload: { businessId } });
    history.push(getSuggestedDraftRoute(businessId, _.get(createdSuggestedCampaign, 'id')));
    if (onDone) {
      onDone();
    }

    const removedTargets = specificTargets.filter(
      (currentTarget) => !validatedTargets?.some((target) => currentTarget.id === target.id),
    ) || [];

    if (removedTargets.length) {
      yield put(setLoadingAsync(false));
      yield put(setConfirmationDialog({
        isOpen: true,
        cancelText: 'ok',
        cancelDataCy: 'duplicateSuggestedCampaign_ok_button',
        confirmText: '',
        text: 'removed_targeting_list',
        title: 'Removed keywords',
        textParams: { list: buildRemovedTargetList(removedTargets) },
      }));
      yield take(RESULT_CONFIRMATION_DIALOG);
      yield put(setConfirmationDialog({ isOpen: false }));
    }

    yield put(showToaster(getToasterOptions(i18n.t('campaigns_duplicate_created', { name }), 'success', null, 5000)));
  } catch (e) {
    yield put(showToaster(getToasterOptions('error_update_campaign')));
    logger.error({
      error: e,
      context: { saga: 'duplicateSuggestedCampaignSaga' },
      params: { payload },
    });
  }
}
