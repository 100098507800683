import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Typography } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';

import auth from '../../services/auth';
import history from '../../services/history';
import ClickableListItem from '../ClickableListItem/ClickableListItem';
import {
  getBusinessesRoute,
  // getDashboardRoute,
  getInsightTemplatesRoute,
  getSCTemplatesRoute,
  getStripeProductsRoute,
  getUsersRoute,
  getCampaignsRoute,
  getQuestionnairesRoute,
  getRichProfileFieldsRoute,
  getMarketingRequestsRoute,
  getMarketingEventsRoute,
} from '../../routes';

const styles = (theme) => ({
  avatar: {
    marginBottom: theme.spacing(2),
  },
  contrast_text: {
    color: theme.palette.primary.contrastText,
  },
  divider: {
    backgroundColor: theme.palette.primary.contrastText,
  },
  drawer_paper: {
    backgroundColor: theme.palette.primary.main,
    width: 300,
  },
  header: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2),
  },
});

const transitionDuration = 250;

const SideMenu = ({
  classes,
  isOpen,
  onClose,
  path,
}) => {
  function makeListItem(text, expectedPath, dataAttribute) {
    return (
      <ClickableListItem
        text={text}
        onClick={() => {
          onClose();
          setTimeout(() => history.push(expectedPath), transitionDuration);
        }}
        primaryTypographyProps={{ className: classes.contrast_text }}
        selected={expectedPath === path}
        dataAttribute={dataAttribute}
      />
    );
  }

  const user = auth.getUser();

  return (
    <Drawer
      classes={{ paper: classes.drawer_paper }}
      transitionDuration={transitionDuration}
      open={isOpen}
      onClose={onClose}
    >
      <div className={classes.header}>
        <Avatar className={classes.avatar} src={user.picture} />
        <Typography className={classes.contrast_text} variant="h2" gutterBottom>
          {user.name}
        </Typography>
        <Typography className={classes.contrast_text} variant="subtitle2">
          {user.email}
        </Typography>
      </div>
      <Divider className={classes.divider} />
      {/* <List component="nav">
        {makeListItem('Dashboard', getDashboardRoute(), 'dashboard')}
      </List> */}
      <List component="nav">
        {makeListItem('Businesses', getBusinessesRoute(), 'businesses')}
      </List>
      <List component="nav">
        {makeListItem('Campaigns', getCampaignsRoute(), 'campaigns')}
      </List>
      <List component="nav">
        {makeListItem('Marketing Requests', getMarketingRequestsRoute(), 'marketingRequests')}
      </List>
      <List component="nav">
        {makeListItem('Marketing Events', getMarketingEventsRoute(), 'marketingEvents')}
      </List>
      <List component="nav">
        {makeListItem('Users', getUsersRoute(), 'users')}
      </List>
      <List component="nav">
        {makeListItem('Stripe Products', getStripeProductsRoute(), 'stripe')}
      </List>
      <List component="nav">
        {makeListItem('SC Templates', getSCTemplatesRoute(), 'suggestedCampaignTemplates')}
      </List>
      <List component="nav">
        {makeListItem('Insights Templates', getInsightTemplatesRoute(), 'insightTemplates')}
      </List>
      <List component="nav">
        {makeListItem('Questionnaires', getQuestionnairesRoute(), 'questionnaires')}
      </List>
      <List component="nav">
        {makeListItem('Rich Profile Fields', getRichProfileFieldsRoute(), 'richProfileFields')}
      </List>
      <List component="nav">
        {makeListItem('Settings', '/settings', 'settings')}
      </List>
      <Divider className={classes.divider} />
    </Drawer>
  );
};

SideMenu.defaultProps = {
  isOpen: false,
  path: '',
};

SideMenu.propTypes = {
  classes: PropTypes.shape({
    avatar: PropTypes.string,
    contrast_text: PropTypes.string,
    divider: PropTypes.string,
    drawer_paper: PropTypes.string,
    header: PropTypes.string,
  }).isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  path: PropTypes.string,
};

const mapStateToProps = (state) => ({
  path: state.router.location.pathname,
});

export default connect(mapStateToProps)(withStyles(styles)(SideMenu));
