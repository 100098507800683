import {
  call,
  put,
  select,
} from 'redux-saga/effects';

import _ from 'lodash';
import { getPaidAdProductInfo, getPaidAAPlusProductInfo } from '@targetable/targetable-web-framework/lib/services/paywallUtils';
import api from '../../services/api';
import { selectBusiness } from '../../selectors';
import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* removeStoreFromSubscription() {
  const business = yield select(selectBusiness);

  const subscription = yield call(
    api.getStripeSubscriptionStatus,
    _.get(business, 'stripeCustomerId'),
  );

  let paidAdProductInfo = getPaidAdProductInfo(subscription);
  if (!paidAdProductInfo) {
    // Check then if user has AA PLUS product on Stripe
    paidAdProductInfo = getPaidAAPlusProductInfo(subscription);
  }

  try {
    yield call(
      api.updateStripeSubscriptionItem,
      _.get(business, 'stripeCustomerId'),
      paidAdProductInfo?.subscriptionId,
      paidAdProductInfo?.subscriptionItemId,
      { quantity: Math.max(0, (paidAdProductInfo?.quantity || 0) - 1) },
    );
    // yield call(getStripeSubscription);
    // yield fork(api.updateStoreHubspotRecord, storeId, { storeTargetableSubscription: true });
  } catch (ex) {
    logger.error({
      error: ex,
      context: { saga: 'removeStoreFromSubscription' },
      params: { business, subscription },
    });
    if (paidAdProductInfo) {
      yield put(showToaster(getToasterOptions('error_remove_store_subscription')));
    } else {
      yield put(showToaster(getToasterOptions('error_remove_store_subscription_not_found')));
    }
  }
}
