import { DATA_CONFIRMATION_DIALOG } from '../constants';

const defaultState = {
  cancelText: undefined,
  cancelDataCy: undefined,
  confirmText: undefined,
  confirmDataCy: undefined,
  isOpen: undefined,
  text: undefined,
  textParams: undefined,
};

export default {
  defaultState,
  reducers: {
    [DATA_CONFIRMATION_DIALOG]: (state, { payload }) => ({ ...state, ...payload }),
  },
};
