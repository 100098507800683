import React from 'react';
import { Route, Switch } from 'react-router-dom';
import qs from 'qs';
import _ from 'lodash';
import reactLazyLoad from '@targetable/targetable-web-framework/lib/utils/react-lazy-retry';

import SecureRoute from '../SecureRoute/SecureRoute';
import Toaster from '../Toaster/Toaster';

import auth from '../../services/auth';
import history from '../../services/history';
import logger from '../../services/logger';

import {
  getBusinessRoute,
  getCampaignsRoute,
  getCallbackRoute,
  getCampaignDetailRoute,
  getHomeRoute,
  getInsightDetailRoute,
  getSuggestedDraftRoute,
  getSettingsRoute,
  getBusinessesRoute,
  getUsersRoute,
  getSuggestedDetailRoute,
  getStripeProductsRoute,
  // getDashboardRoute,
  getInsightTemplatesRoute,
  getInsightTemplatePreviewRoute,
  getInsightTemplateDraftRoute,
  getQuestionnairesRoute,
  getSCTemplatesRoute,
  getSCTemplateDraftRoute,
  getRichProfileFieldsRoute,
  getMarketingRequestsRoute,
  getMarketingEventsRoute,
} from '../../routes';
import UsersList from '../../pages/Users/UsersList';

export const CampaignDetail = reactLazyLoad(() => import('../../pages/CampaignDetail/CampaignDetail'));
export const CampaignsList = reactLazyLoad(() => import('../../pages/Campaigns/Campaigns'));
export const MarketingRequestsList = reactLazyLoad(() => import('../../pages/MarketingRequests/MarketingRequests'));
export const MarketingEventsList = reactLazyLoad(() => import('../../pages/MarketingEvents/MarketingEvents'));
export const Business = reactLazyLoad(() => import('../../pages/Business/Business'));
export const Dashboard = reactLazyLoad(() => import('../../pages/Dashboard/Dashboard'));
export const InsightsDetail = reactLazyLoad(() => import('../../pages/InsightsDetail/InsightsDetail'));
export const Settings = reactLazyLoad(() => import('../../pages/Settings/Settings'));
export const Businesses = reactLazyLoad(() => import('../../pages/Businesses/Businesses'));
export const SuggestedCampaignDraft = reactLazyLoad(() => import('../../pages/SuggestedCampaignDraft/SuggestedCampaignDraft'));
export const SuggestedCampaignDetail = reactLazyLoad(() => import('../../pages/SuggestedCampaignDetail/SuggestedCampaignDetail'));
export const StripeProducts = reactLazyLoad(() => import('../../pages/StripeProducts/StripeProducts'));
export const InsightTemplates = reactLazyLoad(() => import('../../pages/InsightTemplates/InsightTemplates'));
export const RichProfileFields = reactLazyLoad(() => import('../../pages/RichProfileFields/RichProfileFields'));
export const InsightTemplateDraft = reactLazyLoad(() => import('../../pages/InsightTemplateDraft/InsightTemplateDraft'));
export const QuestionnaireTemplates = reactLazyLoad(() => import('../../pages/QuestionnaireTemplates/QuestionnaireTemplates'));
export const SuggestedCampaignsTemplates = reactLazyLoad(() => import('../../pages/SuggestedCampaignTemplates/SuggestedCampaignTemplates'));
export const SuggestedCampaignsTemplateDraft = reactLazyLoad(() => import('../../pages/SuggestedCampaignTemplateDraft/SuggestedCampaignTemplateDraft'));
export const InsightTemplatePreview = reactLazyLoad(() => import('../../pages/InsightTemplates/InsightTemplatePreview'));

// https://auth0.com/docs/quickstart/spa/react/01-login#process-the-authentication-result
const handleAuthentication = ({ location }) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication()
      .then(({ authenticated, error, result }) => {
        let { search } = location;
        const { hash } = location;

        if (authenticated) {
          if (search && search.length > 1) {
            search = search.slice(1);
          }

          const query = qs.parse(search);
          let redirectUrl = query.returnToUrl || '/';
          if (_.startsWith(redirectUrl, '/') === false) {
            redirectUrl = `/${redirectUrl}`;
          }

          return history.push(redirectUrl);
        }

        const errorDetails = {
          userId: result?.idTokenPayload?.sub || 'unknown',
          type: 'login_auth_error',
          ui: {
            title: 'Login failed due to invalid token',
            description: 'The user\'s login attempt failed due to an invalid token. Please check the user\'s device date and time configuration.',
          },
          meta: {
            currentSystemDate: new Date().toISOString(),
            search,
            hash,
            error,
            result,
          },
        };

        /**
         * Since we can not add an activityLog without a valid token,
         * just add the Airbrake error then.
         */
        logger.error({
          error: Error(errorDetails.ui.title),
          context: { component: 'Router' },
          params: {
            errorDetails,
          },
        });

        // if not authenticated, assume there was an issue logging in,
        // so ensure session is removed and send back to login
        return auth.logout();
      });
  }
};

export default () => (
  <>
    <Switch>
      <SecureRoute
        exact
        path={getBusinessRoute()}
        component={Business}
      />
      <SecureRoute
        exact
        path={getCampaignDetailRoute()}
        component={CampaignDetail}
      />
      <SecureRoute
        exact
        path={getHomeRoute()}
        component={MarketingRequestsList}
      />
      <SecureRoute
        exact
        path={getSettingsRoute()}
        component={Settings}
      />
      <SecureRoute
        exact
        path={getSuggestedDetailRoute()}
        component={SuggestedCampaignDetail}
      />
      <SecureRoute
        exact
        path={getSuggestedDraftRoute()}
        component={SuggestedCampaignDraft}
      />
      <SecureRoute
        exact
        path={getInsightDetailRoute()}
        component={InsightsDetail}
      />
      <SecureRoute
        exact
        path={getBusinessesRoute()}
        component={Businesses}
      />
      {/* <SecureRoute
        exact
        path={getDashboardRoute()}
        component={Dashboard}
      /> */}
      <SecureRoute
        exact
        path={getUsersRoute()}
        component={UsersList}
      />
      <SecureRoute
        exact
        path={getStripeProductsRoute()}
        component={StripeProducts}
      />
      <SecureRoute
        exact
        path={getSCTemplatesRoute()}
        component={SuggestedCampaignsTemplates}
      />
      <SecureRoute
        exact
        path={getSCTemplateDraftRoute()}
        component={SuggestedCampaignsTemplateDraft}
      />
      <SecureRoute
        exact
        path={getInsightTemplatesRoute()}
        component={InsightTemplates}
      />
      <SecureRoute
        exact
        path={getInsightTemplateDraftRoute()}
        component={InsightTemplateDraft}
      />
      <SecureRoute
        exact
        path={getCampaignsRoute()}
        component={CampaignsList}
      />
      <SecureRoute
        exact
        path={getMarketingRequestsRoute()}
        component={MarketingRequestsList}
      />
      <SecureRoute
        exact
        path={getMarketingEventsRoute()}
        component={MarketingEventsList}
      />
      <SecureRoute
        exact
        path={getQuestionnairesRoute()}
        component={QuestionnaireTemplates}
      />
      <SecureRoute
        exact
        path={getRichProfileFieldsRoute()}
        component={RichProfileFields}
      />
      <SecureRoute
        exact
        path={getInsightTemplatePreviewRoute()}
        component={InsightTemplatePreview}
      />
      <Route
        exact
        path={getCallbackRoute()}
        render={(props) => {
          handleAuthentication(props);
          return <div />;
        }}
      />
    </Switch>
    <Toaster />
  </>
);
