import { call, put } from 'redux-saga/effects';

import api from '../../services/api';
import {
  setStripeProducts,
  setStripeProductsLoading,
} from '../../actions';
import logger from '../../services/logger';

export default function* getStripeProductsSaga({ payload }) {
  try {
    yield put(setStripeProductsLoading(true));
    const products = yield call(
      api.getAllStripeProducts,
      payload?.lastKey,
      payload?.limit,
    );

    yield put(setStripeProducts(products));
    yield put(setStripeProductsLoading(false));
  } catch (e) {
    yield put(setStripeProductsLoading(false));
    yield put(setStripeProducts([]));
    logger.error({
      error: e,
      context: { saga: 'getStripeProductsSaga' },
      params: { payload },
    });
  }
}
