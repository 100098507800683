import _ from 'lodash';
import {
  all,
  call,
  fork,
  put,
  select,
  take,
} from 'redux-saga/effects';
import moment from 'moment';
import {
  removeTargetingPropsFromAds,
} from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/campaign/campaign';

import i18n from '../../services/i18n';
import api from '../../services/api';
import { selectRouterLocationPath, selectBusinessStores } from '../../selectors';
import {
  showToaster,
  setConfirmationDialog,
  setLoadingAsync,
  showConfirmationDialog,
} from '../../actions';
import logger from '../../services/logger';
import saveCampaign from '../saveCampaign/saveCampaign';
import { getToasterOptions } from '../helpers';
import {
  RESULT_CONFIRMATION_DIALOG,
} from '../../constants';
import validateCampaignUrlPresets from '../validateCampaignUrlPresets/validateCampaignUrlPresets';

export default function* submitCampaign(
  campaign,
  onError,
  validatedCampaign,
  fromSuggestedCampaign = false,
  note,
) {
  const publishMessage = fromSuggestedCampaign
    ? 'Publishing suggested campaign...'
    : 'Publishing campaign...';
  yield put(showToaster(getToasterOptions(publishMessage,
    'publish', null, 7000)));

  const clonedCampaign = _.cloneDeep(campaign);

  const stores = yield select(selectBusinessStores);

  // Only perform following validations if is not a Suggested Campaign,
  // due to those are already validated in the handleFeedbackDialog saga
  if (!validatedCampaign) {
    const presetsValid = yield call(
      validateCampaignUrlPresets,
      campaign.businessId,
      campaign,
    );

    const campaignCTA = _.get(campaign, 'ads.0.ad.callToAction');
    if (!presetsValid.valid && campaignCTA !== 'CALL_NOW') {
      if (presetsValid.error === 'invalid_preset') {
        yield put(setLoadingAsync(false));
        const pathname = yield select(selectRouterLocationPath);
        const inFormView = pathname.includes('campaigns/suggested');

        // When backDrop is clicked, it should returns the 'cancel' string
        // in the RESULT_CONFIRMATION_DIALOG
        yield put(setConfirmationDialog({
          resultOnClose: 'cancel',
        }));

        yield put(showConfirmationDialog({
          text: 'url_preset_publish_missing_error',
          confirmText: 'edit',
          cancelText: 'continue',
          onClick: _.identity,
        }));

        // true is edit, false is continue
        const { payload: confirm } = yield take(RESULT_CONFIRMATION_DIALOG);

        // Cancel the publishing
        if (confirm === 'cancel') return { submitted: false, error: 'canceled' };

        yield put(setLoadingAsync(true));

        if (confirm) {
          if (inFormView) {
            return { submitted: false, error: 'invalid_preset' };
          }
          // todo determine which route
          // history.push(get(campaign.businessId, campaign?.id));
          return { submitted: false, error: 'invalid_preset' };
        }
      } else {
        return { submitted: false, error: presetsValid.error };
      }
    }
  }
  // clean up campaign targeting
  if (clonedCampaign.goal === 'hiring') {
    clonedCampaign.ads = removeTargetingPropsFromAds(clonedCampaign.ads, ['minAge', 'maxAge', 'gender']);
  }

  // ensure goal hiring/housing to 15
  if (campaign.goal === 'hiring' || campaign.goal === 'housing') {
    campaign.ads.forEach((ad) => {
      if (ad.targeting.radius < 15) {
        _.set(ad, 'targeting.radius', 15);
      }
    });
  }

  let showCampaignPublished;
  let suggestedCampaignToUpdate = campaign;

  if (fromSuggestedCampaign) {
    showCampaignPublished = true;
    suggestedCampaignToUpdate = yield call(
      api.getSuggestedCampaign, campaign.id, stores, true,
    );
    if (!suggestedCampaignToUpdate?.type) {
      // Ensure only activated stores
      suggestedCampaignToUpdate.ads = _.filter(suggestedCampaignToUpdate?.ads, (ad) => {
        const { storeId } = ad;
        const store = _.find(stores, { id: storeId });
        return !store || !store.disabled;
      });
    }
    const scId = campaign.id;
    // eslint-disable-next-line no-param-reassign
    delete suggestedCampaignToUpdate.id;
    const questionnaireInfo = [
      { question: i18n.t('feedback_suggested_campaign_saved_question_1') },
      { question: i18n.t('feedback_suggested_campaign_saved_question_2') },
      { question: i18n.t('feedback_suggested_campaign_saved_question_3') },
    ];
    const feedback = {
      questions: [],
      timestamp: moment.utc().valueOf(),
      userId: campaign.userId,
    };
    feedback.questions = questionnaireInfo.map((listItem, i) => ({
      displayOrder: i,
      icon: 'SentimentSatisfiedAlt',
      question: listItem.question,
      value: 5,
    }));
    feedback.questions.push({
      displayOrder: 3,
      question: i18n.t('feedback_additional_thoughts'),
      value: note || '',
    });

    yield call(
      api.updateSuggestedCampaign,
      scId,
      {
        ..._.omit(suggestedCampaignToUpdate, ['id', 'questionnaireId', 'businessName']),
        feedback,
        saved: moment.utc().valueOf(),
        status: 'approved',
      },
    );
    suggestedCampaignToUpdate = {
      ..._.omit(suggestedCampaignToUpdate, ['displayDate', 'displayTime']),
      userId: campaign.userId,
      suggestedCampaignId: scId,
    };
  }

  if (!suggestedCampaignToUpdate?.type) {
    // Ensure only activated stores
    suggestedCampaignToUpdate.ads = _.filter(suggestedCampaignToUpdate?.ads, (ad) => {
      const { storeId } = ad;
      const store = _.find(stores, { id: storeId });
      return !store || !store.disabled;
    });
  }

  // At this stage, the campaign should already have the userId property
  let savedCampaign = yield call(saveCampaign, suggestedCampaignToUpdate);

  if (!savedCampaign) {
    // eslint-disable-next-line consistent-return
    return { submitted: false, error: 'save_error' };
  }

  // get the latest campaign data if the campaign was updated
  if (campaign.id && !fromSuggestedCampaign) {
    savedCampaign = yield call(api.getCampaign, campaign.id, stores);
  }

  try {
    yield call(api.submitCampaign, savedCampaign.id, true);
    yield fork(api.updateBusinessHubspotRecord, _.get(campaign, 'businessId'), { businessTargetableCampaignPublished: true });
    yield all(_.map(_.get(savedCampaign, 'ads'), (ad) => fork(api.updateStoreHubspotRecord, _.get(ad, 'storeId'), { storeTargetableAdPublished: true })));

    if (!fromSuggestedCampaign || showCampaignPublished) {
      yield put(showToaster(getToasterOptions('Campaign has been published!.', 'success', null, 5000)));
    }

    // eslint-disable-next-line consistent-return
    return { submittedCampaign: savedCampaign, submitted: true, error: false };
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'submitCampaign' },
      params: { campaign },
    });
    if (!fromSuggestedCampaign) {
      yield put(showToaster(getToasterOptions(i18n.t('error_publishing_campaign', { reason: e?.message }), 'error')));
    }
    // eslint-disable-next-line consistent-return
    return { submitted: false, error: 'error' };
  }
}
