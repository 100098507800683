import _ from 'lodash';
import { put, call } from 'redux-saga/effects';

import api from '../../services/api';

import {
  setBusinessInsights,
  setBusinessInsightsLoading,
} from '../../actions';
import logger from '../../services/logger';

export default function* getInsightsByBusiness({ payload }) {
  const {
    expired,
    status,
    businessId,
    currentDateTime,
  } = payload;

  yield put(setBusinessInsightsLoading(true));

  try {
    const insights = yield call(
      api.getInsights,
      businessId,
      status || null,
      expired,
      // TGT-5316: forcing ALL insights to be returned since the 'expired'
      // logic used by the front-end does not match that of the endpoint.
      currentDateTime || '1970-01-01',
    );

    const sortedInsights = _.sortBy(insights, ['created']).reverse();
    yield put(setBusinessInsights(sortedInsights));
    yield put(setBusinessInsightsLoading(false));
  } catch (e) {
    yield put(setBusinessInsightsLoading(false));
    logger.error({
      error: e,
      context: { saga: 'getInsightsByBusiness' },
      params: { businessId },
    });
  }
}
