import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Chip,
  ListItemIcon,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import DrawerContent from '@targetable/targetable-web-framework/lib/react/containers/DrawerContent/DrawerContent';
import { Permissions as UserPermissions } from '@targetable/targetable-types/dist/types/permissions';
import PageContentContainer from '@targetable/targetable-web-framework/lib/react/containers/PageContentContainer/PageContentContainer';
import { useGlobalsContext } from '@targetable/targetable-web-framework/lib/context/globals';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import BuildIcon from '@material-ui/icons/Build';
import LinkIcon from '@material-ui/icons/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';

import moment from 'moment';
import {
  openTokenDebugger,
  verifyEmailAction,
  showConfirmationDialog,
  deleteUser,
} from '../../actions';
import dateService from '../../services/date';
import api from '../../services/api';
import RolePermissionsDialog from './RolePermissionsDialog/RolePermissionsDialog';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: '100%',
    margin: `${theme.spacing(2)}px auto`,
  },
  drawer: {
    inset: 'unset !important',
  },
  drawerColor: {
    backgroundColor: '#F3F6F9',
  },
  backdrop: {
    zIndex: 1400,
  },
  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoRowMultiple: {
    paddingBottom: theme.spacing(1),
  },
  graySubHeader: {
    color: '#6E7286',
    fontSize: '12px',
    lineHeight: '16px',
    font: 'montserrat',
  },
  rowHeader: {
    fontSize: '0.875rem',
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: '1.43',
    marginBottom: 8,
  },
  infoItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    borderBottom: '1px solid #e0e0e0',
  },
  button: {
    cursor: 'pointer',
  },
  disabled: {
    cursor: 'default',
    color: '#9FA1AF !important',
  },
  userBlocked: {
    color: '#EE3426',
  },
  userPermitted: {
    color: '#2fc134',
  },
  icon: {
    color: '#3E435E',
  },
  authChip: {
    fontSize: '0.75rem',
    fontWeight: 500,
    backgroundColor: 'white',
    lineheight: 1.33,
    color: '#007bff',
    border: '1px #007bff solid',
  },
  redIcon: {
    color: '#FF0000',
  },
}));

const NOT_AVAILABLE = 'N/A';
/**
 * Determines an equivalent role given a list of permissions
 * @param {string[]} permissions
 * @return {'manager' | 'editor'} the matching role.
 */
const getRoleByPermissions = (permissions) => {
  const isManager = (permissions || []).includes(UserPermissions.UserPortal.Billing.Read);
  const userRole = isManager ? 'Manager' : 'Editor';
  return userRole;
};

/**
 * Mask a part of the IP address with '#' characters.
 *
 * @param {string} part - The part to be masked.
 * @returns {string} The masked part.
 */
function maskPart(part) {
  return '#'.repeat(part?.length);
}

/**
 * Mask the IP address and display only the last part.
 *
 * @param {string} ipAddress - The input IP address.
 * @returns {string} The masked IP address in the format `###.##.###.94`.
 */
function maskIPAddress(ipAddress) {
  // Split the IP address into parts
  if (ipAddress) {
    const parts = ipAddress?.split('.');

    if (parts.length) {
      // Mask the first three parts and keep the last part
      const maskedParts = [
        maskPart(parts[0]),
        maskPart(parts[1]),
        maskPart(parts[2]),
        parts[3],
      ];

      return maskedParts.join('.');
    }
  }
  return '';
}

async function getUserAuth0Permissions(auth0Id) {
  try {
    const result = await api.getAuth0Permissions(auth0Id);
    if (!Array.isArray(result)) throw new Error('Invalid');
    return result;
  } catch (err) {
    return [];
  }
}

/* eslint-disable react/prop-types */
const ItemInfo = ({
  header,
  subHeader,
  icon,
  dataCy,
  onClick,
  disabled,
  chipTitle,
  subHeaderLink,
  subHeaderStyle,
  subHeaderItems,
  preventHrefAction,
  tooltip,
}) => {
  /* eslint-enable react/prop-types */
  const classes = useStyles();
  const handleLinkClick = (event, link) => {
    event.preventDefault();
    if (!preventHrefAction && link) {
      window.open(link, '_blank');
    } else if (preventHrefAction && onClick) {
      onClick();
    }
  };

  const items = subHeaderItems || [{
    subHeader,
    subHeaderLink,
    onClick,
    icon,
    disabled,
    chipTitle,
  }];

  return (
    <div className={classes.infoItem} data-cy={dataCy}>
      <Tooltip title={tooltip}>
        <div className={classes.rowHeader}>
          { header }
        </div>
      </Tooltip>
      <div className={classes.rowSubHeader}>
        { items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div
            className={classNames(
              classes.infoRow,
              subHeaderStyle,
              items.length > 1 ? classes.infoRowMultiple : null,
            )}
            // eslint-disable-next-line react/no-array-index-key
            key={`item_${index}`}
          >
            {item.subHeaderLink ? (
              <a
                rel="noreferrer"
                style={{ color: '#007bff', textDecoration: 'none' }}
                target="_blank"
                href={item.subHeaderLink}
                onClick={(event) => handleLinkClick(event, item.subHeaderLink)}
              >
                {item.subHeader}

              </a>
            ) : (
              <>{item.subHeader}</>
            )}

            <div className={classNames(
              classes.button,
              item.disabled ? classes.disabled : null,
            )}
            >
              {/* edit button */}
              <ListItemIcon
                onClick={!item.disabled ? item.onClick : () => {}}
                className={classNames(
                  classes.icon,
                  item.disabled ? classes.disabled : null,
                )}
              >
                { item.icon }
              </ListItemIcon>
            </div>
          </div>
        ))}
      </div>
      { chipTitle && (
        <div className={classes.rowSubHeader}>
          <Chip
            size="small"
            className={classes.authChip}
            label={chipTitle}
            data-cy="userDetail_auth0LoginMethod_chip"
          />
        </div>
      )}
    </div>
  );
};

/**
 * @param {Object} param0
 * @param {import('@targetable/targetable-types/dist/types/user').TargetableUser} param0.user
 */
const UserDetailsDialog = ({
  onPermissionsClick,
  onBusinessClick,
  openDrawer,
  onClose,
  user,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { HUBSPOT_ACCOUNT_ID } = useGlobalsContext();
  const [selectedUser, setSelectedUser] = useState(user);
  const [copySuccess, setCopySuccess] = useState('');
  const [lastCopied, setLastCopied] = useState('');
  const [verifyingEmail, setVerifyingEmail] = useState(false);
  const [archivingUser, setArchivingUser] = useState(false);
  const [deletingUser, setDeletingUser] = useState(false);
  const [showEditPermissions, setShowEditPermissions] = React.useState(false);
  const userPortalAccess = user.permissions?.includes(UserPermissions.User.Portal.Access);
  const userRole = getRoleByPermissions(selectedUser.auth0Permissions);

  useEffect(() => {
    setSelectedUser(user);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const loadUsersAuth0Permissions = async (auth0Id) => {
      const permissions = await getUserAuth0Permissions(auth0Id);
      setSelectedUser({
        ...user,
        auth0Permissions: permissions,
      });
    };
    if (!_.isEmpty(user) && user.auth0Id && !user.auth0Permissions) {
      loadUsersAuth0Permissions(user.auth0Id);
    }
  }, [user]);

  const copyToClipBoard = async (copyMe) => {
    setLastCopied(copyMe);
    try {
      if (!navigator?.clipboard) {
        // Clipboard not supported
        return;
      }
      navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
      setTimeout(() => {
        setCopySuccess('');
      }, 3000);
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  const getAuth0TypeName = (auth0IdMethod) => {
    if (auth0IdMethod?.indexOf('facebook') >= 0) return t('auth0_facebook');
    if (auth0IdMethod?.indexOf('google') >= 0) return t('auth0_google');
    if (auth0IdMethod?.indexOf('auth0') >= 0) return t('auth0_email_pass');
    return t('user_outboard');
  };

  const openTokenDebuggerCb = useCallback((userId) => {
    dispatch(openTokenDebugger(userId));
  }, [dispatch]);

  const handleVerifyEmail = useCallback(() => {
    setVerifyingEmail(true);
    dispatch(verifyEmailAction({
      user,
      onComplete: () => {
        setVerifyingEmail(false);
      },
    }));
  }, [dispatch, user]);

  const handleArchiveUser = useCallback(() => {
    setArchivingUser(true);
    dispatch(deleteUser({
      userId: user.id,
      onComplete: () => {
        setArchivingUser(false);
      },
    }));
  }, [dispatch, user]);

  const handleDeleteUser = useCallback(() => {
    dispatch(showConfirmationDialog({
      title: 'Delete User',
      text: 'Are you sure you want to delete this user from the database?',
      confirmText: 'confirm',
      confirmDataCy: 'cta-delete-user_confirm',
      cancelText: 'cancel',
      cancelDataCy: 'cta-delete-user_cancel',
      onClick: (confirm) => {
        if (confirm) {
          setDeletingUser(true);
          dispatch(deleteUser({
            userId: user.id,
            hardDelete: true,
            onComplete: () => {
              setDeletingUser(false);
            },
          }));
        }
      },
    }));
  }, [dispatch, user]);

  useEffect(() => {
    if (!openDrawer) {
      setSelectedUser({});
    }
  }, [openDrawer]);

  return (
    <>
      <DrawerContent
        open={openDrawer}
        onClose={onClose}
        title={`${selectedUser.givenName} ${selectedUser.familyName}`}
        buttons={[]}
        drawerClassName={classes.drawer}
        contentClassName={classes.drawerColor}
        hideBackdrop
      >
        <PageContentContainer className={classes.pageContainer}>
          <div className={classes.businessInfoSection}>
            <ItemInfo
              data-cy="userDetailsDialog_name_label"
              header={t('fullname', 'User Name (First & Last)')}
              subHeader={`${selectedUser.givenName} ${selectedUser.familyName}`}
            />
            <ItemInfo
              data-cy="userDetailsDialog_email_label"
              header={t('user_list_col_email', 'Email')}
              subHeader={`${selectedUser.email} ${lastCopied === selectedUser.email ? copySuccess : ''}`}
              icon={<FileCopyIcon />}
              onClick={() => copyToClipBoard(selectedUser.email)}
            />
            <ItemInfo
              data-cy="userDetailsDialog_id_label"
              header={t('user_list_col_id', 'Targetable User ID')}
              subHeader={`${selectedUser.id} ${lastCopied === selectedUser.id ? copySuccess : ''}`}
              icon={<FileCopyIcon />}
              onClick={() => copyToClipBoard(selectedUser.id)}
            />
            <ItemInfo
              data-cy="userDetailsDialog_hubspot_label"
              header={t('user_list_col_hubspot', 'HubSpot Record')}
              subHeader={`${selectedUser.hubSpotId} ${lastCopied === selectedUser.hubSpotId ? copySuccess : ''}`}
              subHeaderLink={`https://app.hubspot.com/contacts/${HUBSPOT_ACCOUNT_ID}/contact/${selectedUser.hubSpotId}/`}
              icon={<FileCopyIcon />}
              onClick={() => copyToClipBoard(selectedUser.hubSpotId)}
            />
            <ItemInfo
              data-cy="userDetailsDialog_lastLogin_label"
              header={t('user_list_col_last_login_targetable', 'Last Login (Targetable)')}
              subHeader={user?.lastLogin
                ? dateService.formatDateTime(selectedUser.lastLogin)
                : NOT_AVAILABLE}
            />
            <ItemInfo
              data-cy="userDetailsDialog_business_label"
              header={t('user_list_col_business', 'Associated Bussiness')}
              subHeader={`${selectedUser.businessName} ${lastCopied === selectedUser.businessName ? copySuccess : ''}`}
              subHeaderLink="#"
              preventHrefAction
              onClick={() => onBusinessClick(selectedUser.businessId)}
            />
            <ItemInfo
              data-cy="userDetailsDialog_role_label"
              header={t('user_list_col_role', 'Business Role/Permissions')}
              subHeader={userRole}
              icon={<EditIcon />}
              onClick={() => setShowEditPermissions(true)}
            />
            <ItemInfo
              data-cy="userDetailsDialog_adminTools_label"
              header={t('admin_tools', 'Admin Tools')}
              subHeaderItems={[
                {
                  subHeader: 'Facebook User Token Debugger',
                  onClick: () => openTokenDebuggerCb(selectedUser.id),
                  icon: (
                    <BuildIcon />
                  ),
                  disabled: !selectedUser.isConnectedToFacebook,
                },
                {
                  subHeader: 'Check User Facebook Permissions',
                  onClick: onPermissionsClick,
                  icon: (
                    <SettingsIcon />
                  ),
                  disabled: !selectedUser.isConnectedToFacebook,
                },
                {
                  subHeader: 'Verify Email (Auth0)',
                  onClick: handleVerifyEmail,
                  icon: (
                    <LinkIcon />
                  ),
                  disabled: !selectedUser.auth0Id || selectedUser.emailVerified || verifyingEmail,
                },
                {
                  subHeader: 'Archive',
                  onClick: handleArchiveUser,
                  icon: (
                    <DeleteIcon className={classes.redIcon} />
                  ),
                  disabled: archivingUser,
                },
                {
                  subHeader: 'Delete',
                  onClick: handleDeleteUser,
                  icon: (
                    <DeleteSweepIcon className={classes.redIcon} />
                  ),
                  disabled: deletingUser,
                },
              ]}
              onClick={() => openTokenDebuggerCb(selectedUser.id)}
            />
            <ItemInfo
              data-cy="userDetailsDialog_auth0Details_label"
              header={t('user_list_col_auth0Details', 'Auth0 Details')}
              subHeader={t('user_list_col_last_seen', `Last Auth0 Login/Logout Event: ${moment(selectedUser.lastSeenAt).utc().format('MM/DD/YYYY hh:mm A UTC')}`)}
              subHeaderStyle={classes.graySubHeader}
            />
            <ItemInfo
              data-cy="userDetailsDialog_auth0Id_label"
              header={t('user_list_col_auth0Id', 'Auth0 ID')}
              subHeader={`${selectedUser.auth0Id || NOT_AVAILABLE} ${lastCopied === selectedUser.auth0Id ? copySuccess : ''}`}
              icon={<FileCopyIcon />}
              onClick={() => copyToClipBoard(selectedUser.auth0Id)}
              chipTitle={getAuth0TypeName(selectedUser.auth0Id)}
            />
            <ItemInfo
              data-cy="userDetailsDialog_auth0AccessStatus_label"
              header="Auth0 Access Status"
              tooltip="This indicates if a user is restricted from an Auth0 level"
              subHeader={t('user_list_col_access_status', selectedUser.isAuth0Blocked ? ' Blocked' : 'Permitted')}
              subHeaderStyle={selectedUser.isAuth0Blocked
                ? classes.userBlocked : classes.userPermitted}
            />
            <ItemInfo
              data-cy="userDetailsDialog_portal_access_permission"
              header="Targetable User Portal Access"
              tooltip="This indicates if a user is restricted from our Targetable Portal. User will still see the Targetable Login Screen."
              subHeader={userPortalAccess ? 'Enabled' : 'Disabled'}
              subHeaderStyle={userPortalAccess ? classes.userPermitted : classes.userBlocked}
            />
            <ItemInfo
              data-cy="userDetailsDialog_auth0Ip_label"
              header={t('user_list_col_auth0Ip', 'Latest IP Address')}
              subHeader={`${maskIPAddress(selectedUser.lastIpAddress) || NOT_AVAILABLE} ${lastCopied === selectedUser.lastIpAddress ? copySuccess : ''}`}
              icon={<FileCopyIcon />}
              onClick={() => copyToClipBoard(selectedUser.lastIpAddress)}
            />
            <ItemInfo
              data-cy="userDetailsDialog_lastest_location_label"
              header={t('user_list_col_lastest_location', 'Latest Location')}
              subHeader={`${selectedUser.lastLocation || NOT_AVAILABLE} ${lastCopied === selectedUser.lastLocation ? copySuccess : ''}`}
            />
          </div>
        </PageContentContainer>
      </DrawerContent>
      {showEditPermissions && (
      <RolePermissionsDialog
        title={`${selectedUser.givenName} ${selectedUser.familyName} - Edit Role/Permissions`}
        onCancel={() => setShowEditPermissions(false)}
        user={selectedUser}
        onUserUpdated={(updatedUser) => setSelectedUser(updatedUser)}
      />
      )}
    </>
  );
};

UserDetailsDialog.defaultProps = {
  user: {
    isConnectedToFacebook: false,
    isAuth0Blocked: false,
    auth0Id: null,
    lastLogin: null,
    email: null,
    givenName: '',
    familyName: '',
    permissions: [],
  },
};

UserDetailsDialog.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPermissionsClick: PropTypes.func.isRequired,
  onBusinessClick: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    auth0Id: PropTypes.string,
    lastLogin: PropTypes.number,
    lastSeenAt: PropTypes.string,
    isConnectedToFacebook: PropTypes.bool,
    emailVerified: PropTypes.bool,
    isAuth0Blocked: PropTypes.bool,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
    hubSpotId: PropTypes.string,
    businessId: PropTypes.string,
    businessName: PropTypes.string,
    lastIpAddress: PropTypes.string,
    lastLocation: PropTypes.string,
    auth0Permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default UserDetailsDialog;
