import { put, call, fork } from 'redux-saga/effects';

import api from '../../services/api';

import {
  setBusiness,
  setBusinessLoading,
} from '../../actions';
import logger from '../../services/logger';
import getStripeSubscriptionByCustomer from '../getStripeSubscriptionByCustomer/getStripeSubscriptionByCustomer';

export default function* getBusinessSaga({ payload: businessId }) {
  yield put(setBusinessLoading(true));

  try {
    const business = yield call(api.getBusiness, businessId);
    if (business.stripeCustomerId) {
      yield fork(getStripeSubscriptionByCustomer, {
        payload: {
          businessId: business.id,
          stripeCustomerId: business.stripeCustomerId,
        },
      });
    }

    yield put(setBusiness(business));
    yield put(setBusinessLoading(false));
  } catch (e) {
    yield put(setBusinessLoading(false));
    logger.error({
      error: e,
      context: { saga: 'getBusinessSaga' },
      params: { businessId },
    });
  }
}
