import {
  put, call, select,
} from 'redux-saga/effects';

import api from '../../services/api';

import { showToaster } from '../../actions';
import { selectBusinessIdQueried } from '../../selectors';
import logger from '../../services/logger';
import getInsightsByBusiness from '../getInsightsByBusiness/getInsightsByBusiness';
import { getToasterOptions } from '../helpers';

export default function* retryUpdateLiveInsight({ payload }) {
  const {
    onRetry, insightId, onError,
  } = payload;

  const toastMessage = 'Retrying live marketing update...';
  try {
    yield put(showToaster(getToasterOptions(
      toastMessage, 'info', null, 7000, true, null, 'bottom', 'right',
    )));

    yield call(api.updateInsight, insightId, null, true);

    const businessId = yield select(selectBusinessIdQueried);
    yield call(getInsightsByBusiness, { payload: { businessId } });

    yield put(showToaster(getToasterOptions('Successfully retried live marketing update', 'success', null, 5000)));

    if (onRetry) {
      onRetry();
    }
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to retry live marketing update. Try again in a few minutes.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'retryUpdateLiveInsight' },
      params: { payload },
    });
    if (onError) {
      onError();
    }
  }
}
