import _ from 'lodash';

import {
  call, fork, put, select,
} from 'redux-saga/effects';

import { selectBusinessIdQueried, selectBusinessLoading } from '../../selectors';

import getBusiness from '../getBusiness/getBusiness';
import getInsightsByBusiness from '../getInsightsByBusiness/getInsightsByBusiness';
import getStoresByBusiness from '../getStoresByBusiness/getStoresByBusiness';
import getUsersByBusiness from '../getUsersByBusiness/getUsersByBusiness';
// import getPixelsByBusiness from '../getPixelsByBusiness/getPixelsByBusiness';
import { setBusinessIdQueried } from '../../actions';

export default function* initBusinessSaga(action) {
  const businessId = _.get(action, 'payload');
  const businessLoading = yield select(selectBusinessLoading);
  const currentBusinessId = yield select(selectBusinessIdQueried);

  // assume something already called this and just exit
  if (businessLoading || businessId === currentBusinessId) {
    return;
  }

  yield call(getBusiness, action);
  yield fork(getInsightsByBusiness, { payload: { businessId } });
  yield fork(getUsersByBusiness, action);
  yield call(getStoresByBusiness, action);
  // Unnecessary call because getting from algolia
  // yield fork(getSuggestedCampaignsByBusiness, { payload: { businessId } });

  yield put(setBusinessIdQueried(businessId));
  // yield call(getPixelsByBusiness, action);
}
