import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Edit as EditIcon,
} from '@material-ui/icons';
import { ListItemIcon, Grid } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DrawerContent from '@targetable/targetable-web-framework/lib/react/containers/DrawerContent/DrawerContent';
import PageContentContainer from '@targetable/targetable-web-framework/lib/react/containers/PageContentContainer/PageContentContainer';
import ComponentLoading from '@targetable/targetable-web-framework/lib/react/components/ComponentLoading/ComponentLoading';
import SubscriptionContent from '@targetable/targetable-web-framework/lib/react/components/SubscriptionDrawer/SubscriptionContent';
import CreditCard from '@targetable/targetable-web-framework/lib/react/components/CreditCard/CreditCard';
import Button from '@targetable/targetable-web-framework/lib/react/components/Button/Button';
import InvoicesDialog from '@targetable/targetable-web-framework/lib/react/components/InvoicesDialog/InvoicesDialog';

import BusinessEditNameDialog from '../../components/BusinessEditNameDialog/BusinessEditNameDialog';
import CampaignReportingToolsDrawer from '../../components/CampaignReportingToolsDrawer/CampaignReportingToolsDrawer';
import SmartfeedOverviewWidgetDrawer from '../../components/SmartfeedOverviewWidgetDrawer/SmartfeedOverviewWidgetDrawer';
import { getBusinessRoute } from '../../routes';
import {
  selectBusinessLoading,
  selectSubscriptionLoading,
} from '../../selectors';
import { getBusiness } from '../../actions';
import PauseSubscriptionCollectionDialog from '../../components/PauseSubscriptionCollectionDialog/PauseSubscriptionCollectionDialog';
import FiltersDialog from '../../components/ActivityLogTab/ActivityLogDialog/FiltersDialog';
import BusinessAddPartner from '../../components/BusinessAddPartner/BusinessAddPartner';
import ReviewResponseCHFDialog from '../../components/ReviewResponseDialogs/ReviewResponseCHFDialog';
import ReviewResponseChatGPTDialog from '../../components/ReviewResponseDialogs/ReviewResponseChatGPTDialog';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: '100%',
    paddingTop: 20,
  },
  drawer: {
    inset: 'unset !important',
  },
  drawerColor: {
    backgroundColor: '#F3F6F9',
  },
  businessInfoSection: {
    borderBottom: '1px solid #e0e0e0',
  },

  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  productItem: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    paddingLeft: 12,
    '&:not(.indented):first-child': {
      marginBottom: theme.spacing(1),
    },
    '&.indented': {
      paddingLeft: 20,
      '&:nth-child(n+3):before': {
        top: -22,
        height: 32,
        color: 'red',
      },

      '&:before': {
        position: 'absolute',
        top: -10,
        left: 4,
        width: 10,
        height: 20,
        verticalAlign: 'top',
        borderBottom: 'thin solid #d8d9df',
        borderLeft: 'thin solid #d8d9df',
        content: '""',
        display: 'inline-block',
      },
    },
  },

  subscriptionsRow: {
    position: 'relative',
    marginBottom: theme.spacing(2),
    '&:before': {
      position: 'absolute',
      left: 0,
      top: 7,
      width: 6,
      height: 6,
      borderRadius: 3,
      backgroundColor: '#007bff',
      content: '""',
    },
  },

  infoItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    borderBottom: '1px solid #e0e0e0',
  },

  rowHeader: {
    fontSize: '0.875rem',
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: '1.43',
    marginBottom: 8,
  },

  subscriptionsSection: {
    marginTop: 24,
  },

  creditCardSection: {
    padding: 16,
  },

  button: {
    cursor: 'pointer',
  },

  notFound: {
    textAlign: 'center',
  },

  invoiceButton: {
    marginTop: 16,
  },
}));

/* eslint-disable react/prop-types */
const ItemInfo = ({
  header,
  subHeader,
  icon,
  dataCy,
  onClick,
  subHeaderLink,
  subHeaderItems,
}) => {
/* eslint-enable react/prop-types */
  const classes = useStyles();
  const items = subHeaderItems || [{
    subHeader,
    subHeaderLink,
    onClick,
    icon,
  }];
  return (
    <div className={classes.infoItem} data-cy={dataCy}>
      <div className={classes.rowHeader}>
        { header }
      </div>
      <div className={classes.rowSubHeader}>
        { items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={classes.infoRow} key={`item_${index}`}>
            {item.subHeaderLink ? (
              <a rel="noreferrer" target="_blank" href={item.subHeaderLink}>{item.subHeader}</a>
            ) : (
              <>{item.subHeader}</>
            )}

            <div className={classes.button}>
              {/* edit button */}
              <ListItemIcon onClick={item.onClick}>
                { item.icon }
              </ListItemIcon>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const DrawerPreview = ({
  onClose,
  businessInfo,
  infoLoading,
  onSuccessSave,
  fetchError,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const businessLoading = useSelector(selectBusinessLoading) || false;
  const subscriptionLoading = useSelector(selectSubscriptionLoading) || false;

  const [businessNameDialog, setBusinessNameDialog] = useState(false);
  const [campaignReportingToolsDrawer, setCampaignReportingToolsDrawer] = useState(false);
  const [smartfeedOverviewDrawer, setSmartfeedOverviewDrawer] = useState(false);
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(true);
  const [showPauseCollectionDialog, setShowPauseCollectionDialog] = useState(false);
  const [showInvoicesDialog, setShowInvoicesDialog] = useState(false);
  const [activityLogsDrawer, setActivityLogsDrawer] = useState(false);
  const [addPartnerDrawer, setAddPartnerDrawer] = useState(false);
  const [reviewResponseCHFDrawer, setReviewResponseCHFDrawer] = useState(false);
  const [reviewResponseChatGPTDrawer, setReviewResponseChatGPTDrawer] = useState(false);

  useEffect(() => {
    dispatch(getBusiness(businessInfo?.id));
  }, [dispatch, businessInfo?.id]);

  const handleInvoicesDialog = useCallback(() => {
    setShowInvoicesDialog(true);
  }, [setShowInvoicesDialog]);

  const handleCancelInvoicesDialog = useCallback(() => {
    setShowInvoicesDialog(false);
  }, []);

  return (
    <>
      <DrawerContent
        open={!!businessInfo || infoLoading || fetchError}
        onClose={onClose}
        title={businessInfo?.name}
        drawerClassName={classes.drawer}
        contentClassName={classes.drawerColor}
        hideBackdrop
      >
        <PageContentContainer className={classes.pageContainer}>
          <>
            <ComponentLoading
              loading={businessLoading}
              waitKey="loading_business_info"
            >
              {businessInfo && !fetchError && (
                <>
                  <div className={classes.businessInfoSection}>
                    <ItemInfo
                      data-cy="business-info-name"
                      header={t('dashboard_drawer_businessName', 'Business Name')}
                      subHeader={businessInfo.name}
                      icon={(
                        <EditIcon
                          size="small"
                          data-cy={`ImageListItem_${businessInfo?.name.split(' ').join('')}_edit_icon`}
                        />
                      )}
                      onClick={() => setBusinessNameDialog(true)}
                    />
                    <ItemInfo
                      data-cy="business-info-id"
                      header={t('dashboard_drawer_businessId', 'Business ID')}
                      subHeader={`${businessInfo.id}`}
                      subHeaderLink={getBusinessRoute(businessInfo.id)}
                    />
                    <ItemInfo
                      data-cy="business-reporting-tools"
                      header={t('dashboard_drawer_reporting_tools', 'Reporting Tools')}
                      subHeaderItems={[
                        {
                          subHeader: t('dashboard_drawer_campaign_performance', 'Campaign Performance'),
                          onClick: () => setCampaignReportingToolsDrawer(true),
                          icon: (<NavigateNextIcon
                            size="small"
                            data-cy={`ImageListItem_${businessInfo?.name.split(' ').join('')}_launch_icon`}
                          />),
                        },
                        {
                          subHeader: t('dashboard_drawer_overview_widget', 'Smartfeed Overview Widget'),
                          onClick: () => setSmartfeedOverviewDrawer(true),
                          icon: (
                            <NavigateNextIcon
                              size="small"
                              data-cy={`ImageListItem_${businessInfo?.name.split(' ').join('')}_launch_icon`}
                            />
                          ),
                        },
                      ]}
                    />
                    <ItemInfo
                      data-cy="admin-tools"
                      header={t('dashboard_drawer_admin_tools', 'Admin Tools')}
                      subHeaderItems={[
                        {
                          subHeader: t('dashboard_drawer_activity_logs', 'Activity Logs'),
                          onClick: () => setActivityLogsDrawer(true),
                          icon: (
                            <NavigateNextIcon
                              size="small"
                              data-cy={`ImageListItem_${businessInfo?.name.split(' ').join('')}_launch_icon`}
                            />
                          ),
                        },
                        {
                          subHeader: t('dashboard_drawer_ads_health_summary', 'Ads Health Summary'),
                          onClick: () => window.open(getBusinessRoute(businessInfo.id, 'ads-health-summary'), '_blank'),
                          icon: (
                            <NavigateNextIcon
                              size="small"
                              data-cy={`ImageListItem_${businessInfo?.name.split(' ').join('')}_launch_icon`}
                            />
                          ),
                        },
                        {
                          subHeader: t('dashboard_drawer_add_partner', 'Add Partner'),
                          onClick: () => setAddPartnerDrawer(true),
                          icon: (
                            <NavigateNextIcon
                              size="small"
                              data-cy={`ImageListItem_${businessInfo?.name.split(' ').join('')}_launch_icon`}
                            />
                          ),
                        },
                      ]}
                    />
                    <ItemInfo
                      data-cy="openai-tools"
                      header={t('dashboard_drawer_openai_tools', 'OpenAI Tools')}
                      subHeaderItems={[
                        {
                          subHeader: t('dashboard_drawer_review_response_chf', 'Review Response: Context, Header & Footer'),
                          onClick: () => setReviewResponseCHFDrawer(true),
                          icon: (
                            <NavigateNextIcon
                              size="small"
                              data-cy={`ImageListItem_${businessInfo?.name.split(' ').join('')}_launch_icon`}
                            />
                          ),
                        },
                        {
                          subHeader: t('dashboard_drawer_review_response_chatgpt', 'Review Response: ChatGPT'),
                          onClick: () => setReviewResponseChatGPTDrawer(true),
                          icon: (
                            <NavigateNextIcon
                              size="small"
                              data-cy={`ImageListItem_${businessInfo?.name.split(' ').join('')}_launch_icon`}
                            />
                          ),
                        },
                      ]}
                    />
                  </div>

                  {businessNameDialog && (
                    <BusinessEditNameDialog
                      onClose={(businessName) => {
                        setBusinessNameDialog(false);
                        if (businessName && typeof businessName === 'string') {
                          onSuccessSave({ ...businessInfo, name: businessName });
                        }
                      }}
                      businessInfo={businessInfo}
                    />
                  )}

                  {campaignReportingToolsDrawer && (
                    <CampaignReportingToolsDrawer
                      onClose={() => setCampaignReportingToolsDrawer(false)}
                      businessInfo={businessInfo}
                    />
                  )}

                  {smartfeedOverviewDrawer && (
                    <SmartfeedOverviewWidgetDrawer
                      onClose={() => setSmartfeedOverviewDrawer(false)}
                      businessInfo={businessInfo}
                    />
                  )}

                  {activityLogsDrawer && (
                    <FiltersDialog
                      open={activityLogsDrawer}
                      onClose={() => setActivityLogsDrawer(false)}
                      businessInfo={businessInfo}
                    />
                  )}

                  {addPartnerDrawer && (
                    <BusinessAddPartner
                      onClose={() => setAddPartnerDrawer(false)}
                    />
                  )}

                  {reviewResponseCHFDrawer && (
                    <ReviewResponseCHFDialog
                      open={reviewResponseCHFDrawer}
                      onClose={() => setReviewResponseCHFDrawer(false)}
                    />
                  )}

                  {reviewResponseChatGPTDrawer && (
                  <ReviewResponseChatGPTDialog
                    open={reviewResponseChatGPTDrawer}
                    onClose={() => setReviewResponseChatGPTDrawer(false)}
                  />
                  )}

                  <div className={classes.subscriptionsSection}>
                    <ComponentLoading loading={businessLoading || subscriptionLoading} waitKey="loading_subscriptions_info">
                      {showSubscriptionDialog && (
                        <SubscriptionContent
                          isAdmin
                          onClose={() => setShowSubscriptionDialog(false)}
                          setShowPauseCollectionDialog={setShowPauseCollectionDialog}
                        />
                      )}
                      {showPauseCollectionDialog && (
                        <PauseSubscriptionCollectionDialog
                          onClose={() => setShowPauseCollectionDialog(false)}
                        />
                      )}
                    </ComponentLoading>
                  </div>

                  {!subscriptionLoading && (
                    <Grid className={classes.creditCardSection} container spacing={4}>
                      <CreditCard />
                      <Grid
                        className={classes.invoiceButton}
                        container
                        xs={12}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={handleInvoicesDialog}
                        >
                          {`${t('billing_view_invoices_btn', 'View Invoices')}`}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {fetchError && (
                <div className={classes.notFound}>No Bussiness info was found</div>
              )}
            </ComponentLoading>

          </>
        </PageContentContainer>
      </DrawerContent>
      {showInvoicesDialog && (
        <InvoicesDialog
          onCancel={handleCancelInvoicesDialog}
        />
      )}
    </>
  );
};

DrawerPreview.defaultProps = {
  businessInfo: null,
  infoLoading: false,
  onSuccessSave: null,
  fetchError: false,
};

DrawerPreview.propTypes = {
  onClose: PropTypes.func.isRequired,
  infoLoading: PropTypes.bool,
  businessInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    stripeCustomerId: PropTypes.string,
  }),
  onSuccessSave: PropTypes.func,
  fetchError: PropTypes.bool,
};

export default DrawerPreview;
