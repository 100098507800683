import { put, call } from 'redux-saga/effects';

import api from '../../services/api';

import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* deleteInsightTemplate({ payload }) {
  const { onDelete, onError, insightTemplateId } = payload;

  try {
    yield call(api.deleteInsightTemplate, insightTemplateId);

    if (onDelete) {
      onDelete();
    }
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to delete the Insight Template. Try again in a few minutes.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'deleteInsightTemplate' },
      params: { payload },
    });

    if (onError) {
      onError();
    }
  }
}
