import _ from 'lodash';
import { mergeCampaignAdItem } from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/campaign/campaign';

import {
  DATA_SUGGESTED_CAMPAIGN,
  DATA_SUGGESTED_CAMPAIGN_ADS,
  DATA_SUGGESTED_CAMPAIGN_DISPLAY_DATE,
  DATA_SUGGESTED_CAMPAIGN_DISPLAY_TIME,
  DATA_SUGGESTED_CAMPAIGN_EXPIRY_DATETIME,
  DATA_SUGGESTED_CAMPAIGN_NAME,
  DATA_SUGGESTED_CAMPAIGN_GOAL,
  DATA_SUGGESTED_CAMPAIGN_OBJECTIVE,
  DATA_SUGGESTED_CAMPAIGN_LOADING,
  DATA_SUGGESTED_CAMPAIGN_UPDATE_ADS,
  DATA_SUGGESTED_CAMPAIGN_PIXEL_EVENT_TYPE,
  DATA_SUGGESTED_CAMPAIGN_OPTIMIZATION_GOAL,
  DATA_SUGGESTED_CAMPAIGN_AD_TYPE,
  DATA_SUGGESTED_CAMPAIGN_OVERRIDE_AUTO_AGING, DATA_SUGGESTED_CAMPAIGN_QUESTIONNAIRE,
  DATA_SUGGESTED_CAMPAIGN_EVENT_ID,
} from '../constants';

const defaultState = {
  suggestedCampaign: null,
  suggestedCampaignLoading: true,
};

export default {
  defaultState,
  reducers: {
    [DATA_SUGGESTED_CAMPAIGN]: (state, { payload: suggestedCampaign }) => ({
      ...state,
      suggestedCampaign,
    }),
    [DATA_SUGGESTED_CAMPAIGN_LOADING]: (state, { payload: suggestedCampaignLoading }) => ({
      ...state,
      suggestedCampaignLoading,
    }),
    [DATA_SUGGESTED_CAMPAIGN_ADS]: (state, { payload: ads }) => ({
      ...state,
      suggestedCampaign: {
        ..._.get(state, 'suggestedCampaign'),
        ads,
      },
    }),
    [DATA_SUGGESTED_CAMPAIGN_NAME]: (state, { payload: name }) => ({
      ...state,
      suggestedCampaign: {
        ..._.get(state, 'suggestedCampaign'),
        name,
      },
    }),
    [DATA_SUGGESTED_CAMPAIGN_GOAL]: (state, { payload: goal }) => ({
      ...state,
      suggestedCampaign: {
        ..._.get(state, 'suggestedCampaign'),
        goal,
      },
    }),
    [DATA_SUGGESTED_CAMPAIGN_OBJECTIVE]: (state, { payload: objective }) => ({
      ...state,
      suggestedCampaign: {
        ..._.get(state, 'suggestedCampaign'),
        objective,
      },
    }),
    [DATA_SUGGESTED_CAMPAIGN_DISPLAY_DATE]: (state, { payload: displayDate }) => ({
      ...state,
      suggestedCampaign: {
        ..._.get(state, 'suggestedCampaign'),
        displayDate,
      },
    }),
    [DATA_SUGGESTED_CAMPAIGN_DISPLAY_TIME]: (state, { payload: displayTime }) => ({
      ...state,
      suggestedCampaign: {
        ..._.get(state, 'suggestedCampaign'),
        displayTime,
      },
    }),
    [DATA_SUGGESTED_CAMPAIGN_EXPIRY_DATETIME]: (state, { payload: expiryDate }) => ({
      ...state,
      suggestedCampaign: {
        ..._.get(state, 'suggestedCampaign'),
        expiryDate,
      },
    }),
    [DATA_SUGGESTED_CAMPAIGN_QUESTIONNAIRE]: (state, { payload: questionnaireId }) => ({
      ...state,
      suggestedCampaign: {
        ..._.get(state, 'suggestedCampaign'),
        questionnaireId,
      },
    }),
    [DATA_SUGGESTED_CAMPAIGN_UPDATE_ADS]: (state, { payload }) => ({
      ...state,
      suggestedCampaign: {
        ..._.get(state, 'suggestedCampaign'),
        ads: state.suggestedCampaign.ads.map((adItem) => mergeCampaignAdItem(adItem, payload)),
      },
    }),
    [DATA_SUGGESTED_CAMPAIGN_PIXEL_EVENT_TYPE]: (state, { payload: pixelEventType }) => ({
      ...state,
      suggestedCampaign: {
        ..._.get(state, 'suggestedCampaign'),
        pixelEventType,
      },
    }),
    [DATA_SUGGESTED_CAMPAIGN_OPTIMIZATION_GOAL]: (state, { payload: optimizationGoal }) => ({
      ...state,
      suggestedCampaign: {
        ..._.get(state, 'suggestedCampaign'),
        optimizationGoal,
      },
    }),
    [DATA_SUGGESTED_CAMPAIGN_AD_TYPE]: (state, { payload: adType }) => ({
      ...state,
      suggestedCampaign: {
        ..._.get(state, 'suggestedCampaign'),
        ads: state.suggestedCampaign.ads.map((adItem) => {
          const ad = _.get(adItem, ['ad']);
          return { ...adItem, ad: { ...ad, type: adType } };
        }),
      },
    }),
    [DATA_SUGGESTED_CAMPAIGN_OVERRIDE_AUTO_AGING]: (state, { payload: overrideAutoAging }) => ({
      ...state,
      suggestedCampaign: {
        ..._.get(state, 'suggestedCampaign'),
        overrideAutoAging,
      },
    }),
    [DATA_SUGGESTED_CAMPAIGN_EVENT_ID]: (state, { payload: eventId }) => ({
      ...state,
      suggestedCampaign: {
        ..._.get(state, 'suggestedCampaign'),
        eventId,
      },
    }),
  },
};
