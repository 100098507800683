import { put } from 'redux-saga/effects';

import { getSuggestedDraftRoute } from '../../routes';
import history from '../../services/history';
import { setSuggestedCampaign, showToaster } from '../../actions';

export default function* openSuggestedCampaignDraft({ payload }) {
  const { suggestedCampaign, ads } = payload;
  yield put(setSuggestedCampaign(suggestedCampaign));

  if (ads) {
    yield put(showToaster({
      duration: 5000,
      message: 'preview_locations_updated',
      open: true,
      type: 'info',
    }));
  }

  history.push({
    pathname: getSuggestedDraftRoute(suggestedCampaign.businessId, suggestedCampaign.id),
    state: ads ? { ads } : {},
  });
}
