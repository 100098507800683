import _ from 'lodash';
import moment from 'moment';
import {
  put, all, select, call,
} from 'redux-saga/effects';
import api from '../../services/api';
import {
  reportsLoading,
  setStoreDateReports,
  setBusinessCampaigns,
} from '../../actions';
import {
  selectBusinessStores,
} from '../../selectors';
import logger from '../../services/logger';
import { sanitizeCSVValue } from '../../services/helpers';
import { EVERYTHING } from '../../constants';

export default function* getStoreDateReports({ payload }) {
  const facebookAdAccountIds = payload?.facebookAdAccountIds;
  const startDate = payload?.startDate;
  const businessInfo = payload?.businessInfo;
  const storesSelected = payload?.storesSelected;
  const origin = payload?.origin;
  const endDate = moment().startOf('day').format('YYYY-MM-DD');

  yield put(reportsLoading(true));
  yield put(setBusinessCampaigns([]));
  const stores = yield select(selectBusinessStores);

  let results = [];

  if (!_.isEmpty(facebookAdAccountIds)) {
    const storesFacebookAdAccountIds = _.filter(
      stores,
      (store) => _.find(store?.integrations, (integration) => {
        const type = integration?.type;
        const adAccountId = integration?.adAccountId;
        return type === 'facebook' && _.includes(facebookAdAccountIds, adAccountId);
      }),
    );

    results = yield all(storesFacebookAdAccountIds.map(async ({ id, name }) => {
      try {
        // TODO request to endpoint which given an ad account id retrieves all ads
        const res = await api.getStoreInsights(id);
        return {
          storeName: name,
          businessName: businessInfo?.name,
          ...res,
        };
      } catch (error) {
        logger.error({
          error,
          context: { saga: 'getStoreDateReports' },
          params: {
            storeId: id,
            startDate,
            endDate,
          },
        });
        return { id, error: true };
      }
    }));
  }

  if (!_.isEmpty(storesSelected)) {
    let adIds = [];
    if (origin === EVERYTHING) {
      yield all(storesSelected.map(async (storeSelected) => {
        const storeId = storeSelected?.id;
        const adAccountId = _.find(storeSelected?.integrations, (integration) => integration?.type === 'facebook')?.adAccountId;
        const ads = await api.getAdAccountAds(storeId, adAccountId);
        _.forEach(ads, (ad) => {
          adIds.push({ storeId, adId: ad?.id });
        });
      }));
      adIds = [...new Set(adIds.filter((item) => item))];
    } else {
      const { campaigns } = yield call(
        api.getCampaigns,
        storesSelected,
        null,
        businessInfo?.id,
        null,
      );

      _.forEach(campaigns, (campaign) => {
        _.forEach(campaign.ads, (ad) => {
          _.forEach(ad.integrations, (integration) => {
            adIds.push({
              adId: integration?.ad?.id,
              storeId: ad.storeId,
            });
          });
        });
      });
      adIds = [...new Set(adIds.filter((item) => item))];
    }

    results = _.concat(results, yield all(adIds.map(async ({ adId, storeId }) => {
      try {
        const insights = await api.getAdInsights(storeId, adId);
        return {
          storeName: _.find(stores, ({ id }) => id === storeId)?.name || '',
          businessName: businessInfo?.name,
          ...insights,
        };
      } catch (error) {
        logger.error({
          error,
          context: { saga: 'getStoreDateReports' },
          params: {
            storeId,
            startDate,
            endDate,
          },
        });
        return { storeId, error: true };
      }
    })));
  }

  results = _.filter(results, ({ date_start: dateStartReport }) => {
    const dateStartTime = moment(dateStartReport).toDate().getTime();
    const startDateTime = moment(startDate).toDate().getTime();
    return dateStartTime > startDateTime;
  });

  results = _.filter(results, (result) => !_.isNil(result?.ad_id));

  results = _.map(results, ({
    businessName,
    ad_id: adId,
    ad_name: adName,
    storeName,
    date_start: dateStart,
    date_stop: dateEnd,
    spend,
    reach,
    cpp,
    frequency,
    impressions,
    cpm,
    actions_link_click: actionsLinkClick,
    cost_per_action_type_link_click: CostPerActionTypeLinkClick,
  }) => ({
    businessName,
    adId,
    adName: sanitizeCSVValue(adName),
    storeName,
    dateStart,
    dateEnd,
    spend,
    reach,
    cpp,
    frequency,
    impressions,
    cpm,
    actionsLinkClick,
    CostPerActionTypeLinkClick,
  }));
  yield put(setStoreDateReports(results));
  yield put(reportsLoading(false));
}
