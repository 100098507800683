import { call, put } from 'redux-saga/effects';
import api from '../../services/api';
import logger from '../../services/logger';
import i18n from '../../services/i18n';
import { showToaster, closeToaster, setLoadingAsync } from '../../actions';
import { getToasterOptions } from '../helpers';

export default function* sendMailChimpTestEmail({ payload }) {
  const {
    onError,
    marketingChannelId,
    suggestedCampaignId,
    recipients,
  } = payload;

  try {
    yield put(showToaster(getToasterOptions(
      'Sending mailchimp test email...',
      'info', null, 7000, true, null, 'bottom', 'right',
    )));
    yield put(setLoadingAsync(true));
    yield call(
      api.sendMailChimpTestEmail,
      marketingChannelId,
      suggestedCampaignId,
      { recipients },
    );
    yield put(setLoadingAsync(false));
    yield put(closeToaster());
    yield put(showToaster(getToasterOptions(i18n.t('test_emails_sent'), 'info', null, 5000)));
  } catch (err) {
    logger.error({
      error: err,
      context: { saga: 'sendMailChimpTestEmail' },
      params: { marketingChannelId, suggestedCampaignId, recipients },
    });
    if (onError) onError();
    yield put(setLoadingAsync(false));
    yield put(showToaster(getToasterOptions('Error when trying to send mailchimp test email', 'error')));
  }
}
