import {
  call,
} from 'redux-saga/effects';

import api from '../../services/api';
import logger from '../../services/logger';

export default function* getGoogleCampaignsByChannelId({
  payload: {
    marketingChannelId, onSuccess, onError,
  },
}) {
  try {
    const campaigns = yield call(api.getGoogleSearchCampaigns, marketingChannelId);

    if (onSuccess) { onSuccess(campaigns?.campaigns); }

    return campaigns?.campaigns || [];
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getGoogleCampaignsByChannelId' },
      params: { marketingChannelId },
    });
    if (onError) { onError(); }
    return [];
  }
}
