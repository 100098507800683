import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
  },
  whiteVariant: {
    backgroundColor: 'white',
  },
  grayVariant: {
    backgroundColor: _.get(theme, 'custom.grayBackground.backgroundColor'),
  },
}));

const PageWrapper = ({ children, variant }) => {
  const classes = useStyles();
  return (
    <div className={classnames(classes.root, classes[`${variant}Variant`])}>
      {children}
    </div>
  );
};

PageWrapper.defaultProps = {
  variant: 'gray',
};

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['white', 'gray']),
};

export default PageWrapper;
