import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ImageCopy from '@targetable/targetable-web-framework/lib/react/components/ImageCopy/ImageCopy';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DrawerContent from '@targetable/targetable-web-framework/lib/react/containers/DrawerContent/DrawerContent';
import PageContentContainer from '@targetable/targetable-web-framework/lib/react/containers/PageContentContainer/PageContentContainer';
import ComponentLoading from '@targetable/targetable-web-framework/lib/react/components/ComponentLoading/ComponentLoading';

import { selectBusiness } from '../../../selectors';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: '100%',
    margin: `${theme.spacing(2)}px auto`,
    '& .MuiCardActionArea-root:hover': {
      backgroundColor: '#E4E6EC',
    },
    overflow: 'hidden',
  },
  imageCopyCtn: {
    '& .MuiListItemText-root': {
      position: 'relative',
      left: '-33px',
    },
    '& .MuiTypography-body2': {
      fontWeight: 500,
    },
  },
  jsonStyleWrapper: {
    overflowY: 'auto',
    height: 'calc(100vh - 150px)',
  },
  jsonStyle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    padding: '10px 10px 10px 20px',
    margin: '0 0 10px 0',
    background: '#F1F1F3',
    borderRadius: '4px',
    whiteSpace: 'pre-wrap',
  },
}));

const DetailDialog = ({
  open,
  onClose,
  activityLogSelected,
}) => {
  const classes = useStyles();
  const business = useSelector(selectBusiness);
  const activityLogTitle = activityLogSelected?.ui?.title || activityLogSelected?.meta?.title || 'N/A';
  const [activityLog, setActivityLog] = React.useState(null);
  const [activityLogLoading, setActivityLogLoading] = React.useState(false);

  useEffect(() => {
    if (!activityLog) {
      setActivityLogLoading(true);
      const getActivityLogById = async (activityLogId) => {
        const result = await api.getActivityLogById(activityLogId);
        setActivityLog(result);
        setActivityLogLoading(false);
      };

      if (activityLogSelected?.id) {
        getActivityLogById(activityLogSelected?.id);
      }
    }
  }, [activityLog, activityLogSelected?.id]);

  const handleOnClose = () => {
    setActivityLog(null);
    onClose();
  };

  return (
    <>
      <DrawerContent
        open={open}
        onClose={handleOnClose}
        title={`${business?.name} / ${activityLogTitle} / Raw Activity Log`}
        invisibleBackdrop
      >
        <PageContentContainer className={classes.pageContainer}>
          <>
            <ComponentLoading
              loading={activityLogLoading}
              waitKey="loading_detail_dialog"
            >
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                data-cy="detailDialog_container"
              >
                <Grid xs={12} className={classes.activityLogWrapper}>
                  <div className={classes.imageCopyCtn}>
                    <ImageCopy
                      icon=""
                      iconAlt=""
                      copyText={JSON.stringify(activityLog)}
                      text="Raw Activity Log"
                      variant="body2"
                    />
                  </div>
                  <div className={classes.jsonStyleWrapper}>
                    <pre className={classes.jsonStyle}>
                      {JSON.stringify(activityLog, null, 2)}
                    </pre>
                  </div>
                </Grid>
              </Grid>
            </ComponentLoading>
          </>
        </PageContentContainer>
      </DrawerContent>
    </>
  );
};

DetailDialog.defaultProps = {
  open: false,
};

DetailDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  activityLogSelected: PropTypes.shape({
    id: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      title: PropTypes.string,
    }),
    ui: PropTypes.shape({
      title: PropTypes.string,
    }),
  }).isRequired,
};

export default DetailDialog;
