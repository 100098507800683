import { put, call } from 'redux-saga/effects';

import i18n from '../../services/i18n';
import api from '../../services/api';
import {
  showToaster,
  setStoreLocation,
  setLoadingAsync,
} from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* updateStoreLocation({ payload }) {
  const { storeId, locations, onDone } = payload;
  try {
    yield put(setLoadingAsync(true));
    yield call(api.updateStore, storeId, { locations });
    yield put(setStoreLocation({ storeId, locations }));
    yield put(showToaster(getToasterOptions(i18n.t('update_store_location_success'), 'success', null, 5000)));
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to update store location', 'error')));
    logger.error({
      error: e,
      context: { saga: 'updateStoreLocation' },
      params: { payload },
    });
  }
  yield put(setLoadingAsync(false));
  if (onDone) {
    onDone();
  }
}
