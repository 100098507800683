import { put, call } from 'redux-saga/effects';
import i18n from '../../services/i18n';
import api from '../../services/api';
import { showToaster, setLoadingAsync } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* updateAdRequest({
  payload: {
    id, status, assignees, linkSuggestedMarketing, onDone,
  },
}) {
  try {
    yield put(setLoadingAsync(true));
    yield call(
      api.updateAdRequest,
      id,
      {
        status,
        assignees,
        linkSuggestedMarketing,
      },
    );
    yield put(showToaster(getToasterOptions(i18n.t('update_ad_request_status', 'Ad Request details were updated successfully!'), 'success', null, 5000)));
    yield put(setLoadingAsync(false));
    if (onDone) {
      onDone();
    }
  } catch (e) {
    yield put(showToaster(getToasterOptions(i18n.t('update_ad_request_status_error', 'An error occurred when updating the Ad Request details'), 'error')));
    yield put(setLoadingAsync(false));
    logger.error({
      error: e,
      context: { saga: 'updateAdRequest' },
      params: {
        id,
        status,
      },
    });
  }
}
