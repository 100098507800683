import { put, call, all } from 'redux-saga/effects';

import api from '../../services/api';

import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* deleteSuggestedCampaign({ payload }) {
  const { onDelete, suggestedCampaignId } = payload;
  const multipleIds = payload?.suggestedCampaignIds;
  const multipleSCs = payload?.suggestedCampaignsMkt;

  try {
    const action = suggestedCampaignId ? 'Deleting' : 'Deleting the selected Suggested Campaigns';
    yield put(showToaster(getToasterOptions(`${action}...`,
      'info', null, 7000, true, null, 'bottom', 'right')));

    // Allow deleting multiple suggested campaigns
    if (multipleIds && Array.isArray(multipleIds)) {
      const operations = multipleIds.map((id) => call(api.deleteSuggestedCampaign, id));
      yield all(operations);
    } else {
      yield call(api.deleteSuggestedCampaign, suggestedCampaignId);
    }
    // Allow deleting multiple suggested marketing campaigns
    if (multipleSCs && Array.isArray(multipleSCs)) {
      const operationsMkt = multipleSCs.map((item) => call(api.updateAdDraft, item?.id, item));
      yield all(operationsMkt);
    }

    if (onDelete) {
      onDelete();
    }
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed to delete suggested campaign draft. Try again in a few minutes.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'deleteSuggestedCampaign' },
      params: { payload },
    });
  }
}
