import {
  call, put,
} from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment';
import { MarketingChannelTypes } from '@targetable/targetable-types/dist/types/index';

import i18n from '../../services/i18n';
import api from '../../services/api';
import date from '../../services/date';
import logger from '../../services/logger';
import { showToaster } from '../../actions';
import { getToasterOptions } from '../helpers';

const ISO_DATE_FORMAT = 'YYYY-MM-DD';

/** @typedef {import('@targetable/targetable-types/dist/types/index').GenericAd} GenericAd */

/**
 * Clears out google fields from a campaign such as errors and ids.
 * @param {GenericAd} ad the original ad
 */
const resetGoogleAdFields = (ad) => {
  /** @type GenericAd */
  const adCopy = JSON.parse(JSON.stringify(ad));

  if (adCopy.google) {
    delete adCopy.google.campaign?.id;
    delete adCopy.google.campaign.error;
    delete adCopy.google.group?.id;
    delete adCopy.google.group?.error;
    delete adCopy.google.budget?.id;
    delete adCopy.google.ad?.id;
    delete adCopy.google.ad?.error;
  }

  return {
    ...adCopy,
    google: {
      ...adCopy.google,
      budget: {
        ...adCopy.google.budget,
        amount: Number(adCopy?.google?.budget?.amount),
        ...(adCopy?.google?.budget?.cpc ? {
          cpc: Number(adCopy?.google?.budget?.cpc),
        } : {}),
      },
    },
  };
};

/**
 * Clears out mailchimp fields from a campaign such as errors and ids.
 * @param {GenericAd} ad the original ad
 */
const resetMailchimpAdFields = (ad) => {
  /** @type GenericAd */
  const adCopy = JSON.parse(JSON.stringify(ad));

  if (adCopy.mailchimp) {
    delete adCopy.mailchimp.campaign?.archiveUrl;
    delete adCopy.mailchimp.campaign.name;
    delete adCopy.mailchimp.campaign?.referenceId;
  }

  return {
    ...adCopy,
  };
};

export default function* duplicateMarketingSuggestedCampaign({ payload }) {
  try {
    const { suggestedCampaign, onDone } = payload;

    yield put(showToaster(getToasterOptions('Creating a copy of the Ad...',
      'info', null, 7000, true, null, 'bottom', 'right')));

    const name = `${_.get(suggestedCampaign, 'name')} - Copy`;
    const todayDateTime = date.parseDateAndAMPMTimeToISO(
      moment().format(ISO_DATE_FORMAT), '12:00 AM',
    );

    /** @type GenericAd */
    let duplicateCampaign = {
      ..._.omit(suggestedCampaign, ['id', 'status', 'createdAt', 'updatedAt', 'feedback', 'endDate', 'publishingLastAttemptAt', 'publishingRetryCount']),
      status: 'draft',
      createdBy: 'admin', // <== otherwise we can't edit copies of user created ads
      withIssues: false,
      name,
      startDate: todayDateTime,
      smartFeed: {
        ...suggestedCampaign?.smartFeed,
        displayDate: todayDateTime,
        expiryDate: undefined,
      },
    };

    // Remove generic errors
    delete duplicateCampaign.configError;

    // Reset fields for google type ads
    if (duplicateCampaign.type === MarketingChannelTypes.Google) {
      duplicateCampaign = resetGoogleAdFields(duplicateCampaign);
    } else {
      duplicateCampaign = resetMailchimpAdFields(duplicateCampaign);
    }

    // const businessId = yield select(selectBusinessIdQueried);
    const createdSuggestedCampaign = yield call(api.createAdDraft, duplicateCampaign);

    // Unnecessary call because getting from algolia
    // yield fork(getSuggestedCampaignsByBusiness, { payload: { businessId } });
    if (onDone) {
      onDone(createdSuggestedCampaign);
    }

    yield put(showToaster(getToasterOptions(i18n.t('campaigns_duplicate_created', { name }), 'info', null, 5000)));
  } catch (e) {
    yield put(showToaster(getToasterOptions('error_update_campaign')));
    logger.error({
      error: e,
      context: { saga: 'duplicateMarketingSuggestedCampaign' },
      params: { payload },
    });
  }
}
