import { call, put } from 'redux-saga/effects';
import _ from 'lodash';

import api from '../../services/api';

import { setManagedLandingPages, showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

function buildManagedlandingPageList(landingPages) {
  let list = '';
  landingPages.forEach((page) => {
    if (page.urlError || page.thumbnailError) {
      list += `- ${page.name}\n`;
    }
  });
  return list;
}

export default function* saveManagedLandingPages({ payload }) {
  const {
    businessId, landingPages, onError, onDone,
  } = payload;

  try {
    const cpLandingPages = _.cloneDeep(landingPages);

    // check urls
    const validationResults = yield Promise.all(cpLandingPages.map(
      async (cpLandingPage) => {
        const isValidUrlProm = api.siteExists(cpLandingPage.url);
        const isValidThumbnailProm = api.siteExists(cpLandingPage.thumbnail);
        const [isValidUrl, isValidThumbnail] = await Promise.all([
          isValidUrlProm,
          isValidThumbnailProm,
        ]);

        // eslint-disable-next-line no-param-reassign
        cpLandingPage.urlError = !isValidUrl;
        // eslint-disable-next-line no-param-reassign
        cpLandingPage.thumbnailError = !isValidThumbnail;
        return isValidUrl && isValidThumbnail;
      },
    ));

    if (!_.every(validationResults)) {
      yield put(setManagedLandingPages(cpLandingPages));
      if (onError) onError(cpLandingPages);
      yield put(showToaster(getToasterOptions(
        'managed_landing_pages_with_issues',
        'error',
        null,
        10000,
        true,
        null,
        'top',
        'center',
        { list: buildManagedlandingPageList(cpLandingPages) },
      )));

      return;
    }

    yield call(
      api.updateBusiness,
      businessId,
      {
        managedLandingPages: _.map(cpLandingPages, ({ name, url, thumbnail }) => ({
          name, url, thumbnail, vendor: 'paperform',
        })),
      },
    );

    if (onDone) onDone(cpLandingPages);
  } catch (e) {
    if (onError) onError();
    yield put(showToaster(getToasterOptions('Failed saving Managed Lading Pages data. Try again in a few minutes.', 'error', null, 5000)));

    logger.error({
      error: e,
      context: { saga: 'saveManagedLandingPages' },
      params: {
        businessId, landingPages,
      },
    });
  }
}
