import React from 'react';
import PropTypes from 'prop-types';

const ErrorPage = ({ error }) => (
  <div>
    {`An error has occurred: ${error.message}`}
  </div>
);

ErrorPage.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
};

export default ErrorPage;
