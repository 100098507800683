import _ from 'lodash';
import { put, call } from 'redux-saga/effects';
import i18n from '../../services/i18n';
import api from '../../services/api';
import { setBusinessStores, showToaster, setLoadingAsync } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* updatePhoneNumber({
  payload: {
    storeId, businessId, phoneNumber, onDone,
  },
}) {
  try {
    yield put(setLoadingAsync(true));
    yield call(
      api.updateStore,
      storeId,
      {
        phoneNumber,
      },
    );
    let stores = yield call(api.getStores, businessId);
    stores = _.sortBy(stores, 'name');
    yield put(setBusinessStores(stores));
    yield put(showToaster(getToasterOptions(i18n.t('update_store_phone_number', 'Phone number was updated successfully!'), 'success', null, 5000)));
    yield put(setLoadingAsync(false));
    onDone();
  } catch (e) {
    yield put(showToaster(getToasterOptions(i18n.t('update_store_phone_number_error', 'An error occurred updating store phone number'), 'error')));
    yield put(setLoadingAsync(false));
    logger.error({
      error: e,
      context: { saga: 'updateStore' },
      params: {
        storeId,
        businessId,
        phoneNumber,
      },
    });
  }
}
