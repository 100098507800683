import _ from 'lodash';
import moment from 'moment';
import { call } from 'redux-saga/effects';
import {
  removeTargetingPropsFromAds,
} from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/campaign/campaign';
import { CAMPAIGN_DRAFT, CAMPAIGN_SUBMITTED } from '@targetable/targetable-web-framework/lib/react/constants';

import api from '../../services/api';
import logger from '../../services/logger';
import {
  CAMPAIGN_SUBMITTED_WITH_ISSUES,
  CAMPAIGN_SUBMITTED_FAILED,
} from '../../constants';

export default function* saveCampaign(campaign, isExtendRetry) {
  const clonedCampaign = _.cloneDeep(campaign);

  // clean up campaign targeting
  if (clonedCampaign.goal === 'hiring') {
    clonedCampaign.ads = removeTargetingPropsFromAds(clonedCampaign.ads, ['minAge', 'maxAge', 'gender']);
  }

  try {
    let updatedCampaign;
    if (!campaign.id) {
      const savedCampaign = yield call(
        api.createCampaign,
        { ...campaign, created: moment.utc().valueOf() },
      );
      updatedCampaign = { ...savedCampaign, ...campaign };
    } else {
      /**
       * Keep the integrations object when updating the campaing
       * because this is an edition of the campaign after try publishing
       */
      const keepCurrentIntegrations = [
        CAMPAIGN_SUBMITTED_FAILED,
        CAMPAIGN_SUBMITTED_WITH_ISSUES,
      ].includes(campaign.status);
      updatedCampaign = {
        ...campaign,
        ...(keepCurrentIntegrations && !isExtendRetry && { keepCurrentIntegrations: true }),
        ...(campaign.status === CAMPAIGN_SUBMITTED_FAILED && { status: CAMPAIGN_DRAFT }),
        ...(campaign.status === CAMPAIGN_SUBMITTED_WITH_ISSUES
          && !isExtendRetry && { status: CAMPAIGN_SUBMITTED }),
      };
      yield call(api.updateCampaign, campaign.id, updatedCampaign);
    }

    return updatedCampaign;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'saveCampaign' },
    });
    return false;
  }
}
