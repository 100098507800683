import _ from 'lodash';
import {
  call, fork, put, select,
} from 'redux-saga/effects';
import { AdStatus } from '@targetable/targetable-types/dist/types/ad-service/types';

import api from '../../services/api';
import logger from '../../services/logger';
import { setBusinessCampaigns, setBusinessCampaignsLoading } from '../../actions';
import {
  selectBusinessStores,
} from '../../selectors';
import getReportsForCampaigns from '../getReportsForCampaigns/getReportsForCampaigns';

export default function* getCampaignsByStoreAndStatus({ payload }) {
  yield put(setBusinessCampaigns([]));

  const stores = yield select(selectBusinessStores);

  // #TGT-6317 - due to unnecessary call that stops the process
  // if (_.isEmpty(stores)) {
  //   yield take(DATA_BUSINESS_STORES);
  //   stores = yield select(selectBusinessStores);
  // }

  let campaigns = [];
  yield put(setBusinessCampaignsLoading(true));
  try {
    ({ campaigns } = yield call(
      api.getCampaigns,
      stores,
      payload.status,
      _.get(payload, 'businessId'),
      null,
      payload.tab,
      null,
      true,
    ));
  } catch (e) {
    yield put(setBusinessCampaignsLoading(false));
    logger.error({
      error: e,
      context: { saga: 'getCampaignsByStoreAndStatus - getCampaigns' },
      params: {
        businessId: _.get(payload, 'businessId'),
        status: payload.status,
        stores,
        tab: payload.tab,
      },
    });
  }

  const skipStatuses = [AdStatus.Draft, AdStatus.Failed];
  const statuses = payload?.status?.split(',');
  const skipReportsLoading = statuses.some((status) => skipStatuses.includes(status));
  if (!skipReportsLoading) {
    yield fork(getReportsForCampaigns, campaigns);
  }

  yield put(setBusinessCampaigns(campaigns));
  yield put(setBusinessCampaignsLoading(false));
}
