import globals from './globals';

export const getHomeRoute = () => '/';

export const getCallbackRoute = () => '/callback';

export const getCampaignDetailRoute = (businessId = ':businessId', campaignId = ':campaignId', tab) => `/businesses/${businessId}/campaigns/${campaignId}/detail${tab ? `?tab=${tab}` : ''}`;

export const getBusinessRoute = (businessId = ':businessId', tab, subTab) => `/businesses/${businessId}${tab ? `?tab=${tab}` : ''}${subTab ? `&subtab=${subTab}` : ''}`;
export const getInsightDraftRoute = (businessId = ':businessId', insightId = ':insightId') => `/businesses/${businessId}/insights/${insightId}`;
export const getInsightDetailRoute = (businessId = ':businessId', insightId = ':insightId') => `/businesses/${businessId}/insights/${insightId}/detail`;

export const getDashboardRoute = () => '/dashboard';
export const getSettingsRoute = () => '/settings';
export const getBusinessesRoute = () => '/businesses';
export const getCampaignsRoute = () => '/campaigns';
export const getMarketingRequestsRoute = () => '/marketing-requests';
export const getMarketingEventsRoute = () => '/marketing-events';
export const getUsersRoute = () => '/users';
export const getStripeProductsRoute = () => '/stripe/products';

export const getSuggestedDraftRoute = (businessId = ':businessId', campaignId = ':campaignId') => `/businesses/${businessId}/campaigns/suggested/${campaignId}`;
export const getSuggestedDetailRoute = (businessId = ':businessId', campaignId = ':campaignId') => `/businesses/${businessId}/campaigns/suggested/${campaignId}/detail`;

export const getInsightTemplatesRoute = () => '/insights/templates';
export const getInsightTemplateDraftRoute = (insightTemplateId = ':insightTemplateId') => `/insights/templates/${insightTemplateId}`;
export const getInsightTemplatePreviewRoute = (insightTemplateId = ':insightTemplateId') => `/insights/templates/preview/${insightTemplateId}`;

export const getQuestionnairesRoute = () => '/questionnaires';
export const getSCTemplatesRoute = () => '/campaigns/suggested/templates';
export const getSCTemplateDraftRoute = (scTemplateId = ':scTemplateId') => `/campaigns/suggested/templates/${scTemplateId}`;

export const getStripeProductUrl = (prodId) => `https://dashboard.stripe.com/${globals.ENVIRONMENT_NAME !== 'prod' ? 'test/' : ''}products/${encodeURIComponent(prodId)}`;
export const getRichProfileFieldsRoute = () => '/rich-profile-fields';

export const getMediaLibraryRoute = (businessId = ':businessId') => `businesses/${businessId}?tab=media_library`;
