import _ from 'lodash';
import {
  DATA_BUSINESS,
  DATA_BUSINESS_ID_QUERIED,
  DATA_BUSINESS_CAMPAIGNS,
  DATA_BUSINESS_CAMPAIGNS_LOADING,
  DATA_BUSINESS_LOADING,
  DATA_BUSINESS_INSIGHTS,
  DATA_BUSINESS_INSIGHTS_LOADING,
  DATA_BUSINESS_STORES,
  DATA_BUSINESS_STORES_LOADING,
  DATA_BUSINESS_PIXELS,
  DATA_BUSINESS_PIXELS_LOADING,
  DATA_BUSINESS_SUGGESTED_CAMPAIGNS,
  DATA_BUSINESS_SUGGESTED_CAMPAIGNS_LOADING,
  DATA_BUSINESS_USERS,
  DATA_BUSINESS_USERS_LOADING,
  SET_STORE_LOCATION,
  SET_STORE_STATUS,
  SET_STORE_FACEBOOK_LOCATION,
  SET_USER_EMAIL,
  DATA_BUSINESS_MANAGED_LANDING_PAGES,
  SET_ALL_PERMISSIONS,
  DATA_USERS_PERMISSIONS_LOADING,
  DATA_BUSINESS_VERTICALS,
  DATA_BUSINESS_LEAD_FORMS,
} from '../constants';

const defaultState = {
  business: null,
  businessIdQueried: null,
  businessLoading: false,
  campaigns: [],
  campaignsLoading: true,
  insights: [],
  insightsLoading: true,
  stores: [],
  storesLoading: true,
  suggestedCampaigns: [],
  suggestedCampaignsLoading: true,
  users: [],
  usersLoading: true,
  pixels: [],
  pixelsLoading: false,
  permissions: [],
  permissionsLoading: false,
};

export default {
  defaultState,
  reducers: {
    [DATA_BUSINESS]: (state, { payload: business }) => ({ ...state, business }),
    [DATA_BUSINESS_ID_QUERIED]: (state, { payload: businessIdQueried }) => ({
      ...state,
      businessIdQueried,
    }),
    [DATA_BUSINESS_LOADING]: (state, { payload: businessLoading }) => ({
      ...state,
      businessLoading,
    }),
    [DATA_BUSINESS_CAMPAIGNS]: (state, { payload: campaigns }) => ({ ...state, campaigns }),
    [DATA_BUSINESS_CAMPAIGNS_LOADING]: (state, { payload: campaignsLoading }) => ({
      ...state,
      campaignsLoading,
    }),
    [DATA_BUSINESS_INSIGHTS]: (state, { payload: insights }) => ({ ...state, insights }),
    [DATA_BUSINESS_INSIGHTS_LOADING]: (state, { payload: insightsLoading }) => ({
      ...state,
      insightsLoading,
    }),
    [DATA_BUSINESS_STORES]: (state, { payload: stores }) => ({ ...state, stores }),
    [DATA_BUSINESS_STORES_LOADING]: (state, { payload: storesLoading }) => ({
      ...state,
      storesLoading,
    }),
    [DATA_BUSINESS_SUGGESTED_CAMPAIGNS]: (state, { payload: suggestedCampaigns }) => ({
      ...state, suggestedCampaigns,
    }),
    [DATA_BUSINESS_SUGGESTED_CAMPAIGNS_LOADING]: (state,
      { payload: suggestedCampaignsLoading }) => ({
      ...state,
      suggestedCampaignsLoading,
    }),
    [DATA_BUSINESS_USERS]: (state, { payload: users }) => ({ ...state, users }),
    [DATA_BUSINESS_USERS_LOADING]: (state, { payload: usersLoading }) => ({
      ...state,
      usersLoading,
    }),
    [DATA_BUSINESS_PIXELS]: (state, { payload: pixels }) => ({ ...state, pixels }),
    [DATA_BUSINESS_PIXELS_LOADING]: (state, { payload: pixelsLoading }) => ({
      ...state,
      pixelsLoading,
    }),
    [SET_STORE_LOCATION]: (state, { payload: { storeId, locations } }) => ({
      ...state,
      stores: state.stores.map((store) => {
        if (store.id === storeId) {
          return {
            ...store,
            locations,
          };
        }
        return store;
      }),
    }),
    [SET_STORE_STATUS]: (state, { payload: { storeId, disabled } }) => ({
      ...state,
      stores: state.stores.map((store) => {
        if (store.id === storeId) {
          return {
            ...store,
            disabled,
          };
        }
        return store;
      }),
    }),
    [SET_STORE_FACEBOOK_LOCATION]: (state, { payload: { storeId, location, pageId } }) => ({
      ...state,
      stores: state.stores.map((store) => {
        if (store.id === storeId && _.has(store, 'integrations')) {
          return {
            ...store,
            integrations: store.integrations.map((integration) => {
              if (integration.pageId === pageId) {
                return {
                  ...integration,
                  location,
                };
              }
              return integration;
            }),
          };
        }
        return store;
      }),
    }),
    [SET_USER_EMAIL]: (state, { payload: { userId, email } }) => ({
      ...state,
      users: state.users.map((user) => {
        if (user.id === userId) {
          return {
            ...user,
            email,
          };
        }
        return user;
      }),
    }),
    [DATA_BUSINESS_MANAGED_LANDING_PAGES]: (state, { payload: managedLandingPages }) => ({
      ...state,
      business: {
        ...state.business,
        managedLandingPages,
      },
    }),
    [DATA_BUSINESS_VERTICALS]: (state, { payload: vertical }) => ({
      ...state,
      business: {
        ...state.business,
        vertical,
      },
    }),
    [SET_ALL_PERMISSIONS]: (state, { payload: permissions }) => ({
      ...state,
      permissions,
    }),
    [DATA_USERS_PERMISSIONS_LOADING]: (state, { payload: permissionsLoading }) => ({
      ...state,
      permissionsLoading,
    }),
    [DATA_BUSINESS_LEAD_FORMS]: (state, { payload: leadForms }) => ({
      ...state,
      leadForms,
    }),
  },
};
